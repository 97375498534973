import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useUser } from "../../context/user";

interface TutorialPromptProps {
  open: boolean;
  onAccept: () => void;
  onDecline: () => void;
  tutorialKey?: string;
}

export const TutorialPrompt: React.FC<TutorialPromptProps> = ({
  open,
  onAccept,
  onDecline,
  tutorialKey = "default",
}) => {
  const theme = useTheme();
  const { subjectLanguage } = useUser();

  const getPromptTitle = () => {
    if (tutorialKey === "schedule-view") {
      return "Welcome to your Appointment Calendar!";
    }
    return "Looks like this is your first time here!";
  };

  const getPromptDescription = () => {
    if (tutorialKey === "schedule-view") {
      return `Would you like a quick tour of how to schedule and manage ${subjectLanguage} appointments?`;
    }
    return `Would you like to see a quick tutorial of how to use JotPsych to record a ${subjectLanguage} encounter and generate an encounter note?`;
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          p: 2,
          border: 1,
          borderRadius: "0.75rem",
          borderColor: "borderColors.primary",
          animation: "float 2s ease-in-out infinite",
          "@keyframes float": {
            "0%, 100%": {
              transform: "translateY(0)",
            },
            "50%": {
              transform: "translateY(-3px)",
            },
          },
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 2 }}>
        <Typography variant="h6">{getPromptTitle()}</Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 0, mb: 2 }}>
        <Typography>{getPromptDescription()}</Typography>
        <Typography sx={{ mt: 2 }}>
          This should take less than 1 minute!
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 0, gap: 1 }}>
        <Button onClick={onDecline} color="inherit">
          Skip
        </Button>
        <Button onClick={onAccept} variant="contained" autoFocus>
          Show Tutorial
        </Button>
      </DialogActions>
    </Dialog>
  );
};
