/**
 * MarkdownImporter Component
 *
 * A reusable component for importing text or files and converting them to markdown format.
 * This component provides a modal with two import options:
 * 1. Drag and drop file upload
 * 2. Text input paste
 *
 * The component handles:
 * - File reading
 * - API communication for conversion
 * - Polling for async job status
 * - Progress indication
 * - Success/error states
 *
 * Example usage:
 * ```jsx
 * <MarkdownImporter
 *   isOpen={isModalOpen}
 *   onClose={() => setIsModalOpen(false)}
 *   onImportSuccess={(markdown) => handleImportedMarkdown(markdown)}
 *   endpoint="/api/convert-to-markdown"
 *   additionalParams={{ context_id: "123" }}
 *   title="Import Document"
 *   description="Import your document as markdown"
 * />
 * ```
 *
 * Required API endpoints:
 * - POST to `endpoint` - Starts the conversion process
 * - GET to `${endpoint}/getAsyncResultsSession` - Checks the status of the async job
 */

import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Alert,
  Modal,
  TextField,
  Divider,
  Fade,
  Backdrop,
} from "@mui/material";
import {
  UploadFile as UploadFileIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { AnimatedCheckmark } from "../SVGIcon";
import ThinkingAnimation from "../Common/ThinkingAnimation";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { v4 as uuidv4 } from "uuid";

// Styled components
const DropZone = styled(Box)(({ theme }) => ({
  border: `2px dashed ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(3),
  textAlign: "center",
  backgroundColor: theme.palette.background.default,
  cursor: "pointer",
  transition: "border-color 0.3s, background-color 0.3s",
  marginBottom: theme.spacing(2),
  "&:hover": {
    borderColor: theme.palette.primary.main,
    backgroundColor: theme.palette.action.hover,
  },
}));

const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 600,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  outline: "none",
}));

// Import status types
type ImportStatus = "idle" | "importing" | "success" | "error";

interface MarkdownImporterProps {
  // Whether the modal is open
  isOpen: boolean;
  // Function to close the modal
  onClose: () => void;
  // Function to call with the generated markdown when import is successful
  onImportSuccess: (markdown: string) => void;
  // The API endpoint to call for converting content to markdown
  endpoint: string;
  // Optional custom field name for the content in the API request
  contentFieldName?: string;
  // Optional additional parameters to include in the API request
  additionalParams?: Record<string, any>;
  // Optional title for the modal
  title?: string;
  // Optional description for the modal
  description?: string;
  // Optional list of accepted file types
  acceptedFileTypes?: string;
  // Optional warning text to display
  warningText?: string;
}

const MarkdownImporter: React.FC<MarkdownImporterProps> = ({
  isOpen,
  onClose,
  onImportSuccess,
  endpoint,
  contentFieldName = "text",
  additionalParams = {},
  title = "Import Content",
  description = "Paste text or drag a file to import content. We'll convert it to markdown format.",
  acceptedFileTypes = ".pdf,.docx,.jpg,.jpeg,.png",
  warningText,
}) => {
  // Get access token from user context
  const { getAccessToken } = useUser();

  // Simple state management using useState hooks
  const [importStatus, setImportStatus] = useState<ImportStatus>("idle");
  const [importText, setImportText] = useState("");
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [progress, setProgress] = useState(0);

  // References
  const fileInputRef = useRef<HTMLInputElement>(null);
  const pollingRef = useRef<NodeJS.Timeout | null>(null);

  // Clean up polling on close or unmount
  const cleanupPolling = () => {
    if (pollingRef.current) {
      clearInterval(pollingRef.current);
      pollingRef.current = null;
    }
  };

  // Handle modal close
  const handleClose = () => {
    if (importStatus !== "importing") {
      cleanupPolling();
      setImportStatus("idle");
      setImportText("");
      setError(null);
      setProgress(0);
      onClose();
    }
  };

  // Reset state for a new import
  const resetState = () => {
    setImportStatus("idle");
    setImportText("");
    setError(null);
    setProgress(0);
    cleanupPolling();
  };

  // Drag and drop handlers
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isDragging) {
      setIsDragging(true);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleFileUpload(files[0]);
    }
  };

  const handleClickDropZone = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      handleFileUpload(files[0]);
    }
  };

  // Poll for the status of an asynchronous import task
  const startPolling = async (taskId: string) => {
    try {
      const accessToken = await getAccessToken();

      pollingRef.current = setInterval(async () => {
        try {
          const response = await APIService.makeAPIGetRequest({
            requestString: `/treatment_plan/getAsyncResultsSession`,
            params: { async_task_id: taskId },
            accessToken: accessToken!,
          });

          if (!response.ok) {
            throw new Error(
              `Failed to check import status: ${response.error?.message}`
            );
          }

          const result = response.value;

          if (result.status === "pending" || result.status === "processing") {
            // Simulate progress updates
            setProgress((prev) => Math.min(prev + 10, 90));
          } else if (result.status === "completed" && result.data) {
            // Stop polling
            cleanupPolling();

            // Update with success
            setProgress(100);
            setImportStatus("success");

            // Call the success callback with the markdown text
            onImportSuccess(result.data.markdown_text);

            // Close modal after a delay
            setTimeout(handleClose, 1500);
          } else if (result.status === "error" && result.data) {
            // Handle specific error types
            cleanupPolling();
            setImportStatus("error");

            // Check if there's a specific error message from the API
            if (result.data.error) {
              setError(result.data.error);
            } else {
              setError("Import failed. Please try again.");
            }
          } else {
            // Handle generic failure
            cleanupPolling();
            setImportStatus("error");
            setError("Import failed. Please try again.");
          }
        } catch (err) {
          console.error("Error checking import status:", err);
          cleanupPolling();
          setImportStatus("error");
          setError("Failed to check import status. Please try again.");
        }
      }, 3000);
    } catch (err) {
      console.error("Error getting access token:", err);
      setImportStatus("error");
      setError("Failed to authenticate. Please try again.");
    }
  };

  // Handle file upload
  const handleFileUpload = async (file: File) => {
    try {
      setImportStatus("importing");
      setProgress(10);
      setError(null);

      // Get access token
      const accessToken = await getAccessToken();

      // Generate task ID
      const taskId = `import-${uuidv4()}`;

      // Step 1: Get the presigned URL for S3 upload
      const getUrlResponse = await APIService.makeAPIPostRequest({
        requestString: `/treatment_plan/getFileUploadUrl`,
        accessToken: accessToken!,
        body: {
          content_type: file.type || "application/octet-stream",
          expiration: 3600,
        },
      });

      if (!getUrlResponse.ok) {
        throw new Error(
          `Failed to get upload URL: ${getUrlResponse.error?.message}`
        );
      }

      const { upload_url, s3_object_id } = getUrlResponse.value;
      setProgress(30);

      // Step 2: Upload the file to S3 using the presigned URL
      const uploadResponse = await fetch(upload_url, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type || "application/octet-stream",
        },
      });

      if (!uploadResponse.ok) {
        throw new Error(
          `Failed to upload file to S3: ${uploadResponse.statusText}`
        );
      }

      setProgress(50);

      // Step 3: Call the conversion endpoint with the s3_object_id
      const conversionResponse = await APIService.makeAPIPostRequest({
        requestString: `/treatment_plan/convertFileToMarkdown`,
        accessToken: accessToken!,
        body: {
          s3_object_id,
          file_type: file.type || "application/octet-stream",
          async_task_id: taskId,
        },
      });

      if (!conversionResponse.ok) {
        throw new Error(
          `Failed to start conversion: ${conversionResponse.error?.message}`
        );
      }

      // Start polling for results
      startPolling(taskId);
    } catch (err) {
      console.error("Error uploading file:", err);
      setImportStatus("error");
      setError(
        `Failed to upload file: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  // Handle text import
  const handleTextImport = async () => {
    if (!importText.trim()) {
      setError("Please enter some content to import.");
      return;
    }

    try {
      setImportStatus("importing");
      setProgress(10);
      setError(null);

      // Get access token
      const accessToken = await getAccessToken();

      // Generate task ID
      const taskId = `import-${uuidv4()}`;

      // Build the request body
      const body = {
        [contentFieldName]: importText,
        async_task_id: taskId,
        ...additionalParams,
      };

      // Make the API call
      const response = await APIService.makeAPIPostRequest({
        requestString: `/treatment_plan/convertTextToMarkdown`,
        accessToken: accessToken!,
        body,
      });

      if (!response.ok) {
        throw new Error(`Failed to start import: ${response.error?.message}`);
      }

      // Start polling for results
      startPolling(taskId);
    } catch (err) {
      console.error("Error importing text:", err);
      setImportStatus("error");
      setError(
        `Failed to import text: ${
          err instanceof Error ? err.message : String(err)
        }`
      );
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={importStatus !== "importing" ? handleClose : undefined}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <ModalContainer>
          {warningText && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              {warningText}
            </Alert>
          )}

          <Typography variant="h6" component="h2" gutterBottom>
            {title}
          </Typography>

          <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
            {description}
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              {error}
            </Alert>
          )}

          {importStatus === "importing" || importStatus === "success" ? (
            <Box sx={{ textAlign: "center", py: 4 }}>
              {importStatus === "success" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 2,
                  }}
                >
                  <AnimatedCheckmark />
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    Import successful!
                  </Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress size={60} sx={{ mb: 2 }} />
                  <ThinkingAnimation
                    words={[
                      "Importing",
                      "Processing",
                      "Analyzing",
                      "Converting",
                      "Writing",
                      "Scraping",
                      "Formatting",
                    ]}
                  />
                </Box>
              )}
            </Box>
          ) : (
            <>
              <DropZone
                sx={{
                  borderColor: isDragging ? "primary.main" : "divider",
                  backgroundColor: isDragging
                    ? "action.hover"
                    : "background.default",
                }}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onClick={handleClickDropZone}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                  accept={acceptedFileTypes}
                />
                <UploadFileIcon
                  sx={{ fontSize: 40, color: "text.secondary", mb: 1 }}
                />
                <Typography variant="body1" gutterBottom>
                  Drag & drop a file here
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  or click to select a file
                </Typography>
              </DropZone>

              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" color="text.secondary">
                  OR
                </Typography>
              </Divider>

              <TextField
                label="Paste content here"
                multiline
                rows={6}
                fullWidth
                variant="outlined"
                value={importText}
                onChange={(e) => setImportText(e.target.value)}
                sx={{ mb: 3 }}
              />

              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleTextImport}
                  disabled={!importText.trim()}
                >
                  Import Text
                </Button>
              </Box>
            </>
          )}
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

export default MarkdownImporter;
