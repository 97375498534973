import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Tooltip,
  styled,
} from "@mui/material";
import { ProviderBillingInfo } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import InfoIcon from "@mui/icons-material/Info";
import { MuiTelInput } from "mui-tel-input";

// Extend ProviderBillingInfo to include email_address
interface ExtendedProviderBillingInfo extends ProviderBillingInfo {
  email_address?: string;
}

// Define UserInfo interface to match the structure in userState
interface UserInfo {
  firstName?: string;
  lastName?: string;
  email?: string;
  mobile_phone_num?: string;
  name?: string;
  [key: string]: any; // Allow for other properties
}

interface ProviderInformationViewProps {
  highlightedFields?: string[];
  onProviderSave?: (updatedProvider: ExtendedProviderBillingInfo) => void;
  fieldKeys?: string[]; // Add field filtering capability
  paperProps?: React.ComponentProps<typeof Paper>; // For customizing the Paper component
  initialProviderInfo?: Partial<ExtendedProviderBillingInfo>; // Add initialProviderInfo prop
}

const HighlightedTextField = styled(TextField)<{ ishighlighted: string }>(
  ({ theme, ishighlighted }) => ({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor:
          ishighlighted === "true" ? theme.palette.primary.main : "inherit",
        borderWidth: 2,
      },
    },
  })
);

const ProviderInformationView: React.FC<ProviderInformationViewProps> = ({
  highlightedFields = [],
  onProviderSave,
  fieldKeys,
  paperProps = {},
  initialProviderInfo = {},
}) => {
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" as "success" | "error",
  });

  const [providerInfo, setProviderInfo] = useState<ExtendedProviderBillingInfo>(
    {
      first_name: "",
      last_name: "",
      npi_number: "",
      billing_npi_number: "",
      facility_address: "",
      facility_address2: "",
      city: "",
      state: "",
      tax_id: "",
      zip: "",
      phone: "",
      email_address: "", // Add email field
      tax_id_type: "",
      accept_insurance_payment_as_full: "",
      place_of_service: "",
      communications_name: "",
      ...initialProviderInfo, // Apply initial values passed from props
    }
  );

  const { getAccessToken, userState } = useUser();

  useEffect(() => {
    fetchProviderInfo();
  }, [getAccessToken]);

  // Update when initialProviderInfo changes
  useEffect(() => {
    if (initialProviderInfo && Object.keys(initialProviderInfo).length > 0) {
      setProviderInfo((prev) => ({
        ...prev,
        ...initialProviderInfo,
      }));
    }
  }, [initialProviderInfo]);

  const fetchProviderInfo = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/user/getProviderBillingInfo`,
        accessToken,
      });

      console.log("Provider Info Response:", response);
      if (response.ok) {
        const data = response.value.provider_billing_info || {};

        // Get user info for auto-population
        const userInfo: UserInfo = userState?.userInfo || {};

        // Merge data from provider_billing_info and user_info
        // Prefer provider_billing_info values if they exist
        const mergedData = {
          // Auto-populate from user_info if not in provider_billing_info
          first_name: data.first_name || userInfo.firstName || "",
          last_name: data.last_name || userInfo.lastName || "",
          email_address: data.email_address || userInfo.email || "",
          phone: data.phone || userInfo.mobile_phone_num || "",
          communications_name:
            data.communications_name ||
            `${userInfo.firstName || ""} ${userInfo.lastName || ""}`.trim() ||
            "",

          // The rest of provider data
          ...data,

          // Apply initialProviderInfo values with highest priority
          ...initialProviderInfo,
        };

        setProviderInfo((prev) => ({
          ...prev,
          ...mergedData,
        }));
      } else {
        throw new Error("Failed to fetch provider information");
      }
    } catch (error) {
      console.error("Error fetching provider information:", error);
      setSnackbar({
        open: true,
        message: "Failed to load provider data",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target as
      | HTMLInputElement
      | { name: string; value: string };
    setProviderInfo((prev) => ({
      ...prev,
      [name]: value,
    }));
    setHasChanges(true);
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setProviderInfo((prev) => ({
      ...prev,
      [name]: value as string,
    }));
    setHasChanges(true);
  };

  const handlePhoneChange = (newValue: string) => {
    setProviderInfo((prev) => ({
      ...prev,
      phone: newValue,
    }));
    setHasChanges(true);
  };

  const handleSave = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/updateProviderBillingInfo",
        accessToken,
        body: {
          updated_fields: providerInfo,
        },
      });

      if (response.ok) {
        setSnackbar({
          open: true,
          message: "Provider information updated successfully",
          severity: "success",
        });
        setHasChanges(false);
        if (onProviderSave) onProviderSave(providerInfo);
      } else {
        throw new Error("Failed to update provider information");
      }
    } catch (error) {
      console.error("Error updating provider information:", error);
      setSnackbar({
        open: true,
        message: "Failed to update provider information",
        severity: "error",
      });
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Define all available fields
  const allFields = [
    { key: "first_name", label: "First Name", type: "text" },
    { key: "last_name", label: "Last Name", type: "text" },
    {
      key: "communications_name",
      label: "Communications Name",
      type: "text",
      tooltip: "This name will be shown in communications sent to patients",
    },
    { key: "email_address", label: "Email Address", type: "email" },
    { key: "phone", label: "Phone", type: "phone" },
    { key: "npi_number", label: "Rendering NPI Number", type: "number" },
    {
      key: "billing_npi_number",
      label: "Billing NPI Number",
      type: "number",
    },
    { key: "facility_address", label: "Facility Address", type: "text" },
    {
      key: "facility_address2",
      label: "Facility Address 2",
      type: "text",
    },
    { key: "city", label: "City", type: "text" },
    { key: "state", label: "State", type: "text" },
    { key: "zip", label: "Zip", type: "text" },
    { key: "tax_id", label: "Tax ID", type: "text" },
    { key: "tax_id_type", label: "Tax ID Type", type: "select" },
    {
      key: "accept_insurance_payment_as_full",
      label: "Accept Insurance Payment as Full",
      type: "select",
    },
    { key: "place_of_service", label: "Place of Service", type: "select" },
  ];

  // Filter fields if fieldKeys is provided
  const fieldsToShow = fieldKeys
    ? allFields.filter((field) => fieldKeys.includes(field.key))
    : allFields;

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 2, ...paperProps.sx }} {...paperProps}>
      <Typography variant="h6" gutterBottom>
        Provider Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {fieldsToShow.map((field) => {
          // Handle different field types
          if (field.type === "phone") {
            return (
              <Box key={field.key} sx={{ flexGrow: 1, minWidth: "150px" }}>
                <MuiTelInput
                  label={field.label}
                  value={providerInfo.phone || ""}
                  onChange={handlePhoneChange}
                  defaultCountry="US"
                  forceCallingCode
                  onlyCountries={["US", "CA", "GB", "AU", "NZ", "ZA", "MX"]}
                  size="small"
                  sx={{
                    flexGrow: 1,
                    "& input": {
                      height: "1.4375em",
                    },
                    ...(highlightedFields.includes(field.key) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                />
                {field.tooltip && (
                  <Tooltip title={field.tooltip} placement="top">
                    <InfoIcon
                      sx={{
                        fontSize: 20,
                        color: "action.active",
                        ml: 1,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            );
          } else if (field.type === "select") {
            if (field.key === "tax_id_type") {
              return (
                <Box key={field.key} sx={{ flexGrow: 1, minWidth: "150px" }}>
                  <FormControl
                    fullWidth
                    sx={{
                      ...(highlightedFields.includes(field.key) && {
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "primary.main",
                            borderWidth: 2,
                          },
                        },
                      }),
                    }}
                  >
                    <InputLabel id={`${field.key}-label`}>
                      {field.label}
                    </InputLabel>
                    <Select
                      labelId={`${field.key}-label`}
                      label={field.label}
                      name={field.key}
                      value={
                        providerInfo[
                          field.key as keyof ExtendedProviderBillingInfo
                        ] || ""
                      }
                      onChange={handleSelectChange}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value="EIN">EIN</MenuItem>
                      <MenuItem value="SSN">SSN</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    title="EIN (Employer Identification Number) is for businesses, while SSN (Social Security Number) is for individuals."
                    placement="top"
                  >
                    <InfoIcon
                      sx={{
                        fontSize: 20,
                        color: "action.active",
                        ml: 1,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            } else if (field.key === "accept_insurance_payment_as_full") {
              return (
                <Box key={field.key} sx={{ flexGrow: 1, minWidth: "150px" }}>
                  <FormControl
                    fullWidth
                    sx={{
                      ...(highlightedFields.includes(field.key) && {
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "primary.main",
                            borderWidth: 2,
                          },
                        },
                      }),
                    }}
                  >
                    <InputLabel id={`${field.key}-label`}>
                      {field.label}
                    </InputLabel>
                    <Select
                      labelId={`${field.key}-label`}
                      label={field.label}
                      name={field.key}
                      value={
                        providerInfo[
                          field.key as keyof ExtendedProviderBillingInfo
                        ] || ""
                      }
                      onChange={handleSelectChange}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value="yes">Yes</MenuItem>
                      <MenuItem value="no">No</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    title="Indicate whether you accept the insurance company's approved amount as full payment for services provided."
                    placement="top"
                  >
                    <InfoIcon
                      sx={{
                        fontSize: 20,
                        color: "action.active",
                        ml: 1,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            } else if (field.key === "place_of_service") {
              return (
                <Box key={field.key} sx={{ flexGrow: 1, minWidth: "150px" }}>
                  <FormControl
                    fullWidth
                    sx={{
                      ...(highlightedFields.includes(field.key) && {
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "primary.main",
                            borderWidth: 2,
                          },
                        },
                      }),
                    }}
                  >
                    <InputLabel id={`${field.key}-label`}>
                      {field.label}
                    </InputLabel>
                    <Select
                      labelId={`${field.key}-label`}
                      label={field.label}
                      name={field.key}
                      value={
                        providerInfo[
                          field.key as keyof ExtendedProviderBillingInfo
                        ] || ""
                      }
                      onChange={handleSelectChange}
                      variant="outlined"
                      size="small"
                    >
                      <MenuItem value="11">Office</MenuItem>
                      <MenuItem value="02">
                        Telehealth Provided Other than in Patient's Home
                      </MenuItem>
                      <MenuItem value="10">
                        Telehealth Provided in Patient's Home
                      </MenuItem>
                      <MenuItem value="12">Home</MenuItem>
                      <MenuItem value="22">Outpatient Hospital</MenuItem>
                      <MenuItem value="53">
                        Community Mental Health Center (CMHC)
                      </MenuItem>
                      <MenuItem value="31">Skilled Nursing Facility</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    title="Most common place of service is 11 (Office)"
                    placement="top"
                  >
                    <InfoIcon
                      sx={{
                        fontSize: 20,
                        color: "action.active",
                        ml: 1,
                        verticalAlign: "middle",
                      }}
                    />
                  </Tooltip>
                </Box>
              );
            }
          }

          // Default text field for other types
          return (
            <Box key={field.key} sx={{ flexGrow: 1, minWidth: "150px" }}>
              <HighlightedTextField
                label={field.label}
                name={field.key}
                type={field.type || "text"}
                value={
                  providerInfo[
                    field.key as keyof ExtendedProviderBillingInfo
                  ] || ""
                }
                onChange={handleInputChange}
                variant="outlined"
                size="small"
                fullWidth
                ishighlighted={highlightedFields.includes(field.key).toString()}
              />
              {field.tooltip && (
                <Tooltip title={field.tooltip} placement="top">
                  <InfoIcon
                    sx={{
                      fontSize: 20,
                      color: "action.active",
                      ml: 1,
                      verticalAlign: "middle",
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          );
        })}
      </Box>

      {hasChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      )}

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default ProviderInformationView;
