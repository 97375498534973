import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  alpha,
  TextField,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  TextareaAutosize,
  InputAdornment,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { FormDefinition, FormField, FormFieldOption } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { useSnackbar } from "notistack";

interface FormPreviewProps {
  formId: string;
}

/**
 * Renders a preview of a form definition with disabled form controls
 */
const FormPreview: React.FC<FormPreviewProps> = ({ formId }) => {
  const theme = useTheme();
  const { getAccessToken, subjectLanguage } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [formDefinition, setFormDefinition] = useState<FormDefinition | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch form definition when formId changes
  useEffect(() => {
    const fetchFormDefinition = async () => {
      if (!formId) return;

      setLoading(true);
      setError(null);

      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/forms/${formId}`,
          accessToken,
        });

        if (response.ok) {
          setFormDefinition(response.value);
        } else {
          setError("Failed to load form definition");
          enqueueSnackbar("Failed to load form preview", { variant: "error" });
        }
      } catch (error) {
        console.error("Error fetching form definition:", error);
        setError("An error occurred while loading the form");
        enqueueSnackbar("Failed to load form preview", { variant: "error" });
      } finally {
        setLoading(false);
      }
    };

    fetchFormDefinition();
  }, [formId, getAccessToken, enqueueSnackbar]);

  const [expandedGroups, setExpandedGroups] = useState<Record<string, boolean>>(
    {}
  );

  // Initialize expanded groups when form definition is loaded
  useEffect(() => {
    if (formDefinition) {
      setExpandedGroups(
        formDefinition.groups.reduce((acc, _, index) => {
          acc[index.toString()] = index === 0; // Expand only the first group initially
          return acc;
        }, {} as Record<string, boolean>)
      );
    }
  }, [formDefinition]);

  const handleGroupChange =
    (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpandedGroups({
        ...expandedGroups,
        [panel]: isExpanded,
      });
    };

  // Helper to get option label for display
  const getOptionLabel = (option: string | FormFieldOption): string => {
    if (typeof option === "string") {
      return option;
    }
    return option.label;
  };

  // Helper to get option value for form controls
  const getOptionValue = (option: string | FormFieldOption): string => {
    if (typeof option === "string") {
      return option;
    }
    return String(option.value);
  };

  const renderField = (field: FormField) => {
    switch (field.type) {
      case "text":
      case "email":
      case "tel":
        return (
          <TextField
            fullWidth
            label={field.label}
            type={field.type}
            disabled
            required={field.required}
            variant="outlined"
            size="small"
            placeholder={`Enter ${field.label.toLowerCase()}`}
            sx={{ mb: 2 }}
          />
        );

      case "number":
        return (
          <TextField
            fullWidth
            label={field.label}
            type="number"
            disabled
            required={field.required}
            variant="outlined"
            size="small"
            inputProps={{
              min: field.validation?.min,
              max: field.validation?.max,
              step: "any",
            }}
            placeholder={`Enter ${field.label.toLowerCase()}`}
            sx={{ mb: 2 }}
          />
        );

      case "textarea":
        return (
          <TextField
            fullWidth
            label={field.label}
            multiline
            rows={4}
            disabled
            required={field.required}
            variant="outlined"
            size="small"
            placeholder={`Enter ${field.label.toLowerCase()}`}
            sx={{ mb: 2 }}
          />
        );

      case "date":
        return (
          <TextField
            fullWidth
            label={field.label}
            type="date"
            InputLabelProps={{ shrink: true }}
            disabled
            required={field.required}
            variant="outlined"
            size="small"
            sx={{ mb: 2 }}
          />
        );

      case "radio":
        return (
          <FormControl component="fieldset" sx={{ mb: 2, width: "100%" }}>
            <FormLabel component="legend" sx={{ color: "text.primary", mb: 1 }}>
              {field.label}
              {field.required && (
                <Typography component="span" color="error" sx={{ ml: 0.5 }}>
                  *
                </Typography>
              )}
            </FormLabel>
            <RadioGroup aria-label={field.label} name={field.id}>
              {field.options?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={getOptionValue(option)}
                  control={<Radio disabled />}
                  label={getOptionLabel(option)}
                  disabled
                />
              ))}
            </RadioGroup>
          </FormControl>
        );

      case "checkbox":
        return (
          <FormControl component="fieldset" sx={{ mb: 2, width: "100%" }}>
            <FormLabel component="legend" sx={{ color: "text.primary", mb: 1 }}>
              {field.label}
              {field.required && (
                <Typography component="span" color="error" sx={{ ml: 0.5 }}>
                  *
                </Typography>
              )}
            </FormLabel>
            <FormGroup>
              {field.options?.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={<Checkbox disabled />}
                  label={getOptionLabel(option)}
                  disabled
                />
              ))}
            </FormGroup>
          </FormControl>
        );

      case "select":
        return (
          <FormControl fullWidth variant="outlined" size="small" sx={{ mb: 2 }}>
            <InputLabel id={`${field.id}-label`}>
              {field.label}
              {field.required && "*"}
            </InputLabel>
            <Select
              labelId={`${field.id}-label`}
              id={field.id}
              label={field.label}
              disabled
            >
              {field.options?.map((option, index) => (
                <MenuItem key={index} value={getOptionValue(option)}>
                  {getOptionLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );

      default:
        return (
          <Typography color="error">
            Unknown field type: {field.type}
          </Typography>
        );
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          p: 4,
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error || !formDefinition) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 4,
        }}
      >
        <ErrorOutlineIcon color="error" sx={{ fontSize: 48, mb: 2 }} />
        <Typography variant="h6" color="error" gutterBottom>
          {error || "Failed to load form"}
        </Typography>
        <Typography color="text.secondary">
          Please try again later or contact support if the problem persists.
        </Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{ p: 3, mb: 3, bgcolor: alpha(theme.palette.primary.main, 0.05) }}
      >
        <Typography variant="h5" gutterBottom>
          {formDefinition.title}
        </Typography>

        <Typography variant="body1" color="text.secondary" gutterBottom>
          {formDefinition.description}
        </Typography>

        <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
          <InfoIcon fontSize="small" color="info" sx={{ mr: 1 }} />
          <Typography variant="body2" color="info.main">
            This is a preview of how the form will appear to {subjectLanguage}s.
            Form fields are disabled in preview mode.
          </Typography>
        </Box>
      </Paper>

      {formDefinition.groups.map((group, groupIndex) => (
        <Accordion
          key={groupIndex}
          expanded={expandedGroups[groupIndex.toString()] || false}
          onChange={handleGroupChange(groupIndex.toString())}
          sx={{
            mb: 2,
            boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.05)",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.05),
              "& .MuiAccordionSummary-content": {
                margin: "12px 0",
              },
            }}
          >
            <Box>
              <Typography variant="h6">{group.title}</Typography>
              {group.description && (
                <Typography variant="body2" color="text.secondary">
                  {group.description}
                </Typography>
              )}
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 3 }}>
            {group.sections.map((section, sectionIndex) => (
              <Box
                key={sectionIndex}
                sx={{ mb: sectionIndex === group.sections.length - 1 ? 0 : 4 }}
              >
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {section.title}
                </Typography>

                <Grid container spacing={2}>
                  {section.fields.map((field, fieldIndex) => (
                    <Grid
                      item
                      xs={12}
                      md={
                        field.type === "textarea" ||
                        field.type === "checkbox" ||
                        field.type === "radio"
                          ? 12
                          : 6
                      }
                      key={fieldIndex}
                    >
                      {renderField(field)}
                    </Grid>
                  ))}
                </Grid>

                {sectionIndex < group.sections.length - 1 && (
                  <Divider sx={{ my: 3 }} />
                )}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FormPreview;
