import { v4 as uuidv4 } from "uuid";

// Message types for inter-tab communication
export enum MessageType {
  START_RECORDING = "startRecording",
  PAUSE_RECORDING = "pauseRecording",
  RESUME_RECORDING = "resumeRecording",
  END_RECORDING = "endRecording",
  HEARTBEAT = "heartbeat",
  RECORDING_ORPHANED = "recordingOrphaned",
  CLAIM_OWNERSHIP = "claimOwnership",
  NOT_AUTHORIZED = "notAuthorized",
  RECORDING_SUBMITTED = "recordingSubmitted",
  RECORDING_DELETED = "recordingDeleted",
}

export const STORAGE_KEYS = {
  RECORDING_OWNER_ID: "recordingOwnerId",
  TAB_ID: "tabId",
};

class TabCommunicator {
  private tabId: string;

  constructor() {
    // Generate or retrieve tab ID from sessionStorage
    const existingTabId = sessionStorage.getItem(STORAGE_KEYS.TAB_ID);
    this.tabId = existingTabId || uuidv4();

    if (!existingTabId) {
      sessionStorage.setItem(STORAGE_KEYS.TAB_ID, this.tabId);
    }
  }

  public getTabId(): string {
    return this.tabId;
  }

  public isRecordingOwner(): boolean {
    const ownerId = localStorage.getItem(STORAGE_KEYS.RECORDING_OWNER_ID);
    return ownerId === this.tabId;
  }
}

// Export a singleton instance
const tabCommunicator = new TabCommunicator();
export default tabCommunicator;
