import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Paper,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import PayerSearch from "./PayerSearch";
import InfoIcon from "@mui/icons-material/Info";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { useSnackbar } from "notistack";
import SentryService from "../../services/SentryService";
import { capitalize } from "../../utils/utils";

interface PatientInformationViewProps {
  patientId: string;
  highlightedFields?: string[];
  onPatientSave?: (updatedPatient: Patient) => void;
}

const PatientInformationView: React.FC<PatientInformationViewProps> = ({
  patientId,
  highlightedFields = [],
  onPatientSave,
}) => {
  const [patient, setPatient] = useState<Patient | null>(null);
  const [loading, setLoading] = useState(true);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedPayer, setSelectedPayer] = useState<{
    id: string;
    name: string;
    eligibility: string;
  } | null>(null);
  const [payerRel, setPayerRel] = useState<string>("");

  const { enqueueSnackbar } = useSnackbar();
  const { getAccessToken, subjectLanguage } = useUser();

  useEffect(() => {
    fetchPatient();
  }, [patientId]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setPatient((prev) => {
      if (!prev) return null;
      return { ...prev, [name]: value as string };
    });
    setHasChanges(true);
  };

  const handlePayerSelect = (
    payer: { id: string; name: string; eligibility: string } | null
  ) => {
    if (payer) {
      setSelectedPayer(payer);
      setPatient((prev) => {
        if (!prev) return null;
        return {
          ...prev,
          payer_id: payer.id,
          payer_name: payer.name,
          eligibility: payer.eligibility,
        };
      });
    } else {
      setSelectedPayer(null);
      setPatient((prev) => {
        if (!prev) return null;
        return { ...prev, payer_id: "", payer_name: "", eligibility: "" };
      });
    }
    setHasChanges(true);
  };

  const fetchPatient = async () => {
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/get?patient_id=${patientId}&origin=PatientInformationView`,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setPatient(data.patient);
        if (data.patient.payer_id && data.patient.payer_name) {
          setSelectedPayer({
            id: data.patient.payer_id,
            name: data.patient.payer_name,
            eligibility: data.patient.eligibility,
          });
        }
      } else {
        throw new Error(
          response.error?.message || `Failed to fetch ${subjectLanguage}`
        );
      }
    } catch (error) {
      console.error("Error fetching patient:", error);
      enqueueSnackbar(
        error instanceof Error
          ? error.message
          : `Failed to load ${subjectLanguage} data`,
        {
          variant: "error",
        }
      );
      SentryService.logEvent("Error fetching patient", {
        level: "error",
        tags: {
          patient_id: patientId,
        },
        extra: {
          error: error,
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name !== "payer_id") {
      if (name === "state") {
        // Only allow letters, convert to uppercase, and limit to 2 characters
        const stateValue = value
          .replace(/[^A-Za-z]/g, "")
          .toUpperCase()
          .slice(0, 2);
        setPatient((prev) => {
          if (!prev) return null;
          const updatedPatient = { ...prev, [name]: stateValue };
          setHasChanges(true);
          return updatedPatient;
        });
      } else {
        setPatient((prev) => {
          if (!prev) return null;
          const updatedPatient = { ...prev, [name]: value };
          setHasChanges(true);
          return updatedPatient;
        });
      }
    }
  };

  const handleSave = async () => {
    if (!patient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/update",
        accessToken,
        body: {
          patient_id: patientId,
          updated_fields: patient,
        },
      });

      if (response.ok) {
        enqueueSnackbar(`${capitalize(subjectLanguage)} updated successfully`, {
          variant: "success",
        });
        setHasChanges(false);
        if (onPatientSave) onPatientSave(patient);
      } else {
        throw new Error(
          response.error?.message || `Failed to update ${subjectLanguage}`
        );
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      enqueueSnackbar(
        error instanceof Error
          ? error.message
          : `Failed to update ${subjectLanguage}`,
        {
          variant: "error",
        }
      );
      SentryService.logEvent("Error updating patient", {
        level: "error",
        tags: {
          patient_id: patientId || "",
        },
        extra: {
          error: error,
        },
      });
    }
  };

  const handlePhoneChange = (newValue: string, field: string) => {
    setPatient((prev) => {
      if (!prev) return null;
      return { ...prev, [field]: newValue };
    });
    setHasChanges(true);
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!patient) {
    return <Typography>No {subjectLanguage} data available.</Typography>;
  }

  const fields = [
    { name: "first_name", label: "First Name" },
    { name: "middle_name", label: "Middle Name" },
    { name: "last_name", label: "Last Name" },
    { name: "identifier", label: "Identifier (PRN)" },
    { name: "dob", label: "Date of Birth", type: "date" },
    { name: "phone", label: "Phone", type: "phone" },
    { name: "email", label: "Email", type: "email" },
    { name: "gender", label: "Gender", type: "select" },
    { name: "pronouns", label: "Pronouns" },
    { name: "race", label: "Race" },
    { name: "address", label: "Address" },
    { name: "city", label: "City" },
    { name: "state", label: "State", maxLength: 2 },
    { name: "zip", label: "Zip", type: "number" },
    { name: "insurance_policy_number", label: "Insurance Policy Number" },
  ];

  return (
    <Paper elevation={1} sx={{ p: 3, mb: 2 }}>
      <Typography variant="h6" gutterBottom>
        {capitalize(subjectLanguage)} Information
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
        {fields
          .filter((field) => field.name !== "payer_id") // Exclude payer_id from the main fields
          .map((field) => (
            <Box key={field.name} sx={{ flexGrow: 1, minWidth: "150px" }}>
              {field.type === "date" ? (
                <TextField
                  label={field.label}
                  name={field.name}
                  type="date"
                  value={patient[field.name as keyof Patient] || ""}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: new Date().toISOString().split("T")[0],
                  }}
                  sx={{
                    flexGrow: 1,
                    minWidth: "150px",
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                />
              ) : field.type === "select" ? (
                <FormControl
                  fullWidth
                  size="small"
                  sx={{
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                >
                  <InputLabel id={`${field.name}-label`}>
                    {field.label}
                  </InputLabel>
                  <Select
                    labelId={`${field.name}-label`}
                    name={field.name}
                    value={
                      (patient[field.name as keyof Patient] as string) || ""
                    }
                    onChange={handleSelectChange}
                    label={field.label}
                    fullWidth
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
                </FormControl>
              ) : field.type === "phone" ? (
                <MuiTelInput
                  label={field.label}
                  value={(patient[field.name as keyof Patient] as string) || ""}
                  onChange={(newValue) =>
                    handlePhoneChange(newValue, field.name)
                  }
                  defaultCountry="US"
                  forceCallingCode
                  onlyCountries={["US", "CA", "GB", "AU", "NZ", "ZA", "MX"]}
                  size="small"
                  sx={{
                    flexGrow: 1,
                    "& input": {
                      height: "1.4375em", // Match TextField height
                    },
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                />
              ) : (
                <TextField
                  label={field.label}
                  name={field.name}
                  value={patient[field.name as keyof Patient]}
                  onChange={handleInputChange}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputLabelProps={
                    field.type === "date" ? { shrink: true } : undefined
                  }
                  inputProps={
                    field.type === "date"
                      ? { max: new Date().toISOString().split("T")[0] }
                      : undefined
                  }
                  InputProps={{
                    readOnly: field.name === "payer_id" ? true : false,
                    endAdornment:
                      field.name === "payer_id" ? (
                        <Tooltip title="Select a payer using the Search Payers above. This field will be automatically filled.">
                          <InfoIcon
                            sx={{ fontSize: 20, color: "action.active" }}
                          />
                        </Tooltip>
                      ) : null,
                  }}
                  sx={{
                    flexGrow: 1,
                    minWidth: "150px",
                    ...(highlightedFields.includes(field.name) && {
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "primary.main",
                          borderWidth: 2,
                        },
                      },
                    }),
                  }}
                />
              )}
            </Box>
          ))}
      </Box>
      <Box sx={{ mt: 2 }}>
        <PayerSearch
          onPayerSelect={handlePayerSelect}
          disabled={false}
          value={selectedPayer}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Payer ID"
          name="payer_id"
          value={patient.payer_id || ""}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Select a payer using the Search Payers above. This field will be automatically filled.">
                <InfoIcon sx={{ fontSize: 20, color: "action.active" }} />
              </Tooltip>
            ),
          }}
          sx={{
            flexGrow: 1,
            minWidth: "150px",
            ...(highlightedFields.includes("payer_id") && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "primary.main",
                  borderWidth: 2,
                },
              },
            }),
          }}
        />
      </Box>
      <Box>
        <FormControl
          fullWidth
          sx={{
            mt: 2,
            mb: 2,
            ...(highlightedFields.includes("payer_relationship") && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "primary.main",
                  borderWidth: 2,
                },
              },
            }),
          }}
        >
          <InputLabel id="payer-rel-label">
            {capitalize(subjectLanguage)} Relationship
          </InputLabel>
          <Select
            labelId="payer-rel-label"
            name="payer_relationship"
            value={patient.payer_relationship || payerRel}
            onChange={handleSelectChange}
            label={`${capitalize(subjectLanguage)} Relationship`}
            fullWidth
          >
            <MenuItem value="self">Self</MenuItem>
            <MenuItem value="spouse">Spouse</MenuItem>
            <MenuItem value="child">Child</MenuItem>
            <MenuItem value="employee">Employee</MenuItem>
            <MenuItem value="unknown">Unknown</MenuItem>
            <MenuItem value="life_partner">Life Partner</MenuItem>
            <MenuItem value="dependent">Dependent</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {hasChanges && (
        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
        </Box>
      )}
    </Paper>
  );
};

export default PatientInformationView;
