import React, { useMemo } from "react";
import {
  Box,
  Typography,
  Paper,
  Chip,
  Divider,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
  alpha,
} from "@mui/material";
import { FormDefinition, FormField } from "../../types/types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { formatDate } from "../../utils/utils";

interface FormDetailViewProps {
  formDefinition: FormDefinition;
  submissionData: Record<string, any>;
  mode?: "default" | "condensed";
}

/**
 * Renders a form with its submitted data in either a detailed or condensed format
 */
const FormDetailView: React.FC<FormDetailViewProps> = ({
  formDefinition,
  submissionData,
  mode = "default",
}) => {
  const theme = useTheme();

  // Determine if a field should be visible based on its conditional logic
  const isFieldVisible = (field: FormField): boolean => {
    if (!field.conditional) return true;

    const { conditional } = field;
    const dependsOnField = conditional.dependsOn;
    const dependsOnValue = submissionData[dependsOnField];
    const showIfValue = conditional.showIf;

    if (!dependsOnValue) return false;

    if (Array.isArray(showIfValue)) {
      return showIfValue.includes(dependsOnValue);
    }

    return dependsOnValue === showIfValue;
  };

  // Render a field value based on its type and the submitted data
  const renderFieldValue = (field: FormField, value: any) => {
    if (value === undefined || value === null || value === "") {
      return (
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          Not provided
        </Typography>
      );
    }

    switch (field.type) {
      case "checkbox":
        if (!Array.isArray(value)) {
          return value ? "Yes" : "No";
        }
        return value.length > 0 ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {value.map((item, index) => (
              <Chip
                key={index}
                label={item}
                size={mode === "condensed" ? "small" : "medium"}
                sx={{
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                  color: theme.palette.primary.main,
                  fontWeight: "medium",
                }}
              />
            ))}
          </Box>
        ) : (
          <Typography variant="body2" color="text.secondary" fontStyle="italic">
            None selected
          </Typography>
        );

      case "radio":
        return (
          <Typography variant="body1" fontWeight="medium">
            {value}
          </Typography>
        );

      case "date":
        try {
          // If value is a date string in format YYYY-MM-DD, display it as MM/DD/YYYY
          if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}/.test(value)) {
            const [year, month, day] = value.split("T")[0].split("-");
            return `${month}/${day}/${year.substring(2)}`;
          }
          // Fallback to original formatting
          return formatDate(new Date(value).getTime() / 1000, "date");
        } catch (e) {
          return value;
        }

      case "textarea":
        return (
          <Typography
            variant="body1"
            sx={{
              whiteSpace: "pre-wrap",
              backgroundColor: alpha(theme.palette.background.default, 0.5),
              p: 1.5,
              borderRadius: 1,
              border: 1,
              borderColor: theme.palette.divider,
            }}
          >
            {value}
          </Typography>
        );

      default:
        return <Typography variant="body1">{value}</Typography>;
    }
  };

  // Filter the form groups to only show relevant sections based on the submitted data
  const relevantGroups = useMemo(() => {
    return formDefinition.groups
      .map((group) => {
        // Map sections to only include visible fields
        const visibleSections = group.sections
          .map((section) => {
            const visibleFields = section.fields.filter(isFieldVisible);
            return {
              ...section,
              fields: visibleFields,
              // Count how many fields have answers
              answeredFieldsCount: visibleFields.filter(
                (field) =>
                  submissionData[field.id] !== undefined &&
                  submissionData[field.id] !== null &&
                  submissionData[field.id] !== "" &&
                  !(
                    Array.isArray(submissionData[field.id]) &&
                    submissionData[field.id].length === 0
                  )
              ).length,
            };
          })
          .filter((section) => section.fields.length > 0);

        return {
          ...group,
          sections: visibleSections,
          // Count total answered fields across all sections
          totalAnsweredFields: visibleSections.reduce(
            (sum, section) => sum + section.answeredFieldsCount,
            0
          ),
          totalFields: visibleSections.reduce(
            (sum, section) => sum + section.fields.length,
            0
          ),
        };
      })
      .filter((group) => group.sections.length > 0);
  }, [formDefinition, submissionData]);

  // Render the default (full) view of the form
  const renderDefaultView = () => (
    <Box sx={{ maxWidth: "100%" }}>
      <Typography variant="h5" gutterBottom>
        {formDefinition.title}
      </Typography>

      {formDefinition.description && (
        <Typography
          variant="body1"
          color="text.secondary"
          gutterBottom
          sx={{ mb: 3 }}
        >
          {formDefinition.description}
        </Typography>
      )}

      {relevantGroups.map((group, groupIndex) => (
        <Accordion
          key={groupIndex}
          defaultExpanded={groupIndex === 0}
          sx={{
            mb: 2,
            border: 1,
            borderColor: "divider",
            "&:before": {
              display: "none",
            },
            borderRadius: "8px !important",
            overflow: "hidden",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.05),
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6" fontWeight="medium">
                {group.title}
              </Typography>
              <Chip
                label={`${group.totalAnsweredFields}/${group.totalFields} Completed`}
                size="small"
                sx={{
                  backgroundColor:
                    group.totalAnsweredFields === 0
                      ? alpha(theme.palette.error.main, 0.1)
                      : group.totalAnsweredFields === group.totalFields
                      ? alpha(theme.palette.success.main, 0.1)
                      : alpha(theme.palette.warning.main, 0.1),
                  color:
                    group.totalAnsweredFields === 0
                      ? theme.palette.error.main
                      : group.totalAnsweredFields === group.totalFields
                      ? theme.palette.success.main
                      : theme.palette.warning.main,
                  ml: 2,
                  fontWeight: "medium",
                }}
              />
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {group.description && (
              <Box
                sx={{
                  px: 3,
                  py: 2.5,
                  bgcolor: alpha(theme.palette.background.default, 0.5),
                  borderBottom: 1,
                  borderColor: "divider",
                }}
              >
                <Typography variant="body1" color="text.secondary">
                  {group.description}
                </Typography>
              </Box>
            )}

            {group.sections.map((section, sectionIndex) => (
              <Box
                key={sectionIndex}
                sx={{
                  p: 3,
                  borderTop: sectionIndex > 0 || group.description ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {section.title}
                  </Typography>
                  <Chip
                    label={`${section.answeredFieldsCount}/${section.fields.length} Answered`}
                    size="small"
                    sx={{
                      ml: 2,
                      backgroundColor:
                        section.answeredFieldsCount === 0
                          ? alpha(theme.palette.error.main, 0.1)
                          : section.answeredFieldsCount ===
                            section.fields.length
                          ? alpha(theme.palette.success.main, 0.1)
                          : alpha(theme.palette.warning.main, 0.1),
                      color:
                        section.answeredFieldsCount === 0
                          ? theme.palette.error.main
                          : section.answeredFieldsCount ===
                            section.fields.length
                          ? theme.palette.success.main
                          : theme.palette.warning.main,
                    }}
                  />
                </Box>

                <Grid container spacing={3}>
                  {section.fields.map((field) => {
                    const value = submissionData[field.id];
                    const hasValue =
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      !(Array.isArray(value) && value.length === 0);

                    return (
                      <Grid
                        item
                        xs={12}
                        md={field.type === "textarea" ? 12 : 6}
                        key={field.id}
                      >
                        <Card
                          variant="outlined"
                          sx={{
                            height: "100%",
                            backgroundColor: hasValue
                              ? "white"
                              : alpha(theme.palette.background.default, 0.7),
                            borderColor: hasValue
                              ? theme.palette.divider
                              : alpha(theme.palette.divider, 0.5),
                          }}
                        >
                          <CardContent>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                mb: 1,
                                gap: 1,
                              }}
                            >
                              {hasValue ? (
                                <CheckCircleIcon
                                  fontSize="small"
                                  color="success"
                                />
                              ) : (
                                <RemoveCircleOutlineIcon
                                  fontSize="small"
                                  color="disabled"
                                />
                              )}
                              <Typography
                                variant="subtitle2"
                                color={
                                  hasValue ? "text.primary" : "text.secondary"
                                }
                                fontWeight={hasValue ? "bold" : "regular"}
                              >
                                {field.label}
                                {field.required && (
                                  <Box component="span" color="error.main">
                                    *
                                  </Box>
                                )}
                              </Typography>
                            </Box>
                            {renderFieldValue(field, value)}
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  // Render the condensed view of the form (for side panels)
  const renderCondensedView = () => (
    <Box sx={{ maxWidth: "100%" }}>
      <Typography variant="h6" gutterBottom>
        {formDefinition.title}
      </Typography>

      {relevantGroups.map((group, groupIndex) => (
        <Accordion
          key={groupIndex}
          defaultExpanded={groupIndex === 0}
          sx={{
            mb: 1,
            "&:before": {
              display: "none",
            },
            borderRadius: "4px !important",
            overflow: "hidden",
            boxShadow: "none",
            border: 1,
            borderColor: "divider",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              minHeight: "48px",
              backgroundColor: alpha(theme.palette.primary.main, 0.05),
              "&:hover": {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
              },
              "& .MuiAccordionSummary-content": {
                my: 0.5,
              },
            }}
          >
            <Typography variant="subtitle1" fontWeight="medium">
              {group.title} ({group.totalAnsweredFields}/{group.totalFields})
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ p: 0 }}>
            {group.sections.map((section, sectionIndex) => (
              <Box
                key={sectionIndex}
                sx={{
                  borderTop: sectionIndex > 0 ? 1 : 0,
                  borderColor: "divider",
                }}
              >
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    px: 2,
                    py: 1,
                    backgroundColor: alpha(
                      theme.palette.background.default,
                      0.5
                    ),
                  }}
                >
                  {section.title}
                </Typography>

                <Box sx={{ p: 2 }}>
                  {section.fields.map((field, fieldIndex) => {
                    const value = submissionData[field.id];
                    const hasValue =
                      value !== undefined &&
                      value !== null &&
                      value !== "" &&
                      !(Array.isArray(value) && value.length === 0);

                    // Skip empty fields in condensed view unless it's required
                    if (!hasValue && !field.required) return null;

                    return (
                      <Box
                        key={field.id}
                        sx={{
                          mb: fieldIndex < section.fields.length - 1 ? 1.5 : 0,
                          opacity: hasValue ? 1 : 0.7,
                        }}
                      >
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          fontWeight="medium"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 0.5,
                          }}
                        >
                          {hasValue ? (
                            <CheckCircleIcon
                              fontSize="small"
                              color="success"
                              sx={{ fontSize: 14 }}
                            />
                          ) : (
                            <RemoveCircleOutlineIcon
                              fontSize="small"
                              color="disabled"
                              sx={{ fontSize: 14 }}
                            />
                          )}
                          {field.label}
                          {field.required && (
                            <Box component="span" color="error.main">
                              *
                            </Box>
                          )}
                        </Typography>
                        <Box sx={{ ml: 2.5 }}>
                          {renderFieldValue(field, value)}
                        </Box>
                        {fieldIndex < section.fields.length - 1 && (
                          <Divider sx={{ mt: 1.5 }} />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );

  return mode === "condensed" ? renderCondensedView() : renderDefaultView();
};

export default FormDetailView;
