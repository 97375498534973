import React, { useState } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Paper,
  Typography,
  Stack,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  Add as AddIcon,
  CalendarMonth,
  ViewWeek,
  ViewDay,
  NavigateBefore,
  NavigateNext,
  Today,
  ViewList,
} from "@mui/icons-material";
import { useEncountersData } from "../../context/EncountersDataContext";
import { format } from "date-fns";
import { TutorialStep } from "../Tutorial/TutorialStep";
import { useUser } from "../../context/user";
import AddEncounterModal from "../Encounter/AddEncounterModal";

interface CalendarNavigationProps {
  // Optional additional props can be added here
}

const CalendarNavigation: React.FC<CalendarNavigationProps> = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { subjectLanguage } = useUser();

  // Get necessary functions from context
  const { viewMode, setViewMode, currentDate, setCurrentDate } =
    useEncountersData();

  // State for the add appointment modal
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  // Handle navigation functions
  const handlePrevious = () => {
    const newDate = new Date(currentDate);
    if (viewMode === "day") {
      newDate.setDate(newDate.getDate() - 1);
    } else if (viewMode === "week") {
      newDate.setDate(newDate.getDate() - 7);
    } else if (viewMode === "month") {
      newDate.setMonth(newDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (viewMode === "day") {
      newDate.setDate(newDate.getDate() + 1);
    } else if (viewMode === "week") {
      newDate.setDate(newDate.getDate() + 7);
    } else if (viewMode === "month") {
      newDate.setMonth(newDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
  };

  const handleToday = () => {
    setCurrentDate(new Date());
  };

  // Use the same type casting approach as in CalendarWidget to handle ViewMode
  const handleViewChange = (newView: string) => {
    setViewMode(newView as any);
  };

  // Get appropriate date format based on view mode
  const getDateDisplay = () => {
    if (viewMode === "day") {
      return format(currentDate, "EEEE, MMMM d, yyyy");
    } else if (viewMode === "week") {
      const startOfWeek = new Date(currentDate);
      const day = currentDate.getDay();
      const diff = currentDate.getDate() - day + (day === 0 ? -6 : 1);
      startOfWeek.setDate(diff);

      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(endOfWeek.getDate() + 6);

      return `${format(startOfWeek, "MMM d")} - ${format(
        endOfWeek,
        "MMM d, yyyy"
      )}`;
    } else {
      return format(currentDate, "MMMM yyyy");
    }
  };

  return (
    <TutorialStep
      step={0}
      description="This is your calendar navigation bar. You can change views, navigate between dates, and create new appointments from here."
      placement="bottom"
    >
      <Paper
        elevation={1}
        sx={{
          p: 2,
          mb: { xs: 2, md: 2 },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: { xs: "flex-start", sm: "center" },
          gap: 2,
          borderRadius: "0.75rem",
          position: "sticky",
          top: 0,
          zIndex: 10,
          backgroundColor: "background.paper",
          boxShadow: 2,
        }}
        className="calendar-navigation"
      >
        {/* Calendar Navigation Controls */}
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems={{ xs: "flex-start", sm: "center" }}
          sx={{ width: { xs: "100%", sm: "auto" }, flexWrap: { sm: "wrap" } }}
        >
          <ButtonGroup size="small" variant="outlined">
            <Button onClick={handlePrevious}>
              <NavigateBefore />
            </Button>
            <Button onClick={handleToday}>Today</Button>
            <Button onClick={handleNext}>
              <NavigateNext />
            </Button>
          </ButtonGroup>

          <Typography
            variant="h6"
            sx={{ whiteSpace: "nowrap", minWidth: { xs: "100%", md: "auto" } }}
          >
            {getDateDisplay()}
          </Typography>

          <ButtonGroup size="small" variant="outlined">
            <Button
              onClick={() => handleViewChange("day")}
              variant={viewMode === "day" ? "contained" : "outlined"}
            >
              <ViewDay fontSize="small" sx={{ mr: 0.5 }} />
              Day
            </Button>
            <Button
              onClick={() => handleViewChange("week")}
              variant={viewMode === "week" ? "contained" : "outlined"}
            >
              <ViewWeek fontSize="small" sx={{ mr: 0.5 }} />
              Week
            </Button>
            <Button
              onClick={() => handleViewChange("month")}
              variant={viewMode === "month" ? "contained" : "outlined"}
            >
              <CalendarMonth fontSize="small" sx={{ mr: 0.5 }} />
              Month
            </Button>
            <Button
              onClick={() => handleViewChange("list")}
              variant={viewMode === "list" ? "contained" : "outlined"}
            >
              <ViewList fontSize="small" sx={{ mr: 0.5 }} />
              List
            </Button>
          </ButtonGroup>
        </Stack>

        {/* Add Appointment Button */}
        <Box>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setIsAddModalOpen(true)}
            sx={{ color: "white", whiteSpace: "nowrap" }}
          >
            New Appointment
          </Button>
        </Box>

        {/* Add Encounter Modal */}
        <AddEncounterModal
          open={isAddModalOpen}
          onClose={() => setIsAddModalOpen(false)}
        />
      </Paper>
    </TutorialStep>
  );
};

export default CalendarNavigation;
