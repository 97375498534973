import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Chip,
  Box,
  Card,
  CardContent,
  Typography,
  Pagination,
  Stack,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Popover,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Skeleton,
  Avatar,
} from "@mui/material";
import {
  Add as AddIcon,
  Search as SearchIcon,
  Delete as DeleteIcon,
  Description,
  FilterList as FilterListIcon,
  Assignment as AssignmentIcon,
  Info as InfoIcon,
  OpenInNew as OpenInNewIcon,
  AccountCircle,
  Groups,
  DocumentScanner,
  Quiz,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import BaseBlock from "./BaseBlock";
import CollapsibleBaseBlock from "./CollapsibleBaseBlock";
import { useUser } from "../../../context/user";
import APIService from "../../../services/APIService";
import SlideSidePane from "../../../Views/Shared/SlideSidePane";
import { FormDefinition, FormDetail, Patient } from "../../../types/types";
import debounce from "lodash/debounce";
import { capitalize, formatDate } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import FormSubmissionView from "../../Forms/FormSubmissionView";
import FormDetailView from "../../Forms/FormDetailView";
import { useSnackbar } from "notistack";
import SendFormOnboarding from "../../Forms/SendFormOnboarding";
import Logo from "../../../components/Icons/Logo";
import { getUserInfoFromLocalStorage } from "../../../utils/LocalStorageUtils";
import FormPreview from "../../Forms/FormPreview";

interface Form {
  id: string;
  title: string;
  status: "pending" | "in_progress" | "completed";
  created_timestamp: number;
  form_session_id: string;
}

interface AvailableForm {
  id: string;
  name: string;
  description: string;
}

interface FormsListBlockProps {
  patientId: string;
  patient?: Patient;
  defaultCollapsed?: boolean;
}

interface FormListResponse {
  forms: (FormDefinition & {
    type?: "public" | "user" | "group";
    form_type?: "standard" | "test";
  })[];
  pagination: {
    total: number;
    skip: number;
    limit: number;
  };
}

const FormsListBlock: React.FC<FormsListBlockProps> = ({
  patientId,
  patient,
  defaultCollapsed = false,
}) => {
  const [forms, setForms] = useState<Form[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalRows, setTotalRows] = useState(0);
  const { getAccessToken, subjectLanguage, userState } = useUser();
  const [isFormSelectorOpen, setIsFormSelectorOpen] = useState(false);
  const [availableForms, setAvailableForms] = useState<FormDefinition[]>([]);
  const [selectedFormIds, setSelectedFormIds] = useState<string[]>([]);
  const [formListPage, setFormListPage] = useState(1);
  const [totalFormPages, setTotalFormPages] = useState(1);
  const FORMS_PER_PAGE = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedForm, setSelectedForm] = useState<Form | null>(null);
  const [isFormDetailsOpen, setIsFormDetailsOpen] = useState(false);
  const [formDetail, setFormDetail] = useState<FormDetail | null>(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [statusFilter, setStatusFilter] = useState<string>("all");
  const [hasLoadedData, setHasLoadedData] = useState(!defaultCollapsed);
  const [isOnboardingOpen, setIsOnboardingOpen] = useState(false);
  const navigate = useNavigate();
  const [previewFormId, setPreviewFormId] = useState<string | null>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const userInfo = getUserInfoFromLocalStorage();

  const fetchForms = async () => {
    if (!hasLoadedData) {
      setHasLoadedData(true);
    }

    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      let requestString = `/patient/forms?patient_id=${patientId}&skip=${
        page * rowsPerPage
      }&limit=${rowsPerPage}`;

      if (statusFilter === "completed") {
        requestString += "&status=completed";
      } else if (statusFilter === "open") {
        requestString += "&exclude_completed=true";
      }

      const response = await APIService.makeAPIGetRequest({
        requestString,
        accessToken,
      });

      if (response.ok) {
        const data = await response.value;
        setForms(data.forms);
        setTotalRows(data.total);
      }
    } catch (error) {
      console.error("Error fetching forms:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!defaultCollapsed) {
      fetchForms();
    }
  }, []);

  useEffect(() => {
    if (hasLoadedData) {
      fetchForms();
    }
  }, [patientId, page, rowsPerPage, statusFilter]);

  const handleFormClick = (form: Form) => {
    setSelectedForm(form);
    setIsFormDetailsOpen(true);
  };

  const fetchFormDetail = async (formSessionId: string) => {
    setIsLoadingDetail(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/formDetail?form_session_id=${formSessionId}`,
        accessToken,
      });

      if (response.ok) {
        setFormDetail(response.value.form_detail);
      }
    } catch (error) {
      console.error("Error fetching form details:", error);
    } finally {
      setIsLoadingDetail(false);
    }
  };

  useEffect(() => {
    if (selectedForm?.form_session_id) {
      fetchFormDetail(selectedForm.form_session_id);
    } else {
      setFormDetail(null);
    }
  }, [selectedForm]);

  const handleFormDetailsClose = () => {
    setIsFormDetailsOpen(false);
    setSelectedForm(null);
    setFormDetail(null);
    window.history.replaceState(null, "", window.location.pathname);
  };

  const fetchAvailableForms = async (page: number, search?: string) => {
    try {
      const accessToken = await getAccessToken();
      const skip = (page - 1) * FORMS_PER_PAGE;
      const searchQuery = search ? `&search=${encodeURIComponent(search)}` : "";

      const response = await APIService.makeAPIGetRequest({
        requestString: `/forms/list?skip=${skip}&limit=${FORMS_PER_PAGE}${searchQuery}`,
        accessToken,
      });

      if (response.ok) {
        const data: FormListResponse = response.value;
        setAvailableForms(data.forms);
        setTotalFormPages(Math.ceil(data.pagination.total / FORMS_PER_PAGE));
      }
    } catch (error) {
      console.error("Error fetching available forms:", error);
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((searchValue: string) => {
        setFormListPage(1);
        fetchAvailableForms(1, searchValue);
      }, 500),
    []
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleSearchSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    debouncedSearch.cancel();
    setFormListPage(1);
    fetchAvailableForms(1, searchTerm);
  };

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleAddForm = () => {
    if (!patient?.dob || !patient?.phone) {
      enqueueSnackbar(
        "Please fill out patient's date of birth and phone number before sending forms.",
        {
          variant: "error",
        }
      );
      return;
    }

    // Check if the onboarding step is completed
    if (!userState?.onboardingSteps?.sms_form_sending_completed) {
      setIsOnboardingOpen(true);
      return;
    }

    setIsFormSelectorOpen(true);
    setFormListPage(1);
    fetchAvailableForms(1);
  };

  const handleFormSelect = (formId: string) => {
    setSelectedFormIds((prev) =>
      prev.includes(formId)
        ? prev.filter((id) => id !== formId)
        : [...prev, formId]
    );
  };

  const handleCreateFormSession = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/assignForms",
        accessToken,
        body: {
          patient_id: patientId,
          form_ids: selectedFormIds,
        },
      });

      if (response.ok) {
        setIsFormSelectorOpen(false);
        setSelectedFormIds([]);
        fetchForms();
      }
    } catch (error) {
      console.error("Error creating form session:", error);
    }
  };

  const handleFormListPageChange = (
    _event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setFormListPage(page);
    fetchAvailableForms(page, searchTerm);
  };

  const handleDeleteForm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setIsDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setAnchorEl(null);
  };

  const handleConfirmDeleteForm = async () => {
    if (!selectedForm) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: `/forms/${selectedForm.form_session_id}/delete`,
        accessToken,
      });

      if (response.ok) {
        enqueueSnackbar("Form deleted successfully", { variant: "success" });
        setIsFormDetailsOpen(false);
        setSelectedForm(null);
        setFormDetail(null);
        fetchForms();
      } else {
        enqueueSnackbar("Failed to delete form", { variant: "error" });
      }
    } catch (error) {
      console.error("Error deleting form:", error);
      enqueueSnackbar("Failed to delete form", { variant: "error" });
    } finally {
      handleCloseDeleteDialog();
    }
  };

  const handleStatusFilterChange = (event: SelectChangeEvent<string>) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const renderStatusChip = (status: string) => (
    <Chip
      label={
        status === "completed"
          ? "Completed"
          : status === "in_progress"
          ? "In Progress"
          : "Pending"
      }
      color={
        status === "completed"
          ? "success"
          : status === "in_progress"
          ? "primary"
          : "warning"
      }
      size="small"
    />
  );

  const handleCollapseChange = (isCollapsed: boolean) => {
    if (!isCollapsed && !hasLoadedData) {
      fetchForms();
    }
  };

  const handlePreviewForm = (formId: string, event: React.MouseEvent) => {
    event.stopPropagation();
    setPreviewFormId(formId);
    setIsPreviewOpen(true);
  };

  const handleClosePreview = () => {
    setIsPreviewOpen(false);
    setPreviewFormId(null);
  };

  return (
    <>
      <CollapsibleBaseBlock
        defaultCollapsed={defaultCollapsed}
        onCollapseChange={handleCollapseChange}
      >
        <CollapsibleBaseBlock.Header
          title="Forms"
          icon={<Description sx={{ fontSize: 20, color: "text.secondary" }} />}
          action={
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl size="small" sx={{ minWidth: 150, width: "auto" }}>
                <InputLabel id="status-filter-label">Status</InputLabel>
                <Select
                  labelId="status-filter-label"
                  id="status-filter"
                  value={statusFilter}
                  label="Status"
                  onChange={handleStatusFilterChange}
                  sx={{
                    borderRadius: "0.5rem",
                    width: "auto",
                    backgroundColor: "background.paper",
                    height: 32,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "borderColors.primary",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary.main",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        borderRadius: "0.5rem",
                        mt: 0.5,
                      },
                    },
                  }}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="open">Open</MenuItem>
                  <MenuItem value="completed">Completed</MenuItem>
                </Select>
              </FormControl>

              <Tooltip
                title={
                  !patient?.dob && !patient?.phone
                    ? `${capitalize(
                        subjectLanguage
                      )} date of birth and phone number are required to send forms`
                    : !patient?.dob
                    ? `${capitalize(
                        subjectLanguage
                      )} date of birth is required to send forms`
                    : !patient?.phone
                    ? `${capitalize(
                        subjectLanguage
                      )} phone number is required to send forms`
                    : ""
                }
                arrow
              >
                <span>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleAddForm}
                    disabled={!patient?.dob || !patient?.phone}
                    sx={{
                      borderRadius: "0.5rem",
                      textTransform: "none",
                      borderColor: "borderColors.primary",
                      "&:hover": {
                        borderColor: "primary.main",
                        backgroundColor: "action.hover",
                      },
                    }}
                  >
                    Send Form
                  </Button>
                </span>
              </Tooltip>

              {!userState?.onboardingSteps?.sms_form_sending_completed && (
                <Tooltip title="Learn about sending forms" arrow>
                  <IconButton
                    size="small"
                    onClick={() => setIsOnboardingOpen(true)}
                    sx={{
                      color: "primary.main",
                      backgroundColor: "transparent",
                      borderRadius: "50%",
                      padding: "4px",
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                    }}
                  >
                    <InfoIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          }
        />

        <CollapsibleBaseBlock.Body>
          {loading ? (
            <Box sx={{ width: "100%", p: 1 }}>
              <Skeleton
                variant="rectangular"
                width="100%"
                height={40}
                sx={{ mb: 1, borderRadius: 1 }}
              />
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Form Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Assigned Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {[...Array(3)].map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="80%" />
                        </TableCell>
                        <TableCell>
                          <Skeleton
                            variant="rectangular"
                            width={80}
                            height={24}
                            sx={{ borderRadius: 1 }}
                          />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="60%" />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box
                sx={{ display: "flex", justifyContent: "flex-end", mt: 1.5 }}
              >
                <Skeleton
                  variant="rectangular"
                  width={250}
                  height={32}
                  sx={{ borderRadius: 1 }}
                />
              </Box>
            </Box>
          ) : forms.length === 0 ? (
            <Typography align="center" color="text.secondary" sx={{ py: 3 }}>
              No forms found. Click "Send Form" to assign forms to this{" "}
              {subjectLanguage}.
            </Typography>
          ) : (
            <>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Form Name</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Assigned Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {forms.map((form) => (
                      <TableRow
                        key={form.id}
                        hover
                        onClick={() => handleFormClick(form)}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell>{form.title}</TableCell>
                        <TableCell>{renderStatusChip(form.status)}</TableCell>
                        <TableCell>
                          {formatDate(form.created_timestamp)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <TablePagination
                component="div"
                count={totalRows}
                page={page}
                onPageChange={(_, newPage) => setPage(newPage)}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={(e) => {
                  setRowsPerPage(parseInt(e.target.value, 10));
                  setPage(0);
                }}
                rowsPerPageOptions={[5, 10, 25]}
              />
            </>
          )}
        </CollapsibleBaseBlock.Body>
      </CollapsibleBaseBlock>

      <SlideSidePane
        open={isFormSelectorOpen}
        onClose={() => setIsFormSelectorOpen(false)}
        title="Select Forms"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 1,
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              overflow: "hidden",
              mb: 2,
            }}
          >
            <form onSubmit={handleSearchSubmit} style={{ marginBottom: 16 }}>
              <TextField
                fullWidth
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search forms..."
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        type="submit"
                        edge="end"
                        aria-label="search forms"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </form>

            <Typography variant="h6" sx={{ mb: 2 }}>
              Available Forms
            </Typography>

            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                mb: 2,
                maxHeight: "calc(100vh - 250px)",
              }}
            >
              {availableForms.length > 0 ? (
                availableForms.map((form) => {
                  const isSelected = selectedFormIds.includes(form.form_id);
                  const formWithType = form as FormDefinition & {
                    type?: "public" | "user" | "group";
                    form_type?: "standard" | "test";
                  };
                  return (
                    <Paper
                      key={form.form_id}
                      sx={{
                        p: 2,
                        mb: 2,
                        backgroundColor: isSelected
                          ? "primary.soft"
                          : "background.paper",
                        border: 1,
                        borderRadius: "0.75rem",
                        borderColor: isSelected
                          ? "primary.main"
                          : "borderColors.primary",
                        cursor: "pointer",
                        transition: "all 0.2s",
                        position: "relative",
                        "&:hover": {
                          bgcolor: isSelected ? "primary.soft" : "action.hover",
                          transform: "translateY(-2px)",
                          boxShadow: 1,
                        },
                        "&:last-child": {
                          mb: 0,
                        },
                      }}
                      onClick={() => handleFormSelect(form.form_id)}
                    >
                      {isSelected && (
                        <Paper
                          elevation={0}
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            px: 1.5,
                            py: 0.5,
                            bgcolor: "primary.soft",
                            borderRadius: 2,
                            border: 1,
                            borderColor: "primary.main",
                          }}
                        >
                          <Typography
                            variant="caption"
                            color="primary"
                            fontWeight="medium"
                          >
                            Selected
                          </Typography>
                        </Paper>
                      )}

                      {!isSelected && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {formWithType.type === "user" && (
                            <Box>
                              {userInfo?.img ? (
                                <Avatar
                                  variant="circular"
                                  src={userInfo.img}
                                  alt="user photo"
                                  sx={{ width: 25, height: 25 }}
                                />
                              ) : (
                                <AccountCircle
                                  fontSize="small"
                                  sx={{ color: "primary.main" }}
                                />
                              )}
                            </Box>
                          )}
                          {formWithType.type === "public" && (
                            <Box>
                              <Logo size={22} />
                            </Box>
                          )}
                          {formWithType.type === "group" && (
                            <Box>
                              <Groups
                                fontSize="small"
                                sx={{ color: "primary.main" }}
                              />
                            </Box>
                          )}
                        </Box>
                      )}

                      <Box sx={{ mb: 1 }}>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <Description
                            fontSize="small"
                            color={isSelected ? "primary" : "action"}
                          />
                          <Typography
                            variant="h6"
                            color={isSelected ? "primary.main" : "text.primary"}
                          >
                            {form.title}
                          </Typography>

                          {/* Preview Icon */}
                          <IconButton
                            size="small"
                            onClick={(e) => handlePreviewForm(form.form_id, e)}
                            sx={{
                              ml: "auto",
                              color: "text.secondary",
                              "&:hover": {
                                color: "primary.main",
                                backgroundColor: "action.hover",
                              },
                            }}
                          >
                            <Visibility fontSize="small" />
                          </IconButton>
                        </Stack>
                      </Box>

                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          mb: 1.5,
                          display: "-webkit-box",
                          overflow: "hidden",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: 2,
                        }}
                      >
                        {form.description}
                      </Typography>

                      <Box>
                        <Stack direction="row" spacing={1} alignItems="center">
                          {/* Form Type Indicator */}
                          {formWithType.form_type === "test" ? (
                            <>
                              <Quiz
                                fontSize="small"
                                color={isSelected ? "primary" : "action"}
                              />
                              <Typography
                                variant="body2"
                                color={
                                  isSelected ? "primary.main" : "text.secondary"
                                }
                                fontWeight={isSelected ? "medium" : "regular"}
                              >
                                Assessment
                              </Typography>
                            </>
                          ) : (
                            <>
                              <DocumentScanner
                                fontSize="small"
                                color={isSelected ? "primary" : "action"}
                              />
                              <Typography
                                variant="body2"
                                color={
                                  isSelected ? "primary.main" : "text.secondary"
                                }
                                fontWeight={isSelected ? "medium" : "regular"}
                              >
                                Form
                              </Typography>
                            </>
                          )}
                        </Stack>
                      </Box>
                    </Paper>
                  );
                })
              ) : (
                <Typography color="text.secondary" align="center">
                  No forms found. Try adjusting your search.
                </Typography>
              )}
            </Box>

            {availableForms.length > 0 && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Pagination
                  count={totalFormPages}
                  page={formListPage}
                  onChange={handleFormListPageChange}
                  color="primary"
                />
              </Box>
            )}
          </Box>

          <Box
            sx={{
              pt: 1,
              borderTop: 1,
              borderColor: "divider",
              mt: "auto",
              position: "sticky",
              bottom: 0,
              backgroundColor: "background.paper",
              zIndex: 1,
            }}
          >
            <Stack spacing={2}>
              <Button
                variant="contained"
                disabled={selectedFormIds.length === 0}
                onClick={handleCreateFormSession}
                fullWidth
                size="large"
                sx={{
                  fontWeight: "medium",
                  height: 48,
                  borderRadius: "0.5rem",
                  textTransform: "none",
                  color: "white",
                }}
                startIcon={<AddIcon />}
              >
                Assign and Send Selected Forms ({selectedFormIds.length})
              </Button>
            </Stack>
          </Box>
        </Box>
      </SlideSidePane>

      <SlideSidePane
        open={isFormDetailsOpen}
        onClose={handleFormDetailsClose}
        title={selectedForm?.title || "Form Details"}
        action={
          selectedForm && (
            <IconButton
              onClick={handleDeleteForm}
              size="small"
              aria-label="delete form"
            >
              <DeleteIcon />
            </IconButton>
          )
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Dialog
            open={isDeleteDialogOpen}
            onClose={handleCloseDeleteDialog}
            aria-labelledby="delete-dialog-title"
            aria-describedby="delete-dialog-description"
            container={() =>
              document.querySelector(".MuiSlideSidePane-content") as HTMLElement
            }
            sx={{
              "& .MuiDialog-paper": {
                width: "80%",
                maxWidth: 400,
                m: 0,
              },
            }}
          >
            <DialogTitle id="delete-dialog-title">Delete?</DialogTitle>
            <DialogContent>
              <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete this form from this{" "}
                {subjectLanguage}? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmDeleteForm} color="error" autoFocus>
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          {isLoadingDetail ? (
            <Box sx={{ p: 2 }}>
              <Skeleton variant="text" width="60%" height={32} sx={{ mb: 1 }} />
              <Skeleton variant="text" width="90%" height={20} sx={{ mb: 3 }} />

              <Skeleton
                variant="rectangular"
                width="100%"
                height={60}
                sx={{ mb: 2, borderRadius: 1 }}
              />

              <Box sx={{ mt: 4, mb: 2 }}>
                <Skeleton
                  variant="text"
                  width="40%"
                  height={24}
                  sx={{ mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={120}
                  sx={{ borderRadius: 1 }}
                />
              </Box>

              <Box sx={{ mt: 4 }}>
                <Skeleton
                  variant="text"
                  width="40%"
                  height={24}
                  sx={{ mb: 1 }}
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={200}
                  sx={{ borderRadius: 1 }}
                />
              </Box>
            </Box>
          ) : selectedForm && formDetail ? (
            <>
              <Box
                sx={{
                  p: 2,
                  flexGrow: 1,
                  overflow: "auto",
                  pb: 9,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {formDetail.form_definition.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {formDetail.form_definition.description}
                </Typography>
                <Box sx={{ mt: 3, mb: 3 }}>
                  <BaseBlock title="Form Status">
                    <Box
                      sx={{ display: "flex", alignItems: "center", mb: 1.5 }}
                    >
                      <Chip
                        label={
                          formDetail.status === "completed"
                            ? "Completed"
                            : formDetail.status === "in_progress"
                            ? "In Progress"
                            : "Pending"
                        }
                        color={
                          formDetail.status === "completed"
                            ? "success"
                            : formDetail.status === "in_progress"
                            ? "primary"
                            : "warning"
                        }
                        size="small"
                        sx={{ mr: 1.5 }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {formDetail.status === "completed"
                          ? "Form has been completed by the patient"
                          : formDetail.status === "in_progress"
                          ? "Patient has started but not completed the form"
                          : "Form has been assigned but not started"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        position: "relative",
                        mt: 3,
                        mb: 1,
                        "&::before": {
                          content: '""',
                          position: "absolute",
                          top: "50%",
                          left: 0,
                          right: 0,
                          height: 4,
                          bgcolor: "grey.200",
                          transform: "translateY(-50%)",
                          zIndex: 0,
                          borderRadius: 2,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          zIndex: 1,
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: "50%",
                            bgcolor: "success.main",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            mb: 1,
                          }}
                        >
                          1
                        </Box>
                        <Typography variant="caption" align="center">
                          Assigned
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          align="center"
                        >
                          {formDetail.created_timestamp
                            ? formatDate(formDetail.created_timestamp)
                            : "-"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          zIndex: 1,
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: "50%",
                            bgcolor: formDetail.start_timestamp
                              ? "success.main"
                              : "grey.400",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            mb: 1,
                          }}
                        >
                          2
                        </Box>
                        <Typography variant="caption" align="center">
                          Started
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          align="center"
                        >
                          {formDetail.start_timestamp
                            ? formatDate(formDetail.start_timestamp)
                            : "-"}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          position: "relative",
                          zIndex: 1,
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            width: 32,
                            height: 32,
                            borderRadius: "50%",
                            bgcolor: formDetail.end_timestamp
                              ? "success.main"
                              : "grey.400",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "white",
                            mb: 1,
                          }}
                        >
                          3
                        </Box>
                        <Typography variant="caption" align="center">
                          Completed
                        </Typography>
                        <Typography
                          variant="caption"
                          color="text.secondary"
                          align="center"
                        >
                          {formDetail.end_timestamp
                            ? formatDate(formDetail.end_timestamp)
                            : "-"}
                        </Typography>
                      </Box>
                    </Box>
                  </BaseBlock>
                </Box>

                {formDetail.status === "completed" && (
                  <BaseBlock
                    title="Form Submission"
                    actions={
                      <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        endIcon={<OpenInNewIcon />}
                        onClick={() =>
                          navigate(`/forms/${formDetail.form_session_id}`)
                        }
                        sx={{
                          borderRadius: "8px",
                          textTransform: "none",
                          fontSize: "0.85rem",
                          px: 2,
                        }}
                      >
                        Full View
                      </Button>
                    }
                  >
                    <FormDetailView
                      formDefinition={formDetail.form_definition}
                      submissionData={formDetail.submission_data}
                      mode="condensed"
                    />
                  </BaseBlock>
                )}
              </Box>

              {formDetail.status !== "completed" && (
                <Box
                  sx={{
                    position: "sticky",
                    bottom: 0,
                    p: 2,
                    bgcolor: "background.paper",
                    borderTop: 1,
                    borderColor: "divider",
                    mt: "auto",
                  }}
                >
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      const patientFormUrl = `${
                        import.meta.env.VITE_FORMS_URL
                      }/f/${formDetail.form_session_id}`;
                      navigator.clipboard
                        .writeText(patientFormUrl)
                        .then(() => {
                          enqueueSnackbar("Link copied to clipboard!", {
                            variant: "success",
                          });
                        })
                        .catch((error) => {
                          console.error("Failed to copy:", error);
                          enqueueSnackbar("Failed to copy link", {
                            variant: "error",
                          });
                        });
                    }}
                  >
                    Copy Patient Link
                  </Button>
                </Box>
              )}
            </>
          ) : (
            <Box sx={{ p: 2 }}>
              <Typography color="text.secondary">
                No form details available
              </Typography>
            </Box>
          )}
        </Box>
      </SlideSidePane>

      <SlideSidePane
        open={isPreviewOpen}
        onClose={handleClosePreview}
        title="Form Preview"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
            overflow: "auto",
          }}
        >
          {previewFormId && <FormPreview formId={previewFormId} />}
        </Box>
      </SlideSidePane>

      <SendFormOnboarding
        open={isOnboardingOpen}
        onClose={() => setIsOnboardingOpen(false)}
      />
    </>
  );
};

export default FormsListBlock;
