import React, { useState, useCallback, useMemo, useRef } from "react";
import {
  Box,
  Chip,
  Button,
  Select,
  MenuItem,
  SelectChangeEvent,
  Tooltip,
  Alert,
  TextField,
  Checkbox,
} from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowId,
  GridColumnVisibilityModel,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import BaseBlock from "./BaseBlock";
import { alpha } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import APIService from "../../../services/APIService";
import { useUser } from "../../../context/user";
import { debounce } from "lodash";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import SentryService from "../../../services/SentryService";
import { TutorialStep } from "../../Tutorial/TutorialStep";

interface SchemaField {
  name: string;
  description: string;
  required: boolean;
  type: string;
}

interface TableModuleData {
  current_data: Record<string, any[]>;
  current_version: number;
  differentials?: {
    dictionary_item_added?: Record<string, any[]>;
    dictionary_item_removed?: Record<string, any[]>;
    values_changed?: Record<string, any>;
    iterable_item_added?: Record<string, any[]>;
    iterable_item_removed?: Record<string, any[]>;
  };
  last_approved_data: any;
  last_approved_version: number;
  last_updated: string;
  patient_module_id: string;
  schema: SchemaField[];
  type: "table";
  name: string;
}

interface TableBlockProps {
  moduleData: TableModuleData;
  title: string;
  patientId: string;
  onDataUpdate?: (
    updatedData: any[],
    action: "accept" | "reject" | "edit"
  ) => void;
}

type RowDiffStatus = {
  isNew?: boolean;
  isRemoved?: boolean;
  changedFields?: string[];
  oldValues?: Record<string, any>;
};

type RowDiffMap = Map<string | number, RowDiffStatus>;

const processTableDifferentials = (moduleData: TableModuleData): RowDiffMap => {
  console.log("Processing differentials for module:", moduleData.name);
  const diffMap = new Map<string | number, RowDiffStatus>();
  const { differentials } = moduleData;

  console.log("Differentials:", differentials);

  if (!differentials) {
    console.log("No differentials found");
    return diffMap;
  }

  // Handle dictionary added items that contain arrays
  if (differentials.dictionary_item_added) {
    console.log(
      "Processing dictionary added items:",
      differentials.dictionary_item_added
    );
    Object.entries(differentials.dictionary_item_added).forEach(
      ([path, items]) => {
        console.log("Processing dictionary path:", path);
        if (Array.isArray(items)) {
          items.forEach((_, index) => {
            console.log("Marking item as new at index:", index);
            diffMap.set(index, { isNew: true });
          });
        }
      }
    );
  }

  // Handle dictionary removed items that contain arrays
  if (differentials.dictionary_item_removed) {
    console.log(
      "Processing dictionary removed items:",
      differentials.dictionary_item_removed
    );
    Object.entries(differentials.dictionary_item_removed).forEach(
      ([path, items]) => {
        console.log("Processing dictionary path:", path);
        if (Array.isArray(items)) {
          items.forEach((_, index) => {
            console.log("Marking item as removed at index:", index);
            diffMap.set(index, { isRemoved: true });
          });
        }
      }
    );
  }

  // Handle added items
  if (differentials.iterable_item_added) {
    console.log("Processing added items:", differentials.iterable_item_added);
    Object.entries(differentials.iterable_item_added).forEach(
      ([path, value]) => {
        console.log("Processing added item path:", path, "value:", value);
        // Extract index from path (e.g., "root['Diagnoses'][2]")
        const matches = path.match(/root\['[^']+'\]\[(\d+)\]/);
        if (matches) {
          const index = parseInt(matches[1]);
          console.log("Found new item at index:", index);
          diffMap.set(index, { isNew: true });
        }
      }
    );
  }

  // Handle removed items
  if (differentials.iterable_item_removed) {
    console.log(
      "Processing removed items:",
      differentials.iterable_item_removed
    );
    Object.entries(differentials.iterable_item_removed).forEach(
      ([path, value]) => {
        console.log("Processing removed item path:", path, "value:", value);
        const matches = path.match(/root\['[^']+'\]\[(\d+)\]/);
        if (matches) {
          const index = parseInt(matches[1]);
          console.log("Found removed item at index:", index);
          diffMap.set(index, { isRemoved: true });
        }
      }
    );
  }

  // Handle changed values
  if (differentials.values_changed) {
    Object.entries(differentials.values_changed).forEach(([path, change]) => {
      const matches = path.match(/root\['[^']+'\]\[(\d+)\]\['([^']+)'\]/);
      if (matches) {
        const [_, index, fieldName] = matches;
        const rowIndex = parseInt(index);

        const existing = diffMap.get(rowIndex) || {};
        const changedFields = existing.changedFields || [];
        const oldValues = existing.oldValues || {};

        // Store the old value
        oldValues[fieldName] = change.old_value;

        diffMap.set(rowIndex, {
          ...existing,
          changedFields: [...new Set([...changedFields, fieldName])],
          oldValues,
        });
      }
    });
  }

  console.log("Final diff map:", Object.fromEntries(diffMap));
  return diffMap;
};

const TableBlock: React.FC<TableBlockProps> = ({
  moduleData,
  title,
  patientId,
  onDataUpdate,
}) => {
  const { getAccessToken } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [filter, setFilter] = useState<"all" | "active" | "inactive">("all");
  const [isDraftMode, setIsDraftMode] = useState(false);
  const [showDifferentials, setShowDifferentials] = useState(true);
  const [editedRows, setEditedRows] = useState<Record<string, any>>({});
  const [pendingChanges, setPendingChanges] = useState<
    Record<GridRowId, GridRowModel>
  >({});
  const hasPendingChanges = Object.keys(pendingChanges).length > 0;
  const gridRef = useRef<any>(null);
  const apiRef = useGridApiRef();

  // Get the actual data array from the first key in current_data
  const dataKey = Object.keys(moduleData.current_data)[0];
  const tableData = moduleData.current_data[dataKey] || [];

  // Calculate initial column visibility based on data
  const initialColumnVisibilityModel = useMemo(() => {
    const visibilityModel: GridColumnVisibilityModel = {};

    moduleData.schema.forEach((field) => {
      if (tableData.length === 0) {
        // When there's no data, show all columns
        visibilityModel[field.name] = true;
      } else {
        // Existing logic for when there is data
        const hasValue = tableData.some((row: any) => {
          const value = row[field.name];
          return value !== null && value !== undefined && value !== "";
        });
        visibilityModel[field.name] = hasValue;
      }
    });

    return visibilityModel;
  }, [moduleData.schema, tableData]);

  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(initialColumnVisibilityModel);

  const handleFilterChange = (
    event: SelectChangeEvent<"all" | "active" | "inactive">
  ) => {
    setFilter(event.target.value as "all" | "active" | "inactive");
  };

  const formatValue = (value: any, type: string): any => {
    if (value === null || value === undefined) return "";
    if (type === "date" && value) return value.split("T")[0];
    if (type === "bool") {
      return (
        <Chip
          label={value ? "Active" : "Inactive"}
          color={value ? "primary" : "default"}
          size="small"
        />
      );
    }
    return value;
  };

  const diffMap = useMemo(
    () => processTableDifferentials(moduleData),
    [moduleData]
  );

  const handleApproveChanges = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/approvePatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
        },
      });

      if (response.ok) {
        setShowDifferentials(false);
        enqueueSnackbar("Changes approved.", { variant: "success" });
        if (onDataUpdate) {
          onDataUpdate(tableData, "accept");
        }
      } else {
        throw new Error("Failed to approve changes");
      }
    } catch (error) {
      console.error("Error approving changes:", error);
      // You might want to add error handling UI feedback here
    }
  };

  const handleRejectChanges = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/rejectPatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
        },
      });

      if (response.ok) {
        setShowDifferentials(false);
        // Update the current data with the last approved data
        const dataKey = Object.keys(moduleData.current_data)[0];
        moduleData.current_data[dataKey] =
          moduleData.last_approved_data?.[dataKey] || []; // Add fallback empty array
        // Clear the differentials to remove highlighting
        moduleData.differentials = undefined;
        enqueueSnackbar("Changes rejected", { variant: "success" });
        if (onDataUpdate) {
          onDataUpdate(
            moduleData.last_approved_data?.[dataKey] || [],
            "reject"
          );
        }
      } else {
        throw new Error("Failed to reject changes");
      }
    } catch (error) {
      console.error("Error rejecting changes:", error);
      enqueueSnackbar("Failed to reject changes", { variant: "error" });
    }
  };

  const handleSaveChanges = async () => {
    const dataKey = Object.keys(moduleData.current_data)[0];
    const updatedData = [...tableData];

    // Apply all pending changes
    Object.entries(pendingChanges).forEach(([id, newRow]) => {
      const index = parseInt(id);
      if (newRow._deleted) {
        // Remove deleted rows
        updatedData[index] = null;
      } else if (index >= updatedData.length) {
        // Add new rows at the end
        updatedData.push(newRow);
      } else {
        // Update existing rows
        updatedData[index] = { ...updatedData[index], ...newRow };
      }
    });

    // Filter out deleted rows
    const filteredData = updatedData.filter((row) => row !== null);

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/updatePatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
          data: { [dataKey]: filteredData },
        },
      });

      if (response.ok) {
        // Update the local tableData reference with the new filtered data
        moduleData.current_data[dataKey] = filteredData;
        setPendingChanges({});
        enqueueSnackbar("Changes saved", { variant: "success" });
        if (onDataUpdate) {
          onDataUpdate(filteredData, "edit");
        }
      } else {
        throw new Error("Failed to update module");
      }
    } catch (error) {
      console.error("Error updating module:", error);
      enqueueSnackbar("Failed to save changes", { variant: "error" });
      SentryService.logEvent("Error updating patient module", {
        level: "error",
        tags: {
          patient_id: patientId || "",
        },
        extra: {
          error: error,
        },
      });
    }
  };

  const rows = useMemo(() => {
    return tableData.map((row: any, index: number) => ({
      ...row,
      id: index,
    }));
  }, [tableData]);

  const columns: GridColDef[] = useMemo(() => {
    return [
      ...moduleData.schema.map((field) => ({
        field: field.name,
        headerName: field.name
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
        flex: 1,
        minWidth: 150,
        description: field.description,
        renderCell: (params: GridRenderCellParams) => {
          const rowDiff = showDifferentials ? diffMap.get(params.id) : null;
          const isChanged = rowDiff?.changedFields?.includes(field.name);
          const oldValue = rowDiff?.oldValues?.[field.name];

          const tooltipContent = isChanged
            ? `Previous value: ${formatValue(oldValue, field.type) || "empty"}`
            : "";

          return (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                padding: "8px",
              }}
            >
              <Tooltip
                title={tooltipContent}
                placement="top"
                arrow
                disableHoverListener={!isChanged}
              >
                <span
                  style={{
                    textDecoration:
                      isChanged && showDifferentials
                        ? "wavy underline #ed6c02"
                        : "none",
                    textUnderlineOffset: "4px",
                    cursor: isChanged ? "help" : "default",
                  }}
                >
                  {formatValue(params.value, field.type)}
                </span>
              </Tooltip>
            </Box>
          );
        },
        editable: !isDraftMode,
        renderEditCell: (params: GridRenderCellParams) => {
          const value = params.value;

          switch (field.type) {
            case "bool":
              return (
                <Checkbox
                  checked={!!value}
                  onChange={(e) =>
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.checked,
                    })
                  }
                  autoFocus
                />
              );
            case "date":
              return (
                <TextField
                  type="date"
                  value={value ? value.split("T")[0] : ""}
                  onChange={(e) =>
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  autoFocus
                  InputProps={{
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
              );
            default:
              return (
                <TextField
                  value={value || ""}
                  onChange={(e) =>
                    params.api.setEditCellValue({
                      id: params.id,
                      field: params.field,
                      value: e.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  autoFocus
                  InputProps={{
                    onClick: (e) => e.stopPropagation(),
                  }}
                />
              );
          }
        },
      })),
      {
        field: "actions",
        headerName: "",
        width: 50,
        sortable: false,
        filterable: false,
        editable: false,
        renderCell: (params) => (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              const rowId = params.id;
              // For new rows, just remove from pendingChanges
              if (Number(rowId) >= rows.length) {
                setPendingChanges((prev) => {
                  const newChanges = { ...prev };
                  delete newChanges[rowId];
                  return newChanges;
                });
                return;
              }
              // For existing rows, mark as pending deletion
              setPendingChanges((prev) => ({
                ...prev,
                [rowId]: { _deleted: true },
              }));
            }}
            sx={{
              color: "grey.500",
              "&:hover": {
                color: "error.main",
              },
            }}
          >
            <RemoveCircleOutlineIcon fontSize="small" />
          </IconButton>
        ),
      },
    ];
  }, [moduleData.schema, isDraftMode, rows.length, showDifferentials, diffMap]);

  const filteredRows = useMemo(() => {
    if (!("isActive" in (rows[0] || {}))) return rows;

    return rows.filter((row) => {
      switch (filter) {
        case "active":
          return row.isActive;
        case "inactive":
          return !row.isActive;
        default:
          return true;
      }
    });
  }, [rows, filter]);

  const getRowClassName = useCallback(
    (params: any) => {
      if (!showDifferentials) {
        const isPendingChange = params.id in pendingChanges;
        const isNewRow = params.id >= rows.length;
        return isPendingChange || isNewRow ? "modified-row" : "";
      }
      const rowDiff = diffMap.get(params.id);
      if (rowDiff?.isNew) return "new-row";
      if (rowDiff?.isRemoved) return "removed-row";
      return "";
    },
    [diffMap, showDifferentials, pendingChanges, rows.length]
  );

  const handleAddNew = () => {
    const defaultValues = moduleData.schema.reduce((acc, field) => {
      switch (field.type) {
        case "bool":
          acc[field.name] = false;
          break;
        case "number":
          acc[field.name] = 0;
          break;
        default:
          acc[field.name] = "";
      }
      return acc;
    }, {} as Record<string, any>);

    // Find the next available ID by getting the maximum ID and adding 1
    const maxId = Math.max(...displayRows.map((row) => row.id), -1);
    const newRowId = maxId + 1;

    setPendingChanges((prev) => ({
      ...prev,
      [newRowId]: defaultValues,
    }));

    // Start editing the first cell after a short delay to ensure the row is rendered
    setTimeout(() => {
      const firstColumn = moduleData.schema[0]?.name;
      if (firstColumn && apiRef.current) {
        apiRef.current.startCellEditMode({ id: newRowId, field: firstColumn });
      }
    }, 100);
  };

  const actions = (
    <>
      {rows.some((row) => "isActive" in row) && (
        <Select
          value={filter}
          onChange={handleFilterChange}
          size="small"
          sx={{ minWidth: 120, mr: 2 }}
        >
          <MenuItem value="all">All</MenuItem>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
        </Select>
      )}
      {!isDraftMode && onDataUpdate && (
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleAddNew}
        >
          Add
        </Button>
      )}
    </>
  );

  // Handle auto-approval of AI changes when user makes an edit
  const handleProcessRowUpdate = async (newRow: GridRowModel) => {
    // If there are pending AI changes, approve them first
    if (showDifferentials && diffMap.size > 0) {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIPostRequest({
          requestString: "/patient/approvePatientModule",
          accessToken,
          body: {
            patient_id: patientId,
            patient_module_id: moduleData.patient_module_id,
          },
        });

        if (response.ok) {
          setShowDifferentials(false);
          enqueueSnackbar("AI changes automatically approved", {
            variant: "info",
          });
          if (onDataUpdate) {
            onDataUpdate(tableData, "accept");
          }
        }
      } catch (error) {
        console.error("Error auto-approving AI changes:", error);
        enqueueSnackbar("Failed to approve AI changes", { variant: "error" });
        return newRow; // Return without applying new changes if approval fails
      }
    }

    // Process the user's edit
    setPendingChanges((prev) => ({
      ...prev,
      [newRow.id]: newRow,
    }));
    return newRow;
  };

  const displayRows = useMemo(() => {
    let allRows = [...rows];

    // Add any pending changes, including new rows
    Object.entries(pendingChanges).forEach(([id, rowData]) => {
      const index = parseInt(id);
      if (rowData._deleted) {
        // Skip deleted rows
        allRows[index] = null;
      } else if (index >= rows.length) {
        // This is a new row
        allRows.push({ ...rowData, id: index });
      } else {
        // This is a modification to an existing row
        allRows[index] = { ...allRows[index], ...rowData, id: index };
      }
    });

    // Filter out deleted rows
    allRows = allRows.filter((row) => row !== null);

    // Apply the filter after all rows are processed
    if (!("isActive" in (rows[0] || {}))) return allRows;

    return allRows.filter((row) => {
      switch (filter) {
        case "active":
          return row.isActive;
        case "inactive":
          return !row.isActive;
        default:
          return true;
      }
    });
  }, [rows, pendingChanges, filter]);

  return (
    <BaseBlock title={title} actions={actions}>
      {showDifferentials && diffMap.size > 0 && (
        <Alert
          severity="info"
          action={
            <Box sx={{ display: "flex", gap: 1 }}>
              <Button
                startIcon={<CheckIcon />}
                variant="contained"
                color="success"
                size="small"
                onClick={handleApproveChanges}
              >
                Accept Changes
              </Button>
              <Button
                startIcon={<CloseIcon />}
                variant="contained"
                color="error"
                size="small"
                onClick={handleRejectChanges}
              >
                Reject Changes
              </Button>
            </Box>
          }
          sx={{ mb: 2 }}
        >
          This table has pending changes that need to be approved.
        </Alert>
      )}

      {hasPendingChanges && (
        <Alert
          severity="warning"
          sx={{ mb: 2 }}
          action={
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          }
        >
          You have unsaved changes in the table.
        </Alert>
      )}

      <Box
        sx={{
          width: "100%",
          maxHeight: "50vh",
          "& .MuiDataGrid-root": {
            border: "none",
            height: displayRows.length > 10 ? "50vh" : "auto",
            "& .MuiDataGrid-columnHeaders": {
              position: "sticky",
              top: 0,
              zIndex: 1,
              borderBottom: 2,
              borderColor: "borderColors.primary",
              bgcolor: "backgroundColors.tableHeader",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: 1,
              borderColor: "borderColors.secondary",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflow: "auto",
              "&::-webkit-scrollbar": {
                width: "0.4em",
                height: "0.4em",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "scrollbarColors.thumb",
                borderRadius: "20px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                backgroundColor: "scrollbarColors.thumbHover",
              },
            },
          },
        }}
      >
        <DataGrid
          apiRef={apiRef}
          rows={displayRows}
          columns={columns}
          autoHeight={displayRows.length <= 10}
          disableRowSelectionOnClick
          getRowClassName={getRowClassName}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            setColumnVisibilityModel(newModel);
          }}
          hideFooter={rows.length <= 100}
          processRowUpdate={handleProcessRowUpdate}
          sx={{
            "& .modified-row": {
              bgcolor: (theme) => alpha(theme.palette.info.main, 0.1),
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.info.main, 0.2),
              },
            },
            "& .new-row": {
              bgcolor: (theme) => alpha(theme.palette.success.main, 0.1),
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.success.main, 0.2),
              },
            },
            "& .removed-row": {
              bgcolor: (theme) => alpha(theme.palette.grey[500], 0.1),
              "&:hover": {
                bgcolor: (theme) => alpha(theme.palette.grey[500], 0.2),
              },
            },
          }}
        />
      </Box>
    </BaseBlock>
  );
};

export default TableBlock;
