import { useEffect, useState } from "react";
import { BorderBox } from "./layout/BorderBox";
import { UserNote } from "./UserNote";
import { NoteCounts, UserNoteTypeII } from "../types/types";
import {
  Box,
  Typography,
  Link,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Stack,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";
import Pagination from "./Pagination";
import { FilterDropdown } from "./FilterDropDown";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Height, Search } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EmptyNoteList } from "../components/EmptyNoteList";
import { NotesLoader } from "../loaders/DashboardLoader";
import { useUser } from "../context/user";
import { DeleteModal } from "./DeleteModal";
import APIService from "../services/APIService";
import LayoutWrapper from "./layout/UILayout";
import { removeScrollBar } from "../styles/globalStyles";
import { DismissableCard } from "../Views/Shared/DismissableCard";
import {
  getCachedNotes,
  setCachedNotes,
  isCacheValid,
} from "../utils/notesCache";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import type { Dayjs } from "dayjs";
import { useDebounce } from "../hooks/useDebounce";
import TitleIcon from "@mui/icons-material/Title";

export const UserNotesList = ({}: {}) => {
  const { getAccessToken } = useUser();
  const theme = useTheme();

  const [sortedNotes, setSortedNotes] = useState<UserNoteTypeII[]>([]);
  const [notesToDisplay, setNotesToDisplay] = useState<string>(() => {
    const savedSelection = localStorage.getItem("noteFilterSelection");
    return savedSelection || "reviewing";
  });
  const [itemsPerPage, setItemsPerPage] = useState<number>(() => {
    const savedItemsPerPage = localStorage.getItem("noteItemsPerPage");
    return savedItemsPerPage ? parseInt(savedItemsPerPage) : 20;
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedNoteIds, setCheckedNoteIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userState } = useUser();
  const [sortOrder, setSortOrder] = useState<number>(() => {
    const savedSortOrder = localStorage.getItem("noteSortOrder");
    return savedSortOrder ? parseInt(savedSortOrder) : -1;
  });
  const [searchTerm, setSearchTerm] = useState<string>("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay
  const [noteTitleOnly, setNoteTitleOnly] = useState<boolean>(() => {
    const savedPreference = localStorage.getItem("noteTitleOnly");
    return savedPreference ? JSON.parse(savedPreference) : false;
  });

  const [totalCount, setTotalCount] = useState<number>(0);
  const [loadError, setLoadError] = useState(false);
  const [isLoadingFresh, setIsLoadingFresh] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(null);

  const getCurrentFilters = () => ({
    category: notesToDisplay as "all" | "reviewing" | "completed",
    sortOrder,
    searchTerm: debouncedSearchTerm,
    itemsPerPage,
    currentPage,
    startDate,
    endDate,
    noteTitleOnly,
  });

  const fetchAndUpdateNotes = async (showLoading = true) => {
    if (showLoading) {
      setIsLoading(true);
    }
    setIsLoadingFresh(true);

    try {
      const currentFilters = getCurrentFilters();
      const { notes, totalCount } = await NotesLoader(
        currentFilters.category,
        itemsPerPage,
        (currentPage - 1) * itemsPerPage,
        sortOrder,
        currentFilters.searchTerm,
        undefined,
        startDate ? (startDate.valueOf() / 1000).toString() : null,
        endDate ? (endDate.valueOf() / 1000).toString() : null,
        currentFilters.noteTitleOnly
      );

      setSortedNotes(notes);
      setTotalCount(totalCount);
      setLoadError(false);

      // Update cache
      setCachedNotes({
        notes,
        totalCount,
        filters: currentFilters,
        timestamp: Date.now(),
      });
    } catch (error) {
      setLoadError(true);
      console.error("Error fetching notes:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingFresh(false);
      setIsInitialLoad(false);
    }
  };

  useEffect(() => {
    const currentFilters = getCurrentFilters();
    const cache = getCachedNotes(currentFilters);

    if (cache?.notes && isCacheValid(cache, currentFilters)) {
      // Use cached data
      setSortedNotes(cache.notes);
      setTotalCount(cache.totalCount);
      setIsLoading(false);
      setIsInitialLoad(false);

      // Fetch fresh data in background
      fetchAndUpdateNotes(false);
    } else {
      // No valid cache, do a normal fetch
      fetchAndUpdateNotes(true);
    }
  }, [
    currentPage,
    itemsPerPage,
    notesToDisplay,
    sortOrder,
    debouncedSearchTerm,
    startDate,
    endDate,
    noteTitleOnly,
  ]);

  const handleSearch = async () => {
    await fetchAndUpdateNotes(true);
  };

  const filterItems = ["a-z", "Z-A", "recent", "oldest"];

  const sortNotes = (notes: UserNoteTypeII[]) => {
    const sorted = notes.sort((a, b) =>
      sortOrder === -1
        ? b.time_uploaded - a.time_uploaded
        : a.time_uploaded - b.time_uploaded
    );
    return sorted;
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteNotes = () => {
    if (checkedNoteIds.length > 0) {
      checkedNoteIds.forEach((noteId) => {
        const deleteNote = async () => {
          const accessToken = await getAccessToken();
          const response = await APIService.makeAPIPostRequest({
            requestString: "/notes/delete",
            accessToken: accessToken,
            body: { note_id: noteId },
          });

          if (response.ok) {
            const data = response.value;
            if (data.status === 200) {
              const win: Window = window;
              win.location = `/notes`;
            }
          }
        };

        deleteNote();
      });
      setShowDeleteModal(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleDeleteNotesClick = () => {
    setShowDeleteModal(true);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement> | SelectChangeEvent<number>
  ) => {
    const newItemsPerPage = parseInt(event.target.value as string);
    localStorage.setItem("noteItemsPerPage", newItemsPerPage.toString());
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSortOrderChange = (event: SelectChangeEvent) => {
    const newSortOrder = event.target.value as unknown as number;

    // Store the sort order preference
    localStorage.setItem("noteSortOrder", newSortOrder.toString());

    // Don't clear notes immediately if we have a valid cache
    const newFilters = {
      ...getCurrentFilters(),
      sortOrder: newSortOrder,
    };

    const cache = getCachedNotes(newFilters);
    if (cache?.notes && isCacheValid(cache, newFilters)) {
      // Use cached data immediately
      setSortedNotes(cache.notes);
      setTotalCount(cache.totalCount);
      setIsLoadingFresh(true); // Show small loading indicator
      setIsLoading(false); // Don't show full loading state
    } else {
      // No cache, show loading state
      setSortedNotes([]);
      setIsLoading(true);
    }

    setSortOrder(newSortOrder);
  };

  const handleNotesToDisplayChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setNotesToDisplay(newValue);
    localStorage.setItem("noteFilterSelection", newValue);
  };

  const handleNoteTitleOnlyChange = (newValue: boolean) => {
    setNoteTitleOnly(newValue);
    localStorage.setItem("noteTitleOnly", JSON.stringify(newValue));
  };

  return (
    <LayoutWrapper>
      <LayoutWrapper.MainContent>
        {isLoadingFresh && !isInitialLoad && (
          <Box
            sx={{
              position: "fixed",
              top: "1rem",
              right: "1rem",
              zIndex: 1000,
            }}
          >
            <CircularProgress size={24} />
          </Box>
        )}
        <DeleteModal
          isOpen={showDeleteModal}
          continueText="Delete"
          cancelText="Cancel"
          onCancel={() => {
            setShowDeleteModal(false);
          }}
          onContinue={handleDeleteNotes}
        >
          Are you sure you want to delete this note? This action cannot be
          undone.
        </DeleteModal>
        <Box
          className="NotesListNav-Container"
          sx={{
            display: "flex",
            flexDirection: { xs: "column" },
            alignItems: { xs: "stretch", md: "flex-start" },
            width: "100%",
            padding: { xs: "1rem", md: "2rem 4rem" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              mb: 2,
              gap: 2,
              flexGrow: 1,
            }}
          >
            <Select
              value={notesToDisplay}
              onChange={handleNotesToDisplayChange}
              sx={{
                minWidth: 120,
                width: { xs: "100%", sm: "auto" },
                flexGrow: 1,
              }}
            >
              <MenuItem value="all">All Notes</MenuItem>
              <MenuItem value="reviewing">Reviewing</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
            <Select
              value={sortOrder as unknown as string}
              onChange={handleSortOrderChange}
              sx={{
                minWidth: 120,
                width: { xs: "100%", sm: "auto" },
                flexGrow: 1,
              }}
            >
              <MenuItem value={-1}>Newest First</MenuItem>
              <MenuItem value={1}>Oldest First</MenuItem>
            </Select>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              sx={{
                minWidth: 120,
                width: { xs: "100%", sm: "auto" },
                flexGrow: 1,
              }}
            >
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                slotProps={{
                  textField: {
                    size: "medium",
                    sx: { width: { xs: "100%", sm: "auto" }, flexGrow: 1 },
                  },
                }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                slotProps={{
                  textField: {
                    size: "medium",
                    sx: { width: { xs: "100%", sm: "auto" }, flexGrow: 1 },
                  },
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              mb: 2,
              gap: 2,
            }}
          >
            <TextField
              placeholder="Search notes..."
              variant="outlined"
              size="medium"
              sx={{
                flexGrow: 1,
                width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" },
              }}
              value={searchTerm}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip
                      title={
                        noteTitleOnly
                          ? "Searching note titles only"
                          : "Search all note content"
                      }
                    >
                      <IconButton
                        onClick={() =>
                          handleNoteTitleOnlyChange(!noteTitleOnly)
                        }
                        color={noteTitleOnly ? "primary" : "default"}
                        size="small"
                        sx={{ mr: 1 }}
                      >
                        <TitleIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton onClick={handleSearch} disabled={isLoading}>
                      {isLoading ? <CircularProgress size={24} /> : <Search />}
                    </IconButton>
                  </>
                ),
              }}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "stretch",
              gap: 2,
              width: { xs: "100%", sm: "auto" },
            }}
          >
            <Button
              variant="outlined"
              onClick={() =>
                checkedNoteIds.length === sortedNotes.length
                  ? setCheckedNoteIds([])
                  : setCheckedNoteIds(sortedNotes.map((note) => note.note_id))
              }
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              {checkedNoteIds.length === sortedNotes.length
                ? "Deselect All"
                : "Select All"}
            </Button>
            {/* <Button
              variant="outlined"
              onClick={handleArchiveNotesClick}
              startIcon={<CheckIcon />}
              disabled={
                checkedNoteIds.length === 0 || notesToDisplay === "completed"
              }
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Complete
            </Button> */}
            <Button
              variant="outlined"
              onClick={handleDeleteNotesClick}
              startIcon={<DeleteIcon />}
              color="error"
              disabled={checkedNoteIds.length === 0}
              sx={{ width: { xs: "100%", sm: "auto" } }}
            >
              Delete
            </Button>
          </Box>
        </Box>
        <Box
          className="notesListMain-container"
          sx={{
            padding: { xs: "1rem", sm: "2rem", md: "4.5rem" },
            paddingTop: "1rem",
          }}
        >
          <Box className="notes-container" sx={{ paddingTop: "1rem" }}>
            {isLoading && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  height: "25vh",
                }}
              >
                <Typography
                  variant="h4"
                  color="textColors.lightHeader"
                  sx={{
                    fontSize: {
                      xs: "1.5rem",
                      sm: "1.75rem",
                      md: "2rem",
                      lg: "2.25rem",
                    },
                    textAlign: "center",
                  }}
                >
                  Loading Notes...
                </Typography>
              </Box>
            )}
            {!isLoading &&
              !userState?.onboardingSteps?.generated_first_note && (
                <Box
                  sx={{
                    border: 1,
                    borderColor: "borderColors.primary",
                    borderRadius: "0.75rem",
                    padding: { xs: "1rem", sm: "2rem" },
                    textAlign: "center",
                    margin: { xs: "1rem", sm: "0" },
                  }}
                >
                  <EmptyNoteList />
                </Box>
              )}
            {!isLoading && (
              <>
                {sortedNotes.map((note: UserNoteTypeII, index: number) => {
                  return (
                    <UserNote
                      key={note.note_id}
                      index={index}
                      note_id={note.note_id}
                      note_title={note.note_title}
                      patient_id={note.patient_id}
                      patient_name={note.patient_name}
                      status={note.status}
                      review_status={note.review_status}
                      time={note.time_uploaded}
                      summary={note.summary}
                      link={
                        note.status === "completed"
                          ? `/note/${note.note_id}`
                          : `/status/${note.note_id}`
                      }
                      checkedNoteIds={checkedNoteIds}
                      setCheckedNoteIds={setCheckedNoteIds}
                      notesToDisplay={notesToDisplay}
                      origin="user"
                      error_message={note.error_message}
                      completion_status={note.completion_status}
                      completion_timestamp={note.completion_timestamp}
                    />
                  );
                })}
              </>
            )}

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: 1,
                marginTop: 2,
              }}
            >
              <Pagination
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalItems={totalCount}
                onPageChange={handlePageChange}
              />
            </Box>
          </Box>
        </Box>
      </LayoutWrapper.MainContent>
      <LayoutWrapper.RightSidebar>
        <DismissableCard
          headerTitle="Notes List"
          bodyText={[
            "Here, you can view all of your notes in one place. By using the controls on the top, you can filter and search through your notes.",
            "Careful! Deleting a note is irreversible. Once it's gone, we cannot recover it.",
          ]}
        />
      </LayoutWrapper.RightSidebar>
    </LayoutWrapper>
  );
};
