import React from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  IconButton,
  SelectChangeEvent,
  Paper,
  Collapse,
  Chip,
} from "@mui/material";
import {
  Search,
  Add as AddIcon,
  Person,
  Title,
  Description,
  Article,
  CalendarMonth,
  FilterList,
  CheckCircle,
  Pending,
  Loop,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Encounter } from "../../types/types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Pagination from "../Pagination";
import { format, formatDistanceToNow, isPast } from "date-fns";
import { useUser } from "../../context/user";
import { useEncountersData } from "../../context/EncountersDataContext";

export const EncounterListView: React.FC = () => {
  const navigate = useNavigate();
  const { getAccessToken, templatesList } = useUser();

  // Get all data from context
  const {
    allEncounterData,
    isLoading,
    isBackgroundLoading,
    totalCount,
    filterMode,
    setFilterMode,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sortOrder,
    setSortOrder,
    refreshEncounters,
  } = useEncountersData();

  // Add a function to clear both date filters
  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleSortOrderChange = (e: SelectChangeEvent) => {
    const newSortOrder = e.target.value;
    setSortOrder(newSortOrder === "default" ? null : newSortOrder);
  };

  const handleStatusChange = (e: SelectChangeEvent) => {
    const newStatus = e.target.value as typeof filterMode;
    setFilterMode(newStatus);
  };

  const handleItemsPerPageChange = (e: SelectChangeEvent) => {
    const newValue = e.target.value;
    setItemsPerPage(newValue);
  };

  const renderEncounter = (encounter: Encounter) => (
    <Paper
      key={encounter.encounter_id}
      onClick={() => navigate(`/encounters/${encounter.encounter_id}`)}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: "background.paper",
        border: 1,
        borderRadius: "0.75rem",
        borderColor: "borderColors.primary",
        cursor: "pointer",
        transition: "all 0.2s",
        "&:hover": {
          bgcolor: "action.hover",
          transform: "translateY(-2px)",
          boxShadow: 1,
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            {encounter.patient ? (
              <Person sx={{ fontSize: 16, color: "text.secondary" }} />
            ) : (
              <Title sx={{ fontSize: 16, color: "text.secondary" }} />
            )}
            <Typography variant="h6">
              {encounter.patient
                ? `${encounter.patient.first_name} ${encounter.patient.last_name}`
                : encounter.note_title || "Untitled Encounter"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Description sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {encounter.encounter_type || "No Template Selected"}
              </Typography>
              <Article sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {encounter.inputs.length}
              </Typography>
            </Stack>
          </Stack>
          {encounter.scheduled_for && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CalendarMonth sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {format(
                  new Date(encounter.scheduled_for),
                  "MMM d, yyyy 'at' h:mm a"
                )}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Stack spacing={1} alignItems="center">
            {encounter.scheduled_for ? (
              <Paper
                elevation={0}
                sx={{
                  px: 2,
                  py: 1,
                  bgcolor: isPast(new Date(encounter.scheduled_for))
                    ? "error.soft"
                    : "success.soft",
                  borderRadius: 2,
                  minWidth: 100,
                  textAlign: "center",
                  border: 1,
                  borderColor: isPast(new Date(encounter.scheduled_for))
                    ? "error.main"
                    : "success.main",
                }}
              >
                <Typography
                  variant="body2"
                  color={
                    isPast(new Date(encounter.scheduled_for))
                      ? "error.main"
                      : "success.main"
                  }
                >
                  {isPast(new Date(encounter.scheduled_for))
                    ? `${formatDistanceToNow(
                        new Date(encounter.scheduled_for)
                      )} ago`
                    : `in ${formatDistanceToNow(
                        new Date(encounter.scheduled_for)
                      )}`}
                </Typography>
              </Paper>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Not Scheduled
              </Typography>
            )}
            {encounter.status !== "pending" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {encounter.status === "processing" ? (
                  <>
                    <Loop fontSize="small" sx={{ color: "warning.main" }} />
                    <Typography variant="body2" color="warning.main">
                      Processing
                    </Typography>
                  </>
                ) : encounter.status === "completed" ? (
                  <>
                    <CheckCircle
                      fontSize="small"
                      sx={{ color: "success.main" }}
                    />
                    <Typography variant="body2" color="success.main">
                      Completed
                    </Typography>
                  </>
                ) : null}
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );

  return (
    <Box sx={{ p: 2, position: "relative" }}>
      {/* Background loading indicator */}
      {isBackgroundLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 2,
            p: 1.5,
            borderRadius: 1,
            bgcolor: "background.paper",
            boxShadow: 1,
            display: "flex",
            alignItems: "center",
            border: "1px solid",
            borderColor: "primary.light",
          }}
        >
          <CircularProgress size={16} sx={{ color: "primary.main" }} />
        </Box>
      )}

      {/* Unified Filter Bar */}
      <Paper
        sx={{
          p: 2,
          mb: 3,
          display: "flex",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          borderRadius: "0.75rem",
        }}
      >
        <Select
          value={filterMode}
          onChange={handleStatusChange}
          size="small"
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="upcoming">Upcoming</MenuItem>
          <MenuItem value="today">Today</MenuItem>
          <MenuItem value="week">This Week</MenuItem>
          <MenuItem value="past">Past Appointments</MenuItem>
          <MenuItem value="all">All Appointments</MenuItem>
        </Select>

        <Select
          value={itemsPerPage}
          onChange={handleItemsPerPageChange}
          size="small"
          sx={{ minWidth: 120 }}
        >
          <MenuItem value="20">20 per page</MenuItem>
          <MenuItem value="50">50 per page</MenuItem>
          <MenuItem value="100">100 per page</MenuItem>
        </Select>

        <Select
          value={sortOrder || "default"}
          onChange={handleSortOrderChange}
          size="small"
          sx={{ minWidth: 150 }}
        >
          <MenuItem value="default">Default Sorting</MenuItem>
          <MenuItem value="newest">Newest First</MenuItem>
          <MenuItem value="oldest">Oldest First</MenuItem>
        </Select>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate ? dayjs(startDate) : null}
            onChange={(newValue) =>
              setStartDate(newValue ? newValue.toDate() : null)
            }
            slotProps={{
              textField: {
                size: "small",
                sx: { width: 150 },
              },
            }}
          />
          <DatePicker
            label="End Date"
            value={endDate ? dayjs(endDate) : null}
            onChange={(newValue) =>
              setEndDate(newValue ? newValue.toDate() : null)
            }
            slotProps={{
              textField: {
                size: "small",
                sx: { width: 150 },
              },
            }}
          />
        </LocalizationProvider>

        {(startDate || endDate) && (
          <Button variant="outlined" size="small" onClick={handleClearDates}>
            Clear Dates
          </Button>
        )}
      </Paper>

      {/* Encounters List */}
      <Stack spacing={2}>
        {isLoading ? (
          <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
            <CircularProgress />
          </Box>
        ) : allEncounterData.length === 0 ? (
          <Box sx={{ textAlign: "center", p: 4 }}>
            <Typography variant="h6" color="text.secondary">
              No encounters found
            </Typography>
          </Box>
        ) : (
          allEncounterData.map(renderEncounter)
        )}
      </Stack>

      {/* Pagination */}
      {!isLoading &&
        allEncounterData.length > 0 &&
        totalCount > Number(itemsPerPage) && (
          <Box sx={{ mt: 4, display: "flex", justifyContent: "center" }}>
            <Pagination
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              totalItems={totalCount}
              onPageChange={setCurrentPage}
            />
          </Box>
        )}
    </Box>
  );
};

export default EncounterListView;
