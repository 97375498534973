import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useState,
  useEffect,
} from "react";
import {
  Encounter,
  EncounterDocument,
  Patient,
  UserTemplateItem,
  EncounterDocumentReference,
  LocalStateEncounter,
} from "../../types/types";
import { useRecordingContext } from "../../context/RecordingContext";
import { RecordingStatus } from "@shared/Types/RecordingTypes";
import { useUIState } from "../../context/uiState";
import { useLocalStorageState } from "../../hooks/useLocalStorageState";
import { v4 as uuidv4 } from "uuid";
import { encounterApi } from "./encounters";
import { useUser } from "../../context/user";

type EncounterAction =
  | { type: "SET_PATIENT"; payload: Patient | undefined | null }
  | { type: "CLEAR_PATIENT" }
  | { type: "START_RECORDING" }
  | { type: "STOP_RECORDING"; payload: Blob }
  | { type: "UPDATE_NOTES"; payload: string }
  | { type: "RESET_ENCOUNTER" }
  | {
      type: "ADD_DOCUMENT";
      payload: EncounterDocumentReference;
      prepend?: boolean;
    }
  | { type: "SET_ACTIVE_DOCUMENT"; payload: string }
  | { type: "RECORDING_COMPLETE"; payload: { duration: number } }
  | { type: "SET_SUBMITTING"; payload: boolean }
  | { type: "CLEAR_RECORDING" }
  | {
      type: "ADD_RECORDING_AS_FORM";
      payload: {
        transcriptionSessionId: string;
        title: string;
      };
    }
  | { type: "LOAD_ENCOUNTER"; payload: Encounter }
  | { type: "UPDATE_ENCOUNTER"; payload: Partial<Encounter> }
  | { type: "DELETE_DOCUMENT"; payload: string }
  | { type: "SET_SCHEDULE"; payload: Date | undefined | null }
  | { type: "CLEAR_SCHEDULE" }
  | { type: "UPDATE_LOCAL_STATE"; payload: Partial<LocalStateEncounter> }
  | {
      type: "UPDATE_LOCAL_DOCUMENT";
      payload: {
        encounter_document_id: string;
        updates: LocalDocumentState;
      };
    }
  | {
      type: "SYNC_LOCAL_DOCUMENT";
      payload: {
        encounter_document_id: string;
        updates: LocalDocumentState;
      };
    }
  | { type: "SET_STATUS"; payload: "pending" | "processing" | "completed" }
  | { type: "SET_ACTIVE_ENCOUNTER"; payload: string }
  | { type: "CLEAR_ACTIVE_ENCOUNTER" }
  | {
      type: "SET_RECORDING_TIMES";
      payload: {
        startTime: Date | null;
        endTime?: Date | null;
      };
    };

interface EncounterValidation {
  canSubmit: boolean;
  missingRequirements: string[];
  disablingReasons: string[];
}

interface EncounterContextType {
  state: Encounter;
  dispatch: React.Dispatch<EncounterAction>;
  activeEncounterId: string | undefined;
}

interface LocalDocumentState {
  content?: string;
  title?: string;
  // Add any future fields here
}

const initialState: Encounter = {
  encounter_id: uuidv4(),
  patient_id: "",
  provider_id: "",
  organization_id: "",
  inputs: [],
  outputs: [],
  template_id: "",
  encounter_type: "",
  note_title: "",
  start_time: "",
  end_time: "",
  status: "pending",
  scheduled_for: "",
  scheduled_duration: undefined,
  created_at: "",
  modified_at: "",
  note_id: "",
  _localState: {
    localDocumentState: {},
  },
};

const EncounterContext = createContext<EncounterContextType | undefined>(
  undefined
);

function encounterReducer(
  state: Encounter,
  action: EncounterAction
): Encounter {
  const newState = (() => {
    switch (action.type) {
      case "SET_PATIENT":
        return {
          ...state,
          patient: action.payload,
          patient_id: action.payload ? action.payload.patient_id : null,
          note_title: action.payload ? null : state.note_title,
        };
      case "CLEAR_PATIENT":
        return {
          ...state,
          patient: null,
          patient_id: null,
          note_title: state.note_title,
        };
      case "START_RECORDING":
        return {
          ...state,
          isRecording: true,
        };
      case "STOP_RECORDING":
        return {
          ...state,
          isRecording: false,
          recordingData: action.payload,
        };
      case "UPDATE_NOTES":
        return {
          ...state,
          encounterNotes: action.payload,
        };
      case "RESET_ENCOUNTER":
        return {
          ...initialState,
        };
      case "ADD_DOCUMENT":
        try {
          return {
            ...state,
            inputs: [action.payload, ...state.inputs],
          };
        } catch (error) {
          console.error("Failed to create document:", error);
          return state;
        }
      case "SET_ACTIVE_DOCUMENT":
        return {
          ...state,
          activeDocumentId: action.payload,
        };
      case "RECORDING_COMPLETE":
        return {
          ...state,
          recordingStatus: {
            isComplete: true,
            duration: action.payload.duration,
          },
        };
      case "SET_SUBMITTING":
        return {
          ...state,
          recordingStatus: {
            isSubmitting: action.payload,
          },
        };
      case "CLEAR_RECORDING":
        return {
          ...state,
          recordingStatus: {
            isComplete: false,
            duration: 0,
            isSubmitting: false,
          },
        };
      case "ADD_RECORDING_AS_FORM":
        const newRecordingForm: EncounterDocument = {
          encounter_document_id: uuidv4(),
          title: action.payload.title,
          content: "Audio recording saved as context",
          created_at: new Date().toISOString(),
          transcription_session_id: action.payload.transcriptionSessionId,
          type: "recording",
        };
        return {
          ...state,
          inputs: [newRecordingForm, ...state.inputs],
        };
      case "LOAD_ENCOUNTER":
        return {
          ...action.payload,
          _localState: {
            localDocumentState: {},
          },
          encounter_id: action.payload.encounter_id,
        };
      case "UPDATE_ENCOUNTER":
        return {
          ...state,
          ...action.payload,
        };
      case "DELETE_DOCUMENT":
        return {
          ...state,
          inputs: state.inputs.filter(
            (doc) => doc.encounter_document_id !== action.payload
          ),
          _localState: {
            ...state._localState,
            localDocumentState: {
              ...state._localState.localDocumentState,
              [action.payload]: undefined, // Clear any local content for the deleted document
            },
          },
        };
      case "SET_SCHEDULE":
        return {
          ...state,
          scheduledFor: action.payload,
        };
      case "CLEAR_SCHEDULE":
        return {
          ...state,
          scheduledFor: undefined,
        };
      case "UPDATE_LOCAL_STATE":
        return {
          ...state,
          _localState: {
            ...state._localState,
            ...action.payload,
          },
        };
      case "UPDATE_LOCAL_DOCUMENT":
        return {
          ...state,
          _localState: {
            ...state._localState,
            localDocumentState: {
              ...state._localState.localDocumentState,
              [action.payload.encounter_document_id]: {
                ...state._localState.localDocumentState[
                  action.payload.encounter_document_id
                ],
                ...action.payload.updates,
              },
            },
          },
        };
      case "SYNC_LOCAL_DOCUMENT":
        return {
          ...state,
          _localState: {
            ...state._localState,
            localDocumentState: {
              ...state._localState.localDocumentState,
              [action.payload.encounter_document_id]: {
                ...state._localState.localDocumentState[
                  action.payload.encounter_document_id
                ],
                ...action.payload.updates,
              },
            },
          },
          inputs: state.inputs.map((doc) =>
            doc.encounter_document_id === action.payload.encounter_document_id
              ? {
                  ...doc,
                  ...action.payload.updates,
                }
              : doc
          ),
        };
      case "SET_STATUS":
        return {
          ...state,
          status: action.payload,
        };
      case "SET_ACTIVE_ENCOUNTER":
        return {
          ...state,
          encounter_id: action.payload,
        };
      case "CLEAR_ACTIVE_ENCOUNTER":
        return {
          ...initialState,
          encounter_id: undefined,
        };
      case "SET_RECORDING_TIMES":
        return {
          ...state,
          start_time: action.payload.startTime?.toISOString(),
          end_time: action.payload.endTime?.toISOString(),
        };
      default:
        return state;
    }
  })();

  return newState as Encounter;
}

const validateEncounterSubmission = (
  state: Encounter,
  recordingStatus?: RecordingStatus
): EncounterValidation => {
  const missingRequirements: string[] = [];
  const disablingReasons: string[] = [];

  if (!state.template_id) {
    missingRequirements.push("template");
  }

  if (!state.patient_id && !state.note_title) {
    missingRequirements.push("patient or title");
  }

  const hasAudioRecording = state.inputs?.some(
    (doc) => doc.type === "recording"
  );
  if (!hasAudioRecording) {
    missingRequirements.push("recorded audio");
  }

  // Check recording status if provided
  if (recordingStatus) {
    if (recordingStatus.isRecording) {
      disablingReasons.push("recording_in_progress");
    }

    if (recordingStatus.isStopping) {
      disablingReasons.push("recording_stopping");
    }

    if (recordingStatus.chunksToBeUploaded) {
      disablingReasons.push("chunks_uploading");
    }
  }

  return {
    canSubmit:
      missingRequirements.length === 0 && disablingReasons.length === 0,
    missingRequirements,
    disablingReasons,
  };
};

export const EncounterProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getAccessToken, templatesList } = useUser();
  const [state, dispatch] = useReducer(encounterReducer, initialState);
  const [activeEncounterId, setActiveEncounterId] = useLocalStorageState<
    string | undefined
  >("activeEncounterId", undefined);

  // Modify the reducer actions to handle API calls
  const wrappedDispatch = async (action: EncounterAction) => {
    if (action.type === "DELETE_DOCUMENT" && state.encounter_id) {
      try {
        const accessToken = await getAccessToken();

        // First delete the document itself
        await encounterApi.deleteDocument(action.payload, accessToken);

        // Then update the encounter to remove the document reference
        const updatedInputs = state.inputs.filter(
          (doc) => doc.encounter_document_id !== action.payload
        );

        await encounterApi.updateEncounter(
          state.encounter_id,
          { inputs: updatedInputs },
          accessToken
        );

        // Only dispatch if both API calls succeed
        dispatch(action);
      } catch (error) {
        console.error("Failed to delete document:", error);
        return; // Don't update local state if API calls fail
      }
      return; // Return early since we already dispatched
    }

    // Continue with normal dispatch for other actions
    dispatch(action);

    if (action.type === "UPDATE_ENCOUNTER" && state.encounter_id) {
      console.log(
        `[DEBUG] Updating encounter: ${
          state.encounter_id
        } with payload: ${JSON.stringify(action)}`
      );
      try {
        const accessToken = await getAccessToken();
        await encounterApi.updateEncounter(
          state.encounter_id,
          action.payload as Partial<Encounter>,
          accessToken
        );
      } catch (error) {
        console.error("Failed to update encounter:", error);
        return; // Don't update local state if API call fails
      }
    }

    if (action.type === "ADD_DOCUMENT") {
      try {
        const accessToken = await getAccessToken();
        await encounterApi.updateEncounter(
          state.encounter_id,
          {
            inputs: [action.payload, ...state.inputs],
          },
          accessToken
        );
      } catch (error) {
        console.error("Failed to create document:", error);
      }
    }

    if (action.type === "SET_RECORDING_TIMES") {
      const accessToken = await getAccessToken();
      await encounterApi.updateEncounter(
        state.encounter_id,
        {
          start_time: action.payload.startTime?.toISOString(),
          end_time: action.payload.endTime?.toISOString(),
        },
        accessToken
      );
    }

    if (action.type === "SET_ACTIVE_ENCOUNTER") {
      setActiveEncounterId(action.payload);
    } else if (action.type === "CLEAR_ACTIVE_ENCOUNTER") {
      setActiveEncounterId(undefined);
    }
  };

  // Load encounter data when activeEncounterId changes
  useEffect(() => {
    const loadEncounter = async () => {
      if (activeEncounterId) {
        try {
          const encounter = await encounterApi.getEncounter(
            activeEncounterId,
            await getAccessToken()
          );
          dispatch({ type: "LOAD_ENCOUNTER", payload: encounter });
        } catch (error) {
          console.error("Failed to load encounter:", error);
          // You may want to show an error message to the user here
          setActiveEncounterId(undefined);
        }
      }
    };

    loadEncounter();
  }, [activeEncounterId]);

  return (
    <EncounterContext.Provider
      value={{
        state,
        dispatch: wrappedDispatch,
        activeEncounterId,
      }}
    >
      {children}
    </EncounterContext.Provider>
  );
};

export const useEncounter = () => {
  const context = useContext(EncounterContext);
  if (context === undefined) {
    throw new Error("useEncounter must be used within an EncounterProvider");
  }
  return context;
};

export const useEncounterSync = () => {
  const { state, dispatch } = useEncounter();
  const { getAccessToken } = useUser();

  const updateEncounter = async (updates: Partial<Encounter>) => {
    if (!state.encounter_id) return;

    try {
      const accessToken = await getAccessToken();
      await encounterApi.updateEncounter(
        state.encounter_id,
        updates,
        accessToken
      );
    } catch (error) {
      console.error("Failed to update encounter:", error);
      throw error;
    }
  };

  const deleteDocument = async (documentId: string) => {
    try {
      const accessToken = await getAccessToken();
      await encounterApi.deleteDocument(documentId, accessToken);
      dispatch({ type: "DELETE_DOCUMENT", payload: documentId });
    } catch (error) {
      console.error("Failed to delete document:", error);
    }
  };

  const syncDocumentContent = async (documentId: string, content: string) => {
    try {
      const accessToken = await getAccessToken();
      await encounterApi.updateDocument(documentId, { content }, accessToken);

      dispatch({
        type: "SYNC_LOCAL_DOCUMENT",
        payload: { encounter_document_id: documentId, updates: { content } },
      });
    } catch (error) {
      console.error("Failed to sync document content:", error);
    }
  };

  return {
    updateEncounter,
    deleteDocument,
    syncDocumentContent,
  };
};

export const useEncounterActions = () => {
  const { state, dispatch } = useEncounter();
  const {
    sendRecording,
    deleteRecordingHelper,
    cleanUpSession,
    currentTranscriptionSessionId,
    recordingStatus,
  } = useRecordingContext();
  const { showAlertBanner } = useUIState();

  const handleDeleteRecording = async () => {
    const success = await deleteRecordingHelper();
    if (success) {
      dispatch({ type: "CLEAR_RECORDING" });
      showAlertBanner("Recording deleted successfully.", "success");
    } else {
      showAlertBanner("Failed to delete recording.", "error");
    }
  };

  const clearEncounterState = () => {
    dispatch({ type: "CLEAR_ACTIVE_ENCOUNTER" });
    localStorage.removeItem("encounterState");
  };

  const clearActiveEncounter = () => {
    dispatch({ type: "CLEAR_ACTIVE_ENCOUNTER" });
  };

  const getSubmissionValidation = (): EncounterValidation => {
    return validateEncounterSubmission(state, recordingStatus);
  };

  const getSubmissionTooltip = (): string => {
    const validation = validateEncounterSubmission(state, recordingStatus);

    // First check for recording status issues
    if (validation.disablingReasons.length > 0) {
      if (validation.disablingReasons.includes("recording_in_progress")) {
        return "Please finish recording before submitting";
      }
      if (validation.disablingReasons.includes("recording_stopping")) {
        return "Finishing recording, please wait...";
      }
      if (validation.disablingReasons.includes("chunks_uploading")) {
        return "Uploading recording chunks, please wait...";
      }
    }

    // Then proceed with existing validation checks
    if (validation.canSubmit) {
      return "Generate a note from this encounter";
    }

    const missing = [...validation.missingRequirements];
    if (missing.length === 1) {
      if (missing[0] === "recorded audio") {
        return "Please make at least one audio recording to submit";
      }
      return `Please select a ${missing[0]} before submitting`;
    }

    const lastItem = missing.pop();
    const formattedLastItem =
      lastItem === "recorded audio"
        ? "make at least one audio recording"
        : `select a ${lastItem}`;

    const formattedItems = missing.map((item) =>
      item === "recorded audio"
        ? "make at least one audio recording"
        : `select a ${item}`
    );

    return `Please ${formattedItems.join(
      ", "
    )} and ${formattedLastItem} to submit`;
  };

  return {
    // handleSubmitRecording,
    handleDeleteRecording,
    // handleSaveRecordingToContext,
    clearEncounterState,
    clearActiveEncounter,
    getSubmissionValidation,
    getSubmissionTooltip,
  };
};
