import React from "react";
import { Box, Typography, Tooltip } from "@mui/material";
import {
  Alarm,
  AssignmentIndOutlined,
  CalendarToday,
  Check,
  Person,
} from "@mui/icons-material";
import { formatDate, formatDuration } from "../utils/utils";
import { NoteDataType, Patient } from "../types/types";

interface NoteMetadataProps {
  noteData: NoteDataType;
  patient?: Patient;
}

export const NoteMetadata: React.FC<NoteMetadataProps> = ({
  noteData,
  patient,
}) => {
  const MetadataItem: React.FC<{
    icon: React.ReactNode;
    text: string;
    tooltip: string;
  }> = ({ icon, text, tooltip }) => (
    <Tooltip title={tooltip} arrow>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          my: 0.5,
          mr: 1,
          minWidth: 0,
          color: "newDesignColors.eggplantNeutral700",
        }}
      >
        {React.cloneElement(icon as React.ReactElement, {
          sx: { mr: 0.5, flexShrink: 0 },
          fontSize: "small",
          color: "newDesignColors.eggplantNeutral700",
        })}
        <Typography
          variant="subtitle1"
          noWrap
          sx={{
            flexGrow: 1,
            minWidth: 0,
            color: "newDesignColors.eggplantNeutral700",
          }}
        >
          {text}
        </Typography>
      </Box>
    </Tooltip>
  );

  const metadataItems = [
    !noteData.note_title &&
      patient?.first_name &&
      patient?.last_name && {
        icon: <Person />,
        text: `${patient.first_name} ${patient.last_name}`,
        tooltip: "Patient",
      },
    noteData.completion_status == "completed" &&
      noteData.completion_timestamp && {
        icon: <Check />,
        text: formatDate(noteData.completion_timestamp, "both"),
        tooltip: "Note marked as complete",
      },
    {
      icon: <AssignmentIndOutlined />,
      text: noteData.issuing_user_name,
      tooltip: "Provider",
    },
    {
      icon: <CalendarToday />,
      text: `${formatDate(noteData.time_uploaded, "datelong")}, ${
        noteData.transcription_start_timestamp
          ? `${formatDate(
              noteData.transcription_start_timestamp,
              "time"
            )} – ${formatDate(noteData.transcription_end_timestamp, "time")}`
          : formatDate(noteData.time_uploaded, "time")
      }`,
      tooltip: "Encounter Date and Time",
    },
    noteData.transcription_length_seconds && {
      icon: <Alarm />,
      text: formatDuration(noteData.transcription_length_seconds),
      tooltip: "Encounter Duration",
    },
  ].filter(Boolean) as Array<{
    icon: React.ReactNode;
    text: string;
    tooltip: string;
  }>;

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: 1,
        "& > *:not(:last-child)::after": {
          content: '""',
          display: "inline-block",
          width: "2px",
          height: "24px",
          backgroundColor: "divider",
          marginLeft: 1,
        },
      }}
    >
      {metadataItems.map((item, index) => (
        <MetadataItem
          key={index}
          icon={item.icon}
          text={item.text}
          tooltip={item.tooltip}
        />
      ))}
    </Box>
  );
};
