import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Grid,
  Snackbar,
  Alert,
  CircularProgress,
  Paper,
  useMediaQuery,
  Theme,
  Skeleton,
  Card,
  CardContent,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  ArrowBack,
  Edit as EditIcon,
  KeyboardArrowLeftOutlined,
  Save as SaveIcon,
  Delete as DeleteIcon,
  Settings,
  Widgets,
} from "@mui/icons-material";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { useNavigate, useParams } from "react-router-dom";
import PatientInformationBlock from "./PatientInformationBlock";
import TextBlock from "./Blocks/TextBlock";
import StructuredDataBlock from "./StructuredDataBlock";
import PatientEncounters from "./PatientEncountersBlock";
import MedicationsDataBlock, {
  Medication,
  MedicationChange,
} from "./MedicationsDataBlock";
import DiagnosesDataBlock, { Diagnosis } from "./DiagnosesDataBlock";
import LayoutWrapper from "../layout/UILayout";
import { removeScrollBar } from "../../styles/globalStyles";
import { DismissableCard } from "../../Views/Shared/DismissableCard";
import { RoundedButton } from "../../styles/CustomButtons";
import { DeleteModal } from "../DeleteModal";
import { ConditionalTooltip } from "../ConditionalTooltip";
import FormsListBlock from "./Blocks/FormsListBlock";
import { useSnackbar } from "notistack";
import TableBlock from "./Blocks/TableBlock";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import { PatientModule } from "../../types/types";
import TestBlock from "./Blocks/TestBlock";
import { phq9Fixture } from "./Blocks/fixtures/testBlockFixtures";
import PatientOverviewBlock from "./Blocks/PatientOverviewBlock";
import { FeatureFeedback } from "../FeatureFeedback/FeatureFeedback";
import SentryService from "../../services/SentryService";
import { capitalize } from "../../utils/utils";
import {
  PatientDataProvider,
  usePatientData,
} from "../../context/PatientDataContext";
import {
  TutorialProvider,
  useTutorial,
} from "../../components/Tutorial/TutorialContext";
import { TutorialStep } from "../../components/Tutorial/TutorialStep";
import LastEncounterBlock from "./Blocks/LastEncounterBlock";
import TreatmentPlan from "./Blocks/TreatmentPlanBlock";
import TreatmentPlanBlock from "./Blocks/TreatmentPlanBlock";

// Inner component that uses PatientDataContext
const PatientDetailViewInner: React.FC = () => {
  const { patientId } = useParams();
  const { getAccessToken, subjectLanguage } = useUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { userState } = useUser();
  const { isActive: isTutorialActive } = useTutorial();

  // Get data from our context
  const {
    patient,
    patientModulesData,
    isLoading,
    isLoadingModules,
    isTutorialMode,
    fetchPatient,
    fetchPatientData,
    setTutorialMode,
    setPatient,
    setIsLoadingModules,
  } = usePatientData();

  const [originalPatient, setOriginalPatient] = useState<Patient | null>(null);
  const [hasChanges, setHasChanges] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showTooltips, setShowTooltips] = useState(true);
  const [showPatientSectionsTooltip, setShowPatientSectionsTooltip] = useState(
    localStorage.getItem("editPatientSectionsClicked") !== "true"
  );
  const [shouldNavigate, setShouldNavigate] = useState(false);
  const [showModulesPane, setShowModulesPane] = useState(false);
  const [availableModules, setAvailableModules] = useState<PatientModule[]>([]);
  const [userPatientModules, setUserPatientModules] = useState<PatientModule[]>(
    []
  );

  // Set tutorial mode when tutorial is active
  useEffect(() => {
    // Only update when the active state actually changes
    console.log("Patient tutorial active state changed:", isTutorialActive);
    setTutorialMode(isTutorialActive);
  }, [isTutorialActive, setTutorialMode]);

  // Reference to track if initial fetch has been made
  const initialFetchMade = useRef(false);

  useEffect(() => {
    if (patientId && !initialFetchMade.current) {
      // Only fetch if we don't already have the patient or if patientId changed
      if (!patient || patient.patient_id !== patientId) {
        console.log(`Making initial fetch for patient: ${patientId}`);
        fetchPatient(patientId);
        fetchPatientData(patientId);
        initialFetchMade.current = true;
      }
    }

    // Reset the reference when patientId changes
    return () => {
      if (initialFetchMade.current && patientId) {
        initialFetchMade.current = false;
      }
    };
  }, [patientId, fetchPatient, fetchPatientData, patient]);

  useEffect(() => {
    if (shouldNavigate) {
      const timer = setTimeout(() => {
        navigate("/patients");
      }, 2000); // Wait for 2 seconds before navigating

      return () => clearTimeout(timer);
    }
  }, [shouldNavigate, navigate]);

  const handleSave = async () => {
    if (!patient) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/update",
        accessToken,
        body: {
          patient_id: patientId,
          updated_fields: patient,
        },
      });

      if (response.ok) {
        enqueueSnackbar(`${capitalize(subjectLanguage)} updated successfully`, {
          variant: "success",
        });
        setOriginalPatient(patient);
        setHasChanges(false);
      } else {
        throw new Error(`Failed to update ${subjectLanguage}`);
      }
    } catch (error) {
      console.error("Error updating patient:", error);
      enqueueSnackbar(`Failed to update ${subjectLanguage}`, {
        variant: "error",
      });
      SentryService.logEvent("Error updating patient", {
        level: "error",
        tags: {
          patient_id: patientId || "",
        },
        extra: {
          error: error,
        },
      });
    }
  };

  const handleDeletePatient = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: `/patient/delete`,
        accessToken,
        body: {
          patient_id: patientId,
        },
      });

      if (response.ok) {
        enqueueSnackbar(`${capitalize(subjectLanguage)} deleted successfully`, {
          variant: "success",
        });
        setShouldNavigate(true);
      } else {
        throw new Error(`Failed to delete ${subjectLanguage}`);
      }
    } catch (error) {
      console.error("Error deleting patient:", error);
      enqueueSnackbar(`Failed to delete ${subjectLanguage}`, {
        variant: "error",
      });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const isEqual = useCallback((obj1: any, obj2: any) => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }, []);

  const fetchAvailableModules = async () => {
    setIsLoadingModules(true);
    try {
      const accessToken = await getAccessToken();
      const [availableResponse, userModulesResponse] = await Promise.all([
        APIService.makeAPIGetRequest({
          requestString: "/patient_module/getPublicPatientModules",
          accessToken,
        }),
        APIService.makeAPIGetRequest({
          requestString: `/patient_module/getUserPatientModules`,
          accessToken,
        }),
      ]);

      if (availableResponse.ok && userModulesResponse.ok) {
        const availableData = await availableResponse.value;
        const userModulesData = await userModulesResponse.value;

        // Find the full module data for enabled user modules
        const userModules = availableData.filter(
          (module: PatientModule) =>
            userModulesData[module.patient_module_id] === true
        );

        setAvailableModules(availableData);
        setUserPatientModules(userModules);
      } else {
        throw new Error("Failed to fetch modules");
      }
    } catch (error) {
      console.error("Error fetching modules:", error);
      enqueueSnackbar("Failed to load modules", { variant: "error" });
    } finally {
      setIsLoadingModules(false);
    }
  };

  const handleAssignModule = async (moduleId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient_module/assignUserPatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleId,
        },
      });

      if (response.ok) {
        enqueueSnackbar("Module added successfully", { variant: "success" });
        setShowModulesPane(false);
        fetchPatientData(patientId || ""); // Refresh patient data
      } else {
        throw new Error("Failed to assign module");
      }
    } catch (error) {
      console.error("Error assigning module:", error);
      enqueueSnackbar("Failed to add module", { variant: "error" });
    }
  };

  const handleRemoveModule = async (moduleId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient_module/removeUserPatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleId,
        },
      });

      if (response.ok) {
        enqueueSnackbar("Module removed successfully", { variant: "success" });
        setShowModulesPane(false);
        fetchPatientData(patientId || ""); // Refresh patient data
      } else {
        throw new Error("Failed to remove module");
      }
    } catch (error) {
      console.error("Error removing module:", error);
      enqueueSnackbar("Failed to remove module", { variant: "error" });
    }
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!patient || !patientId) {
    return <Typography>No {subjectLanguage} data available.</Typography>;
  }

  return (
    <>
      <DeleteModal
        isOpen={showDeleteModal}
        continueText="Delete"
        cancelText="Cancel"
        onCancel={() => setShowDeleteModal(false)}
        onContinue={handleDeletePatient}
      >
        Are you sure you want to delete this {subjectLanguage} and all
        associated data? This action cannot be undone.
      </DeleteModal>
      <LayoutWrapper>
        <LayoutWrapper.TopBar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              gap: 2,
              height: "100%",
              mx: 2,
              overflowX: "auto",
            }}
          >
            <Typography
              variant="body2"
              onClick={() => navigate(-1)}
              sx={{
                color: "primary.main",
                textDecoration: "none",
                cursor: "pointer",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <KeyboardArrowLeftOutlined />
              Back
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <TutorialStep
                step={1}
                description={`JotPsych's ${subjectLanguage} record is highly customizable. Click here to customize which sections appear in view.`}
                placement="bottom"
              >
                <ConditionalTooltip
                  title={
                    localStorage.getItem("editPatientSectionsClicked") !==
                    "true"
                      ? "Click here to customize your view"
                      : "Change sections"
                  }
                  condition={showTooltips}
                  placement="bottom"
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      setShowModulesPane(true);
                      fetchAvailableModules();
                      setShowPatientSectionsTooltip(false);
                      localStorage.setItem(
                        "editPatientSectionsClicked",
                        "true"
                      );
                    }}
                  >
                    <Widgets />
                  </IconButton>
                </ConditionalTooltip>
              </TutorialStep>
              <ConditionalTooltip
                title={`Delete ${subjectLanguage}`}
                condition={showTooltips}
                placement="bottom"
                arrow
              >
                <IconButton onClick={() => setShowDeleteModal(true)}>
                  <DeleteIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </ConditionalTooltip>
            </Box>
          </Box>
        </LayoutWrapper.TopBar>
        <LayoutWrapper.MainContent>
          {/* Final Tutorial Step for Complete Overview */}
          <TutorialStep
            step={3}
            description={`And that's it! JotPsych's ${subjectLanguage} record is a powerful tool for quickly reviewing information about the ${subjectLanguage} and getting up to speed on their care. No more wading through previous encounter notes looking for the most important information!`}
          >
            {/* Empty div to anchor the final tutorial step */}
            <div></div>
          </TutorialStep>
          <Box sx={{ px: 2 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 2,
              }}
            >
              {hasChanges && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  sx={{ mt: 2 }}
                >
                  Save Changes
                </Button>
              )}
            </Box>

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TutorialStep
                  step={0}
                  description={`This overview shows the ${subjectLanguage}'s basic information and demographics. Below you can reference all of their encounters.`}
                  placement="right"
                >
                  <Box sx={{ position: "sticky", top: 16 }}>
                    <PatientOverviewBlock
                      patient={patient}
                      onPatientUpdate={(updatedPatient) => {
                        setPatient(updatedPatient);
                      }}
                    />

                    {patientId && <PatientEncounters patientId={patientId} />}
                  </Box>
                </TutorialStep>
              </Grid>

              <Grid item xs={12} md={8}>
                <TutorialStep
                  step={2}
                  description={`As you record encounters with the ${subjectLanguage}, JotPsych will automatically fill out information in the ${subjectLanguage} record. Differences since the last time you approved the record will always be highlighted. You can approve and edit any changes, as well as add new information on your own.`}
                  placement="left"
                >
                  <Box>
                    <LastEncounterBlock
                      patientId={patientId}
                      defaultCollapsed={true}
                    />

                    {userState?.featureFlags?.treatment_plan && (
                      <TreatmentPlanBlock
                        patientId={patientId}
                        defaultCollapsed={true}
                      />
                    )}

                    {userState?.featureFlags?.forms && patientId && (
                      <FormsListBlock patientId={patientId} patient={patient} />
                    )}
                    {Object.entries(patientModulesData || {}).map(
                      ([moduleId, moduleData]: [string, any]) => {
                        const title =
                          moduleData.name[0].toUpperCase() +
                          moduleData.name.substring(1);

                        let moduleBlock = null;
                        switch (moduleData?.type) {
                          case "table":
                            moduleBlock = (
                              <TableBlock
                                key={moduleId}
                                patientId={patientId}
                                moduleData={moduleData}
                                title={title}
                                onDataUpdate={(
                                  updatedData: any,
                                  action: any
                                ) => {
                                  // TODO: Implement the update handler
                                  console.log(
                                    "Update data for module:",
                                    moduleId,
                                    updatedData,
                                    action
                                  );
                                }}
                              />
                            );
                            break;
                          case "test":
                            moduleBlock = (
                              <TestBlock
                                key={moduleId}
                                patientId={patientId}
                                moduleData={moduleData}
                                title={title}
                                patient={patient}
                              />
                            );
                            break;
                          case "text":
                            moduleBlock = (
                              <TextBlock
                                key={moduleId}
                                patientId={patientId}
                                moduleData={moduleData}
                                title={title}
                              />
                            );
                            break;
                          default:
                            return null;
                        }

                        return (
                          <React.Fragment key={moduleId}>
                            {moduleBlock}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <FeatureFeedback
                                featureId={`${moduleId}_feedback`}
                                mountsUntilShow={5}
                                percentToShow={10}
                                prompt="Are you finding this feature useful?"
                                context={{
                                  patient_module_data: moduleData,
                                }}
                              />
                            </Box>
                          </React.Fragment>
                        );
                      }
                    )}
                  </Box>
                </TutorialStep>
              </Grid>
            </Grid>
          </Box>
        </LayoutWrapper.MainContent>
        <LayoutWrapper.RightSidebar>
          <DismissableCard
            headerTitle="Who can see this information?"
            bodyText={[
              `JotPsych securely encrypts all identifiable ${subjectLanguage} information (name, DOB, contact info, etc.). This information is never shared with any AI models or third parties and can only be seen by you and other members of your organization after you've entered an authenticated session.`,
            ]}
            learnMoreLink="https://www.jotpsych.com/security-and-privacy"
            tooltipMessage="Learn more about our security practices."
          />
        </LayoutWrapper.RightSidebar>
      </LayoutWrapper>
      <SlideSidePane
        open={showModulesPane}
        onClose={() => setShowModulesPane(false)}
        title="Patient Sections"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Assigned Sections
            </Typography>
            {isLoadingModules ? (
              <CircularProgress />
            ) : userPatientModules.length === 0 ? (
              <Typography color="text.secondary">
                No sections assigned yet
              </Typography>
            ) : (
              userPatientModules.map((module) => (
                <Card key={module.patient_module_id} sx={{ p: 2, mb: 1 }}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography variant="subtitle1">
                        {module.public_name || module.patient_module_name}
                      </Typography>
                    </Box>
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() =>
                        handleRemoveModule(module.patient_module_id)
                      }
                    >
                      Remove
                    </Button>
                  </Stack>
                </Card>
              ))
            )}
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Available Sections
            </Typography>
            {isLoadingModules ? (
              <CircularProgress />
            ) : (
              availableModules
                .filter(
                  (module) =>
                    !userPatientModules.some(
                      (assigned) =>
                        assigned.patient_module_id === module.patient_module_id
                    )
                )
                .map((module) => (
                  <Card key={module.patient_module_id} sx={{ p: 2, mb: 1 }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          {module.public_name || module.patient_module_name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {module.public_description}
                        </Typography>
                      </Box>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                          handleAssignModule(module.patient_module_id)
                        }
                      >
                        Add
                      </Button>
                    </Stack>
                  </Card>
                ))
            )}
          </Box>
        </Box>
      </SlideSidePane>
    </>
  );
};

// Wrapper component that provides the contexts
const PatientDetailView: React.FC = () => {
  return (
    <PatientDataProvider>
      <TutorialProvider
        tutorialKey="patient-detail-view"
        totalSteps={4}
        tutorialDisabled={false}
      >
        <PatientDetailViewInner />
      </TutorialProvider>
    </PatientDataProvider>
  );
};

export default PatientDetailView;
