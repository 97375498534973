import React, { useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Tooltip,
  Paper,
  Grid,
  IconButton,
  Stack,
  Collapse,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { format, differenceInMinutes } from "date-fns";
import { Person, Description, Event, Clear } from "@mui/icons-material";
import { Patient } from "../../types/types";
import { useUser } from "../../context/user";
import { capitalize } from "../../utils/utils";
import { useEncounter } from "./EncounterContext";
import PatientPicker, { PatientPickerRef } from "../Patient/PatientPicker";
import TemplatePicker from "../Pickers/TemplatePicker";
import EncounterScheduleDuration from "./EncounterScheduleDuration";
import BaseBlock from "../Patient/Blocks/BaseBlock";

// Define the possible states for our state machine
type SelectorState =
  | "IDLE"
  | "SELECTING_PATIENT"
  | "SELECTING_TEMPLATE"
  | "SELECTING_SCHEDULE";

interface EncounterSelectorButtonsProps {
  isViewOnly: boolean;
  hasAudioDocuments: boolean;
  vertical?: boolean;
}

const EncounterSelectorButtons: React.FC<EncounterSelectorButtonsProps> = ({
  isViewOnly,
  hasAudioDocuments,
  vertical = false,
}) => {
  const { subjectLanguage, templatesList } = useUser();
  const { state, dispatch } = useEncounter();

  // State machine state
  const [currentState, setCurrentState] = React.useState<SelectorState>("IDLE");
  const [noteTitle, setNoteTitle] = React.useState(state.note_title || "");

  // Check if each selector is filled
  const isPatientFilled = Boolean(state.patient_id || state.note_title);
  const isTemplateFilled = Boolean(state.template_id || state.encounter_type);
  const isScheduleFilled = Boolean(state.scheduled_for);

  // Utility function to get the next unfilled selector
  const getNextUnfilledSelector = (): SelectorState => {
    if (!isPatientFilled) return "SELECTING_PATIENT";
    if (!isTemplateFilled) return "SELECTING_TEMPLATE";
    if (!isScheduleFilled) return "SELECTING_SCHEDULE";
    return "IDLE";
  };

  // Update local state when context changes
  React.useEffect(() => {
    setNoteTitle(state.note_title || "");
  }, [state.note_title]);

  // Format duration text
  const formatDuration = (start: string, end: string) => {
    const minutes = differenceInMinutes(new Date(end), new Date(start));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours > 0
      ? `${hours}h ${remainingMinutes}m`
      : `${remainingMinutes}m`;
  };

  // Get text for schedule button
  const getScheduleButtonText = () => {
    if (state.scheduled_for) {
      const scheduledDate = format(
        new Date(state.scheduled_for),
        "MMM d, h:mm a"
      );

      // Add duration information if available
      if (state.scheduled_duration) {
        return `${scheduledDate} · ${formatDurationText(
          state.scheduled_duration
        )}`;
      } else if (state.start_time && state.end_time) {
        return `${scheduledDate} · ${formatDuration(
          state.start_time,
          state.end_time
        )}`;
      }

      return scheduledDate;
    } else {
      return "Select date and duration";
    }
  };

  // Format duration as text from minutes
  const formatDurationText = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours > 0
      ? `${hours}h ${remainingMinutes > 0 ? `${remainingMinutes}m` : ""}`
      : `${remainingMinutes}m`;
  };

  // Get label text for each selector button
  const getSelectorLabel = (type: string) => {
    const patient = state.patient;

    switch (type) {
      case "patient":
        return patient
          ? `${patient.first_name} ${patient.last_name}`
          : state.note_title
          ? state.note_title
          : `Select ${capitalize(subjectLanguage?.toLowerCase() || "")}`;
      case "template":
        return state.encounter_type || "Select template";
      case "schedule":
        return getScheduleButtonText();
      default:
        return "";
    }
  };

  // State machine transition handlers
  const transitionTo = (newState: SelectorState) => {
    setCurrentState(newState);
  };

  // Added a field parameter to know which field was just filled
  const autoProgressToNext = (
    justFilledField?: "patient" | "template" | "schedule"
  ) => {
    // First close the current selector
    setCurrentState("IDLE");

    // After a small delay, move to the next unfilled state if there is one
    setTimeout(() => {
      // Get current fill status but override the field that was just filled
      let nextUnfilled: SelectorState = "IDLE";

      // Assuming the field that was just updated is now filled
      const patientJustFilled =
        justFilledField === "patient" || isPatientFilled;
      const templateJustFilled =
        justFilledField === "template" || isTemplateFilled;
      const scheduleJustFilled =
        justFilledField === "schedule" || isScheduleFilled;

      // Find the next unfilled selector, taking into account what was just filled
      if (!patientJustFilled) nextUnfilled = "SELECTING_PATIENT";
      else if (!templateJustFilled) nextUnfilled = "SELECTING_TEMPLATE";
      else if (!scheduleJustFilled) nextUnfilled = "SELECTING_SCHEDULE";

      if (nextUnfilled !== "IDLE") {
        setCurrentState(nextUnfilled);
      }
    }, 100);
  };

  useEffect(() => {
    autoProgressToNext();
  }, [state.encounter_id]);

  // Create a ref for the PatientPicker
  const patientPickerRef = useRef<PatientPickerRef>(null);

  // Focus the PatientPicker input when selecting patient opens
  useEffect(() => {
    if (currentState === "SELECTING_PATIENT") {
      setTimeout(() => {
        if (patientPickerRef.current) {
          patientPickerRef.current.focusInput();
        }
      }, 150); // A slightly longer delay to ensure the selector is visible
    }
  }, [currentState]);

  // Handler functions for the form elements
  const handlePatientSelect = (selectedPatient: Patient | undefined) => {
    if (!state.encounter_id || isViewOnly) return;

    if (selectedPatient) {
      dispatch({
        type: "UPDATE_ENCOUNTER",
        payload: {
          patient_id: selectedPatient.patient_id,
          note_title: null,
        },
      });

      dispatch({
        type: "SET_PATIENT",
        payload: selectedPatient,
      });

      setNoteTitle("");
      autoProgressToNext("patient");
    }
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNoteTitle(event.target.value);
  };

  const handleTitleSubmit = () => {
    if (!state.encounter_id || isViewOnly || !noteTitle.trim()) return;

    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: {
        note_title: noteTitle.trim(),
        patient_id: null,
      },
    });

    dispatch({
      type: "SET_PATIENT",
      payload: null,
    });

    autoProgressToNext("patient");
  };

  const handleTitleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleTitleSubmit();
    }
  };

  const handleTemplateSelect = (template: any) => {
    if (!state.encounter_id || isViewOnly) return;

    console.log("template", template);

    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: {
        template_id: template?.template_id,
        encounter_type: template?.display_name,
      },
    });

    autoProgressToNext("template");
  };

  const handleScheduleSelect = (
    date: Date | undefined,
    duration: number | undefined
  ) => {
    if (!state.encounter_id || isViewOnly) return;

    if (date && !duration) {
      dispatch({
        type: "UPDATE_ENCOUNTER",
        payload: {
          scheduled_for: date.toISOString(),
          scheduled_duration: undefined,
        },
      });
    } else if (date && duration) {
      const startTime = date;
      const endTime = new Date(startTime.getTime() + duration * 60000);

      dispatch({
        type: "UPDATE_ENCOUNTER",
        payload: {
          scheduled_for: date.toISOString(),
          start_time: startTime.toISOString(),
          end_time: endTime.toISOString(),
          scheduled_duration: duration,
        },
      });
    }

    autoProgressToNext("schedule");
  };

  // Clear handlers
  const handleClearPatient = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isViewOnly || !state.encounter_id) return;

    dispatch({
      type: "CLEAR_PATIENT",
    });
    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: { patient_id: null, note_title: null },
    });
    setNoteTitle("");
  };

  const handleClearTemplate = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isViewOnly || !state.encounter_id) return;

    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: { template_id: null, encounter_type: null },
    });
    dispatch({
      type: "UPDATE_LOCAL_STATE",
      payload: { template: null },
    });
  };

  const handleClearSchedule = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isViewOnly || !state.encounter_id) return;

    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: {
        scheduled_for: null,
        start_time: null,
        end_time: null,
        scheduled_duration: undefined,
      },
    });
  };

  // Patient selector component
  const renderPatientSelector = () => {
    return (
      <Box sx={{ width: "100%" }}>
        <Box sx={{ mb: 1, mt: 1 }}>
          <PatientPicker
            ref={patientPickerRef}
            onPatientSelect={handlePatientSelect}
            initialPatient={state.patient ? state.patient : undefined}
            autoFocus={currentState === "SELECTING_PATIENT"}
          />
        </Box>

        <Divider sx={{ my: 2 }}>
          <Typography variant="caption" color="text.secondary">
            OR
          </Typography>
        </Divider>

        <Box sx={{ width: "100%" }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Enter a title for the note instead
          </Typography>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder="Note Title"
              size="small"
              fullWidth
              value={noteTitle}
              onChange={handleTitleChange}
              onKeyDown={handleTitleKeyDown}
              sx={{ mr: 1 }}
              autoFocus={currentState === "SELECTING_PATIENT" && !state.patient}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleTitleSubmit}
              disabled={!noteTitle.trim()}
              sx={{
                minWidth: "80px",
                height: "40px",
                boxShadow: "none",
                color: "white",
              }}
            >
              USE
            </Button>
          </Box>
        </Box>
      </Box>
    );
  };

  // Render a selector button with its content
  const renderSelector = (
    type: "patient" | "template" | "schedule",
    title: string,
    icon: React.ReactNode,
    isFilled: boolean,
    stateValue: SelectorState,
    clearHandler: (e: React.MouseEvent) => void,
    tooltipTitle: string
  ) => {
    const isOpen = currentState === stateValue;

    return (
      <Box sx={{ width: "100%", mb: isOpen ? 1 : 0 }}>
        {/* Button */}
        <Paper
          elevation={0}
          onClick={
            isViewOnly
              ? undefined
              : () => transitionTo(isOpen ? "IDLE" : stateValue)
          }
          sx={{
            py: 1,
            px: 1.5,
            width: "100%",
            cursor: isViewOnly ? "default" : "pointer",
            borderRadius: 1,
            border: "1px solid",
            borderColor: isFilled || isOpen ? "primary.main" : "divider",
            backgroundColor: isOpen ? "action.selected" : "background.paper",
            transition: "all 0.2s",
            "&:hover": isViewOnly
              ? {}
              : {
                  borderColor: "primary.main",
                  backgroundColor: isOpen ? "action.selected" : "action.hover",
                },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              variant="overline"
              color="text.secondary"
              sx={{ fontWeight: "bold", fontSize: "0.7rem", lineHeight: 1.2 }}
            >
              {title}
            </Typography>
            {isFilled && !isViewOnly && (
              <IconButton size="small" onClick={clearHandler} sx={{ p: 0.5 }}>
                <Clear fontSize="small" />
              </IconButton>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {icon}
            <Typography
              variant="body2"
              color={isFilled ? "text.primary" : "text.secondary"}
              noWrap
            >
              {getSelectorLabel(type)}
            </Typography>
          </Box>
        </Paper>

        {/* Selector Content */}
        <Collapse in={isOpen} timeout="auto">
          <Box sx={{ mt: 1 }}>
            <BaseBlock sx={{ p: 1.5 }}>
              {type === "patient" && renderPatientSelector()}

              {type === "template" && (
                <Box sx={{ my: 1 }}>
                  <TemplatePicker
                    onTemplateSelect={handleTemplateSelect}
                    key={`template-picker-${state.encounter_id}-${
                      state.template_id || "empty"
                    }`}
                    initialTemplateId={state.template_id}
                  />
                </Box>
              )}

              {type === "schedule" && (
                <EncounterScheduleDuration
                  onScheduleSelect={handleScheduleSelect}
                  onClose={() => setCurrentState("IDLE")}
                />
              )}
            </BaseBlock>
          </Box>
        </Collapse>
      </Box>
    );
  };

  // Render the component based on vertical prop
  if (vertical) {
    return (
      <Stack spacing={1.5} width="100%">
        {renderSelector(
          "patient",
          "WHO",
          <Person
            color={isPatientFilled ? "primary" : "disabled"}
            fontSize="small"
          />,
          isPatientFilled,
          "SELECTING_PATIENT",
          handleClearPatient,
          `Select ${subjectLanguage}`
        )}

        {renderSelector(
          "template",
          "WHAT",
          <Description
            color={isTemplateFilled ? "primary" : "disabled"}
            fontSize="small"
          />,
          isTemplateFilled,
          "SELECTING_TEMPLATE",
          handleClearTemplate,
          "Select template"
        )}

        {renderSelector(
          "schedule",
          "WHEN",
          <Event
            color={isScheduleFilled ? "primary" : "disabled"}
            fontSize="small"
          />,
          isScheduleFilled,
          "SELECTING_SCHEDULE",
          handleClearSchedule,
          "Set schedule and duration"
        )}
      </Stack>
    );
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        {renderSelector(
          "patient",
          "WHO",
          <Person
            color={isPatientFilled ? "primary" : "disabled"}
            fontSize="small"
          />,
          isPatientFilled,
          "SELECTING_PATIENT",
          handleClearPatient,
          `Select ${subjectLanguage}`
        )}
      </Grid>

      <Grid item xs={12} md={4}>
        {renderSelector(
          "template",
          "WHAT",
          <Description
            color={isTemplateFilled ? "primary" : "disabled"}
            fontSize="small"
          />,
          isTemplateFilled,
          "SELECTING_TEMPLATE",
          handleClearTemplate,
          "Select template"
        )}
      </Grid>

      <Grid item xs={12} md={4}>
        {renderSelector(
          "schedule",
          "WHEN",
          <Event
            color={isScheduleFilled ? "primary" : "disabled"}
            fontSize="small"
          />,
          isScheduleFilled,
          "SELECTING_SCHEDULE",
          handleClearSchedule,
          "Set schedule and duration"
        )}
      </Grid>
    </Grid>
  );
};

export default EncounterSelectorButtons;
