import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Tooltip,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import { Event, Launch, InfoOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import SlideSidePane from "../../../Views/Shared/SlideSidePane";
import { NoteSectionDisplayBox } from "../../Note/NoteSectionDisplayBox";
import { NoteDataType } from "../../../types/types";
import { NoteMetadata } from "../../NoteMetadata";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../context/user";
import APIService from "../../../services/APIService";
import { formatContent } from "../../../utils/utils";
import CollapsibleBaseBlock from "./CollapsibleBaseBlock";
import { trackEvent } from "../../../utils/analytics_utils";

interface LastEncounterBlockProps {
  patientId: string | undefined;
  defaultCollapsed?: boolean;
}

interface PatientData {
  past_visit_summary: string;
  patient: {
    latest_encounter: {
      scheduled_for?: string;
      created_at: string;
      start_time?: string;
      note_id: string;
      outputs?: {
        type: string;
        encounter_document_id: string;
      }[];
    };
  };
}

const LoadingContainer = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    }}
  >
    {children}
  </Box>
);

const LastEncounterBlock: React.FC<LastEncounterBlockProps> = ({
  patientId,
  defaultCollapsed = false,
}) => {
  const { getAccessToken, subjectLanguage } = useUser();
  const navigate = useNavigate();
  const [data, setData] = useState<PatientData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [noteData, setNoteData] = useState<NoteDataType | null>(null);
  const hasTrackedHoverRef = useRef(false);
  const [hasLoadedData, setHasLoadedData] = useState(!defaultCollapsed);

  const fetchSummary = async () => {
    if (!patientId) return;

    if (!hasLoadedData) {
      setHasLoadedData(true);
    }

    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/getPreRecordingInformation?patient_id=${patientId}`,
        accessToken,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch summary: ${response.error?.message}`);
      }

      setData(response.value);
    } catch (error) {
      console.error("Error fetching past visit summary:", error);
      setError("Failed to load past visit summary");
    } finally {
      setLoading(false);
    }
  };

  const fetchNoteContent = async (noteId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/notes/${noteId}`,
        accessToken,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch note: ${response.error?.message}`);
      }

      setNoteData(response.value);
    } catch (error) {
      console.error("Error fetching note content:", error);
      setNoteData(null);
    }
  };

  const handleMouseEnter = useCallback(() => {
    if (!hasTrackedHoverRef.current) {
      hasTrackedHoverRef.current = true;
      trackEvent({
        event: "past_visit_summary_hover",
        hasSummary: !!data?.past_visit_summary,
      });
    }
  }, [patientId, data]);

  const handleViewNoteClick = useCallback(() => {
    // Get note_id from either direct note_id or from outputs
    const noteId =
      data?.patient?.latest_encounter?.note_id ||
      data?.patient?.latest_encounter?.outputs?.find(
        (output) => output.type === "note"
      )?.encounter_document_id;

    if (noteId) {
      trackEvent({
        event: "past_visit_summary_note_open",
      });
      fetchNoteContent(noteId);
      setIsPaneOpen(true);
    }
  }, [data, patientId]);

  // Initial data load - only if not starting collapsed
  useEffect(() => {
    if (!defaultCollapsed) {
      fetchSummary();
    }
  }, [patientId, defaultCollapsed]);

  // Handle collapse state changes
  const handleCollapseChange = (isCollapsed: boolean) => {
    // If expanding and we haven't loaded data yet, fetch it
    if (!isCollapsed && !hasLoadedData) {
      fetchSummary();
    }
  };

  // Get formatted encounter date
  const encounterDate = useMemo(() => {
    if (!data?.patient?.latest_encounter) return null;

    const date =
      data.patient.latest_encounter.scheduled_for ||
      data.patient.latest_encounter.start_time ||
      data.patient.latest_encounter.created_at;

    return date ? format(new Date(date), "MMM d, yyyy - p") : null;
  }, [data]);

  // Get note ID for navigation
  const noteId = useMemo(() => {
    if (!data?.patient?.latest_encounter) return null;

    return (
      data.patient.latest_encounter.note_id ||
      data.patient.latest_encounter.outputs?.find(
        (output) => output.type === "note"
      )?.encounter_document_id ||
      null
    );
  }, [data]);

  if (!patientId) {
    return (
      <CollapsibleBaseBlock
        defaultCollapsed={defaultCollapsed}
        onCollapseChange={handleCollapseChange}
        sx={{ width: "100%" }}
      >
        <CollapsibleBaseBlock.Header
          title="Last Encounter"
          icon={<Event sx={{ fontSize: 20, color: "text.secondary" }} />}
        />
        <CollapsibleBaseBlock.Body>
          <Typography>
            Please select a {subjectLanguage} to view information about their
            last encounter.
          </Typography>
        </CollapsibleBaseBlock.Body>
      </CollapsibleBaseBlock>
    );
  }

  return (
    <Box onMouseEnter={handleMouseEnter}>
      <CollapsibleBaseBlock
        defaultCollapsed={defaultCollapsed}
        onCollapseChange={handleCollapseChange}
        sx={{ width: "100%" }}
      >
        <CollapsibleBaseBlock.Header
          title="Last Encounter"
          subtitle={encounterDate}
          loading={loading}
          icon={<Event sx={{ fontSize: 20, color: "text.secondary" }} />}
          onTitleClick={() => {
            if (noteId) {
              handleViewNoteClick();
            }
          }}
        />

        <CollapsibleBaseBlock.Body>
          {loading ? (
            <Box sx={{ px: 1, py: 2 }}>
              <Skeleton variant="text" width="90%" height={24} sx={{ mb: 1 }} />
              <Skeleton variant="text" width="75%" height={24} sx={{ mb: 1 }} />
              <Skeleton variant="text" width="80%" height={24} />
            </Box>
          ) : (
            <>
              {error && <Typography color="error">{error}</Typography>}

              {!error && data?.past_visit_summary && (
                <Typography variant="body2">
                  {formatContent(data.past_visit_summary)}
                </Typography>
              )}

              {!error && (
                <>
                  {!data?.patient?.latest_encounter ? (
                    <Typography variant="body2" color="text.secondary">
                      This {subjectLanguage} has no previous encounters. Let's
                      record one!
                    </Typography>
                  ) : (
                    !data?.past_visit_summary && (
                      <Typography variant="body2" color="text.secondary">
                        No information available about this {subjectLanguage}'s
                        last visit.
                      </Typography>
                    )
                  )}
                </>
              )}
            </>
          )}
        </CollapsibleBaseBlock.Body>
        <CollapsibleBaseBlock.BottomExtension>
          <Button
            variant="text"
            onClick={handleViewNoteClick}
            disabled={!noteId}
          >
            View Encounter Note
          </Button>
        </CollapsibleBaseBlock.BottomExtension>
      </CollapsibleBaseBlock>

      <SlideSidePane
        open={isPaneOpen}
        onClose={() => setIsPaneOpen(false)}
        title="Encounter Note"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4">{noteData?.note_title}</Typography>
            <Tooltip title="Go to Note">
              <IconButton
                onClick={() => navigate(`/note/${noteData?.note_id}`)}
              >
                <Launch />
              </IconButton>
            </Tooltip>
          </Box>
          {noteData && <NoteMetadata noteData={noteData} />}
          {noteData?.content.sections.map((section) => (
            <Box key={section.module_id}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {section.name?.replace(/\s*\([^)]*\)/g, "")}
              </Typography>
              <NoteSectionDisplayBox isNA={section.text === "N/A"}>
                {section.text}
              </NoteSectionDisplayBox>
            </Box>
          ))}
        </Box>
      </SlideSidePane>
    </Box>
  );
};

export default LastEncounterBlock;
