import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { NotesLoader } from "../../loaders/DashboardLoader";
import { UserNoteTypeII } from "../../types/types";
import { formatDate } from "../../utils/utils";

interface NotesPickerProps {
  onNoteSelect: (noteId: string) => void;
}

const NotesPicker: React.FC<NotesPickerProps> = ({ onNoteSelect }) => {
  const [notes, setNotes] = useState<UserNoteTypeII[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedNoteId, setSelectedNoteId] = useState<string>();

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await NotesLoader("all", 10, 0, -1);
        setNotes(response.notes);
      } catch (error) {
        console.error("Error fetching notes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotes();
  }, []);

  const handleNoteChange = (event: any, value: UserNoteTypeII | null) => {
    if (value) {
      setSelectedNoteId(value.note_id);
      onNoteSelect(value.note_id);
    } else {
      setSelectedNoteId("");
      onNoteSelect("");
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl fullWidth>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <Autocomplete
            options={notes}
            getOptionLabel={(option) =>
              `${option.note_title || "Untitled"} - ${
                option.patient_name || "Unknown Patient"
              }`
            }
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <div>
                  <div>
                    <strong>
                      {option.patient_name || ""} -{" "}
                      {option.note_title || "Untitled"}
                    </strong>
                  </div>
                  <div style={{ fontSize: "0.8em", color: "#666" }}>
                    {option.summary?.slice(0, 200)}...
                  </div>
                  <div style={{ fontSize: "0.7em", color: "#888" }}>
                    {formatDate(option.time_uploaded)}
                  </div>
                </div>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a recent encounter"
                variant="outlined"
              />
            )}
            onChange={handleNoteChange}
          />
        )}
      </FormControl>
    </Box>
  );
};

export default NotesPicker;
