import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Paper,
  Stack,
  Skeleton,
} from "@mui/material";
import {
  Add as AddIcon,
  Person,
  Description,
  Article,
  CalendarMonth,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { format, formatDistanceToNow } from "date-fns";
import { DashboardCard } from "../../Views/Shared/DashboardCard";
import { Encounter } from "../../types/types";
import { encounterApi } from "../Encounter/encounters";
import { useUser } from "../../context/user";

const UpcomingEncountersDashboardCard: React.FC = () => {
  const navigate = useNavigate();
  const [encounters, setEncounters] = useState<Array<Encounter>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAccessToken, templatesList } = useUser();

  const fetchEncounters = async () => {
    setIsLoading(true);
    try {
      const { encounters: fetchedEncounters } =
        await encounterApi.listEncounters(
          {
            filter_mode: "upcoming",
            per_page: 5, // Show only the next 5 upcoming encounters
          },
          await getAccessToken()
        );
      setEncounters(fetchedEncounters);
    } catch (error) {
      console.error("Error fetching encounters:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchEncounters();
  }, []);

  const handleCreateEncounter = async () => {
    try {
      const newEncounter = await encounterApi.createEncounter(
        {
          status: "pending",
          inputs: [],
          outputs: [],
          encounter_type: templatesList?.[0]?.display_name,
          template_id: templatesList?.[0]?.template_id,
        },
        await getAccessToken()
      );
      navigate(`/encounters/${newEncounter.encounter_id}`);
    } catch (error) {
      console.error("Error creating encounter:", error);
    }
  };

  const LoadingSkeleton = () => (
    <Stack spacing={2}>
      {[...Array(3)].map((_, index) => (
        <Paper
          key={index}
          sx={{
            p: 2,
            backgroundColor: "background.paper",
            border: 1,
            borderRadius: "0.75rem",
            borderColor: "borderColors.primary",
            position: "relative",
          }}
        >
          {/* Time indicator skeleton */}
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              width: 80,
              height: 24,
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ borderRadius: 2 }}
            />
          </Box>

          <Stack spacing={1}>
            {/* Patient name skeleton */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="60%" height={24} />
            </Stack>

            {/* Template name skeleton */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="40%" height={20} />
            </Stack>

            {/* Date/time skeleton */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="50%" height={20} />
            </Stack>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );

  return (
    <DashboardCard
      customStyles={{
        padding: 2,
        minHeight: { xs: "400px", md: "450px" },
        maxHeight: "450px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6">Upcoming Encounters</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={handleCreateEncounter}
          size="small"
        >
          New
        </Button>
      </Box>

      <Box sx={{ flex: 1, overflowY: "auto" }}>
        {isLoading ? (
          <LoadingSkeleton />
        ) : encounters.length === 0 ? (
          <Box sx={{ textAlign: "center", p: 4 }}>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              No upcoming encounters
            </Typography>
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={handleCreateEncounter}
              sx={{ mt: 2 }}
            >
              Schedule an Encounter
            </Button>
          </Box>
        ) : (
          <Stack spacing={2}>
            {encounters.map((encounter) => (
              <Paper
                key={encounter.encounter_id}
                onClick={() =>
                  navigate(`/encounters/${encounter.encounter_id}`)
                }
                sx={{
                  p: 2,
                  backgroundColor: "background.paper",
                  border: 1,
                  borderRadius: "0.75rem",
                  borderColor: "borderColors.primary",
                  cursor: "pointer",
                  transition: "all 0.2s",
                  position: "relative",
                  "&:hover": {
                    bgcolor: "action.hover",
                    transform: "translateY(-2px)",
                    boxShadow: 1,
                  },
                }}
              >
                {encounter.scheduled_for && (
                  <Paper
                    elevation={0}
                    sx={{
                      position: "absolute",
                      top: 8,
                      right: 8,
                      px: 1.5,
                      py: 0.5,
                      bgcolor: "success.soft",
                      borderRadius: 2,
                      border: 1,
                      borderColor: "success.main",
                    }}
                  >
                    <Typography
                      variant="caption"
                      color="success.main"
                      fontWeight="medium"
                    >
                      {formatDistanceToNow(new Date(encounter.scheduled_for), {
                        addSuffix: true,
                      })}
                    </Typography>
                  </Paper>
                )}

                <Stack spacing={1}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    {encounter.patient ? (
                      <Person sx={{ fontSize: 16, color: "text.secondary" }} />
                    ) : null}
                    <Typography variant="subtitle1">
                      {encounter.patient
                        ? `${encounter.patient.first_name} ${encounter.patient.last_name}`
                        : encounter.note_title || "Untitled Encounter"}
                    </Typography>
                  </Stack>

                  <Stack direction="row" spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Description
                        sx={{ fontSize: 16, color: "text.secondary" }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {encounter.encounter_type || "No Template"}
                      </Typography>
                    </Stack>
                  </Stack>

                  {encounter.scheduled_for && (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <CalendarMonth
                        sx={{ fontSize: 16, color: "text.secondary" }}
                      />
                      <Typography variant="body2" color="text.secondary">
                        {format(
                          new Date(encounter.scheduled_for),
                          "MMM d, yyyy 'at' h:mm a"
                        )}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Paper>
            ))}
          </Stack>
        )}
      </Box>
    </DashboardCard>
  );
};

export default UpcomingEncountersDashboardCard;
