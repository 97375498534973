import React from "react";
import { Box, Button, Alert, TextField } from "@mui/material";
import BaseBlock from "./BaseBlock";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import APIService from "../../../services/APIService";
import { useUser } from "../../../context/user";
import SaveIcon from "@mui/icons-material/Save";
import { useSnackbar } from "notistack";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import SentryService from "../../../services/SentryService";

interface TextModuleData {
  current_data: {
    [key: string]: string;
  };
  current_version: number;
  differentials?: {
    values_changed?: {
      [key: `root['${string}']`]: {
        old_value: string;
        new_value: string;
      };
    };
  };
  last_approved_data: {
    [key: string]: string;
  };
  last_approved_version: number;
  last_updated: string;
  patient_module_id: string;
  schema: { name: string; type: string }[];
  type: "text";
  name: string;
}

interface TextBlockProps {
  moduleData: TextModuleData;
  title: string;
  patientId: string;
  onDataUpdate?: (
    updatedData: string,
    action: "accept" | "reject" | "edit"
  ) => void;
}

const TextBlock: React.FC<TextBlockProps> = ({
  moduleData,
  title,
  patientId,
  onDataUpdate,
}) => {
  const { getAccessToken } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [pendingChanges, setPendingChanges] = React.useState<string | null>(
    null
  );
  const [showDifferentials, setShowDifferentials] = React.useState(true);
  const [isEditing, setIsEditing] = React.useState(false);

  const textFieldName = Object.keys(moduleData.current_data)[0];

  const hasChanges = moduleData.differentials !== undefined;

  console.log(hasChanges);

  const hasPendingChanges = pendingChanges !== null;

  const oldValue = moduleData.last_approved_data?.[textFieldName] || "";
  const newValue = moduleData.current_data?.[textFieldName] || "";

  const handleApproveChanges = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/approvePatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
        },
      });

      if (response.ok) {
        setShowDifferentials(false);
        moduleData.differentials = undefined;
        enqueueSnackbar("Changes approved.", { variant: "success" });
        if (onDataUpdate) {
          onDataUpdate(moduleData.current_data[textFieldName], "accept");
        }
      } else {
        throw new Error("Failed to approve changes");
      }
    } catch (error) {
      console.error("Error approving changes:", error);
      enqueueSnackbar("Failed to approve changes", { variant: "error" });
    }
  };

  const handleRejectChanges = async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/rejectPatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
        },
      });

      if (response.ok) {
        setShowDifferentials(false);
        moduleData.current_data[textFieldName] =
          moduleData.last_approved_data?.[textFieldName] || "";
        moduleData.differentials = undefined;
        enqueueSnackbar("Changes rejected", { variant: "success" });
        if (onDataUpdate) {
          onDataUpdate(
            moduleData.last_approved_data?.[textFieldName] || "",
            "reject"
          );
        }
      } else {
        throw new Error("Failed to reject changes");
      }
    } catch (error) {
      console.error("Error rejecting changes:", error);
      enqueueSnackbar("Failed to reject changes", { variant: "error" });
    }
  };

  const handleSaveChanges = async () => {
    if (!pendingChanges) return;

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/updatePatientModule",
        accessToken,
        body: {
          patient_id: patientId,
          patient_module_id: moduleData.patient_module_id,
          data: { [textFieldName]: pendingChanges },
        },
      });

      if (response.ok) {
        moduleData.current_data[textFieldName] = pendingChanges;
        setPendingChanges(null);
        enqueueSnackbar("Changes saved", { variant: "success" });
        if (onDataUpdate) {
          onDataUpdate(pendingChanges, "edit");
        }
      } else {
        throw new Error("Failed to update module");
      }
    } catch (error) {
      console.error("Error updating module:", error);
      enqueueSnackbar("Failed to save changes", { variant: "error" });
      SentryService.logEvent("Error updating patient module", {
        level: "error",
        tags: {
          patient_id: patientId || "",
        },
        extra: {
          error: error,
        },
      });
    }
  };

  const handleTextChange = async (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newText = e.target.value;

    if (showDifferentials && hasChanges) {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIPostRequest({
          requestString: "/patient/approvePatientModule",
          accessToken,
          body: {
            patient_id: patientId,
            patient_module_id: moduleData.patient_module_id,
          },
        });

        if (response.ok) {
          setShowDifferentials(false);
          enqueueSnackbar("AI changes automatically approved", {
            variant: "info",
          });
          if (onDataUpdate) {
            onDataUpdate(moduleData.current_data[textFieldName], "accept");
          }
        }
      } catch (error) {
        console.error("Error auto-approving AI changes:", error);
        enqueueSnackbar("Failed to approve AI changes", { variant: "error" });
        return;
      }
    }

    setPendingChanges(newText);
  };

  const customDiffStyles = {
    variables: {
      light: {
        diffViewerBackground: "#fff",
        diffViewerColor: "#212121",
        addedBackground: "#e6ffec",
        addedColor: "#24292f",
        removedBackground: "#ffebe9",
        removedColor: "#24292f",
        wordAddedBackground: "#dafbe1",
        wordRemovedBackground: "#ffeef0",
      },
    },
    line: {
      padding: "10px",
    },
    wordDiff: {
      added: {
        backgroundColor: "#dafbe1",
        textDecoration: "none",
        padding: "2px 0",
      },
      removed: {
        backgroundColor: "#ffeef0",
        textDecoration: "line-through",
        padding: "2px 0",
      },
    },
  };

  return (
    <BaseBlock
      title={title}
      sx={{
        maxHeight: "50vh",
        overflowY: "auto",
      }}
    >
      {showDifferentials && hasChanges ? (
        <>
          <Alert
            severity="info"
            action={
              <Box sx={{ display: "flex", gap: 1 }}>
                <Button
                  startIcon={<CheckIcon />}
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={handleApproveChanges}
                >
                  Accept Changes
                </Button>
                <Button
                  startIcon={<CloseIcon />}
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={handleRejectChanges}
                >
                  Reject Changes
                </Button>
              </Box>
            }
            sx={{ mb: 2 }}
          >
            This text has pending changes that need to be approved.
          </Alert>

          <Box sx={{ mb: 2 }}>
            <ReactDiffViewer
              oldValue={oldValue}
              newValue={newValue}
              splitView={false}
              useDarkTheme={false}
              compareMethod={DiffMethod.SENTENCES}
              styles={customDiffStyles}
              hideLineNumbers={true}
            />
          </Box>
        </>
      ) : (
        <>
          {isEditing ? (
            <TextField
              multiline
              fullWidth
              minRows={4}
              value={pendingChanges ?? moduleData.current_data[textFieldName]}
              onChange={handleTextChange}
              onBlur={() => {
                if (!pendingChanges) {
                  setIsEditing(false);
                }
              }}
            />
          ) : (
            <Box
              onDoubleClick={() => setIsEditing(true)}
              sx={{
                p: 2,
                minHeight: "100px",
                maxHeight: "50vh",
                overflowY: "auto",
                whiteSpace: "pre-wrap",
                bgcolor: "background.paper",
                borderRadius: 1,
                border: "1px solid",
                borderColor: "divider",
              }}
            >
              {moduleData.current_data[textFieldName]}
            </Box>
          )}
        </>
      )}

      {hasPendingChanges && isEditing && (
        <Alert
          severity="warning"
          sx={{ mt: 2 }}
          action={
            <Button
              startIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              size="small"
              onClick={handleSaveChanges}
            >
              Save Changes
            </Button>
          }
        >
          You have unsaved changes.
        </Alert>
      )}
    </BaseBlock>
  );
};

export default TextBlock;
