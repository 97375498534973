import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckIcon from "@mui/icons-material/Check";
import { formatDate } from "../../utils/utils";
import { BlockNoteEditor } from "@blocknote/core";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import "../../styles/blockNoteStyles.css";

// Define the JotDocDataType interface for jotdoc data
export interface JotDocDataType {
  jotdoc_id: string;
  title: string;
  content: string;
  created_at: string;
}

interface JotDocViewerProps {
  jotdoc: JotDocDataType | null;
  loading?: boolean;
}

/**
 * Utility function to copy rich text to clipboard
 * @param html HTML string to copy as rich text
 * @param plainText Fallback plain text version
 */
const copyRichText = async (html: string, plainText: string) => {
  try {
    // For browsers that support the Clipboard API with ClipboardItem
    if (navigator.clipboard && window.ClipboardItem) {
      const type = "text/html";
      const blob = new Blob([html], { type });
      const data = [new ClipboardItem({ [type]: blob })];
      await navigator.clipboard.write(data);
      return true;
    } else {
      // Fallback for browsers that don't support the Clipboard API with ClipboardItem
      // Create a temporary element to hold the HTML content
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      tempElement.style.position = "absolute";
      tempElement.style.left = "-9999px";
      document.body.appendChild(tempElement);

      // Select the temporary element
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(tempElement);
      selection?.removeAllRanges();
      selection?.addRange(range);

      // Execute the copy command
      const successful = document.execCommand("copy");

      // Clean up
      selection?.removeAllRanges();
      document.body.removeChild(tempElement);

      if (!successful) {
        // If HTML copy fails, fall back to plain text
        await navigator.clipboard.writeText(plainText);
      }

      return true;
    }
  } catch (err) {
    console.error("Copy failed:", err);
    // Last resort fallback to plain text
    try {
      await navigator.clipboard.writeText(plainText);
      return true;
    } catch (clipboardErr) {
      console.error("Plain text copy also failed:", clipboardErr);
      return false;
    }
  }
};

const JotDocViewer: React.FC<JotDocViewerProps> = ({
  jotdoc,
  loading = false,
}) => {
  // Create a BlockNote editor instance for viewing the jotdoc
  const editor = useCreateBlockNote();
  const [copied, setCopied] = useState(false);

  // Update the editor content when jotdoc changes
  useEffect(() => {
    if (jotdoc && jotdoc.content) {
      const updateEditorContent = async () => {
        try {
          // Convert markdown to blocks and update the editor
          const blocks = await editor.tryParseMarkdownToBlocks(jotdoc.content);
          editor.replaceBlocks(editor.document, blocks);
        } catch (err) {
          console.error("Error parsing jotdoc content to blocks:", err);
        }
      };

      updateEditorContent();
    }
  }, [jotdoc, editor]);

  // Handle copying content to clipboard as rich text
  const handleCopyContent = async () => {
    if (jotdoc && editor) {
      try {
        // Get HTML representation of the content from the editor
        const htmlContent = await editor.blocksToHTMLLossy(editor.document);

        // Copy as rich text with plain text fallback
        const success = await copyRichText(
          htmlContent,
          jotdoc.content // Fallback to markdown if rich text copy fails
        );

        if (success) {
          // Show success state
          setCopied(true);
          // Reset after 2 seconds
          setTimeout(() => setCopied(false), 2000);
        }
      } catch (err) {
        console.error("Error copying rich text:", err);
        // Fall back to copying plain markdown
        navigator.clipboard.writeText(jotdoc.content).then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        });
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!jotdoc) {
    return (
      <Box sx={{ p: 2, textAlign: "center" }}>
        <Typography variant="body1" color="text.secondary">
          No document content available
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h6" color="primary">
          {jotdoc.title}
        </Typography>
        <Tooltip title={copied ? "Copied!" : "Copy formatted content"}>
          <IconButton
            onClick={handleCopyContent}
            color={copied ? "success" : "default"}
            size="medium"
          >
            {copied ? <CheckIcon /> : <ContentCopyIcon />}
          </IconButton>
        </Tooltip>
      </Box>
      <BlockNoteView
        editor={editor}
        editable={false}
        theme="light"
        style={{ maxHeight: "70vh" }}
      />
    </Box>
  );
};

export default JotDocViewer;
