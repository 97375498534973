import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  IconButton,
  SelectChangeEvent,
  Paper,
  Collapse,
  Chip,
  Grid,
  useMediaQuery,
  useTheme,
  Divider,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Search,
  Add as AddIcon,
  Person,
  Title,
  Description,
  Article,
  CalendarMonth,
  FilterList,
  CheckCircle,
  Pending,
  Loop,
  AccessTime,
  Mic,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import LayoutWrapper from "../layout/UILayout";
import { encounterApi } from "./encounters";
import { useEncounter } from "./EncounterContext";
import { Encounter, Patient } from "../../types/types";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Pagination from "../Pagination";
import { DismissableCard } from "../../Views/Shared/DismissableCard";
import { format, formatDistanceToNow, isPast } from "date-fns";
import { useUser } from "../../context/user";
import CalendarWidget from "../Appointment/CalendarWidget";
import {
  EncountersDataProvider,
  useEncountersData,
} from "../../context/EncountersDataContext";
import PatientPicker from "../Patient/PatientPicker";
import TemplatePicker from "../Pickers/TemplatePicker";
import EncounterScheduleDuration from "./EncounterScheduleDuration";
import AddEncounterModal from "./AddEncounterModal";
import { TutorialProvider, useTutorial } from "../Tutorial/TutorialContext";
import { TutorialStep } from "../Tutorial/TutorialStep";
import CalendarNavigation from "../Appointment/CalendarNavigation";
import EncounterListView from "./EncounterListView";

// Create a new component for the calendar sidebar list
const CalendarSidebarList: React.FC = () => {
  const navigate = useNavigate();
  const {
    allEncounterData,
    upcomingEncounters,
    isLoading,
    isBackgroundLoading,
    hoveredEncounterId,
    setHoveredEncounterId,
    refreshEncounters,
  } = useEncountersData();
  const { getAccessToken, templatesList, subjectLanguage } = useUser();
  const [isAddModalOpen, setIsAddModalOpen] = React.useState(false);

  // Get only the encounters with scheduled_for dates for the current calendar view
  const scheduledEncounters = allEncounterData.filter(
    (encounter) => encounter.scheduled_for
  );

  // Sort them by date (chronologically)
  const sortedEncounters = [...scheduledEncounters].sort((a, b) => {
    return (
      new Date(a.scheduled_for!).getTime() -
      new Date(b.scheduled_for!).getTime()
    );
  });

  // Get next upcoming encounter from our pre-filtered list
  const nextEncounter =
    upcomingEncounters.length > 0 ? upcomingEncounters[0] : null;

  // Function to count recording inputs
  const countRecordingInputs = (encounter: Encounter) => {
    return encounter.inputs.filter((input) => input.type === "recording")
      .length;
  };

  // Simplified card for list view - similar to the upcoming one but with hover effect
  const renderCalendarEncounter = (encounter: Encounter) => (
    <Paper
      key={encounter.encounter_id}
      onClick={() => navigate(`/encounters/${encounter.encounter_id}`)}
      onMouseEnter={() => setHoveredEncounterId(encounter.encounter_id)}
      onMouseLeave={() => setHoveredEncounterId(null)}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor:
          hoveredEncounterId === encounter.encounter_id
            ? "action.hover"
            : "background.paper",
        border: 1,
        borderRadius: "0.75rem",
        borderColor:
          hoveredEncounterId === encounter.encounter_id
            ? "primary.main"
            : "borderColors.primary",
        cursor: "pointer",
        transition: "all 0.2s",
        position: "relative",
        "&:hover": {
          bgcolor: "action.hover",
          transform: "translateY(-2px)",
          boxShadow: 1,
        },
      }}
    >
      {encounter.scheduled_for && (
        <Paper
          elevation={0}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            px: 1.5,
            py: 0.5,
            bgcolor: "success.soft",
            borderRadius: 2,
            border: 1,
            borderColor: "success.main",
          }}
        >
          <Typography
            variant="caption"
            color="success.main"
            fontWeight="medium"
          >
            {formatDistanceToNow(new Date(encounter.scheduled_for), {
              addSuffix: true,
            })}
          </Typography>
        </Paper>
      )}

      <Stack spacing={1}>
        <Stack direction="row" spacing={1} alignItems="center">
          {encounter.patient ? (
            <Person sx={{ fontSize: 16, color: "text.secondary" }} />
          ) : null}
          <Typography variant="subtitle1">
            {encounter.patient
              ? `${encounter.patient.first_name} ${encounter.patient.last_name}`
              : encounter.note_title || "Untitled Encounter"}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Description sx={{ fontSize: 16, color: "text.secondary" }} />
            <Typography variant="body2" color="text.secondary">
              {encounter.encounter_type || "No Template"}
            </Typography>
          </Stack>
          {countRecordingInputs(encounter) > 0 && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Mic sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {countRecordingInputs(encounter)}
              </Typography>
            </Stack>
          )}
        </Stack>

        {encounter.scheduled_for && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarMonth sx={{ fontSize: 16, color: "text.secondary" }} />
            <Typography variant="body2" color="text.secondary">
              {format(
                new Date(encounter.scheduled_for),
                "MMM d, yyyy 'at' h:mm a"
              )}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );

  // Enhanced card for the next encounter with more details
  const renderNextEncounterCard = (encounter: Encounter) => (
    <Paper
      onClick={() => navigate(`/encounters/${encounter.encounter_id}`)}
      onMouseEnter={() => setHoveredEncounterId(encounter.encounter_id)}
      onMouseLeave={() => setHoveredEncounterId(null)}
      sx={{
        p: 2.5,
        mb: 3,
        backgroundColor: "primary.soft",
        border: 1,
        borderRadius: "0.75rem",
        borderColor: "primary.main",
        cursor: "pointer",
        transition: "all 0.2s",
        position: "relative",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: 2,
        },
      }}
    >
      <Typography
        variant="overline"
        color="primary"
        sx={{ fontWeight: "bold", display: "block", mb: 1 }}
      >
        NEXT APPOINTMENT
      </Typography>

      {encounter.scheduled_for && (
        <Paper
          elevation={0}
          sx={{
            position: "absolute",
            top: 12,
            right: 12,
            px: 1.5,
            py: 0.5,
            bgcolor: "success.soft",
            borderRadius: 2,
            border: 1,
            borderColor: "success.main",
          }}
        >
          <Typography
            variant="caption"
            color="success.main"
            fontWeight="medium"
          >
            {formatDistanceToNow(new Date(encounter.scheduled_for), {
              addSuffix: true,
            })}
          </Typography>
        </Paper>
      )}

      <Stack spacing={1.5}>
        <Stack direction="row" spacing={1} alignItems="center">
          {encounter.patient ? (
            <Person sx={{ fontSize: 20, color: "primary.main" }} />
          ) : (
            <Title sx={{ fontSize: 20, color: "primary.main" }} />
          )}
          <Typography variant="h6">
            {encounter.patient
              ? `${encounter.patient.first_name} ${encounter.patient.last_name}`
              : encounter.note_title || "Untitled Encounter"}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={1} alignItems="center">
          <Description sx={{ fontSize: 18, color: "primary.main" }} />
          <Typography variant="body1">
            {encounter.encounter_type || "No Template"}
          </Typography>
        </Stack>

        {countRecordingInputs(encounter) > 0 && (
          <Stack direction="row" spacing={1} alignItems="center">
            <Mic sx={{ fontSize: 18, color: "primary.main" }} />
            <Typography variant="body1">
              {countRecordingInputs(encounter)} Recording
              {countRecordingInputs(encounter) > 1 ? "s" : ""}
            </Typography>
          </Stack>
        )}

        {encounter.scheduled_for && (
          <Stack direction="row" spacing={1} alignItems="center">
            <CalendarMonth sx={{ fontSize: 18, color: "primary.main" }} />
            <Typography variant="body1" fontWeight="medium">
              {format(
                new Date(encounter.scheduled_for),
                "EEEE, MMMM d, yyyy 'at' h:mm a"
              )}
            </Typography>
          </Stack>
        )}

        {/* Show duration if available */}
        {encounter.scheduled_duration && (
          <Stack direction="row" spacing={1} alignItems="center">
            <AccessTime sx={{ fontSize: 18, color: "primary.main" }} />
            <Typography variant="body1">
              {`Duration: ${Math.floor(encounter.scheduled_duration / 60)}h ${
                encounter.scheduled_duration % 60
              }m`}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Paper>
  );

  const LoadingSkeleton = () => (
    <Stack spacing={2}>
      {[...Array(3)].map((_, index) => (
        <Paper
          key={index}
          sx={{
            p: 2,
            backgroundColor: "background.paper",
            border: 1,
            borderRadius: "0.75rem",
            borderColor: "borderColors.primary",
            position: "relative",
          }}
        >
          {/* Time indicator skeleton */}
          <Box
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
              width: 80,
              height: 24,
            }}
          >
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ borderRadius: 2 }}
            />
          </Box>

          <Stack spacing={1}>
            {/* Patient name skeleton */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="60%" height={24} />
            </Stack>

            {/* Template name skeleton */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="40%" height={20} />
            </Stack>

            {/* Date/time skeleton */}
            <Stack direction="row" spacing={1} alignItems="center">
              <Skeleton variant="circular" width={24} height={24} />
              <Skeleton variant="text" width="50%" height={20} />
            </Stack>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );

  return (
    <Box
      sx={{
        height: { md: "calc(100vh - 240px)" },
        overflow: { md: "auto" },
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      {/* Next Encounter Section */}
      <TutorialStep
        step={2}
        description="Your next upcoming appointment will be highlighted here. Click on it to jump to the encounter view where you can start the recording."
      >
        {!isLoading && nextEncounter ? (
          renderNextEncounterCard(nextEncounter)
        ) : (
          <Paper
            sx={{
              p: 2.5,
              mb: 3,
              backgroundColor: "background.paper",
              border: 1,
              borderRadius: "0.75rem",
              borderColor: "borderColors.primary",
              position: "relative",
              minHeight: 150,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <CircularProgress size={28} />
            ) : (
              <Typography variant="body1" color="text.secondary" align="center">
                Your next upcoming appointment will appear here
              </Typography>
            )}
          </Paper>
        )}
      </TutorialStep>
      <Divider sx={{ my: 2 }} />

      {/* Add Encounter Modal */}
      <AddEncounterModal
        open={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
      />

      {/* Calendar-filtered Encounters List */}
      <Box sx={{ flex: 1, position: "relative" }}>
        <TutorialStep
          step={3}
          description="All your scheduled appointments appear here. Once an appointment is scheduled, you can start adding dictations, notes, and other documents to it. This can save you time when the encounter actually happens."
        >
          {isLoading ? (
            <LoadingSkeleton />
          ) : sortedEncounters.length === 0 ? (
            <Box sx={{ textAlign: "center", p: 4 }}>
              <Typography variant="body1" color="text.secondary" gutterBottom>
                No scheduled encounters in this view
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Try changing the calendar view or date range
              </Typography>
            </Box>
          ) : (
            <Stack spacing={2}>
              {/* Filter out the next encounter if it's already shown in the Next Encounter section */}
              {sortedEncounters
                .filter(
                  (encounter) =>
                    !nextEncounter ||
                    encounter.encounter_id !== nextEncounter.encounter_id
                )
                .map(renderCalendarEncounter)}
            </Stack>
          )}
        </TutorialStep>
      </Box>
    </Box>
  );
};

// Inner component that uses EncountersDataContext
const ScheduleViewInner: React.FC = () => {
  const navigate = useNavigate();
  const { getAccessToken, templatesList, subjectLanguage } = useUser();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const { isActive: isTutorialActive } = useTutorial();

  // Get all data from context
  const {
    allEncounterData,
    isLoading,
    totalCount,
    filterMode,
    setFilterMode,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sortOrder,
    setSortOrder,
    refreshEncounters,
    setTutorialMode,
    viewMode,
  } = useEncountersData();

  // Set tutorial mode when tutorial is active
  const prevTutorialActiveRef = React.useRef<boolean | null>(null);

  useEffect(() => {
    // Initialize on first render or when changed
    if (
      prevTutorialActiveRef.current === null ||
      prevTutorialActiveRef.current !== isTutorialActive
    ) {
      console.log("Tutorial active state changed:", isTutorialActive);
      setTutorialMode(isTutorialActive);
      prevTutorialActiveRef.current = isTutorialActive;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTutorialActive]);

  // Local state just for UI elements
  const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(() => {
    return localStorage.getItem("encounterShowAdvancedFilters") === "true";
  });

  // Add a function to clear both date filters
  const handleClearDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleSortOrderChange = (e: SelectChangeEvent) => {
    const newSortOrder = e.target.value;
    setSortOrder(newSortOrder === "default" ? null : newSortOrder);
  };

  const handleCreateEncounter = async () => {
    try {
      const newEncounter = await encounterApi.createEncounter(
        {
          status: "pending",
          inputs: [],
          outputs: [],
          encounter_type: templatesList?.[0]?.display_name,
          template_id: templatesList?.[0]?.template_id,
        },
        await getAccessToken()
      );
      navigate(`/encounters/${newEncounter.encounter_id}`);
      // Refresh encounter lists after creating a new one - full refresh since it's a new entry
      refreshEncounters({ forceFullLoading: false, quietRefresh: true });
    } catch (error) {
      console.error("Error creating encounter:", error);
    }
  };

  const handleStatusChange = (e: SelectChangeEvent) => {
    const newStatus = e.target.value as typeof filterMode;
    setFilterMode(newStatus);
  };

  const handleItemsPerPageChange = (e: SelectChangeEvent) => {
    const newValue = e.target.value;
    setItemsPerPage(newValue);
  };

  const toggleAdvancedFilters = () => {
    const newValue = !showAdvancedFilters;
    setShowAdvancedFilters(newValue);
    localStorage.setItem("encounterShowAdvancedFilters", String(newValue));
  };

  const renderEncounter = (encounter: Encounter) => (
    <Paper
      key={encounter.encounter_id}
      onClick={() => navigate(`/encounters/${encounter.encounter_id}`)}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: "background.paper",
        border: 1,
        borderRadius: "0.75rem",
        borderColor: "borderColors.primary",
        cursor: "pointer",
        transition: "all 0.2s",
        "&:hover": {
          bgcolor: "action.hover",
          transform: "translateY(-2px)",
          boxShadow: 1,
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack spacing={0.5}>
          <Stack direction="row" spacing={1} alignItems="center">
            {encounter.patient ? (
              <Person sx={{ fontSize: 16, color: "text.secondary" }} />
            ) : (
              <Title sx={{ fontSize: 16, color: "text.secondary" }} />
            )}
            <Typography variant="h6">
              {encounter.patient
                ? `${encounter.patient.first_name} ${encounter.patient.last_name}`
                : encounter.note_title || "Untitled Encounter"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Description sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {encounter.encounter_type || "No Template Selected"}
              </Typography>
              <Article sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {encounter.inputs.length}
              </Typography>
            </Stack>
          </Stack>
          {encounter.scheduled_for && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CalendarMonth sx={{ fontSize: 16, color: "text.secondary" }} />
              <Typography variant="body2" color="text.secondary">
                {format(
                  new Date(encounter.scheduled_for),
                  "MMM d, yyyy 'at' h:mm a"
                )}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Stack spacing={1} alignItems="center">
            {encounter.scheduled_for ? (
              <Paper
                elevation={0}
                sx={{
                  px: 2,
                  py: 1,
                  bgcolor: isPast(new Date(encounter.scheduled_for))
                    ? "error.soft"
                    : "success.soft",
                  borderRadius: 2,
                  minWidth: 100,
                  textAlign: "center",
                  border: 1,
                  borderColor: isPast(new Date(encounter.scheduled_for))
                    ? "error.main"
                    : "success.main",
                }}
              >
                <Typography
                  variant="body2"
                  color={
                    isPast(new Date(encounter.scheduled_for))
                      ? "error.main"
                      : "success.main"
                  }
                >
                  {isPast(new Date(encounter.scheduled_for))
                    ? `${formatDistanceToNow(
                        new Date(encounter.scheduled_for)
                      )} ago`
                    : `in ${formatDistanceToNow(
                        new Date(encounter.scheduled_for)
                      )}`}
                </Typography>
              </Paper>
            ) : (
              <Typography variant="body2" color="text.secondary">
                Not Scheduled
              </Typography>
            )}
            {encounter.status !== "pending" && (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                {encounter.status === "processing" ? (
                  <>
                    <Loop fontSize="small" sx={{ color: "warning.main" }} />
                    <Typography variant="body2" color="warning.main">
                      Processing
                    </Typography>
                  </>
                ) : encounter.status === "completed" ? (
                  <>
                    <CheckCircle
                      fontSize="small"
                      sx={{ color: "success.main" }}
                    />
                    <Typography variant="body2" color="success.main">
                      Completed
                    </Typography>
                  </>
                ) : null}
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </Paper>
  );

  return (
    <LayoutWrapper>
      <LayoutWrapper.MainContent>
        {/* First render the CalendarNavigation component */}
        <Box sx={{ p: { xs: 2, md: 4 } }}>
          {/* Calendar Navigation is now sticky within itself - this is now step 0 in CalendarNavigation */}
          <CalendarNavigation />

          {/* Conditionally render either calendar view or list view based on viewMode */}
          {viewMode === "list" ? (
            // Show the list view using the standalone component
            <EncounterListView />
          ) : (
            // Show the calendar view with grid layout
            <Grid container spacing={3}>
              {/* Calendar Widget - Takes 70% on md screens and up, full width on smaller screens */}
              <Grid item xs={12} md={8.4}>
                <Box
                  sx={{
                    height: { xs: "auto", md: "calc(100vh - 240px)" }, // Adjusted height for all screens
                    minHeight: { xs: "600px", md: "700px" }, // Ensure minimum height for the calendar
                    display: "flex",
                    flexDirection: "column",
                    mb: { xs: 4, md: 0 },
                  }}
                >
                  <TutorialStep
                    step={1}
                    description="This is your appointment calendar. You can view and manage your appointments here. Click and drag on the calendar to create a new appointment at that time."
                    placement="right"
                  >
                    <CalendarWidget />
                  </TutorialStep>
                </Box>
              </Grid>

              {/* Calendar Encounters List - Takes 30% on md screens and up, full width on smaller screens */}
              <Grid item xs={12} md={3.6}>
                <CalendarSidebarList />
              </Grid>
            </Grid>
          )}
        </Box>

        {/* Final Tutorial Step for Complete Overview - only show in calendar mode */}
        {viewMode !== "list" && (
          <TutorialStep
            step={4}
            description={`And that's it! JotPsych lets you prepare for ${subjectLanguage} encounters in advance, so you can focus on what really matters.`}
          >
            {/* Empty div to anchor the final tutorial step */}
            <div
              style={{
                position: "fixed",
                bottom: 20,
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1000,
              }}
            ></div>
          </TutorialStep>
        )}
      </LayoutWrapper.MainContent>
    </LayoutWrapper>
  );
};

// Wrapper component that provides the context
export const ScheduleView: React.FC = () => {
  return (
    <EncountersDataProvider>
      <TutorialProvider
        tutorialKey="schedule-view"
        totalSteps={5}
        tutorialDisabled={false}
      >
        <ScheduleViewInner />
      </TutorialProvider>
    </EncountersDataProvider>
  );
};

export default ScheduleView;
