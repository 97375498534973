import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  SelectChangeEvent,
} from "@mui/material";
import CollapsibleBaseBlock from "./Patient/Blocks/CollapsibleBaseBlock";

const SCENARIOS = {
  "mdd-dictation": {
    title: "MDD Session Dictation",
    script: [
      {
        speaker: "Provider",
        utterance:
          "Met with the patient today who prefers not to have sessions recorded.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient reports ongoing depressive symptoms, including persistent low mood, fatigue, and reduced motivation.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient describes difficulty maintaining focus at work and diminished interest in previously enjoyed activities.",
      },
      {
        speaker: "Provider",
        utterance:
          "Sleep disturbances were also reported, including both difficulty falling asleep and early morning awakening.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient continues to tolerate current antidepressant medication without significant side effects but notes only minimal improvement in symptoms.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient expressed frustration about the lack of progress, citing impact on social interactions and performance at work.",
      },
      {
        speaker: "Provider",
        utterance:
          "Discussed possibility of adjusting the current medication dosage or considering an alternative antidepressant.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient expressed openness to medication adjustment but voiced concerns about potential side effects, especially regarding weight gain or emotional blunting.",
      },
      {
        speaker: "Provider",
        utterance:
          "Reviewed the patient's recent PHQ-9 score, which remains moderately elevated at 16, indicating significant depressive symptoms.",
      },
      {
        speaker: "Provider",
        utterance:
          "Briefly discussed adjunctive therapeutic options, including behavioral activation strategies, increased physical activity, and mindfulness practices.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient indicated interest in attempting more structured physical activity and mindfulness exercises to support mood management.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient agreed to monitor mood and activity levels daily and track sleep patterns over the coming weeks.",
      },
      {
        speaker: "Provider",
        utterance:
          "Addressed patient's question regarding therapy effectiveness, reinforcing the value of ongoing psychotherapy in combination with pharmacological treatment.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient acknowledged feeling somewhat isolated and agreed to consider increasing social engagement gradually as a therapeutic goal.",
      },
      {
        speaker: "Provider",
        utterance:
          "Set a plan for patient to review educational materials on depression management techniques provided during session.",
      },
      {
        speaker: "Provider",
        utterance:
          "Patient agreed to consider new strategies and revisit treatment decisions during the next session.",
      },
    ],
  },
  "adhd-med-check": {
    title: "ADHD Medication Follow-Up",
    script: [
      {
        speaker: "Provider",
        utterance: "How have you been doing since starting your Vyvanse?",
      },
      {
        speaker: "Patient",
        utterance:
          "Overall, better. I'm able to focus more at work, and I feel less scattered throughout the day.",
      },
      {
        speaker: "Provider",
        utterance:
          "That's excellent. Have you noticed any side effects with Vyvanse?",
      },
      {
        speaker: "Patient",
        utterance:
          "Initially, I had trouble sleeping, but that's gotten better. Occasionally, though, I still have a reduced appetite during the day.",
      },
      {
        speaker: "Provider",
        utterance:
          "Both decreased appetite and sleep disturbances are common when starting stimulant medications. You previously mentioned not tolerating Adderall well due to some side effects—remind me, what specifically bothered you about Adderall?",
      },
      {
        speaker: "Patient",
        utterance:
          "Yes, I took Adderall about two years ago for around six months, but it made me feel jittery, anxious, and gave me bad headaches. That's why I stopped.",
      },
      {
        speaker: "Provider",
        utterance:
          "That's understandable. Many patients experience anxiety or jitteriness with Adderall, which is why Vyvanse can sometimes be a better option since it often has smoother effects. How effective do you feel the current dosage of Vyvanse is?",
      },
      {
        speaker: "Patient",
        utterance:
          "It's definitely helping, but sometimes it wears off by late afternoon, especially around 4 PM.",
      },
      {
        speaker: "Provider",
        utterance:
          "We can discuss a couple of options—perhaps adjusting the timing of when you take it or considering dosage adjustments. Do you have a preference?",
      },
      {
        speaker: "Patient",
        utterance:
          "I'd prefer adjusting the timing first—maybe taking it slightly later in the morning.",
      },
      {
        speaker: "Provider",
        utterance:
          "That's reasonable. Before we finalize that, let's quickly review your latest assessment scores. Your follow-up Adult ADHD Self-Report Scale (ASRS) score shows noticeable improvement—you initially scored a 32, and now you've come down to 18. That's encouraging progress.",
      },
      {
        speaker: "Patient",
        utterance:
          "That's great to hear. I definitely feel improvements at work.",
      },
      {
        speaker: "Provider",
        utterance:
          "Are there specific tasks or situations at work that remain challenging despite taking Vyvanse?",
      },
      {
        speaker: "Patient",
        utterance:
          "Later afternoon meetings are still tough. I find myself zoning out more as the medication fades.",
      },
      {
        speaker: "Provider",
        utterance:
          "That matches up with your observation about timing. We'll explore shifting your medication time a bit later. Overall, how has your mood been? Have you had any irritability or increased anxiety?",
      },
      {
        speaker: "Patient",
        utterance:
          "My mood has generally been stable. Occasionally, when the Vyvanse wears off, I feel mildly irritable, but it's manageable and much less than what I felt with Adderall.",
      },
      {
        speaker: "Provider",
        utterance:
          "That's useful feedback. Any concerns regarding appetite or weight? I know you mentioned less hunger.",
      },
      {
        speaker: "Patient",
        utterance:
          "I've lost around 3 pounds, nothing extreme, but I've been mindful to eat regular meals anyway.",
      },
      {
        speaker: "Provider",
        utterance:
          "Good, continue monitoring your weight regularly. How about your sleep now—has it stabilized since those initial difficulties?",
      },
      {
        speaker: "Patient",
        utterance:
          "Yes, it's much better now. I'm consistently getting around 7 hours of sleep per night.",
      },
      {
        speaker: "Provider",
        utterance:
          "That's reassuring. Is there anything else you'd like to discuss or questions you have about your medication or managing ADHD overall?",
      },
      {
        speaker: "Patient",
        utterance:
          "Just if there are other things I should consider besides medication, such as specific lifestyle changes or habits.",
      },
      {
        speaker: "Provider",
        utterance:
          "Absolutely. Besides medication, it's helpful to incorporate regular routines, structured breaks at work, minimizing distractions in your workspace, and regular exercise. These can greatly enhance the effectiveness of your medication.",
      },
      {
        speaker: "Patient",
        utterance:
          "I've actually started exercising more regularly, and it seems to help quite a bit.",
      },
      {
        speaker: "Provider",
        utterance:
          "That's fantastic. Exercise often boosts dopamine naturally, improving attention and overall mood significantly.",
      },
      {
        speaker: "Patient",
        utterance: "Thanks, that's very encouraging.",
      },
      {
        speaker: "Provider",
        utterance:
          "Let's go ahead and shift your Vyvanse to slightly later in the morning as discussed. I'd like to schedule a follow-up in about one month to reassess how this timing adjustment works for you and ensure things continue improving.",
      },
    ],
  },
};

export const RecordingScript: React.FC = () => {
  const [selectedScenario, setSelectedScenario] =
    useState<string>("mdd-dictation");
  const [speakers, setSpeakers] = useState<string[]>([]);

  const handleScenarioChange = (event: SelectChangeEvent) => {
    setSelectedScenario(event.target.value);
  };

  const currentScript = SCENARIOS[selectedScenario as keyof typeof SCENARIOS];

  useEffect(() => {
    // Identify unique speakers in the script
    const uniqueSpeakers = Array.from(
      new Set(currentScript.script.map((line) => line.speaker))
    );
    setSpeakers(uniqueSpeakers);
  }, [selectedScenario, currentScript]);

  return (
    <CollapsibleBaseBlock defaultCollapsed={false}>
      <CollapsibleBaseBlock.Header
        title="Example Scripts"
        action={
          <FormControl fullWidth sx={{ mb: 1, mt: 1 }}>
            <InputLabel>Select Scenario</InputLabel>
            <Select
              value={selectedScenario}
              onChange={handleScenarioChange}
              label="Select Scenario"
              sx={{
                borderRadius: "0.5rem",
                width: "auto",
                backgroundColor: "background.paper",
                height: 32,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "borderColors.primary",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.main",
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    borderRadius: "0.5rem",
                    mt: 0.5,
                  },
                },
              }}
            >
              {Object.entries(SCENARIOS).map(([key, scenario]) => (
                <MenuItem key={key} value={key}>
                  {scenario.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
      />
      <CollapsibleBaseBlock.Body>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          {currentScript.script.map((line, index) => (
            <Paper key={index} elevation={1} sx={{ p: 1 }}>
              <Typography
                variant="subtitle2"
                color={
                  speakers.indexOf(line.speaker) === 0 ? "primary" : "secondary"
                }
                sx={{ mb: 0.5 }}
              >
                {line.speaker}
              </Typography>
              <Typography variant="body1">{line.utterance}</Typography>
            </Paper>
          ))}
        </Box>
      </CollapsibleBaseBlock.Body>
    </CollapsibleBaseBlock>
  );
};
