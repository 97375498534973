import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  Box,
  Autocomplete,
  FormGroup,
  FormControlLabel,
  Switch,
  IconButton,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import APIService from "../../services/APIService";
import {
  AdminPublicTemplate,
  AdminUserTemplate,
  UserTemplateItem,
} from "../../types/types";
import { useUser } from "../../context/user";

interface TemplatePickerProps {
  onTemplateSelect: (template: UserTemplateItem | undefined) => void;
  initialTemplate?: UserTemplateItem;
  initialTemplateId?: string | null;
}

const TemplatePicker: React.FC<TemplatePickerProps> = ({
  onTemplateSelect,
  initialTemplate,
  initialTemplateId,
}) => {
  const { templatesList, getAccessToken, refreshTemplatesList } = useUser();
  const [selectedTemplate, setSelectedTemplate] = useState<
    UserTemplateItem | undefined
  >(initialTemplate);

  const handleTemplateChange = (event: any, value: UserTemplateItem | null) => {
    if (value) {
      setSelectedTemplate(value);
      onTemplateSelect(value);
    } else {
      setSelectedTemplate(undefined);
      onTemplateSelect(undefined);
    }
  };

  useEffect(() => {
    console.log("initialTemplateId", initialTemplateId);
    if (initialTemplateId) {
      const template = templatesList?.find(
        (t) => t.template_id === initialTemplateId
      );
      console.log("template", template);
      if (template) {
        setSelectedTemplate(template);
      }
    }
  }, [initialTemplateId, templatesList]);

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <FormControl fullWidth>
        {templatesList && (
          <Autocomplete
            options={templatesList}
            value={selectedTemplate || null}
            getOptionLabel={(option) =>
              option.display_name ? option.display_name : ""
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Choose a template"
                variant="outlined"
              />
            )}
            onChange={handleTemplateChange}
          />
        )}
      </FormControl>
    </Box>
  );
};

export default TemplatePicker;
