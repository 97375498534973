import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import { Encounter } from "../types/types";
import { encounterApi } from "../components/Encounter/encounters";
import { useUser } from "./user";
import dayjs from "dayjs";

type ViewMode = "day" | "week" | "month" | "list" | "all" | "upcoming";

// Add a cache interface to track date ranges we've already loaded
interface DateRangeCache {
  start: string;
  end: string;
  lastFetched: number; // timestamp of when this range was fetched
}

interface EncountersDataContextType {
  allEncounterData: Encounter[];
  upcomingEncounters: Encounter[];
  isLoading: boolean;
  isBackgroundLoading: boolean; // Add background loading state
  totalCount: number;
  viewMode: ViewMode;
  currentDate: Date;
  setViewMode: (mode: ViewMode) => void;
  setCurrentDate: (date: Date) => void;
  refreshEncounters: (options?: {
    forceFullLoading?: boolean;
    quietRefresh?: boolean;
  }) => Promise<void>;
  filterMode: "all" | "today" | "week" | "past" | "upcoming";
  setFilterMode: (mode: "all" | "today" | "week" | "past" | "upcoming") => void;
  currentPage: number;
  itemsPerPage: string;
  setCurrentPage: (page: number) => void;
  setItemsPerPage: (perPage: string) => void;
  startDate: Date | null;
  endDate: Date | null;
  setStartDate: (date: Date | null) => void;
  setEndDate: (date: Date | null) => void;
  sortOrder: string | null;
  setSortOrder: (order: string | null) => void;
  hoveredEncounterId: string | null;
  setHoveredEncounterId: (id: string | null) => void;
  setTutorialMode: (isActive: boolean) => void;
}

const EncountersDataContext = createContext<
  EncountersDataContextType | undefined
>(undefined);

export const useEncountersData = () => {
  const context = useContext(EncountersDataContext);
  if (context === undefined) {
    throw new Error(
      "useEncountersData must be used within an EncountersDataProvider"
    );
  }
  return context;
};

interface EncountersDataProviderProps {
  children: ReactNode;
}

export const EncountersDataProvider: React.FC<EncountersDataProviderProps> = ({
  children,
}) => {
  const { getAccessToken } = useUser();

  // Single source of truth for all encounter data
  const [allEncounterData, setAllEncounterData] = useState<Encounter[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  // Loading states - separate initial loading from background updates
  const [isLoading, setIsLoading] = useState(true);
  const [isBackgroundLoading, setIsBackgroundLoading] = useState(false);

  // Cache to track which date ranges we've already loaded
  const [cachedRanges, setCachedRanges] = useState<DateRangeCache[]>([]);

  // Track if this is the first load
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  // View control states
  const [viewMode, setViewMode] = useState<ViewMode>(() => {
    return (localStorage.getItem("calendarViewMode") as ViewMode) || "week";
  });
  const [currentDate, setCurrentDate] = useState<Date>(() => {
    const savedDate = localStorage.getItem("calendarCurrentDate");
    return savedDate ? new Date(savedDate) : new Date();
  });

  // Filter states for the all encounters section
  const [filterMode, setFilterMode] = useState<
    "all" | "today" | "week" | "past" | "upcoming"
  >(() => {
    return (localStorage.getItem("encounterFilterStatus") as any) || "week";
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return localStorage.getItem("encounterItemsPerPage") || "20";
  });
  const [startDate, setStartDate] = useState<Date | null>(() => {
    const savedStartDate = localStorage.getItem("encounterStartDate");
    return savedStartDate ? new Date(savedStartDate) : null;
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    const savedEndDate = localStorage.getItem("encounterEndDate");
    return savedEndDate ? new Date(savedEndDate) : null;
  });
  const [sortOrder, setSortOrder] = useState<string | null>(() => {
    return localStorage.getItem("encounterSortOrder");
  });

  // Hover state for synchronized highlighting
  const [hoveredEncounterId, setHoveredEncounterId] = useState<string | null>(
    null
  );

  // Add tutorial mode state
  const [isTutorialMode, setIsTutorialMode] = useState(false);
  // Add state for storing mock encounters
  const [mockEncounters, setMockEncounters] = useState<Encounter[]>([]);

  // Update mock data when tutorial mode changes
  useEffect(() => {
    if (isTutorialMode) {
      console.log("Tutorial mode activated - generating mock data");
      setMockEncounters(createMockEncounters());
      // Also set loading states to false
      setIsLoading(false);
      setIsBackgroundLoading(false);
    }
  }, [isTutorialMode]);

  // Calculate current date range based on view mode
  const getCurrentDateRange = useMemo(() => {
    let start: Date = new Date(currentDate);
    let end: Date = new Date(currentDate);

    if (viewMode === "day") {
      start.setHours(0, 0, 0, 0);
      end.setHours(23, 59, 59, 999);
    } else if (viewMode === "week") {
      // Start from Sunday of the current week
      const day = currentDate.getDay();
      start.setDate(currentDate.getDate() - day);
      start.setHours(0, 0, 0, 0);

      // End on Saturday of the current week
      end.setDate(start.getDate() + 6);
      end.setHours(23, 59, 59, 999);
    } else if (viewMode === "month") {
      // Start from the 1st day of the month
      start.setDate(1);
      start.setHours(0, 0, 0, 0);

      // End on the last day of the month
      end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      end.setHours(23, 59, 59, 999);
    }

    return { start, end };
  }, [viewMode, currentDate]);

  // Check if a date range is already in our cache
  const isDateRangeInCache = (start: Date, end: Date): boolean => {
    // If we're not in a calendar view, don't use cache
    if (viewMode === "list" || viewMode === "all" || viewMode === "upcoming") {
      return false;
    }

    // Look for an overlapping cached range that's recent (less than 30 seconds old)
    const now = Date.now();
    const CACHE_TTL = 30 * 1000; // 30 seconds cache validity

    return cachedRanges.some((range) => {
      const rangeStart = new Date(range.start);
      const rangeEnd = new Date(range.end);
      const isRecent = now - range.lastFetched < CACHE_TTL;

      // Check if the current range is fully contained within a cached range
      const isContained = start >= rangeStart && end <= rangeEnd && isRecent;

      return isContained;
    });
  };

  // Single function to fetch encounters with appropriate parameters
  const fetchEncounters = async (forceFullLoading = false) => {
    if (!getAccessToken) return;

    const { start, end } = getCurrentDateRange;
    const hasExistingData = !isInitialLoad && allEncounterData.length > 0;
    const isInCache = isDateRangeInCache(start, end);

    // Decide how to show loading states based on existing data
    if (isInitialLoad || forceFullLoading || allEncounterData.length === 0) {
      // If no data at all, show full loading spinner
      setIsLoading(true);
      setIsBackgroundLoading(false);
    } else {
      // If we have some data already, only show background loading indicator
      setIsLoading(false);
      setIsBackgroundLoading(true);
    }

    // Log whether we're using cached data while fetching
    if (hasExistingData && isInCache) {
      console.log(
        "Using cached data while fetching fresh data for range:",
        start,
        end
      );
    }

    try {
      const requestParams: any = {
        page: currentPage,
        per_page: Number(itemsPerPage),
      };

      // Add date range based on view mode or explicit filters
      if (startDate && endDate) {
        // If explicit date range is set, use that
        requestParams.start_date = dayjs(startDate)
          .startOf("day")
          .toISOString();
        requestParams.end_date = dayjs(endDate).endOf("day").toISOString();
      } else if (
        viewMode !== "list" &&
        viewMode !== "all" &&
        viewMode !== "upcoming"
      ) {
        // For calendar views, set date range based on the view
        requestParams.start_date = start.toISOString();
        requestParams.end_date = end.toISOString();

        // For calendar views we need more items
        requestParams.per_page = 100;

        // Update our cache with this date range
        setCachedRanges((prev) => {
          // Remove any overlapping or old ranges
          const filteredRanges = prev.filter((range) => {
            const rangeStart = new Date(range.start);
            const rangeEnd = new Date(range.end);

            // Keep ranges that don't overlap with this one
            return !(rangeStart <= end && rangeEnd >= start);
          });

          // Add the new range
          return [
            ...filteredRanges,
            {
              start: start.toISOString(),
              end: end.toISOString(),
              lastFetched: Date.now(),
            },
          ];
        });
      } else if (!startDate && !endDate) {
        // If not calendar view and no explicit dates, use filter mode
        requestParams.filter_mode = filterMode;
      }

      // Add explicit sort order if set
      if (sortOrder) {
        requestParams.sort_by = sortOrder === "newest" ? -1 : 1;
      }

      const accessToken = await getAccessToken();
      const { encounters: fetchedEncounters, total } =
        await encounterApi.listEncounters(requestParams, accessToken);

      // If we're not doing a full reset, merge with existing encounters
      if (!isInitialLoad && !forceFullLoading && viewMode !== "list") {
        // For calendar views, we want to keep encounters that aren't in this date range
        const existingIds = new Set(
          fetchedEncounters.map((e) => e.encounter_id)
        );
        const existingNotInDateRange = allEncounterData.filter((e) => {
          // Keep if not in fetched data
          return (
            !existingIds.has(e.encounter_id) &&
            // And either has no scheduled date or outside current range
            (!e.scheduled_for ||
              new Date(e.scheduled_for) < start ||
              new Date(e.scheduled_for) > end)
          );
        });

        // Merge existing encounters with new ones
        setAllEncounterData([...existingNotInDateRange, ...fetchedEncounters]);
      } else {
        // For initial load or list view, replace all data
        setAllEncounterData(fetchedEncounters);
      }

      setTotalCount(total);
      setIsInitialLoad(false);
    } catch (error) {
      console.error("Error fetching encounters:", error);
    } finally {
      setIsLoading(false);
      setIsBackgroundLoading(false);
    }
  };

  // Refresh encounter data with a force reload
  const refreshEncounters = async (options?: {
    forceFullLoading?: boolean;
    quietRefresh?: boolean;
  }) => {
    // Default to force loading unless specified otherwise
    const forceFullLoading = options?.forceFullLoading ?? true;
    const quietRefresh = options?.quietRefresh ?? false;

    // If it's a quiet refresh, always use background loading
    if (quietRefresh) {
      // Never show full loading spinner for quiet refreshes
      setIsLoading(false);
      setIsBackgroundLoading(true);
    }

    await fetchEncounters(forceFullLoading);
  };

  // Effect for initial data loading
  useEffect(() => {
    if (!isTutorialMode) {
      refreshEncounters({ forceFullLoading: true, quietRefresh: false });
    }
  }, []);

  // Derived data using memoization
  const getFilteredEncounters = useMemo(() => {
    if (isTutorialMode) {
      return mockEncounters;
    }

    // Filter the encounters based on current view if needed
    if (viewMode === "day" || viewMode === "week" || viewMode === "month") {
      const { start, end } = getCurrentDateRange;

      // Return encounters that fall within the current date range
      return allEncounterData.filter((encounter) => {
        if (!encounter.scheduled_for) return false;

        const encounterDate = new Date(encounter.scheduled_for);
        return encounterDate >= start && encounterDate <= end;
      });
    }

    return allEncounterData;
  }, [
    isTutorialMode,
    mockEncounters,
    allEncounterData,
    viewMode,
    getCurrentDateRange,
  ]);

  // Effect to fetch encounters when view or filter parameters change
  useEffect(() => {
    if (isTutorialMode) return; // Don't fetch real data in tutorial mode

    // Save view mode and current date to localStorage
    localStorage.setItem("calendarViewMode", viewMode);
    localStorage.setItem("calendarCurrentDate", currentDate.toISOString());

    // Use background loading for subsequent fetches if we already have data
    const hasExistingData = !isInitialLoad && allEncounterData.length > 0;

    // If we have data already, do a quiet refresh in the background
    // For initial loads or empty datasets, use normal loading indicators
    refreshEncounters({
      forceFullLoading: !hasExistingData,
      quietRefresh: hasExistingData,
    });
  }, [
    viewMode,
    currentDate,
    filterMode,
    currentPage,
    itemsPerPage,
    startDate,
    endDate,
    sortOrder,
  ]);

  // Update localStorage when filter preferences change
  useEffect(() => {
    localStorage.setItem("encounterFilterStatus", filterMode);
  }, [filterMode]);

  useEffect(() => {
    localStorage.setItem("encounterItemsPerPage", itemsPerPage);
  }, [itemsPerPage]);

  useEffect(() => {
    if (sortOrder) {
      localStorage.setItem("encounterSortOrder", sortOrder);
    } else {
      localStorage.removeItem("encounterSortOrder");
    }
  }, [sortOrder]);

  // Save date range to localStorage
  useEffect(() => {
    if (startDate) {
      localStorage.setItem("encounterStartDate", startDate.toISOString());
    } else {
      localStorage.removeItem("encounterStartDate");
    }
  }, [startDate]);

  useEffect(() => {
    if (endDate) {
      localStorage.setItem("encounterEndDate", endDate.toISOString());
    } else {
      localStorage.removeItem("encounterEndDate");
    }
  }, [endDate]);

  // Create mock data for the tutorial
  const createMockEncounters = (): Encounter[] => {
    const now = new Date();

    // Create encounter for current time
    const currentTime = new Date(now);

    // Create encounter for 2 hours from now
    const twoHoursFromNow = new Date(now);
    twoHoursFromNow.setHours(now.getHours() + 2);

    // Create encounter for tomorrow around same time
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);

    // Create encounter for 2 days later, 3.5 hours from current time
    const twoDaysLater = new Date(now);
    twoDaysLater.setDate(twoDaysLater.getDate() + 2);
    twoDaysLater.setHours(now.getHours() + 3);
    twoDaysLater.setMinutes(now.getMinutes() + 30);

    return [
      {
        encounter_id: "tutorial-encounter-1",
        status: "pending",
        patient: {
          patient_id: "tutorial-patient-1",
          first_name: "John",
          last_name: "Doe",
          dob: "1980-01-01",
        },
        encounter_type: "Initial Assessment",
        patient_id: "tutorial-patient-1",
        scheduled_for: currentTime.toISOString(),
        scheduled_duration: 60,
        inputs: [{ encounter_document_id: "rec1", type: "recording" }],
        outputs: [],
        note_title: "",
        template_id: "template-1",
        _localState: {
          localDocumentState: {},
        },
      },
      {
        encounter_id: "tutorial-encounter-2",
        status: "pending",
        patient: {
          patient_id: "tutorial-patient-2",
          first_name: "Jane",
          last_name: "Smith",
          dob: "1985-05-15",
        },
        encounter_type: "Follow-up",
        patient_id: "tutorial-patient-2",
        scheduled_for: twoHoursFromNow.toISOString(),
        scheduled_duration: 30,
        inputs: [],
        outputs: [],
        note_title: "",
        template_id: "template-2",
        _localState: {
          localDocumentState: {},
        },
      },
      {
        encounter_id: "tutorial-encounter-3",
        status: "pending",
        patient: {
          patient_id: "tutorial-patient-3",
          first_name: "Robert",
          last_name: "Johnson",
          dob: "1975-10-20",
        },
        encounter_type: "Medication Review",
        patient_id: "tutorial-patient-3",
        scheduled_for: tomorrow.toISOString(),
        scheduled_duration: 45,
        inputs: [
          { encounter_document_id: "rec2", type: "recording" },
          { encounter_document_id: "rec3", type: "recording" },
        ],
        outputs: [],
        note_title: "",
        template_id: "template-3",
        _localState: {
          localDocumentState: {},
        },
      },
      {
        encounter_id: "tutorial-encounter-4",
        status: "pending",
        patient: {
          patient_id: "tutorial-patient-4",
          first_name: "Emily",
          last_name: "Wilson",
          dob: "1990-08-12",
        },
        encounter_type: "Annual Physical",
        patient_id: "tutorial-patient-4",
        scheduled_for: twoDaysLater.toISOString(),
        scheduled_duration: 60,
        inputs: [],
        outputs: [],
        note_title: "",
        template_id: "template-4",
        _localState: {
          localDocumentState: {},
        },
      },
    ];
  };

  // Helper functions to get filtered data for different views
  const upcomingEncounters = useMemo(() => {
    const now = new Date();
    return getFilteredEncounters
      .filter(
        (encounter) =>
          encounter.scheduled_for && new Date(encounter.scheduled_for) > now
      )
      .sort((a, b) => {
        return (
          new Date(a.scheduled_for!).getTime() -
          new Date(b.scheduled_for!).getTime()
        );
      })
      .slice(0, 5); // Only return top 5 upcoming
  }, [getFilteredEncounters]);

  // Function to handle setting tutorial mode
  const setTutorialMode = (isActive: boolean) => {
    console.log("Setting tutorial mode to:", isActive);
    if (isActive) {
      // Immediately generate mock data when activating tutorial mode
      const mockData = createMockEncounters();
      setMockEncounters(mockData);
      // Set loading to false
      setIsLoading(false);
      setIsBackgroundLoading(false);
    }
    setIsTutorialMode(isActive);
  };

  const contextValue: EncountersDataContextType = {
    // Use a single source of truth
    allEncounterData: getFilteredEncounters,
    upcomingEncounters,
    isLoading: !isTutorialMode && isLoading,
    isBackgroundLoading: !isTutorialMode && isBackgroundLoading,
    totalCount: isTutorialMode ? mockEncounters.length : totalCount,
    viewMode,
    currentDate,
    setViewMode,
    setCurrentDate,
    refreshEncounters,
    filterMode,
    setFilterMode,
    currentPage,
    itemsPerPage,
    setCurrentPage,
    setItemsPerPage,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    sortOrder,
    setSortOrder,
    hoveredEncounterId,
    setHoveredEncounterId,
    setTutorialMode,
  };

  return (
    <EncountersDataContext.Provider value={contextValue}>
      {children}
    </EncountersDataContext.Provider>
  );
};
