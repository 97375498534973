import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { PropsWithChildren, ReactNode } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { RootView } from "./Views/RootView";
import { UserProvider } from "./context/user";
import { MobileLockout } from "./components/MobileLockout";
import { RecordingContextProvider } from "./context/RecordingContext";
import { AudioDataProvider } from "./context/AudioDataContext";
import { EncounterProvider } from "./components/Encounter/EncounterContext";

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element | null => {
  const navigate = useNavigate();

  window.location.hostname;

  // production
  const domain = "smartscribehealth.us.auth0.com";
  const clientId = "RG4FwiozZWwAAe83xQxv2metpgJaPe0j";
  const redirectUri = window.location.origin + "/callback";
  const audience = "https://api.smartscribe.health";

  // development
  // const domain = "smartscribe-development.us.auth0.com";
  // const clientId = "ohWExKeDUG8L1LY5oRJXPseSfaqN1mid";
  // const redirectUri = window.location.origin + "/callback";
  // const audience = "https://api.smartscribe.health/development";

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        scope: "openid profile email offline_access",
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <UserProvider>
        <AudioDataProvider>
          <RecordingContextProvider>
            <EncounterProvider>
              <RootView>
                {children}
                <Outlet />
              </RootView>
            </EncounterProvider>
          </RecordingContextProvider>
        </AudioDataProvider>
      </UserProvider>
    </Auth0Provider>
  );
};

export const DemoAuth0ProviderWithNavigate = ({
  children,
}: {
  children?: ReactNode;
}): JSX.Element | null => {
  const navigate = useNavigate();

  const domain = "smartscribehealth.us.auth0.com";
  const clientId = "RG4FwiozZWwAAe83xQxv2metpgJaPe0j";
  const redirectUri = window.location.origin + "/callback";
  const audience = "https://api.smartscribe.health";

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  // const isMobileDevice = () => {
  //   const userAgent = navigator.userAgent || navigator.vendor;
  //   return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(
  //     userAgent.toLowerCase()
  //   );
  // };

  // if (isMobileDevice()) {
  //   return <MobileLockout />;
  // }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <UserProvider>{children}</UserProvider>
    </Auth0Provider>
  );
};
