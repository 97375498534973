import React, { useEffect, useState } from "react";
import DashboardNote from "../components/Dashboard/DashboardNote";
import { useNavigate } from "react-router-dom";
import {
  NoteCounts,
  UserNoteTypeII,
  GamificationType,
  UserMetricsType,
  ReferralInfoType,
  OnboardingStatus,
} from "../types/types";
import {
  Box,
  Typography,
  Link,
  Skeleton,
  Stack,
  IconButton,
  Button,
} from "@mui/material";
import LayoutWrapper from "../components/layout/UILayout";
import { styled, useTheme } from "@mui/material/styles";
import DashboardNums from "../components/Dashboard/DashboardNums";
import { useUIState } from "../context/uiState";
import LinearProgress from "@mui/material/LinearProgress";
import RefreshIcon from "@mui/icons-material/Refresh";
import { BoxColumn } from "../components/layout/BoxColumn";
import { useUser } from "../context/user/context";
import { EmptyNoteList } from "../components/EmptyNoteList";
import {
  ForReviewNotesLoader,
  NoteCountsLoader,
  NotesLoader,
} from "../loaders/DashboardLoader";
import {
  MicNone as MicNoneIcon,
  EditNoteOutlined as EditNoteOutlinedIcon,
  ShapeLineOutlined as ShapeLineOutlinedIcon,
  BorderColor,
  Web,
  Close as CloseIcon,
  ContentCopy as ContentCopyIcon,
} from "@mui/icons-material";
import { useThemeContext } from "../context/theme/context";
import { TermsOfServiceModal } from "../components/Modals/ToSModal";
import { NewFeatureModal } from "../components/Modals/NewFeatureModal";
import { DashboardCard } from "./Shared/DashboardCard";
import { UserNote } from "../components/UserNote";
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "50%",
  borderRadius: 20,
}));
import ActionableButtonCard from "./Shared/ActionableButtonCard";
import { RoundedButton } from "../styles/CustomButtons";
import OnboardingTasksCard from "../components/OnboardingTasksCard";
import Paper from "@mui/material/Paper";
import {
  getDashboardCache,
  setDashboardCache,
  isDashboardCacheValid,
} from "../utils/notesCache";
import { FeatureFeedback } from "../components/FeatureFeedback/FeatureFeedback";
import APIService from "../services/APIService";
import UpcomingEncountersDashboardCard from "../components/Dashboard/UpcomingEncountersDashboardCard";

const RefreshComponent = () => {
  return (
    <Box
      onClick={() => {
        window.location.reload();
      }}
      sx={{
        "&:hover": {
          cursor: "pointer",
          borderRadius: "0.25rem",
          backgroundColor: "backgroundColors.secondary",
        },
      }}
    >
      <RefreshIcon style={{ fontSize: 30 }} />
    </Box>
  );
};

const GetStartedActionButtons = () => {
  const navigate = useNavigate();
  const buttonStyles = {
    color: "textColors.brand",
    bgcolor: "backgroundColors.secondary",
    px: 2,
    py: 1,
    border: 0.1,
    borderColor: "gray",
    fontWeight: 600,
    "&:hover": {
      bgcolor: "backgroundColors.secondary",
    },
  };

  return (
    <Box sx={{ pt: 2 }}>
      <RoundedButton sx={buttonStyles} onClick={() => navigate(`/record`)}>
        Record my own note
      </RoundedButton>
      <RoundedButton
        sx={buttonStyles}
        onClick={() => navigate(`/demo/psychiatric-intake-demo`)}
      >
        Explore demo note
      </RoundedButton>
    </Box>
  );
};

const OnboardingAndNotesToReview = React.memo(
  ({
    noteCounts,
    notesForReview,
    noNotesGenerated,
    brandLanguage,
    navigate,
    onboardingStatus,
  }: {
    noteCounts?: NoteCounts;
    notesForReview?: UserNoteTypeII[] | null;
    noNotesGenerated: boolean;
    brandLanguage: any;
    navigate: any;
    onboardingStatus?: OnboardingStatus;
  }) => {
    const { userState } = useUser();
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          gap: 2,
          mb: 4,
        }}
      >
        <Box
          sx={{
            flex: { xs: "1 1 auto", md: 1 },
            minHeight: { xs: "400px", md: "450px" },
            maxHeight: "450px",
            width: "100%",
          }}
        >
          {userState?.featureFlags?.encounters ? (
            onboardingStatus?.show_onboarding_tasks ? (
              <OnboardingTasksCard onboardingStatus={onboardingStatus} />
            ) : (
              <UpcomingEncountersDashboardCard />
            )
          ) : (
            <OnboardingTasksCard onboardingStatus={onboardingStatus} />
          )}
        </Box>
        <Box
          sx={{
            flex: { xs: "1 1 auto", md: 2 },
            minHeight: { xs: "400px", md: "450px" },
            maxHeight: "450px",
            width: "100%",
          }}
        >
          <Box
            id="review-box"
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",

              border: 1,
              borderColor: "borderColors.primary",
              bgcolor: "backgroundColors.primary",
              borderRadius: "0.75rem",
            }}
          >
            <Box
              sx={{
                bgcolor: "backgroundColors.secondary",
                borderBottom: 1,
                borderColor: "borderColors.primary",
                borderTopLeftRadius: "0.75rem",
                borderTopRightRadius: "0.75rem",
                display: "flex",
                padding: "1rem 1.5rem",
                justifyContent: "space-between",
              }}
            >
              <Typography
                fontSize="1.0625rem"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="1.59375rem"
                color="textColors.lightHeader"
                sx={{ justifyContent: "flex-start" }}
              >
                Notes to Review
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "100%",
                    backgroundColor: "newDesignColors.eggplant200",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "2rem",
                    height: "2rem",
                  }}
                >
                  <Typography
                    fontSize="1.0625rem"
                    fontStyle="normal"
                    fontWeight="600"
                    lineHeight="1.59375rem"
                    color="newDesignColors.eggplant700"
                  >
                    {noteCounts ? (
                      noteCounts.for_review_notes_count
                    ) : (
                      <Skeleton
                        variant="circular"
                        width={"80%"}
                        height={"80%"}
                      />
                    )}
                  </Typography>
                </Box>
                &nbsp;
                <RefreshComponent />
              </Box>
            </Box>
            <Box
              id="note-content"
              sx={{
                display: "flex",
                flexDirection: "column",
                bgcolor: "backgroundColors.primary",
                borderRadius: "0.75rem",
                overflowY: "auto", // Change to auto
                flex: 1, // Add this line
              }}
            >
              {(!notesForReview || noNotesGenerated) && (
                <Box height="100%">
                  {notesForReview === undefined && (
                    <>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                      <Box py={2}>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                      </Box>
                    </>
                  )}
                  {(noNotesGenerated || notesForReview === null) && (
                    <Box sx={{ height: "100%" }}>
                      <UserNote
                        index={0}
                        key={0}
                        status={"completed"}
                        review_status={"ready_for_review"}
                        note_id={"xyz"}
                        note_title={"Demo Intake Note"}
                        time={Date.now() / 1000}
                        summary={`Want to see what a ${brandLanguage.brandName} note looks like? Click here to view an example note of a Psychiatric Intake note.`}
                        link={`/demo/psychiatric-intake-demo`}
                      />
                      <hr />
                      <Box>
                        <Box
                          sx={{
                            p: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "center",
                          }}
                        >
                          <Typography sx={{ mb: 2, color: "gray" }}>
                            You haven't recorded any notes yet. To get started,
                            we suggest you either explore our pre-made demo note
                            or dive in and record your own.
                          </Typography>
                          <GetStartedActionButtons />
                        </Box>
                      </Box>
                      {/* <EmptyNoteList /> */}
                    </Box>
                  )}
                </Box>
              )}
              {notesForReview &&
                notesForReview.map((note: UserNoteTypeII, index: number) => {
                  return (
                    <UserNote
                      key={note.note_id}
                      index={index}
                      note_id={note.note_id}
                      note_title={note.note_title}
                      patient_id={note.patient_id}
                      patient_name={note.patient_name}
                      status={note.status}
                      review_status={note.review_status}
                      time={note.time_uploaded}
                      summary={note.summary}
                      link={
                        note.status === "completed"
                          ? `/note/${note.note_id}`
                          : `/status/${note.note_id}`
                      }
                      origin="dashboard"
                      error_message={note.error_message}
                      completion_status={note.completion_status}
                      completion_timestamp={note.completion_timestamp}
                    />
                  );
                })}
              {notesForReview && (
                <Box
                  sx={{
                    display: "flex",
                    flex: "1 1 0%",
                    bgcolor: "backgroundColors.primary",
                    border: 0,
                    borderTop: 1,
                    borderBottomLeftRadius: "0.75rem",
                    borderBottomRightRadius: "0.75rem",
                    borderColor: "borderColors.primary",
                    height: "min-content",
                    maxHeight: "100%",
                    padding: "1.5rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "0.5rem",
                    "&:hover": {
                      cursor: "pointer",
                      bgcolor: "backgroundColors.secondary",
                    },
                  }}
                  onClick={() => {
                    navigate("/notes");
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Typography
                      fontSize="0.9375rem"
                      fontStyle="normal"
                      fontWeight="700"
                      lineHeight="1.40625rem"
                      color="textColors.lightHeader"
                    >
                      View all Notes
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
);

const Dashboard = () => {
  const theme = useTheme();
  const {
    showAlertBanner,
    state: uiState,
    setState: setUIState,
  } = useUIState();
  const { platform } = uiState;

  const [notesForReview, setNotesForReview] = useState<
    UserNoteTypeII[] | undefined | null
  >();
  const [noNotesGenerated, setNoNotesGenerated] = useState<boolean>(false);
  const [noteCounts, setNoteCounts] = useState<NoteCounts>();
  const [loading, setLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const [notesLoading, setNotesLoading] = useState(true);

  const [gamificationInfo, setGamificationInfo] = useState<
    GamificationType | undefined
  >();
  const [userMetrics, setUserMetrics] = useState<UserMetricsType | undefined>();
  const [referralInfo, setReferralInfo] = useState<
    ReferralInfoType | undefined
  >();

  const {
    updateOnboardingStep,
    userState,
    newFeature,
    setNewFeature,
    getAccessToken,
  } = useUser();
  const { brandLanguage } = useThemeContext();

  const [showToSModal, setShowToSModal] = useState<boolean>(false);
  const [showAddToHomeScreen, setShowAddToHomeScreen] =
    useState<boolean>(false);

  const [showNewFeatureModal, setShowNewFeatureModal] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState<boolean | null>(true);
  const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>();

  const getCardId = (): string => {
    return btoa(JSON.stringify({ referralInfo: referralInfo }));
  };

  const getVisibilityFromStorage = (cardId: string): boolean => {
    const visibilityFlags = JSON.parse(
      localStorage.getItem("cardVisibilityFlags") || "{}"
    );
    return visibilityFlags[cardId] !== false;
  };

  const setVisibilityInStorage = (cardId: string, isVisible: boolean): void => {
    const visibilityFlags = JSON.parse(
      localStorage.getItem("cardVisibilityFlags") || "{}"
    );
    visibilityFlags[cardId] = isVisible;
    localStorage.setItem(
      "cardVisibilityFlags",
      JSON.stringify(visibilityFlags)
    );
  };
  const handleDismiss = () => {
    setIsVisible(false);
    setVisibilityInStorage(getCardId(), false);
  };
  useEffect(() => {
    if (referralInfo) {
      const storedVisibility = getVisibilityFromStorage(getCardId());
      setIsVisible(storedVisibility);
    }
  }, [referralInfo]);

  const fetchNotesAndCounts = async (showLoading = true) => {
    try {
      // Check cache first
      const cache = getDashboardCache();

      if (cache && isDashboardCacheValid(cache)) {
        // Use cached data without changing loading state
        setNotesForReview(cache.notes);
        setNoteCounts({
          for_review_notes_count: cache.totalCount,
        });
        setNotesLoading(false);

        // Fetch fresh data in background without showing loading states
        fetchFreshData(false);
      } else {
        // No valid cache, show loading state and fetch fresh
        if (showLoading) {
          setLoading(true);
          setNotesLoading(true);
        }
        await fetchFreshData(true);
      }
    } catch (error) {
      console.log("Error fetching notes and counts:", error);
      setLoadError(true);
    } finally {
      if (showLoading) {
        setLoading(false);
      }
    }
  };

  const fetchFreshData = async (updateLoadingState = true) => {
    try {
      const { notes, totalCount } = await NotesLoader("reviewing", 20, 0);

      // Update data smoothly without clearing existing data first
      setNotesForReview((prevNotes) => {
        // Only update if new data is different
        if (JSON.stringify(prevNotes) !== JSON.stringify(notes)) {
          return notes;
        }
        return prevNotes;
      });

      setNoteCounts((prevCounts) => {
        if (prevCounts?.for_review_notes_count !== totalCount) {
          return { for_review_notes_count: totalCount };
        }
        return prevCounts;
      });

      if (totalCount === 0) {
        setNoNotesGenerated(true);
      }

      // Update cache
      setDashboardCache({
        notes,
        totalCount,
        filters: {
          category: "reviewing",
          limit: 20,
          offset: 0,
        },
        timestamp: Date.now(),
      });
    } finally {
      if (updateLoadingState) {
        setNotesLoading(false);
        setLoading(false);
      }
    }
  };

  const handleCopyReferralLink = (referralCode: string) => {
    const signupUrl =
      import.meta.env.VITE_MAIN_URL + `/signup?ref=${referralCode}`;
    navigator.clipboard.writeText(signupUrl).then(() => {
      setUIState({ ...uiState, navbar: { showCopyNotification: true } });
    });
  };

  const findCorrespondingUserMetric = (metric: string) => {
    if (gamificationInfo && userMetrics) {
      const user_metric = userMetrics[metric];
      if (user_metric) {
        return user_metric;
      }
    }
    return 0;
  };

  const constructGamificationText = (info: GamificationType) => {
    if (!info.game_completed) {
      return `Level ${info.current_level}: ${info.activity_verb} ${info.target} ${info.activity_unit}.`;
    }
  };

  const constructMetricText = (info: GamificationType) => {
    return `${
      findCorrespondingUserMetric(info.current_metric) -
      info.starting_metric_value
    } of ${info.target} ${info.activity_unit}`;
  };

  const getGamificationProgress = (info: GamificationType) => {
    if (info && userMetrics) {
      const progress =
        ((findCorrespondingUserMetric(info.current_metric) -
          info.starting_metric_value) /
          info.target) *
        100;

      if (progress >= 100) {
        return 100;
      } else if (progress <= 0) {
        return 0;
      } else {
        return progress;
      }
    }
    return 0;
  };

  useEffect(() => {
    if (noteCounts && notesForReview) {
      setNotesLoading(false);
    }
  }, [noteCounts, notesForReview]);

  useEffect(() => {
    if (userState) {
      try {
        fetchNotesAndCounts();
        if (userState.gamification) {
          setGamificationInfo(userState.gamification);
        }
        if (userState.userMetrics) {
          setUserMetrics(userState.userMetrics);
        }
        if (userState.referralInfo) {
          setReferralInfo(userState.referralInfo);
        }
        if (userState.onboardingSteps) {
          if (userState.onboardingSteps.accepted_tos_0524 !== true) {
            setShowToSModal(true);
          }
        }
      } catch (e) {
        console.log("error in retrieval", e);
      }
    }
  }, []);

  useEffect(() => {
    if (newFeature) {
      setShowNewFeatureModal(true);
    }

    if (userState) {
      if (
        platform !== "desktop" &&
        !newFeature &&
        userState.onboardingSteps?.accepted_tos_0524 === true
      ) {
        setShowAddToHomeScreen(true);
      }
    }
  }, [userState]);

  useEffect(() => {
    const fetchOnboardingStatus = async () => {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: "/user/getOnboardingStatus",
          accessToken,
        });

        if (response.ok) {
          const data = await response.value.onboarding_status;
          setOnboardingStatus(data);
        }
      } catch (error) {
        console.error("Error fetching onboarding status:", error);
      }
    };

    fetchOnboardingStatus();
  }, []);

  const UtilHomescreenComponents: React.FC = () => {
    const iconWrapperStyle = {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 48,
      height: 48,
    };

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          flexWrap: "wrap",
          gap: 1,
          width: "100%",
          height: "auto",
        }}
      >
        <Box
          sx={{
            flex: { xs: "1 1 100%", sm: "1 1 30%" },
            minWidth: { xs: "100%", sm: "250px" },
            height: "150px",
          }}
        >
          <ActionableButtonCard
            title="Record new note"
            Icon={
              <Box sx={iconWrapperStyle}>
                <MicNoneIcon sx={{ fontSize: 35 }} />
              </Box>
            }
            navigation={
              userState?.featureFlags?.encounters
                ? "/encounters/active"
                : "/record"
            }
          />
        </Box>
        <Box
          sx={{
            flex: { xs: "1 1 100%", sm: "1 1 30%" },
            minWidth: "250px",
            height: "150px",
          }}
        >
          <ActionableButtonCard
            title="Review existing notes"
            Icon={
              <Box sx={iconWrapperStyle}>
                <EditNoteOutlinedIcon sx={{ fontSize: 35 }} />
              </Box>
            }
            navigation="/notes"
          />
        </Box>
        <Box
          sx={{
            flex: { xs: "1 1 100%", sm: "1 1 30%" },
            minWidth: "250px",
            height: "150px",
          }}
        >
          <ActionableButtonCard
            title="Build note template"
            Icon={
              <Box sx={iconWrapperStyle}>
                <Web sx={{ fontSize: 30 }} />
              </Box>
            }
            navigation="/templates"
          />
        </Box>
      </Box>
    );
  };

  const ReferralCard = () => {
    return (
      <>
        {isVisible && (
          <DashboardCard
            customStyles={{
              bgcolor: "#ffeac6",
              padding: 2,
              width: "100%",
              margin: "0 auto",
              position: "relative",
            }}
          >
            {/* Close button positioned absolutely */}
            <IconButton
              size="small"
              onClick={() => handleDismiss()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "newDesignColors.eggplant800",
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>

            {/* Main content flex container */}
            <Box sx={{ display: "flex", gap: 4, mt: 2, mx: 2 }}>
              {/* Left side - Title and description */}
              <Box sx={{ flex: "0 0 60%", justifyContent: "flex-start" }}>
                <Typography
                  variant="h5"
                  fontWeight="700"
                  sx={{ color: "newDesignColors.eggplant800", mb: 2 }}
                >
                  Referral Program
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Send your referral code to colleagues and we'll give them $15
                  off their first month, and give you $40 off your next month!{" "}
                  <br />
                  <Link
                    sx={{
                      fontWeight: "bold",
                      textDecoration: "underline",
                      color: "newDesignColors.eggplant700",
                    }}
                    href={`https://www.jotpsych.com/referral-program`}
                    target="_blank"
                    underline="hover"
                  >
                    {"Click here to learn more."}
                  </Link>
                </Typography>
              </Box>
              <Box sx={{ flex: "0 0 30%", justifyContent: "flex-end" }}>
                <Paper
                  elevation={2}
                  sx={{
                    p: 3,
                    bgcolor: "white",
                    borderRadius: 2,
                    mb: 2,
                  }}
                >
                  <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                    Your Referral Code
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      fontWeight="700"
                      color="textColors.brand"
                      sx={{ cursor: "pointer" }}
                      onClick={() =>
                        referralInfo &&
                        handleCopyReferralLink(referralInfo.referral_code)
                      }
                    >
                      {referralInfo?.referral_code}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() =>
                        referralInfo &&
                        handleCopyReferralLink(referralInfo.referral_code)
                      }
                      startIcon={<ContentCopyIcon fontSize="small" />}
                    >
                      Copy link
                    </Button>
                  </Box>
                </Paper>

                {/* <Paper 
                  elevation={2}
                  sx={{
                    p: 3,
                    bgcolor: "white",
                    borderRadius: 2
                  }}
                >
                  <Typography variant="h6" align="center" sx={{ mb: 1 }}>
                    Your Referral Earnings
                  </Typography>
                  <Typography
                    variant="h5"
                    fontWeight="700"
                    align="center"
                    color="textColors.lightHeader"
                  >
                    {referralInfo?.referral_earnings}
                  </Typography>
                </Paper> */}
              </Box>
            </Box>
          </DashboardCard>
        )}
      </>
    );
  };

  const Challenge = () => {
    const ChallengeCard = () => {
      return (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: "newDesignColors.eggplant800",
            }}
          >
            <Typography variant="h6" fontStyle="normal">
              Your {brandLanguage.brandName} Challenge
            </Typography>

            {!userState?.gamification?.game_completed ? (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    py: 1,
                  }}
                >
                  <Typography variant="h6" fontStyle="normal" fontWeight="600">
                    {gamificationInfo &&
                      constructGamificationText(gamificationInfo)}
                  </Typography>
                </Box>
                <Typography
                  variant="subtitle1"
                  fontStyle="normal"
                  fontWeight="500"
                >
                  {gamificationInfo?.reward.text}.{" "}
                  <Link
                    href={`https://${brandLanguage.brandSite}/rewardsandreferrals`}
                    target="_blank"
                    underline="hover"
                    sx={{ fontWeight: "bold", textDecoration: "underline" }}
                  >
                    {"Click here"}
                  </Link>{" "}
                  to learn more about {brandLanguage.brandName} challenges
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    height: "30%",
                    pt: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "15px",
                      minHeight: "5px",
                    }}
                  >
                    <BorderLinearProgress
                      variant="determinate"
                      value={
                        gamificationInfo &&
                        getGamificationProgress(gamificationInfo)
                      }
                    />
                  </Box>
                  <Typography
                    variant="subtitle2"
                    fontStyle="normal"
                    fontWeight="500"
                  >
                    {gamificationInfo && constructMetricText(gamificationInfo)}
                  </Typography>
                </Box>
              </>
            ) : (
              <Box>
                <Typography variant="h6" fontWeight={700} sx={{ py: 1 }}>
                  Nice work! You've completed your challenge.{" "}
                  {String.fromCodePoint(0x1f389)}
                </Typography>
                <Typography variant="body1">
                  Keep an eye out for your reward and for future challenges!
                </Typography>
              </Box>
            )}
          </Box>
        </>
      );
    };
    return (
      <>
        <DashboardCard
          children={<ChallengeCard />}
          customStyles={{
            maxHeight: "200px",
            bgcolor: "newDesignColors.orange100",
            padding: 4,
          }}
        />
      </>
    );
  };
  return (
    <>
      <LayoutWrapper>
        <LayoutWrapper.MainContent>
          <Stack
            spacing={2}
            sx={{
              width: { xs: "95%", sm: "90%", md: "85%" },
              margin: "auto",
              mt: 4,
              mb: 4,
              color: "newDesignColors.eggplant800",
            }}
          >
            {userState?.userInfo?.name &&
              (!userState?.onboardingSteps?.generated_first_note ? (
                <Typography variant="h5" fontWeight={700} sx={{ pb: 2 }}>
                  Welcome, {userState?.userInfo?.name}!
                </Typography>
              ) : (
                <Typography variant="h5" fontWeight={700} sx={{ pb: 2 }}>
                  Welcome back, {userState?.userInfo?.name}!
                </Typography>
              ))}
            {!userState?.onboardingSteps?.generated_first_note && (
              <>
                <EmptyNoteList />
              </>
            )}
            {userState?.onboardingSteps?.generated_first_note && (
              <>
                {/* {gamificationInfo && <Challenge />} */}
                <Box sx={{ mb: 2 }}>
                  <UtilHomescreenComponents />
                </Box>
              </>
            )}
            <OnboardingAndNotesToReview
              noteCounts={noteCounts}
              notesForReview={notesForReview}
              noNotesGenerated={noNotesGenerated}
              brandLanguage={brandLanguage}
              navigate={navigate}
              onboardingStatus={onboardingStatus}
            />
            {referralInfo && (
              <>
                <Box
                  sx={{
                    width: "100%",
                  }}
                >
                  <ReferralCard />
                </Box>
              </>
            )}
            <br />
          </Stack>
        </LayoutWrapper.MainContent>
      </LayoutWrapper>
      <TermsOfServiceModal
        isOpen={showToSModal}
        onClose={() => setShowToSModal(false)}
      />
      <NewFeatureModal
        isOpen={showNewFeatureModal}
        onClose={() => {
          setShowNewFeatureModal(false);
          setNewFeature(false);
        }}
      />
      {/* {showAddToHomeScreen && <AddToHomeScreen />} */}
    </>
  );
};

export default Dashboard;
