import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useState,
} from "react";
import {
  Paper,
  Typography,
  Box,
  useTheme,
  SxProps,
  IconButton,
  Skeleton,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// Context for handling collapse state
interface CollapsibleBlockContext {
  isCollapsed: boolean;
  toggleCollapse: () => void;
}

const BlockContext = createContext<CollapsibleBlockContext | undefined>(
  undefined
);

export const useBlockContext = () => {
  const context = useContext(BlockContext);
  if (!context) {
    throw new Error(
      "CollapsibleBaseBlock components must be used within CollapsibleBaseBlock"
    );
  }
  return context;
};

// Main component props
interface CollapsibleBaseBlockProps {
  children: ReactNode;
  defaultCollapsed?: boolean;
  sx?: SxProps;
  onCollapseChange?: (isCollapsed: boolean) => void;
}

// Header component props
interface HeaderProps {
  title: string;
  subtitle?: string | null;
  icon?: ReactNode;
  action?: ReactNode;
  loading?: boolean;
  onTitleClick?: () => void;
}

// Body component props
interface BodyProps {
  children: ReactNode;
}

// Extension component props
interface ExtensionProps {
  children: ReactNode;
  position?: "top" | "bottom";
}

// Header component
const Header: FC<HeaderProps> = ({
  title,
  subtitle,
  icon,
  action,
  loading = false,
  onTitleClick,
}) => {
  const { isCollapsed, toggleCollapse } = useBlockContext();
  const theme = useTheme();

  const handleToggleClick = (e: React.MouseEvent) => {
    // Prevent event from bubbling up to the parent container
    e.stopPropagation();
    toggleCollapse();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        mb: isCollapsed ? 0 : 2,
        minWidth: "fit-content",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: onTitleClick ? "pointer" : "default",
        }}
        onClick={
          onTitleClick
            ? (e) => {
                e.stopPropagation();
                onTitleClick();
              }
            : undefined
        }
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {icon && <Box sx={{ mr: 1 }}>{icon}</Box>}
          <Typography
            variant="h6"
            noWrap
            sx={{
              "&:hover": {
                textDecoration: onTitleClick ? "underline" : "none",
              },
            }}
          >
            {title}
          </Typography>
        </Box>
        {loading ? (
          <Skeleton width={150} height={20} />
        ) : (
          subtitle && (
            <Typography variant="body2" color="text.secondary">
              {subtitle}
            </Typography>
          )
        )}
      </Box>
      <Box display="flex" alignItems="center" gap={1}>
        {action && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            sx={{
              opacity: isCollapsed ? 0 : 1,
              visibility: isCollapsed ? "hidden" : "visible",
              transition:
                "opacity 0.2s ease-in-out, max-width 0.2s ease-in-out",
              maxWidth: isCollapsed ? 0 : "none",
              width: isCollapsed ? 0 : "auto",
              overflow: isCollapsed ? "hidden" : "visible",
              flexShrink: 0,
            }}
            onClick={isCollapsed ? undefined : (e) => e.stopPropagation()}
          >
            {action}
          </Box>
        )}
        <IconButton
          onClick={handleToggleClick}
          size="small"
          sx={{ flexShrink: 0 }}
        >
          {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
        </IconButton>
      </Box>
    </Box>
  );
};

// Body component
const Body: FC<BodyProps> = ({ children }) => {
  const { isCollapsed } = useBlockContext();

  return (
    <Box
      sx={{
        maxHeight: isCollapsed ? 0 : "500px",
        overflow: isCollapsed ? "hidden" : "auto",
        opacity: isCollapsed ? 0 : 1,
        transform: isCollapsed ? "translateY(-20px)" : "translateY(0)",
        transition: `
          opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          transform 0.3s cubic-bezier(0.4, 0, 0.2, 1),
          max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1)
        `,
      }}
      onClick={isCollapsed ? undefined : (e) => e.stopPropagation()}
    >
      {children}
    </Box>
  );
};

// Extension component
const Extension: FC<ExtensionProps> = ({ children, position = "top" }) => {
  const { isCollapsed } = useBlockContext();

  if (isCollapsed && position === "bottom") {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        mt: position === "bottom" ? 2 : 0,
        mb: position === "top" ? 2 : 0,
        pt: position === "bottom" ? 2 : 0,
        borderTop: position === "bottom" ? 1 : 0,
        borderColor: "divider",
      }}
    >
      {children}
    </Box>
  );
};

// Main collapsible base block component
const CollapsibleBaseBlock: FC<CollapsibleBaseBlockProps> & {
  Header: typeof Header;
  Body: typeof Body;
  TopExtension: typeof Extension;
  BottomExtension: typeof Extension;
} = ({ children, defaultCollapsed = false, sx = {}, onCollapseChange }) => {
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);
  const theme = useTheme();

  const toggleCollapse = () => {
    const newValue = !isCollapsed;
    setIsCollapsed(newValue);

    // Notify parent if callback provided
    if (onCollapseChange) {
      onCollapseChange(newValue);
    }
  };

  return (
    <BlockContext.Provider
      value={{
        isCollapsed,
        toggleCollapse,
      }}
    >
      <Paper
        elevation={1}
        onClick={isCollapsed ? toggleCollapse : undefined}
        sx={{
          p: 2,
          mb: 2,
          backgroundColor: theme.palette.background.paper,
          border: 1,
          borderRadius: "0.75rem",
          borderColor: "borderColors.primary",
          width: "100%",
          overflowX: "auto",
          cursor: isCollapsed ? "pointer" : "default",
          transition: "all 0.2s",
          ...(isCollapsed && {
            "&:hover": {
              bgcolor: "action.hover",
              transform: "translateY(-2px)",
              boxShadow: 1,
            },
          }),
          ...sx,
        }}
      >
        {children}
      </Paper>
    </BlockContext.Provider>
  );
};

// Attach subcomponents
CollapsibleBaseBlock.Header = Header;
CollapsibleBaseBlock.Body = Body;
CollapsibleBaseBlock.TopExtension = (props: ExtensionProps) => (
  <Extension {...props} position="top" />
);
CollapsibleBaseBlock.BottomExtension = (props: ExtensionProps) => (
  <Extension {...props} position="bottom" />
);

export default CollapsibleBaseBlock;
