import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Paper,
  Stack,
  TextField,
  Button,
  Grow,
  alpha,
  Tooltip,
} from "@mui/material";
import {
  Note as NoteIcon,
  Description as DocumentIcon,
  Mic as RecordingIcon,
  Add as AddIcon,
  Lock as LockIcon,
  Delete,
} from "@mui/icons-material";
import { useEncounter } from "./EncounterContext";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import TranscriptViewer from "./TranscriptViewer";
import { debounce } from "lodash";
import DocumentEditor from "./DocumentEditor";
import { useTheme } from "@mui/material/styles";
import { DeleteModal } from "../DeleteModal";
import {
  EncounterDocument,
  EncounterDocumentReference,
  LocalDocumentState,
} from "../../types/types";
import { encounterApi } from "./encounters";
import { useUser } from "../../context/user";

interface EncounterDocumentsProps {
  triggerRecordingTutorial?: () => void;
}

const EncounterDocuments: React.FC<EncounterDocumentsProps> = ({
  triggerRecordingTutorial,
}) => {
  const { getAccessToken } = useUser();
  const { state, dispatch } = useEncounter();
  const [editingDocument, setEditingDocument] =
    useState<EncounterDocument | null>(null);
  const [viewingTranscript, setViewingTranscript] = useState<string | null>(
    null
  );
  const theme = useTheme();
  const [documentToDelete, setDocumentToDelete] = useState<string | null>(null);
  const [documentData, setDocumentData] = useState<{
    [key: string]: EncounterDocument;
  }>({});

  const isViewOnly =
    state.status === "processing" || state.status === "completed";

  const getIconAndColor = (type: "recording" | "document" | "text") => {
    switch (type) {
      case "recording":
        return { icon: <RecordingIcon />, color: "#e91e63" }; // Pink for recordings
      case "text":
        return { icon: <NoteIcon />, color: "#2196f3" }; // Blue for notes
      case "document":
        return { icon: <DocumentIcon />, color: "#4caf50" }; // Green for documents
    }
  };

  const debouncedUpdateDocumentRef = useRef(
    debounce(
      async (encounter_document_id: string, updates: LocalDocumentState) => {
        try {
          // Sync the local state
          dispatch({
            type: "SYNC_LOCAL_DOCUMENT",
            payload: {
              encounter_document_id,
              updates,
            },
          });

          // Make the API call
          const accessToken = await getAccessToken();
          await encounterApi.updateDocument(
            encounter_document_id,
            updates,
            accessToken
          );
        } catch (error) {
          console.error("Failed to update document:", error);
        }
      },
      1000
    )
  );

  const handleDocumentUpdate = useCallback(
    (encounter_document_id: string, updates: LocalDocumentState) => {
      // Update local state immediately
      dispatch({
        type: "UPDATE_LOCAL_DOCUMENT",
        payload: {
          encounter_document_id,
          updates,
        },
      });

      // Debounce the API call and sync
      debouncedUpdateDocumentRef.current(encounter_document_id, updates);
    },
    [dispatch]
  );

  // Update the document rendering to use the new local state
  const getDocumentLocalState = (documentId: string) =>
    state._localState.localDocumentState[documentId] || {};

  useEffect(() => {
    const fetchDocuments = async () => {
      const newDocData: { [key: string]: EncounterDocument } = {};

      if (state.inputs) {
        for (const docRef of state.inputs) {
          try {
            if (!documentData[docRef.encounter_document_id]) {
              const doc = await encounterApi.getDocument(
                docRef.encounter_document_id,
                await getAccessToken()
              );
              newDocData[docRef.encounter_document_id] = doc;
            }
          } catch (error) {
            console.error("Failed to fetch document:", error);
          }
        }
      }

      setDocumentData((prev) => ({ ...prev, ...newDocData }));
    };

    fetchDocuments();
  }, [state.inputs]);

  const handleAddNote = async () => {
    const newNote: Partial<EncounterDocument> = {
      type: "text",
      title: `Note ${state.inputs.filter((d) => d.type === "text").length + 1}`,
      content: "",
    };

    try {
      const createdDoc = await encounterApi.createDocument(
        newNote,
        await getAccessToken()
      );

      const docRef: EncounterDocumentReference = {
        encounter_document_id: createdDoc.encounter_document_id,
        type: createdDoc.type,
        created_at: createdDoc.created_at,
      };

      dispatch({ type: "ADD_DOCUMENT", payload: docRef });

      setDocumentData((prev) => ({
        [createdDoc.encounter_document_id]: createdDoc,
        ...prev,
      }));

      dispatch({
        type: "SYNC_LOCAL_DOCUMENT",
        payload: {
          encounter_document_id: createdDoc.encounter_document_id,
          updates: { content: newNote.content, title: newNote.title },
        },
      });

      setEditingDocument(createdDoc);
    } catch (error) {
      console.error("Failed to create document:", error);
    }
  };

  // Force save function for when we need immediate sync
  const forceSaveCurrentDocument = useCallback(() => {
    if (!editingDocument) return;

    const content =
      state._localState.localDocumentState[
        editingDocument.encounter_document_id
      ]?.content ?? editingDocument.content;

    // Immediately sync the content
    dispatch({
      type: "SYNC_LOCAL_DOCUMENT",
      payload: {
        encounter_document_id: editingDocument.encounter_document_id,
        updates: {
          content,
        },
      },
    });
  }, [editingDocument, state._localState.localDocumentState, dispatch]);

  const handleDocumentClick = (document: EncounterDocument) => {
    forceSaveCurrentDocument();
    setEditingDocument(document);
  };

  const handleCloseEditor = () => {
    // Save any pending changes before closing
    forceSaveCurrentDocument();
    setEditingDocument(null);
  };

  const formatDate = (date: Date) => {
    try {
      const timestamp = new Date(date).getTime();
      if (!isFinite(timestamp)) return "Invalid date";

      const days = Math.ceil((Date.now() - timestamp) / (1000 * 60 * 60 * 24));
      if (!isFinite(days)) return "Invalid date";

      return new Intl.RelativeTimeFormat("en", { numeric: "auto" })
        .format(days, "day")
        .replace("in ", "");
    } catch (e) {
      return "Invalid date";
    }
  };

  const handleDeleteDocument = (id: string) => {
    setDocumentToDelete(id);
  };

  const handleConfirmDelete = () => {
    if (documentToDelete) {
      dispatch({ type: "DELETE_DOCUMENT", payload: documentToDelete });
      if (editingDocument?.encounter_document_id === documentToDelete) {
        setEditingDocument(null);
      }
    }
    setDocumentToDelete(null);
  };

  const hasAudioRecordings = state.inputs?.some(
    (form) => form.type === "recording"
  );

  return (
    <Box sx={{ height: "100%" }}>
      <Stack spacing={2} sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6">Encounter Documents</Typography>
          {!isViewOnly && (
            <IconButton onClick={handleAddNote} size="small" color="primary">
              <AddIcon />
            </IconButton>
          )}
        </Stack>

        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            pr: 1,
            mr: -1,
          }}
        >
          <Stack spacing={2} sx={{ mt: 2, pb: 2 }}>
            {state.inputs?.map((docRef, index) => {
              const doc = documentData[docRef.encounter_document_id];
              if (!doc) return null; // Or loading state
              const { icon, color } = getIconAndColor(doc.type);
              return (
                <Grow
                  key={docRef.encounter_document_id}
                  in={true}
                  timeout={300 + index * 100}
                >
                  <Paper
                    sx={{
                      p: 2,
                      backgroundColor: theme.palette.background.paper,
                      border: 1,
                      borderRadius: "0.75rem",
                      borderColor: "borderColors.primary",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: "action.hover",
                      },
                      transition: "background-color 0.2s",
                    }}
                    onClick={() => handleDocumentClick(doc)}
                  >
                    <Stack spacing={1}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box sx={{ color }}>{icon}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle2">
                            {getDocumentLocalState(doc.encounter_document_id)
                              .title ?? doc.title}
                          </Typography>
                          {doc.created_at && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {formatDate(new Date(doc.created_at))}
                            </Typography>
                          )}
                        </Box>
                        {!isViewOnly && (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteDocument(doc.encounter_document_id);
                            }}
                          >
                            <Delete fontSize="small" />
                          </IconButton>
                        )}
                      </Stack>

                      {doc.type === "text" && !isViewOnly && (
                        <TextField
                          multiline
                          rows={3}
                          value={
                            getDocumentLocalState(doc.encounter_document_id)
                              .content ?? doc.content
                          }
                          onChange={(e) =>
                            !isViewOnly &&
                            handleDocumentUpdate(doc.encounter_document_id, {
                              content: e.target.value,
                            })
                          }
                          disabled={isViewOnly}
                          onMouseDown={(e) => {
                            e.stopPropagation();
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          placeholder={
                            isViewOnly
                              ? ""
                              : "Start typing here or click to expand..."
                          }
                          variant="standard"
                          fullWidth
                          InputProps={{
                            disableUnderline: true,
                            readOnly: isViewOnly,
                          }}
                          sx={{
                            "& .MuiInputBase-root": {
                              p: 1,
                              backgroundColor: isViewOnly
                                ? "action.disabledBackground"
                                : "action.hover",
                              borderRadius: 1,
                              fontSize: "0.75rem",
                              lineHeight: 1.2,
                            },
                            "& .MuiInputBase-input": {
                              lineHeight: 1.2,
                            },
                            "& .MuiInputBase-input::placeholder": {
                              fontSize: "0.75rem",
                              opacity: 0.7,
                            },
                          }}
                        />
                      )}
                    </Stack>
                  </Paper>
                </Grow>
              );
            })}
          </Stack>
        </Box>
      </Stack>

      <DocumentEditor
        document={editingDocument}
        onClose={handleCloseEditor}
        onUpdate={handleDocumentUpdate}
        onDelete={handleDeleteDocument}
        isViewOnly={isViewOnly}
      />

      <DeleteModal
        isOpen={!!documentToDelete}
        onCancel={() => setDocumentToDelete(null)}
        onContinue={handleConfirmDelete}
        continueText="Delete"
        cancelText="Cancel"
      >
        Are you sure you want to delete this document? This action cannot be
        undone.
      </DeleteModal>
    </Box>
  );
};

export default EncounterDocuments;
