import React, { createContext, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import {
  createBrowserRouter,
  BrowserRouter,
  RouterProvider,
  Routes,
  Route,
  useRouteError,
  isRouteErrorResponse,
  useNavigate,
  Outlet,
  RouteObject,
} from "react-router-dom";
import "./index.css";
import { RootView } from "./Views/RootView";
import { TranscribeView } from "./Views/Admin/TranscribeView";
import UserNotesView from "./Views/UserNotesView";
import { UIStateProvider } from "./context/uiState";
import {
  Auth0ProviderWithNavigate,
  DemoAuth0ProviderWithNavigate,
} from "./auth0-provider";
import UserView, { UserLoader } from "./Views/UserView";
import AuthenticationCallback, {
  AuthenticationCallbackWrapper,
} from "./Views/AuthenticationCallback";
import { UnauthorizedError } from "./components/errors/UnathorizedError";
import ConfigView from "./Views/Admin/ConfigView";
import { AdminViewOnly } from "./Views/Admin/AdminViewOnly";
import { NoteProcessingView } from "./Views/NoteProcessingView";
import DemoNotesView, { DemoNotesLoader } from "./Views/DemoNotesView";
import DemoNoteView, { DemoNoteLoader } from "./Views/DemoNoteView";
import { DemoNav } from "./components/layout/DemoNav";
import ExtractionTestsView from "./Views/Admin/ExtractionTestsView";
import NoteProcessingViewTests from "./Views/Admin/NoteProcessingTestsView";
import { UserOverview } from "./Views/Admin/UsersOverview";
import { QueryClient, QueryClientProvider } from "react-query";
import { SelectedUserProvider } from "./context/selectedUserContext.tsx";
import { TemplateEditor } from "./Views/Admin/TemplateEditor";
import ViewGroupInvite from "./Views/Shared/ViewGroupInvite";
import {
  BaseTemplateLoader,
  PublicTemplateLoader,
  TemplateLoader,
  UserTemplateLoader,
} from "./loaders/TemplatesLoader";
import { ModuleEditor } from "./Views/Admin/ModuleEditor";
import { ModuleLoader } from "./loaders/ModulesLoader";
import { TemplatesOverview } from "./Views/Admin/TemplatesOverview";
import { AdminNav } from "./components/Admin/AdminNav";
import { ModulesOverview } from "./Views/Admin/ModulesOverview";
import { NotesOverview } from "./Views/Admin/NotesOverview";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { theme } from "./styles/styles";
import { FeedbackOverview } from "./Views/Admin/FeedbackOverview";
import { AdminFeedbackLoader } from "./loaders/AdminFeedbackLoader";
import { AdminFeedbackSessionLoader } from "./loaders/AdminFeedbackSessionLoader";
import AdminFeedbackSessionView from "./Views/Admin/AdminFeedbackSessionView";
import { AdminToolsView } from "./Views/Admin/AdminToolsView";
import { AdminGroupsView } from "./Views/Admin/AdminGroupsView";
import GroupNotesView from "./Views/GroupNotesView";
import UserSignUp, {
  AuthenticationSignupWrapper,
} from "./Views/Admin/UserSignup";
// import TemplatesView from "./Views/TemplatesView";
import TemplatesViewRevamp from "./Views/TemplatesViewRevamp";
import { BaseTemplatesOverview } from "./Views/Admin/BaseTemplatesOverview";
import { BaseTemplateEditor } from "./Views/Admin/BaseTemplateEditor";
import { PublicTemplatesOverview } from "./Views/Admin/PublicTemplatesOverview";
import { PublicTemplateEditor } from "./Views/Admin/PublicTemplateEditor";
import { UserTemplatesOverview } from "./Views/Admin/UserTemplatesOverview";
import { UserTemplateEditor } from "./Views/Admin/UserTemplateEditor";
import { NotFoundError } from "./components/errors/NotFoundError";
import { GenerationSessionView } from "./Views/Admin/GenerationSessionView";
import { ModeratorView } from "./Views/Admin/ModeratorView";
import { AdminGroupDetails } from "./Views/Admin/AdminGroupDetails";
import { GenerationSessionModelComparisonView } from "./Views/Admin/GenerationSessionModelComparisonView";
import OnboardingView from "./Views/OnboardingViewRevamp";
import Dashboard from "./Views/DashboardViewRevamp";
import { ThemeContextProvider } from "./context/theme/context";
import { DeleteModalProvider } from "./context/modals/DeleteModalContext";
import { UserGroupAdministration } from "./Views/UserGroupAdministration";
import { ModuleCustomizationView } from "./Views/ModuleCustomizationView";
import { RecorderView } from "./Views/RecorderView";
// import NoteView, { NoteLoader } from "./Views/NoteView";
import NoteViewRevamp, { NoteLoader } from "./Views/NoteViewRevamp";
import { TestNoteRecordingView } from "./Views/TestNoteRecordingView";
import SentryService from "./services/SentryService";
import { SpecialtiesOverview } from "./Views/Admin/SpecialtiesOverview";
import { SpecialtyEditor } from "./Views/Admin/SpecialtyEditor";
import { PatientsView } from "./Views/PatientsView";
import PatientDetailView from "./components/Patient/PatientDetailView";
import { ClaimSubmittedView } from "./Views/ClaimSubmittedView";
import AnalysisSessionInspector from "./Views/Admin/AnalysisSessionInspector";
import NoteProcessingSessionPicker from "./components/Admin/pickers/NoteProcessingSessionPicker";
import ViewTemplate from "./Views/Shared/ViewTemplate";
import ViewUserTemplates from "./Views/Shared/ViewUserTemplates";
import UserUploadedTemplates from "./Views/Admin/UserUploadedTemplates";
import GenerateNoteForUser from "./Views/Admin/GenerateNoteForUser";
import { AdminNoteLoader } from "./Views/Admin/AdminNoteView";
import AdminNoteView from "./Views/Admin/AdminNoteView";
import CohortDefinitionsView from "./Views/Admin/CohortDefinitionsView";
import CheckoutSuccess from "./Views/CheckoutSuccess";
import CheckoutCancel from "./Views/CheckoutCancel";
import { SnackbarProvider } from "notistack";
import { Zoom } from "@mui/material";
import EncounterView from "./components/Encounter/EncounterView";
import ScheduleView from "./components/Encounter/ScheduleView";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { GlobalTutorialProvider } from "./components/Tutorial/GlobalTutorialContext";
import FormDetailFullView from "./Views/FormDetailFullView";

export const FRONTEND_APP_VERSION = "1.1.1";

// Define a type for conditionally adding routes to emphasize type checking
type ConditionalRouteGroup = {
  condition: boolean;
  routes: RouteObject[];
};

const DEBUG = false;
export const DebugContext = createContext(true);

function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    // Handle unauthorized and server errors
    if (error.status === 401 || error.status === 500) {
      return (
        <UnauthorizedError message={error.data.message} cta={error.data.cta} />
      );
    }

    // Handle not found errors
    if (error.status === 404) {
      // Return a component or redirect for 404 errors
      return <NotFoundError />;
    }

    // Optionally, you could handle other error statuses here as well
  }

  // If it's not a route error response, return a general error fallback

  return (
    <UnauthorizedError message={"An error has occurred. Please login again."} />
  );
}

const mainRoutes: RouteObject[] = [
  { path: "/callback", element: <AuthenticationCallbackWrapper /> },
  {
    path: "/signup",
    element: <AuthenticationSignupWrapper />,
  },
  {
    path: "/share/templates/:templateId",
    element: <ViewTemplate />,
  },
  {
    path: "/share/templates/created-by/:userId",
    element: <ViewUserTemplates />,
  },
  {
    path: "/",
    element: <Auth0ProviderWithNavigate />,
    errorElement: (
      <Auth0ProviderWithNavigate>
        <ErrorBoundary />
      </Auth0ProviderWithNavigate>
    ),
    children: [
      {
        path: "/",
        element: <Dashboard />,
      },
      { path: "/dashboard", element: <Dashboard /> },
      {
        path: "/billing",
        element: <ClaimSubmittedView />,
      },
      {
        path: "/patients/",
        element: <PatientsView />,
      },
      {
        path: "/patient/:patientId",
        element: <PatientDetailView />,
      },
      {
        path: "/group-invite/",
        element: <ViewGroupInvite />,
      },
      {
        path: "/notes/",
        element: <UserNotesView />,
      },
      {
        path: "/templates/",
        element: <TemplatesViewRevamp />,
      },
      {
        path: "/onboarding/",
        element: <OnboardingView />,
      },
      {
        path: "/record/",
        element: <RecorderView />,
      },
      {
        path: "/schedule",
        element: <ScheduleView />,
      },
      {
        path: "/encounters/:encounterId",
        element: <EncounterView />,
      },
      {
        path: "/forms/:formSessionId",
        element: <FormDetailFullView />,
      },
      {
        path: "/exampleRecord/",
        element: <TestNoteRecordingView />,
      },
      {
        path: "/note/:noteId",
        element: <NoteViewRevamp />,
        loader: NoteLoader,
      },
      {
        path: "/status/:noteId",
        element: <NoteProcessingView />,
      },
      {
        path: "/transcribe",
        element: (
          <AdminViewOnly>
            <TranscribeView />
          </AdminViewOnly>
        ),
      },
      {
        path: "/user",
        element: <UserView />,
        loader: UserLoader,
      },
      {
        path: "/group",
        element: <GroupNotesView />,
      },
      {
        path: "/group_administration",
        element: <UserGroupAdministration />,
      },
      {
        path: "/checkout-success",
        element: <CheckoutSuccess />,
      },
      {
        path: "/checkout-cancel",
        element: <CheckoutCancel />,
      },
    ],
  },
  {
    path: "/demo/",
    element: (
      <DemoAuth0ProviderWithNavigate>
        <div>
          <DemoNav />
          <div className="pt-20">
            <Outlet />
          </div>
        </div>
      </DemoAuth0ProviderWithNavigate>
    ),
    children: [
      {
        path: "/demo/",
        element: <DemoNotesView />,
        loader: DemoNotesLoader,
      },
      {
        path: "/demo/:noteId",
        element: <NoteViewRevamp demoMode={true} />,
        loader: DemoNoteLoader,
      },
    ],
  },
];

const adminRoutes: ConditionalRouteGroup = {
  condition: import.meta.env.VITE_BUILD_ENV !== "production",
  routes: [
    {
      path: "/admin/",
      element: (
        <div>
          <AdminNav />
          <div className="pt-12">
            <Auth0ProviderWithNavigate />
          </div>
        </div>
      ),
      children: [
        {
          path: "/admin/pdfuploads",
          element: (
            <AdminViewOnly>
              <UserUploadedTemplates />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/generateNoteForUser",
          element: (
            <AdminViewOnly>
              <GenerateNoteForUser />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/cohorts",
          element: (
            <AdminViewOnly>
              <CohortDefinitionsView />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/analysis",
          element: (
            <AdminViewOnly>
              <AnalysisSessionInspector />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/feedbacks/:user_id?",
          element: (
            <AdminViewOnly>
              <FeedbackOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/feedback/:feedbackSessionId",
          element: (
            <AdminViewOnly>
              <AdminFeedbackSessionView />
            </AdminViewOnly>
          ),
          loader: AdminFeedbackSessionLoader,
        },
        {
          path: "/admin/notes/:user_id?",
          element: (
            <AdminViewOnly>
              <NotesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/note/:noteId",
          element: (
            <AdminViewOnly>
              <AdminNoteView />
            </AdminViewOnly>
          ),
          loader: AdminNoteLoader,
        },
        {
          path: "/admin/users",
          element: (
            <ModeratorView>
              <SelectedUserProvider>
                <UserOverview />
              </SelectedUserProvider>
            </ModeratorView>
          ),
        },
        {
          path: "/admin/specialties",
          element: (
            <AdminViewOnly>
              <SpecialtiesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/templates",
          element: (
            <AdminViewOnly>
              <TemplatesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/basetemplates",
          element: (
            <AdminViewOnly>
              <BaseTemplatesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/publictemplates",
          element: (
            <AdminViewOnly>
              <PublicTemplatesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/usertemplates",
          element: (
            <AdminViewOnly>
              <UserTemplatesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/modules",
          element: (
            <AdminViewOnly>
              <ModulesOverview />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/template/:templateId",
          element: (
            <AdminViewOnly>
              <TemplateEditor />
            </AdminViewOnly>
          ),
          loader: TemplateLoader,
        },
        {
          path: "/admin/basetemplate/:baseTemplateId",
          element: (
            <AdminViewOnly>
              <BaseTemplateEditor />
            </AdminViewOnly>
          ),
          loader: BaseTemplateLoader,
        },
        {
          path: "/admin/publictemplate/:templateId",
          element: (
            <AdminViewOnly>
              <PublicTemplateEditor />
            </AdminViewOnly>
          ),
          loader: PublicTemplateLoader,
        },
        {
          path: "/admin/specialty/:specialtyId",
          element: (
            <AdminViewOnly>
              <SpecialtyEditor />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/usertemplate/:templateId",
          element: (
            <AdminViewOnly>
              <UserTemplateEditor />
            </AdminViewOnly>
          ),
          loader: UserTemplateLoader,
        },
        {
          path: "/admin/module/:moduleId",
          element: (
            <AdminViewOnly>
              <ModuleEditor />
            </AdminViewOnly>
          ),
          loader: ModuleLoader,
        },
        {
          path: "/admin/config",
          element: (
            <AdminViewOnly>
              <ConfigView />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/extractionTests",
          element: (
            <AdminViewOnly>
              <ExtractionTestsView />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/tools/",
          element: (
            <AdminViewOnly>
              <AdminToolsView />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/groups/",
          element: (
            <ModeratorView>
              <AdminGroupsView />
            </ModeratorView>
          ),
        },
        {
          path: "/admin/groups/:groupId",
          element: (
            <ModeratorView>
              <AdminGroupDetails />
            </ModeratorView>
          ),
        },
        {
          path: "/admin/noteProcessingTests/",
          element: (
            <AdminViewOnly>
              <NoteProcessingViewTests />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/generation",
          element: (
            <AdminViewOnly>
              <GenerationSessionView />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/generationComparison",
          element: (
            <AdminViewOnly>
              <GenerationSessionModelComparisonView />
            </AdminViewOnly>
          ),
        },
        {
          path: "/admin/moduleCustomization",
          element: (
            <AdminViewOnly>
              <ModuleCustomizationView />
            </AdminViewOnly>
          ),
        },
      ],
    },
  ],
};

function addConditionalRoutes(
  mainRoutes: RouteObject[],
  ...conditionalGroups: ConditionalRouteGroup[]
): RouteObject[] {
  return [
    ...mainRoutes,
    ...conditionalGroups.reduce(
      (acc, group) => (group.condition ? [...acc, ...group.routes] : acc),
      [] as RouteObject[]
    ),
  ];
}

const router = createBrowserRouter(
  addConditionalRoutes(mainRoutes, adminRoutes)
);

SentryService.initialize({
  dsn: "https://8cf568e22a82ba04ed2b260af2025617@o4506123418206208.ingest.us.sentry.io/4507573931016192",
  environment: import.meta.env.VITE_BUILD_ENV,
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <DebugContext.Provider value={DEBUG}>
    <UIStateProvider>
      <ThemeContextProvider>
        <GlobalTutorialProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <SnackbarProvider
              autoHideDuration={2000}
              TransitionComponent={Zoom}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <DeleteModalProvider>
                <SelectedUserProvider>
                  <RouterProvider router={router} />
                </SelectedUserProvider>
              </DeleteModalProvider>
            </SnackbarProvider>
          </LocalizationProvider>
        </GlobalTutorialProvider>
      </ThemeContextProvider>
    </UIStateProvider>
  </DebugContext.Provider>
);
