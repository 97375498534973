import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { saveUserInfoToLocalStorage } from "../utils/LocalStorageUtils";
import { UserProvider, useUser } from "../context/user";
import { Nav } from "../components/Nav";
import { Box, CircularProgress } from "@mui/material";
import { FRONTEND_APP_VERSION } from "../main";
import APIService from "../services/APIService";
import { RecordingContextProvider } from "../context/RecordingContext";
import { AudioDataProvider } from "../context/AudioDataContext";

export type UserDataType = any;

export const AuthenticationCallbackWrapper = () => {
  // production
  const domain = "smartscribehealth.us.auth0.com";
  const clientId = "RG4FwiozZWwAAe83xQxv2metpgJaPe0j";
  const redirectUri = window.location.origin + "/callback";
  const audience = "https://api.smartscribe.health";

  // development
  // const domain = "smartscribe-development.us.auth0.com";
  // const clientId = "ohWExKeDUG8L1LY5oRJXPseSfaqN1mid";
  // const redirectUri = window.location.origin + "/callback";
  // const audience = "https://api.smartscribe.health/development";

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
        scope: "openid profile email offline_access",
      }}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      <UserProvider>
        <AudioDataProvider>
          <RecordingContextProvider>
            <Nav />
            <AuthenticationCallback />
          </RecordingContextProvider>
        </AudioDataProvider>
      </UserProvider>
    </Auth0Provider>
  );
};

const AuthenticationCallback = () => {
  const { error, getAccessTokenSilently, user } = useAuth0();

  if (error) {
    return <div className="max-w-screen-lg mx-auto">{error.message}</div>;
  }

  // Function to append parameters from localStorage to a URL
  const appendParamsFromLocalStorage = (url: string) => {
    const paramsJson = localStorage.getItem("urlParams");
    if (!paramsJson) return url;

    const paramsObj = JSON.parse(paramsJson);
    const urlObj = new URL(url);

    Object.keys(paramsObj).forEach((key) => {
      urlObj.searchParams.set(key, paramsObj[key]);
    });

    localStorage.removeItem("urlParams");

    return urlObj.toString();
  };

  const saveAccessToken = async () => {
    try {
      console.log("Attempting to get access token...");
      const accessToken = await getAccessTokenSilently();
      console.log("Got access token:", !!accessToken);
      if (accessToken) {
        localStorage.setItem("accessToken", accessToken);
      }
    } catch (err) {
      console.error("Token error:", err);
    }
  };

  const updateUserLogins = async () => {
    const accessToken = await getAccessTokenSilently();
    if (accessToken) {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/userLogin",
        accessToken: accessToken,
        body: {
          device_info: {
            platform: "web",
            userAgent: navigator.userAgent,
            appVersion: FRONTEND_APP_VERSION,
            hostname: window.location.hostname,
            screen: { height: screen.height, width: screen.width },
          },
        },
      });

      if (response.ok) {
        const data = response.value;
        if (data.status === 200) {
          const win: Window = window;
          win.location = appendParamsFromLocalStorage(win.location.origin);
        }
      }
    }
  };

  const saveUserInfo = async () => {
    const accessToken = await getAccessTokenSilently();
    if (accessToken) {
      const response = await APIService.makeAPIGetRequest({
        requestString: "/user/getUserInfo",
        accessToken: accessToken,
      });

      if (response.ok) {
        const data = response.value;
        let userInfo = data.user_info;
        userInfo = {
          ...userInfo,
          featureFlags: data.feature_flags,
          payment_status: data.payment_status,
          email_verified: data.email_verified,
        };

        saveUserInfoToLocalStorage(userInfo, user);

        // call redirect after user info saved
        updateUserLogins();
      }
    }
  };

  useEffect(() => {
    if (user) {
      saveAccessToken();
      saveUserInfo();
    }
  }, [user]);

  // Add a timeout to refresh the page if the redirect doesn't happen within 10 seconds
  useEffect(() => {
    // Get current refresh count from sessionStorage or initialize to 0
    const refreshCount = parseInt(
      sessionStorage.getItem("auth_refresh_count") || "0"
    );

    if (refreshCount < 3) {
      const timeoutId = setTimeout(() => {
        console.log(
          `Timeout reached: Refreshing the page (attempt ${
            refreshCount + 1
          } of 3) as redirect didn't happen`
        );
        // Increment the refresh count
        sessionStorage.setItem(
          "auth_refresh_count",
          (refreshCount + 1).toString()
        );
        window.location.reload();
      }, 10000); // 10 seconds timeout

      return () => {
        // Clean up the timeout if the component unmounts
        clearTimeout(timeoutId);
      };
    } else {
      console.log(
        "Maximum refresh attempts (3) reached. Not refreshing anymore."
      );
      // Reset the counter after user leaves this page
      return () => {
        sessionStorage.removeItem("auth_refresh_count");
      };
    }
  }, []);

  return (
    <Box sx={{ display: "flex", flex: 1, height: "100vh", width: "100vw" }}>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <CircularProgress />
        {parseInt(sessionStorage.getItem("auth_refresh_count") || "0") >= 3 && (
          <Box sx={{ mt: 3, textAlign: "center", maxWidth: "80%" }}>
            <p>
              Authentication is taking longer than expected. Please try
              refreshing the page manually or contact support if the issue
              persists.
            </p>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AuthenticationCallback;
