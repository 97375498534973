import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  Divider,
  useTheme,
  Chip,
  CircularProgress,
} from "@mui/material";
import {
  Description as DocumentIcon,
  Article as ArticleIcon,
  Assignment as AssignmentIcon,
  NoteAlt as NoteAltIcon,
} from "@mui/icons-material";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { NoteExtraType } from "../../types/types";

interface NoteDocumentPickerProps {
  onSelectDocument: (docType: string, id: string) => void;
  encounterData: Encounter | null;
  selectedDocId?: string;
  parentNoteId?: string;
  extras?: NoteExtraType[];
}

interface EncounterOutput {
  created_at: string;
  encounter_document_id?: string;
  jotdoc_id?: string;
  title?: string;
  type: string;
}

interface Encounter {
  encounter_id: string;
  outputs: EncounterOutput[];
}

interface SidebarDocumentCardProps {
  title: string;
  date?: string;
  icon: React.ReactNode;
  color: string;
  isSelected: boolean;
  onClick?: () => void;
  processing?: boolean;
}

// Compact card component specifically for sidebar documents
const SidebarDocumentCard: React.FC<SidebarDocumentCardProps> = ({
  title,
  date,
  icon,
  color,
  isSelected,
  onClick = () => {},
  processing = false,
}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        mb: 1,
        p: 1,
        borderRadius: 1,
        border: 1,
        borderColor: isSelected ? "primary.main" : "borderColors.light",
        backgroundColor: isSelected ? "rgba(54, 34, 158, 0.05)" : "transparent",
        cursor: processing ? "default" : "pointer",
        transition: "all 0.2s ease",
        position: "relative",
        overflow: "hidden",
        "&:hover": {
          borderColor: isSelected ? "primary.main" : "borderColors.primary",
          backgroundColor: isSelected
            ? "rgba(54, 34, 158, 0.05)"
            : "action.hover",
        },
        opacity: processing ? 0.8 : 1,
      }}
      onClick={processing ? undefined : onClick}
    >
      {isSelected && (
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            width: "3px",
            backgroundColor: "primary.main",
          }}
        />
      )}
      <Stack direction="row" spacing={1} alignItems="flex-start">
        <Box sx={{ color, mt: 0.5 }}>{icon}</Box>
        <Box sx={{ flex: 1, minWidth: 0 }}>
          <Typography
            variant="body2"
            fontWeight={isSelected ? "bold" : "normal"}
            noWrap
          >
            {title}
          </Typography>
          {processing && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="caption" color="text.secondary">
                Processing
              </Typography>
              <CircularProgress size={12} color="inherit" />
            </Stack>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};

const NoteDocumentPicker: React.FC<NoteDocumentPickerProps> = ({
  onSelectDocument,
  encounterData,
  selectedDocId,
  parentNoteId,
  extras = [],
}) => {
  const theme = useTheme();
  const { getAccessToken } = useUser();
  const [previousExtras, setPreviousExtras] = useState<NoteExtraType[]>([]);

  // Helper function to get icon based on output type
  const getIconAndColor = (type: string) => {
    switch (type?.toLowerCase()) {
      case "note":
        return { icon: <ArticleIcon fontSize="small" />, color: "#2196f3" }; // Blue for notes
      case "progress_note":
      case "treatment_plan_progress_note":
        return { icon: <NoteAltIcon fontSize="small" />, color: "#ff9800" }; // Orange for progress notes
      case "jotdoc":
        return { icon: <DocumentIcon fontSize="small" />, color: "#4caf50" }; // Green for jotdocs
      default:
        return { icon: <AssignmentIcon fontSize="small" />, color: "#9c27b0" }; // Purple for other types
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  const handleDocumentSelect = (type: string, id: string) => {
    onSelectDocument(type, id);
  };

  // Check if any extra has changed from processing to completed
  useEffect(() => {
    if (previousExtras.length > 0 && extras.length > 0) {
      const statusChangedToCompleted = extras.some((extra) => {
        const prevExtra = previousExtras.find(
          (prev) => prev.name === extra.name
        );
        return (
          prevExtra?.status === "processing" && extra.status === "completed"
        );
      });

      // Here you could trigger a refetch of encounter data if needed
      // if (statusChangedToCompleted && onRefetchEncounter) {
      //   onRefetchEncounter();
      // }
    }

    setPreviousExtras(extras);
  }, [extras]);

  if (
    (!encounterData ||
      !encounterData.outputs ||
      encounterData.outputs.length === 0) &&
    extras.length === 0
  ) {
    return null; // Don't show anything in sidebar if no documents and no extras
  }

  // Create a mapping of document types to prevent duplicates
  const documentMap = new Map();

  // First add encounter outputs to the map
  if (encounterData?.outputs) {
    encounterData.outputs.forEach((output, index) => {
      const docId = output.jotdoc_id || output.encounter_document_id;

      // Skip if not clickable or no docId
      const isClickable =
        (output.type?.toLowerCase() === "note" &&
          output.encounter_document_id) ||
        output.jotdoc_id;

      if (!isClickable || !docId) return;

      // Skip notes that aren't the parent note
      if (
        output.type?.toLowerCase() === "note" &&
        output.encounter_document_id !== parentNoteId
      ) {
        return;
      }

      // Add to the map, keyed by type
      documentMap.set(output.type, {
        docId,
        title: output.title || "Encounter Note",
        type: output.type,
        date: output.created_at ? formatDate(output.created_at) : undefined,
        processing: false,
      });
    });
  }

  // Then process extras, which may override encounter outputs if they exist
  extras.forEach((extra) => {
    if (extra.status === "completed" && extra.data?.jotdoc_id) {
      // If completed with jotdoc_id, treat like a normal document
      documentMap.set(extra.name, {
        docId: extra.data.jotdoc_id,
        title: extra.data.title || getTitleFromType(extra.name),
        type: extra.name,
        processing: false,
      });
    } else if (extra.status === "processing") {
      // If processing, show with processing indicator
      documentMap.set(extra.name, {
        docId: null, // No ID yet
        title: extra.data.title || getTitleFromType(extra.name),
        type: extra.name,
        processing: true,
      });
    }
  });

  // Helper function to get a friendly title from the type
  function getTitleFromType(type: string): string {
    switch (type.toLowerCase()) {
      case "treatment_plan_progress_note":
        return "Treatment Plan Progress Note";
      case "progress_note":
        return "Progress Note";
      default:
        return type
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: theme.palette.background.paper,
        p: 1,
      }}
    >
      <Stack spacing={0.5}>
        {Array.from(documentMap.values()).map((doc, index) => {
          const { icon, color } = getIconAndColor(doc.type || "default");
          const isSelected = doc.docId === selectedDocId;

          return (
            <SidebarDocumentCard
              key={doc.docId || `processing-${doc.type}-${index}`}
              title={doc.title}
              date={doc.date}
              icon={icon}
              color={color}
              isSelected={isSelected}
              processing={doc.processing}
              onClick={
                doc.processing
                  ? undefined
                  : () => handleDocumentSelect(doc.type, doc.docId)
              }
            />
          );
        })}
      </Stack>
      <Divider sx={{ my: 1 }} />
    </Box>
  );
};

export default NoteDocumentPicker;
