import APIService from "../../services/APIService";
import { EncounterDocument, Encounter } from "../../types/types";

export interface EncounterListResponse {
  encounters: Encounter[];
  page: number;
  per_page: number;
  status: number;
  total: number;
  total_pages: number;
}

export const encounterApi = {
  // Document operations
  createDocument: async (
    documentData: Partial<EncounterDocument>,
    accessToken: string | null
  ) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/document/create",
      body: {
        document_data: documentData,
      },
      accessToken,
    });
    if (response.ok) {
      return response.value;
    } else {
      throw response.error;
    }
  },

  // Processing operations
  submitEncounter: async (encounterId: string, accessToken: string | null) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/startEncounterProcessing",
      body: {
        encounter_id: encounterId,
      },
      accessToken,
    });
    if (response.ok) {
      return response.value.note_id;
    } else {
      throw response.error;
    }
  },

  getDocument: async (
    encounterDocumentId: string,
    accessToken: string | null
  ) => {
    const response = await APIService.makeAPIGetRequest({
      requestString: "/encounter/document/get",
      params: { encounter_document_id: encounterDocumentId },
      accessToken,
    });
    if (response.ok) {
      return response.value.document;
    } else {
      throw response.error;
    }
  },

  updateDocument: async (
    encounterDocumentId: string,
    updatedFields: Partial<EncounterDocument>,
    accessToken: string | null
  ) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/document/update",
      body: {
        encounter_document_id: encounterDocumentId,
        updated_fields: updatedFields,
      },
      accessToken,
    });
    if (response.ok) {
      return response.value.document;
    } else {
      throw response.error;
    }
  },

  deleteDocument: async (
    encounterDocumentId: string,
    accessToken: string | null
  ) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/document/delete",
      body: { encounter_document_id: encounterDocumentId },
      accessToken,
    });
    if (response.ok) {
      return response.value;
    } else {
      throw response.error;
    }
  },

  // Encounter operations
  createEncounter: async (
    encounterData: Partial<Encounter>,
    accessToken: string | null
  ) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/create",
      body: {
        encounter_data: encounterData,
      },
      accessToken,
    });
    if (response.ok) {
      return response.value;
    } else {
      throw response.error;
    }
  },

  deleteEncounter: async (encounterId: string, accessToken: string | null) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/delete",
      body: { encounter_id: encounterId },
      accessToken,
    });
    if (response.ok) {
      return response.value;
    } else {
      throw response.error;
    }
  },

  getEncounter: async (
    encounterId: string,
    accessToken: string | null
  ): Promise<Encounter> => {
    const response = await APIService.makeAPIGetRequest({
      requestString: "/encounter/get",
      params: { encounter_id: encounterId },
      accessToken,
    });
    if (response.ok) {
      return response.value.encounter;
    } else {
      throw response.error;
    }
  },

  updateEncounter: async (
    encounterId: string,
    updates: Partial<Encounter>,
    accessToken: string | null
  ) => {
    const response = await APIService.makeAPIPostRequest({
      requestString: "/encounter/update",
      body: {
        encounter_id: encounterId,
        updated_fields: updates,
      },
      accessToken,
    });
    if (response.ok) {
      return response.value.encounter;
    } else {
      throw response.error;
    }
  },

  listEncounters: async (
    params: {
      patient_id?: string;
      provider_id?: string;
      organization_id?: string;
      page?: number;
      per_page?: number;
      filter_mode?: "past" | "upcoming" | "today" | "week" | "all";
      sort_by?: number;
      start_date?: string;
      end_date?: string;
    },
    accessToken: string | null
  ): Promise<EncounterListResponse> => {
    let filterObject: Record<string, any> = {};

    // Only set sortBy if it's not already defined in params
    let sortBy = params.sort_by;

    // Apply custom date range if provided (overrides filter_mode)
    if (params.start_date || params.end_date) {
      // Initialize scheduled_for filter if it doesn't exist
      if (!filterObject.scheduled_for) {
        filterObject.scheduled_for = {};
      }

      // Add start date to filter if provided
      if (params.start_date) {
        filterObject.scheduled_for.gte = params.start_date;
      }

      // Add end date to filter if provided
      if (params.end_date) {
        filterObject.scheduled_for.lte = params.end_date;
      }

      console.log(`[DEBUG] Custom date range filter - showing encounters between:
      Start date: ${params.start_date || "none"}
      End date: ${params.end_date || "none"}`);
    }
    // Otherwise, use the filter_mode if provided
    else if (params.filter_mode) {
      switch (params.filter_mode) {
        case "past":
          filterObject.status = "completed";
          console.log("[DEBUG] Past filter - showing completed encounters");
          break;
        case "upcoming":
          // Set default sort only if not explicitly provided
          if (sortBy === undefined) {
            sortBy = 1;
          }
          filterObject = {
            status: { neq: "completed" },
            scheduled_for: { gt: new Date().toISOString() },
          };
          break;
        case "today": {
          // Get today's date in user's local timezone
          const now = new Date();

          // Create start of day in user's local timezone
          const startOfDay = new Date(now);
          startOfDay.setHours(0, 0, 0, 0);

          // Create end of day in user's local timezone
          const endOfDay = new Date(now);
          endOfDay.setHours(23, 59, 59, 999);

          const startISO = startOfDay.toISOString();
          const endISO = endOfDay.toISOString();

          filterObject = {
            status: { neq: "completed" },
            scheduled_for: {
              gte: startISO,
              lte: endISO,
            },
          };

          // Set default sort only if not explicitly provided
          if (sortBy === undefined) {
            sortBy = 1;
          }
          break;
        }
        case "week": {
          // Get current date in user's local timezone
          const now = new Date();

          // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
          const currentDayOfWeek = now.getDay();

          // Calculate days to go back to reach Monday
          // If today is Sunday (0), go back 6 days
          // If today is Monday (1), go back 0 days
          // If today is Tuesday (2), go back 1 day, etc.
          const daysToMonday =
            currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;

          // Calculate days forward to reach Sunday
          // If today is Sunday (0), add 0 days
          // If today is Monday (1), add 6 days
          // If today is Tuesday (2), add 5 days, etc.
          const daysToSunday =
            currentDayOfWeek === 0 ? 0 : 7 - currentDayOfWeek;

          // Create start of week (Monday) in user's local timezone
          const startOfWeek = new Date(now);
          startOfWeek.setDate(now.getDate() - daysToMonday);
          startOfWeek.setHours(0, 0, 0, 0);

          // Create end of week (Sunday) in user's local timezone
          const endOfWeek = new Date(now);
          endOfWeek.setDate(now.getDate() + daysToSunday);
          endOfWeek.setHours(23, 59, 59, 999);

          const startISO = startOfWeek.toISOString();
          const endISO = endOfWeek.toISOString();

          filterObject = {
            status: { neq: "completed" },
            scheduled_for: {
              gte: startISO,
              lte: endISO,
            },
          };

          // Set default sort only if not explicitly provided
          if (sortBy === undefined) {
            sortBy = 1;
          }
          break;
        }
      }
    }

    // Set final default sort if still undefined
    if (sortBy === undefined) {
      sortBy = -1; // Default to newest first
    }

    const response = await APIService.makeAPIGetRequest({
      requestString: "/encounter/list",
      params: {
        ...Object.fromEntries(
          Object.entries(params).map(([key, value]) => [key, String(value)])
        ),
        filters: JSON.stringify(filterObject),
        sort_by: String(sortBy),
      },
      accessToken,
    });
    if (response.ok) {
      return response.value;
    } else {
      throw response.error;
    }
  },
};
