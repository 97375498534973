import React, { createContext, useContext, useState, useEffect } from "react";
import { trackEvent } from "../../utils/analytics_utils";

interface TutorialInfo {
  key: string;
  startTutorial: () => void;
}

interface GlobalTutorialContextType {
  availableTutorials: TutorialInfo[];
  registerTutorial: (tutorial: TutorialInfo) => void;
  unregisterTutorial: (key: string) => void;
  startTutorial: (key?: string) => void;
  hasTutorials: boolean;
}

const GlobalTutorialContext = createContext<GlobalTutorialContextType>({
  availableTutorials: [],
  registerTutorial: () => {},
  unregisterTutorial: () => {},
  startTutorial: () => {},
  hasTutorials: false,
});

export const useGlobalTutorial = () => useContext(GlobalTutorialContext);

export const GlobalTutorialProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [availableTutorials, setAvailableTutorials] = useState<TutorialInfo[]>(
    []
  );

  const registerTutorial = (tutorial: TutorialInfo) => {
    setAvailableTutorials((prev) => {
      // If tutorial with the same key already exists, replace it
      const exists = prev.some((t) => t.key === tutorial.key);
      if (exists) {
        return prev.map((t) => (t.key === tutorial.key ? tutorial : t));
      } else {
        return [...prev, tutorial];
      }
    });
  };

  const unregisterTutorial = (key: string) => {
    setAvailableTutorials((prev) => prev.filter((t) => t.key !== key));
  };

  const startTutorial = (key?: string) => {
    // If key is provided, start that specific tutorial
    // Otherwise start the first available tutorial
    if (key) {
      const tutorial = availableTutorials.find((t) => t.key === key);
      if (tutorial) {
        tutorial.startTutorial();

        // Track that the tutorial was restarted from help menu
        trackEvent({
          event: "tutorial_restarted",
          tutorial_id: tutorial.key,
          source: "help_menu",
        });
      }
    } else if (availableTutorials.length > 0) {
      availableTutorials[0].startTutorial();

      // Track that the tutorial was restarted from help menu
      trackEvent({
        event: "tutorial_restarted",
        tutorial_id: availableTutorials[0].key,
        source: "help_menu",
      });
    }
  };

  return (
    <GlobalTutorialContext.Provider
      value={{
        availableTutorials,
        registerTutorial,
        unregisterTutorial,
        startTutorial,
        hasTutorials: availableTutorials.length > 0,
      }}
    >
      {children}
    </GlobalTutorialContext.Provider>
  );
};
