import React, { Component, ErrorInfo } from "react";
import { Box, Button, Typography } from "@mui/material";
import { RefreshRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
}

// Create a wrapper component to use hooks
const ErrorBoundaryWrapper: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  return (
    <TemplateEditorErrorBoundary navigate={navigate}>
      {children}
    </TemplateEditorErrorBoundary>
  );
};

class TemplateEditorErrorBoundary extends Component<
  Props & { navigate: (path: string) => void },
  State
> {
  constructor(props: Props & { navigate: (path: string) => void }) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return {
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Template Editor Error:", error, errorInfo);
  }

  handleRefresh = () => {
    window.location.reload(); // This will actually refresh the page
  };

  render() {
    if (this.state.hasError) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
            gap: 2,
            p: 3,
          }}
        >
          <Typography variant="h6" color="error">
            Something went wrong in the template editor
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {this.state.error?.message}
          </Typography>
          <Button
            variant="contained"
            startIcon={<RefreshRounded />}
            onClick={this.handleRefresh}
          >
            Reload Template Editor
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundaryWrapper;
