import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUIState } from "../context/uiState";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { SVGLogo } from "./SVGLogo";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";
import { LoginButton } from "./Buttons/LoginButton";
import UserMenuButton from "./Buttons/UserMenuButton";
import {
  Box,
  AppBar,
  Toolbar,
  Badge,
  Typography,
  Chip,
  Avatar,
  useTheme,
  IconButton,
  List,
  Drawer,
  ListItem,
  ListItemText,
  Snackbar,
  Modal,
  Stack,
  Alert,
} from "@mui/material";
import { Menu, MenuItem, Button } from "@mui/material";
import { trackEvent } from "../utils/analytics_utils";
import { UIStateType } from "../context/uiState/context";
import { useCallback, useEffect, useState, useMemo } from "react";
import React from "react";
import { useUser } from "../context/user";
import { JotPalWordmarkLogo, JotPsychWordmarkLogo } from "./Icons/Logo";
import {
  capitalize,
  compareVersions,
  unixTimestampToDaysRemaining,
} from "../utils/utils";
import { ConditionalTooltip } from "./ConditionalTooltip";
import { AlertBanner } from "./AlertBanner";
import { useThemeContext } from "../context/theme/context";
import { FRONTEND_APP_VERSION } from "../main";
import { NewFeatureModal } from "./Modals/NewFeatureModal";
import {
  Menu as MenuIcon,
  KeyboardArrowLeftOutlined as ArrowOutIcon,
  KeyboardArrowRightOutlined as ArrowInIcon,
} from "@mui/icons-material";
import { useFrontChatBoot } from "../lib/hooks/use-front-chat-boot";
import { PatientMatchModal } from "./Modals/PatientMatchModal";
import { useAudioDataContext } from "../context/AudioDataContext";
import { useRecordingContext } from "../context/RecordingContext";
import APIService from "../services/APIService";
import { ShowNotifications } from "../Views/Shared/ShowNotifications";
import EmailVerificationPrompt from "../Views/Shared/EmailVerificationPrompt";
import { ProcessingSlowdownModal } from "./Modals/ProcessingSlowdownModal";
import { useGlobalTutorial } from "./Tutorial/GlobalTutorialContext";

export const Nav = ({}: {}) => {
  const {
    userState,
    newFeature,
    getAccessToken,
    awaitingSignup,
    subjectLanguage,
  } = useUser();
  const [loadingBilling, setLoadingBilling] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorElement, setAnchorElement] = React.useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [showEmailVerificationModal, setShowEmailVerificationModal] =
    useState<boolean>(false);
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const { state: UIState, setState, showAlertBanner } = useUIState();
  const { loadedInChromeExtension } = UIState;
  const { loginWithRedirect, logout } = useAuth0();
  const { brandLanguage } = useThemeContext();
  const theme = useTheme();
  const { platform } = UIState;
  const [showPatientMatchModal, setShowPatientMatchModal] =
    useState<boolean>(false);
  const [showNewScheduleBadge, setShowNewScheduleBadge] = useState<boolean>(
    !localStorage.getItem("navScheduleBadgeClicked")
  );
  const [showProcessingSlowdownModal, setShowProcessingSlowdownModal] =
    useState<boolean>(!localStorage.getItem("auth0ModalShown032525"));

  const { audioData } = useAudioDataContext();
  const { recordingStatus, elapsedTime, allowRecorderControl } =
    useRecordingContext();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [showNewFeatureModal, setShowNewFeatureModal] =
    useState<boolean>(false);
  const isLeftSideBarPage = ["/note/", "/test", "/templates", "/demo"].some(
    (path) => location.pathname.startsWith(path)
  );
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElement(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElement(null);
  };

  let trial_remaining_days = undefined;

  if (userState?.stripeInfo?.stripe_current_period_end) {
    const remainingSeconds =
      userState.stripeInfo.stripe_current_period_end -
      Math.floor(Date.now() / 1000);
    if (remainingSeconds <= 86400) {
      // 86400 seconds in 24 hours
      trial_remaining_days = `${Math.ceil(remainingSeconds / 3600)} hours`; // 3600 seconds in an hour
    } else {
      trial_remaining_days = `${unixTimestampToDaysRemaining(
        userState.stripeInfo.stripe_current_period_end
      )} days`;
    }
  }

  const formatTime = (totalSeconds: number): string => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  const isRecorderPage =
    location.pathname.includes("/record") ||
    location.pathname.includes("/encounters/"); // Check for both record and encounters paths

  // Front ChatBot Initialization

  const chatId = "ee1815dea718524ed6bebe059556b58b";
  const { frontChat, initialize, isInitialized } = useFrontChatBoot(
    document.body
  );
  const [isWindowVisible, setIsWindowVisible] = useState(true);
  const [chatBotHash, setChatBotHash] = useState<string | null>(null);

  const initializeChatBot = useCallback(async () => {
    console.log("initializeChatBot", loadedInChromeExtension);

    const shouldNotInitialize = [
      loadedInChromeExtension,
      import.meta.env.VITE_BUILD_ENV == "devv",
      !userState?.userInfo,
    ];

    if (shouldNotInitialize.some((condition) => condition)) {
      console.log("Not initializing chat bot");
      return;
    }

    const accessToken = await getAccessToken();
    if (!accessToken) return;

    try {
      const response = await APIService.makeAPIGetRequest({
        requestString: "/user/getChatBotHash",
        accessToken: accessToken,
      });

      if (!response.ok) {
        throw new Error("Failed to fetch user hash");
      }

      const data = response.value;
      const userChatBotHash = data.chatbot_hash;

      console.log("userChatBotHash", userChatBotHash);

      if (userChatBotHash) {
        console.log("initializing chat bot");
        setChatBotHash(userChatBotHash);
        if (initialize) {
          console.log("actually initializing chat bot");
          initialize({
            chatId,
            email: userState?.userInfo?.email || "",
            name: userState?.userInfo?.name || "",
            userHash: userChatBotHash,
          });
        }
      }
    } catch (error) {
      console.error("Error initializing chat bot:", error);
    }
  }, [getAccessToken, initialize, userState, loadedInChromeExtension]);

  const handleBillingClick = async () => {
    setLoadingBilling(true);
    const accessToken = await getAccessToken();

    try {
      let response;
      if (
        userState?.paymentStatus &&
        ["paused", "canceled"].includes(userState?.paymentStatus)
      ) {
        // Generate checkout session
        response = await APIService.makeAPIPostRequest({
          requestString: "/user/createOrRetrieveCheckoutSession",
          accessToken: accessToken,
          body: {},
        });
      } else {
        // Generate billing portal link
        response = await APIService.makeAPIPostRequest({
          requestString: "/user/createCustomerPortalSession",
          accessToken: accessToken,
          body: {},
        });
      }

      if (response.ok) {
        const data = response.value;
        if (data && (data.portal_session_url || data.checkout_session_url)) {
          window.open(
            data.portal_session_url || data.checkout_session_url,
            "_blank"
          );
        } else {
          // Fallback to default Stripe billing portal
          window.open(
            `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
              userState?.userInfo?.email || ""
            )}`,
            "_blank"
          );
        }
      } else {
        // Fallback to default Stripe billing portal
        window.open(
          `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
            userState?.userInfo?.email || ""
          )}`,
          "_blank"
        );
      }
    } catch (error) {
      console.error("Error creating session:", error);
      // Fallback to default Stripe billing portal
      window.open(
        `https://billing.stripe.com/p/login/7sIdRo5NjdyN1QA8ww?prefilled_email=${encodeURIComponent(
          userState?.userInfo?.email || ""
        )}`,
        "_blank"
      );
    } finally {
      setLoadingBilling(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      initializeChatBot();
    }, 3000);

    return () => clearTimeout(timer);
  }, [initialize, loadedInChromeExtension]);

  useEffect(() => {
    if (
      !frontChat ||
      !isInitialized ||
      isWindowVisible ||
      loadedInChromeExtension
    ) {
      return;
    }

    frontChat("hide");
    setIsWindowVisible(false);
  }, [frontChat, isInitialized, isWindowVisible, loadedInChromeExtension]);

  // End Front ChatBox Initialization

  const handleToggleSidebar = () => {
    if (isLeftSideBarPage) {
      setState({
        ...UIState,
        sidebar: { isHidden: !UIState.sidebar.isHidden },
      });
    } else {
      setDrawerOpen(!drawerOpen);
    }
  };

  const routeMap = [
    { path: "/", pageName: "Home", id: "App" },
    {
      path: "/group_administration",
      pageName: "Group Administration",
      id: "GroupAdministrationView",
      condition: () => {
        return userState?.group_user_roles?.includes("billing_admin");
      },
    },
    { path: "/notes", pageName: "Notes", id: "UserNotesView" },
    {
      path: "/group",
      pageName: "Group Notes",
      id: "GroupNotesView",
      condition: () => {
        return userState?.group_user_roles?.includes("admin");
      },
    },
    { path: "/templates", pageName: "Templates", id: "TemplatesView" },
    {
      path: "/patients",
      pageName: `${capitalize(subjectLanguage)}s`,
      id: "PatientsView",
    },
    {
      path: "/billing",
      pageName: "Billing",
      id: "BillingView",
      condition: () => {
        return userState?.featureFlags?.billing_feature;
      },
    },
    {
      path: "/schedule",
      pageName: "Schedule",
      id: "ScheduleView",
      condition: () => {
        return userState?.featureFlags?.encounters;
      },
      badge: showNewScheduleBadge,
      onClick: () => {
        if (showNewScheduleBadge) {
          localStorage.setItem("navScheduleBadgeClicked", "true");
          setShowNewScheduleBadge(false);
        }
      },
    },
    {
      path: userState?.featureFlags?.encounters
        ? "/encounters/active"
        : "/record",
      pageName: "Record",
      id: "RecorderView",
      customStyle: { color: theme.palette.error.main },
    },
  ];

  const currentPage =
    routeMap.find((route) => route.path === location.pathname)?.id || "";

  const getButtonStyle = (route: any) => {
    const isActive = location.pathname === route.path;
    const baseStyle = {
      fontWeight: isActive ? "bold" : "normal",
      color: isActive ? theme.palette.primary.light : "textColors.lightHeader",
    };

    if (route.customStyle) {
      return { ...baseStyle, ...route.customStyle };
    }

    return baseStyle;
  };

  useEffect(() => {
    if (
      userState?.onboardingSteps?.generated_first_note === false &&
      !localStorage.getItem("onboardingBannerShown")
    ) {
      // Set a timeout to delay the alert banner display
      const timerId = setTimeout(() => {
        localStorage.setItem("onboardingBannerShown", "true");
        showAlertBanner(
          "Need help getting started? Click here to schedule 15 minutes with our product team.",
          "warning",
          false,
          () => {
            window.open(
              `https://calendly.com/jackson-smartscribe/onboarding-support-office-hours`,
              "_blank"
            );
          }
        );
      }, 20000); // Delay set to 20 seconds

      return () => clearTimeout(timerId);
    }
  }, [userState]);

  const handleProcessingSlowdownModalClose = () => {
    localStorage.setItem("auth0ModalShown032525", "true");
    setShowProcessingSlowdownModal(false);
  };

  const { hasTutorials, startTutorial } = useGlobalTutorial();

  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        sx={{
          bgcolor: awaitingSignup ? "rgba(213, 211, 224, 0.3)" : "grey.100",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "space-between",
              flex: 1,
            }}
          >
            <Box
              color="primary"
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
              }}
            >
              {isLeftSideBarPage ? (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="back"
                  onClick={() => navigate(-1)}
                  sx={{ display: { xs: "block", md: "none" }, color: "black" }}
                >
                  <ArrowOutIcon />
                </IconButton>
              ) : (
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleToggleSidebar}
                  sx={{ display: { xs: "block", md: "none" }, color: "black" }}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <CustomButton
                onClick={() => {
                  setState((prevState: UIStateType) => ({
                    ...prevState,
                    navigation: { currentPage: "home" },
                  }));
                  navigate("/");
                }}
                size="large"
                aria-label="logo-home-button"
              >
                {brandLanguage.brandName === "JotPsych" && (
                  <JotPsychWordmarkLogo size={100} />
                )}
                {brandLanguage.brandName === "JotPal" && (
                  <JotPalWordmarkLogo size={100} />
                )}
                {/* {brandLanguage.brandName} */}
              </CustomButton>
              {/* {isLeftSideBarPage && (
                <CustomButton
                  sx={{ display: { xs: "inherit", md: "none" } }}
                  onClick={() => {
                    setState((prevState: UIStateType) => ({
                      ...prevState,
                      navigation: { currentPage: "home" },
                    }));
                    navigate("/");
                  }}
                  size="large"
                  aria-label="logo-home-button"
                >
                  Home
                </CustomButton>
              )} */}

              <Box
                className="left-nav-buttons-container"
                sx={{ display: { xs: "none", md: "flex" } }}
              >
                {routeMap
                  .filter((route) => !route.condition || route.condition())
                  .map((route) => (
                    <CustomButton
                      key={route.path || route.id}
                      sx={getButtonStyle(route)}
                      variant="text"
                      onClick={() => {
                        route.onClick?.();
                        if (route.path) {
                          navigate(route.path);
                        }
                      }}
                    >
                      {route.badge ? (
                        <Badge
                          color="info"
                          badgeContent="NEW"
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: "0.5rem",
                              padding: "0 6px",
                              fontWeight: "bold",
                              transform: "scale(1) translate(30%, -80%)",
                            },
                          }}
                        >
                          {route.pageName}
                        </Badge>
                      ) : (
                        route.pageName
                      )}
                    </CustomButton>
                  ))}
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack
              direction={"row"}
              gap={4}
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              {/* Recording Status */}
              {!isRecorderPage &&
                recordingStatus.isRecording &&
                allowRecorderControl && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      cursor: "pointer", // Indicate that the box is clickable
                      "&:hover": {
                        opacity: 0.8, // Optional: Add a hover effect
                      },
                    }}
                    onClick={() =>
                      navigate(
                        userState?.featureFlags?.encounters
                          ? "/encounters/active"
                          : "/record"
                      )
                    }
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 15,
                          height: 15,
                          borderRadius: "50%",
                          backgroundColor: "red",
                          animation: "pulse 1s infinite",
                          marginRight: 1,
                        }}
                      />
                      <Typography variant="body2" sx={{ color: "black" }}>
                        {formatTime(elapsedTime)}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        height: 10,
                        backgroundColor: "grey.500",
                        borderRadius: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: `${audioData}%`,
                          height: "100%",
                          backgroundColor:
                            audioData > 50 ? "success.main" : "warning.main",
                          borderRadius: 1,
                        }}
                      />
                    </Box>
                  </Box>
                )}

              {/* Trial Days Remaining */}
              {userState?.paymentStatus === "trialing" &&
                trial_remaining_days && (
                  <ConditionalTooltip
                    condition={
                      trial_remaining_days.includes("hours") ||
                      parseInt(trial_remaining_days) <= 3
                    }
                    title="Your trial is almost up! Click here to access your billing portal so you don't lose access to your notes."
                    placement="bottom"
                    arrow
                  >
                    <Chip
                      label={`${trial_remaining_days} left in trial`}
                      color={
                        trial_remaining_days.includes("hours") ||
                        parseInt(trial_remaining_days) > 3
                          ? "info"
                          : "warning"
                      }
                      avatar={
                        <Avatar sx={{ fontWeight: 700 }}>
                          {
                            trial_remaining_days.includes("hours")
                              ? 0 // Display days as 0 if trial is less than 24 hours
                              : trial_remaining_days.split(" ")[0] // Display only the number of days
                          }
                        </Avatar>
                      }
                      onClick={() => handleBillingClick()}
                      disabled={loadingBilling}
                    />
                  </ConditionalTooltip>
                )}

              {/* Email Verification */}
              {userState?.paymentStatus === "active" &&
                !userState?.emailVerified && (
                  <ConditionalTooltip
                    condition={true}
                    title="Click here to verify your email."
                    placement="bottom"
                    arrow
                  >
                    <Alert
                      severity="error"
                      sx={{
                        py: 0,
                        px: 1,
                        cursor: "pointer",
                        height: "auto",
                        borderRadius: "100px",
                        border: "1px solid red",
                        "& .MuiAlert-message": {
                          width: "100%",
                        },
                      }}
                      onClick={() => setShowEmailVerificationModal(true)}
                    >
                      Email not verified.
                    </Alert>
                  </ConditionalTooltip>
                )}
              {/* Help Menu */}
              <Box>
                <CustomButton
                  sx={{ color: "textColors.lightHeader" }}
                  variant="text"
                  onClick={handleClick}
                >
                  Help
                </CustomButton>
                <Menu
                  id="support-menu"
                  anchorEl={anchorElement}
                  keepMounted
                  open={Boolean(anchorElement)}
                  onClose={handleClose}
                >
                  {hasTutorials && (
                    <MenuItem
                      onClick={() => {
                        startTutorial();
                        trackEvent({
                          event: "help_tutorial_clicked",
                          platform: platform,
                        });
                        handleClose();
                      }}
                    >
                      <Box sx={{ color: "textColors.lightHeader" }}>
                        Show Page Tutorial
                      </Box>
                    </MenuItem>
                  )}
                  <MenuItem onClick={handleClose}>
                    <Box
                      component="a"
                      href={`https://jotpsych.frontkb.com/en`}
                      target="_blank"
                      sx={{ color: "textColors.lightHeader" }}
                      onClick={() =>
                        trackEvent({
                          event: "help_knowledgebase_clicked",
                          platform: platform,
                        })
                      }
                    >
                      Knowledge Base
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Box
                      component="a"
                      href={`https://${brandLanguage.brandSite}/onboarding-support`}
                      target="_blank"
                      sx={{ color: "textColors.lightHeader" }}
                      onClick={() =>
                        trackEvent({
                          event: "help_onboarding_clicked",
                          platform: platform,
                        })
                      }
                    >
                      Onboarding
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      trackEvent({
                        event: "help_whatsnew_clicked",
                        platform: platform,
                      });
                      setShowNewFeatureModal(true);
                      handleClose();
                    }}
                  >
                    <Box sx={{ color: "textColors.lightHeader" }}>
                      What's New?
                    </Box>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Box
                      component="a"
                      href={`https://${brandLanguage.brandSite}/contact`}
                      target="_blank"
                      sx={{ color: "textColors.lightHeader" }}
                      onClick={() =>
                        trackEvent({
                          event: "help_contactus_clicked",
                          platform: platform,
                        })
                      }
                    >
                      Contact Us
                    </Box>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("userInfo");
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      });
                    }}
                  >
                    <Box sx={{ color: "textColors.lightHeader" }}>Logout</Box>
                  </MenuItem>
                </Menu>
              </Box>
            </Stack>
            <ShowNotifications />
            {/* User Menu Button */}
            <UserMenuButton />
          </Box>
        </Toolbar>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <List>
            {routeMap
              .filter((route) => !route.condition || route.condition())
              .map((route) => (
                <ListItem
                  key={route.path || route.id}
                  button
                  onClick={() => {
                    route.onClick?.();
                    if (route.path) {
                      navigate(route.path);
                    }
                    setDrawerOpen(false);
                  }}
                  sx={getButtonStyle(route)}
                >
                  <ListItemText
                    primary={
                      route.badge ? (
                        <Badge
                          color="error"
                          badgeContent="NEW"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          sx={{
                            "& .MuiBadge-badge": {
                              fontSize: "0.7rem",
                              height: "20px",
                              minWidth: "35px",
                              padding: "0 6px",
                              fontWeight: "bold",
                              transform: "scale(1) translate(50%, 40%)",
                            },
                          }}
                        >
                          {route.pageName}
                        </Badge>
                      ) : (
                        route.pageName
                      )
                    }
                  />
                </ListItem>
              ))}
            {/* <ListItem
              button
              onClick={() => {
                setShowNewFeatureModal(true);
                setDrawerOpen(false);
              }}
              sx={{ fontWeight: "normal", color: "textColors.lightHeader" }}
            >
              <ListItemText primary="What's New?" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={`https://${brandLanguage.brandSite}/how-it-works`}
              target="_blank"
              onClick={() => setDrawerOpen(false)}
              sx={{ fontWeight: "normal", color: "textColors.lightHeader" }}
            >
              <ListItemText primary="Instructions" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={`https://${brandLanguage.brandSite}/onboarding-support`}
              target="_blank"
              onClick={() => setDrawerOpen(false)}
              sx={{ fontWeight: "normal", color: "textColors.lightHeader" }}
            >
              <ListItemText primary="Onboarding" />
            </ListItem>
            <ListItem
              button
              component="a"
              href={`https://${brandLanguage.brandSite}/contact`}
              target="_blank"
              onClick={() => setDrawerOpen(false)}
              sx={{ fontWeight: "normal", color: "textColors.lightHeader" }}
            >
              <ListItemText primary="Contact Us" />
            </ListItem> */}
            <ListItem
              button
              onClick={() => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userInfo");
                logout({
                  logoutParams: {
                    returnTo: window.location.origin,
                  },
                });
                setDrawerOpen(false);
              }}
              sx={{ fontWeight: "normal", color: "textColors.lightHeader" }}
            >
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        {UIState.navbar.showCopyNotification && (
          <div
            className={`tooltip-animation absolute z-40 top-0 left-0 inline-block p-4 mt-16 w-full font-medium text-black duration-300 transition-opacity bg-green-400 shadow-sm tooltip`}
          >
            Copied
          </div>
        )}
        <AlertBanner />
        <NewFeatureModal
          isOpen={showNewFeatureModal}
          onClose={() => {
            setShowNewFeatureModal(false);
          }}
        />
        <PatientMatchModal
          isOpen={showPatientMatchModal}
          onClose={() => {
            setShowPatientMatchModal(false);
          }}
        />
      </AppBar>
      <Snackbar
        open={toastOpen}
        autoHideDuration={3000}
        onClose={() => setToastOpen(false)}
        message={toastMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      {/* Email Verification Prompt */}
      <Modal
        open={showEmailVerificationModal}
        onClose={() => setShowEmailVerificationModal(false)}
      >
        <EmailVerificationPrompt
          onClose={() => setShowEmailVerificationModal(false)}
        />
      </Modal>
      {/* Processing Slowdown Modal */}
      {/* <ProcessingSlowdownModal
        isOpen={showProcessingSlowdownModal}
        onClose={handleProcessingSlowdownModalClose}
      /> */}
    </>
  );
};
