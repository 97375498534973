import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import React from "react";

const ripple = keyframes`
  0%, 30%, 100% {
    transform: translateY(0);
    opacity: 1;
  }
  15% {
    transform: translateY(-4px);
    opacity: 0.7;
  }
`;

const wordTransition = keyframes`
  0%, 85% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

interface ThinkingAnimationProps {
  words?: string[];
}

const ThinkingAnimation: React.FC<ThinkingAnimationProps> = ({
  words = ["Thinking...", "Extracting...", "Writing...", "Generating..."],
}) => {
  const [currentWordIndex, setCurrentWordIndex] = React.useState(0);
  const text = words[currentWordIndex];

  // Calculate the longest word length to keep animation timing consistent
  const maxLength = Math.max(...words.map((word) => word.length));
  const letterDuration = 0.15;
  const totalDuration = maxLength * letterDuration + 1;

  React.useEffect(() => {
    const lastLetterDelay = (text.length - 1) * letterDuration;
    const transitionTimeout = setTimeout(() => {
      setCurrentWordIndex((prev) => (prev + 1) % words.length);
    }, totalDuration * 1000 - 100); // Slightly before animation ends to ensure smooth transition

    return () => clearTimeout(transitionTimeout);
  }, [currentWordIndex, totalDuration, text.length, words.length]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        animation: `${wordTransition} ${totalDuration}s ease-in-out`,
      }}
    >
      {text.split("").map((char, index) => (
        <Typography
          key={`${currentWordIndex}-${index}`}
          component="span"
          sx={{
            animation: `${ripple} ${totalDuration}s ease-in-out infinite`,
            animationDelay: `${index * letterDuration}s`,
            display: "inline-block",
          }}
        >
          {char}
        </Typography>
      ))}
    </Box>
  );
};

export default ThinkingAnimation;
