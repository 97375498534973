import React from "react";
import { Box } from "@mui/material";
import CustomButton from "../styles/CustomButtons";

type PaginationProps = {
  currentPage: number; // the current page number
  itemsPerPage: number | string; // currently accepts both number and string
  totalItems: number; // the total number of items in the dataset
  onPageChange: (newPage: number) => void; // the function to call when a page is changed
};

const Pagination = ({
  currentPage,
  itemsPerPage,
  totalItems,
  onPageChange,
}: PaginationProps) => {
  // Convert itemsPerPage to number before calculation
  const itemsPerPageNum = Number(itemsPerPage);
  const totalPages = Math.ceil(totalItems / itemsPerPageNum);
  const pages = []; // an array of page numbers to display

  console.log(
    "itemsPerPage:",
    itemsPerPageNum,
    "totalItems:",
    totalItems,
    "totalPages:",
    totalPages
  );

  // generate the page numbers based on some logic
  // for example, you could show the first, last, current, and adjacent pages
  if (totalPages > 1) {
    pages.push(1); // always show the first page
    if (currentPage > 3) {
      pages.push("..."); // show ellipsis if there is a gap
    }
    if (currentPage > 2) {
      pages.push(currentPage - 1); // show the previous page
    }
    if (currentPage !== 1 && currentPage !== totalPages) {
      pages.push(currentPage); // show the current page
    }
    if (currentPage < totalPages - 1) {
      pages.push(currentPage + 1); // show the next page
    }
    if (currentPage < totalPages - 2) {
      pages.push("..."); // show ellipsis if there is a gap
    }
    pages.push(totalPages); // always show the last page
  }

  return (
    <Box
      className="pagination"
      sx={{ display: "flex", justifyContent: "center" }}
    >
      {/* Add a button to go to the previous page */}
      <CustomButton
        variant="text"
        sx={{ margin: "0.25rem" }}
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1} // disable the button if it is the first page
      >
        Prev
      </CustomButton>
      {pages.map((page, index) => (
        <CustomButton
          variant="text"
          sx={{
            margin: "0.1rem",
            textDecoration: page === currentPage ? "underline" : "none",
          }}
          key={index}
          className={page === currentPage ? "active" : ""}
          onClick={() => typeof page === "number" && onPageChange(page)}
          disabled={page === "..." || page === currentPage}
        >
          {page}
        </CustomButton>
      ))}
      {/* Add a button to go to the next page */}
      <CustomButton
        variant="text"
        sx={{ margin: "0.25rem" }}
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages} // disable the button if it is the last page
      >
        Next
      </CustomButton>
    </Box>
  );
};

export default Pagination;
