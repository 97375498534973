import React, { useState, useEffect } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import APIService from "../../services/APIService";

interface TranscriptViewerProps {
  transcriptionSessionId: string;
}

interface TranscriptSentence {
  speaker: string;
  text: string;
  confidence: number;
  start: number;
  end: number;
}

const LABELED_SPEAKERS = ["PROVIDER", "PATIENT", "CLIENT"];

const TranscriptViewer: React.FC<TranscriptViewerProps> = ({
  transcriptionSessionId,
}) => {
  const [loading, setLoading] = useState(true);
  const [displayedSentences, setDisplayedSentences] = useState<
    TranscriptSentence[]
  >([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transcript, setTranscript] = useState<TranscriptSentence[]>([]);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    const fetchTranscript = async () => {
      const result = await APIService.makeAPIGetRequest({
        requestString: "/transcriptionSession/getTranscript",
        params: { transcription_session_id: transcriptionSessionId },
        accessToken: localStorage.getItem("accessToken"),
      });

      if (result.ok) {
        if (result.value.status === "processing") {
          if (retryCount < 10) {
            setTimeout(() => {
              setRetryCount((prev) => prev + 1);
            }, 5000);
          } else {
            setLoading(false);
          }
          return;
        }

        setTranscript(result.value.results.transcript_sentences || []);
        setLoading(false);

        // Start streaming in sentences
        const streamTimer = setInterval(() => {
          setCurrentIndex((prev) => {
            const nextIndex = prev + 1;
            if (nextIndex >= result.value.results.transcript_sentences.length) {
              clearInterval(streamTimer);
            }
            return nextIndex;
          });
        }, 30);

        return () => clearInterval(streamTimer);
      } else {
        console.error("Failed to fetch transcript:", result.error);
        setLoading(false);
      }
    };

    fetchTranscript();
  }, [transcriptionSessionId, retryCount]);

  useEffect(() => {
    setDisplayedSentences(transcript.slice(0, currentIndex));
  }, [currentIndex, transcript]);

  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      {displayedSentences.map((sentence, index) => (
        <Box
          key={index}
          sx={{
            mb: 2,
            opacity: 1,
            animation: "fadeIn 0.3s ease-in",
            "@keyframes fadeIn": {
              "0%": { opacity: 0 },
              "100%": { opacity: 1 },
            },
          }}
        >
          {LABELED_SPEAKERS.includes(sentence.speaker) && (
            <Typography
              variant="subtitle2"
              color="text.secondary"
              sx={{ mb: 0.5 }}
            >
              {sentence.speaker}
            </Typography>
          )}
          <Typography variant="body1">{sentence.text}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default TranscriptViewer;
