import React from "react";
import { Paper, Typography, Box, useTheme, SxProps } from "@mui/material";

interface BaseBlockProps {
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  sx?: SxProps;
}

const BaseBlock: React.FC<BaseBlockProps> = ({
  title,
  children,
  actions,
  sx,
}) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: theme.palette.background.paper,
        border: 1,
        borderRadius: "0.75rem",
        borderColor: "borderColors.primary",
        width: "100%",
        overflowX: "auto",
        ...sx,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{ minWidth: "fit-content" }}
      >
        <Typography variant="h6" noWrap>
          {title}
        </Typography>
        {actions && (
          <Box display="flex" alignItems="center" gap={1}>
            {actions}
          </Box>
        )}
      </Box>
      <Box sx={{ overflowX: "auto" }}>{children}</Box>
    </Paper>
  );
};

export default BaseBlock;
