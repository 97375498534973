import React, { useState, useCallback, useEffect, KeyboardEvent } from "react";
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Divider,
  Chip,
} from "@mui/material";
import { Patient } from "../../types/types";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import { MuiTelInput } from "mui-tel-input";
import { useSnackbar } from "notistack";
import { capitalize } from "../../utils/utils";
import { HorizontalRule } from "@mui/icons-material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

interface NewPatientModalProps {
  open: boolean;
  onClose: () => void;
  onSuccessfulSubmission: (patientId: string) => void;
  simplifiedView?: boolean;
  initialName?: string;
}

const NewPatientModal: React.FC<NewPatientModalProps> = ({
  open,
  onClose,
  onSuccessfulSubmission,
  simplifiedView = false,
  initialName,
}) => {
  const { getAccessToken, subjectLanguage } = useUser();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const getInitialPatientState = useCallback(() => {
    let firstName = "";
    let lastName = "";

    if (initialName) {
      const nameParts = initialName.trim().split(/\s+/);
      firstName =
        (nameParts[0] || "").charAt(0).toUpperCase() +
        (nameParts[0] || "").slice(1);
      lastName =
        nameParts.slice(1).join(" ").charAt(0).toUpperCase() +
          nameParts.slice(1).join(" ").slice(1) || "";
    }

    return {
      first_name: firstName,
      last_name: lastName,
      identifier: "",
      dob: "",
      gender: "",
      pronouns: "",
      ...(simplifiedView
        ? {}
        : {
            middle_name: "",
            phone: "",
            email: "",
            race: "",
            address: "",
          }),
    };
  }, [initialName, simplifiedView]);

  const [patient, setPatient] = useState<
    Omit<Patient, "patient_id" | "updated_at">
  >(getInitialPatientState());

  // Reset form when modal opens or initialName changes
  useEffect(() => {
    setPatient(getInitialPatientState());
  }, [open, initialName, getInitialPatientState]);

  const [errors, setErrors] = useState({
    name: false,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPatient((prev) => ({ ...prev, [name]: value }));
    if (name === "first_name" || name === "last_name") {
      setErrors((prev) => ({ ...prev, name: false }));
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;
    setPatient((prev) => ({ ...prev, [name]: value }));
  };

  const handlePhoneChange = (newValue: string) => {
    setPatient((prev) => ({ ...prev, phone: newValue }));
  };

  const isNameValid = useCallback(() => {
    return (
      (patient.first_name?.length ?? 0) >= 2 ||
      (patient.last_name?.length ?? 0) >= 2
    );
  }, [patient.first_name, patient.last_name]);

  const handleSubmit = async () => {
    if (!isNameValid()) {
      setErrors((prev) => ({ ...prev, name: true }));
      return;
    }

    setIsSubmitting(true);

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/patient/create",
        accessToken,
        body: { patient_data: patient },
      });

      if (response.ok) {
        const data = await response.value;
        enqueueSnackbar(`${capitalize(subjectLanguage)} created successfully`, {
          variant: "success",
        });
        onSuccessfulSubmission(data.patientId);
        handleClose();
      } else {
        throw new Error(
          response.error?.message || `Failed to create ${subjectLanguage}`
        );
      }
    } catch (error) {
      console.error("Error creating patient:", error);
      enqueueSnackbar(
        error instanceof Error
          ? error.message
          : `Failed to create ${subjectLanguage}. Please try again.`,
        {
          variant: "error",
        }
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setPatient({
      first_name: "",
      last_name: "",
      identifier: "",
      dob: "",
      gender: "",
      pronouns: "",
      ...(simplifiedView
        ? {}
        : {
            middle_name: "",
            phone: "",
            email: "",
            race: "",
            address: "",
          }),
    });
    setErrors({ name: false });
    onClose();
  };

  // Handle Enter key press to submit form
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      !event.ctrlKey &&
      isNameValid() &&
      !isSubmitting
    ) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Card
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isMobile ? "95%" : "80%",
            maxWidth: 600,
            maxHeight: "90%",
            display: "flex",
            flexDirection: "column",
          }}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          <CardContent sx={{ flexGrow: 1, overflow: "auto" }}>
            <Typography variant="h5" component="div" gutterBottom>
              New {capitalize(subjectLanguage)}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              <TextField
                label="First Name"
                name="first_name"
                value={patient.first_name}
                onChange={handleChange}
                error={errors.name && !patient.first_name}
                helperText={
                  errors.name && !patient.first_name
                    ? "First or Last Name is required"
                    : ""
                }
                fullWidth
                autoFocus
              />
              <TextField
                label="Last Name"
                name="last_name"
                value={patient.last_name}
                onChange={handleChange}
                error={errors.name && !patient.last_name}
                helperText={
                  errors.name && !patient.last_name
                    ? "First or Last Name is required"
                    : ""
                }
                fullWidth
              />

              <Divider />

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Identifier (PRN)"
                    name="identifier"
                    value={patient.identifier}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Date of Birth"
                    name="dob"
                    type="date"
                    value={patient.dob}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      name="gender"
                      value={patient.gender || ""}
                      onChange={handleSelectChange}
                      label="Gender"
                    >
                      <MenuItem value="male">Male</MenuItem>
                      <MenuItem value="female">Female</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Pronouns"
                    name="pronouns"
                    value={patient.pronouns}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <MuiTelInput
                    label="Phone"
                    value={patient.phone}
                    onChange={handlePhoneChange}
                    defaultCountry="US"
                    forceCallingCode
                    onlyCountries={["US", "CA", "GB", "AU", "NZ", "ZA", "MX"]}
                    fullWidth
                    sx={{
                      "& input": {
                        height: "1.4375em",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    label="Email"
                    name="email"
                    type="email"
                    value={patient.email}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {!simplifiedView && (
                <>
                  <TextField
                    label="Middle Name"
                    name="middle_name"
                    value={patient.middle_name}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="Race"
                    name="race"
                    value={patient.race}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="Address"
                    name="address"
                    value={patient.address}
                    onChange={handleChange}
                    multiline
                    rows={2}
                    fullWidth
                  />
                </>
              )}
            </Box>
          </CardContent>
          <CardActions
            sx={{
              justifyContent: "flex-end",
              padding: 2,
              borderTop: "1px solid rgba(0, 0, 0, 0.12)",
            }}
          >
            <Button onClick={handleClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <Tooltip
              title={
                !isNameValid()
                  ? "At least one name field must have at least two characters."
                  : isNameValid() && !isSubmitting
                  ? "Press Enter to create"
                  : ""
              }
              placement="top"
            >
              <span>
                <Box sx={{ position: "relative" }}>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || !isNameValid()}
                    sx={{
                      paddingRight:
                        isNameValid() && !isSubmitting ? "30px" : "16px",
                    }}
                  >
                    {isSubmitting
                      ? "Creating..."
                      : `Create ${capitalize(subjectLanguage)}`}
                  </Button>
                  {isNameValid() && !isSubmitting && (
                    <KeyboardReturnIcon
                      fontSize="small"
                      sx={{
                        position: "absolute",
                        right: "8px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        fontSize: "1rem",
                        opacity: 0.7,
                        color: "white",
                      }}
                    />
                  )}
                </Box>
              </span>
            </Tooltip>
          </CardActions>
        </Card>
      </Modal>
    </>
  );
};

export default NewPatientModal;
