import React, { useState, useCallback, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Fade,
  IconButton,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import NotesPicker from "../Pickers/NotesPicker";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";
import ThinkingAnimation from "../Common/ThinkingAnimation";
import { Send, Close } from "@mui/icons-material";
import { FeatureFeedback } from "../FeatureFeedback/FeatureFeedback";
import SentryService from "../../services/SentryService";

interface ModuleConversationViewProps {
  initialModuleId?: string;
  templateId?: string;
  initialNoteId?: string;
  initialUserFeedback?: string;
  initialSectionText?: string;
  onAccept?: (generation_output: string) => void;
  onCancel?: () => void;
}

interface Message {
  type: "assistant" | "user" | "feedback";
  content: string;
}

interface PollData {
  generation_result_text?: string;
  user_response_text?: string;
  module_conversation_session_id?: string;
}

// Add a new type to track the current step
type Step = "initial" | "conversation" | "review" | "error";

const usePolling = (asyncTaskId: string | null) => {
  const { getAccessToken } = useUser();
  const [pollData, setPollData] = useState<PollData>({});
  const [isPolling, setIsPolling] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!asyncTaskId) return;

    setIsPolling(true);
    let pollCount = 0;
    const maxPolls = 30;

    const pollInterval = setInterval(async () => {
      try {
        const pollResponse = await APIService.makeAPIGetRequest({
          requestString: `/customization/getAsyncResultsSession?async_task_id=${asyncTaskId}`,
          accessToken: await getAccessToken(),
        });

        if (pollResponse.ok) {
          if (pollResponse.value.data) {
            setPollData(pollResponse.value.data);
          }
          if (
            pollResponse.value.status === "completed" ||
            pollResponse.value.status === "errored"
          ) {
            clearInterval(pollInterval);
            setIsPolling(false);
            if (pollResponse.value.status === "errored") {
              setError("The request failed to complete");
            }
          }
        }

        pollCount++;
        if (pollCount >= maxPolls) {
          clearInterval(pollInterval);
          setIsPolling(false);
          setError("Can't customize right now. Please try again later.");
          SentryService.captureMessage(
            "Failed to create custom user module. Polling reached max attempts.",
            "error"
          );
          console.log("Max poll attempts reached");
        }
      } catch (err) {
        console.error("Error polling results:", err);
        clearInterval(pollInterval);
        setIsPolling(false);
        setError("Failed to get response from server");
      }
    }, 2000);

    return () => clearInterval(pollInterval);
  }, [asyncTaskId, getAccessToken]);

  return { pollData, isPolling, setPollData, error };
};

const ModuleConversationView: React.FC<ModuleConversationViewProps> = ({
  initialModuleId,
  templateId,
  initialNoteId,
  initialUserFeedback,
  initialSectionText,
  onAccept,
  onCancel,
}) => {
  const { getAccessToken } = useUser();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [formatExample, setFormatExample] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [conversationSessionId, setConversationSessionId] = useState<
    string | null
  >(null);
  const [selectedNoteId, setSelectedNoteId] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [feedback, setFeedback] = useState("");
  const [currentAsyncTaskId, setCurrentAsyncTaskId] = useState<string | null>(
    null
  );
  const [currentStep, setCurrentStep] = useState<Step>("initial");

  const {
    pollData,
    isPolling,
    setPollData,
    error: pollingError,
  } = usePolling(currentAsyncTaskId);

  // Add ref for the messages container
  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  // Add this new function to reset all state
  const resetState = useCallback(() => {
    setTitle("");
    setDescription("");
    setFormatExample("");
    setLoading(false);
    setError(null);
    setConversationSessionId(null);
    setSelectedNoteId("");
    setMessages([]);
    setFeedback("");
    setCurrentAsyncTaskId(null);
    setCurrentStep("initial");
    setPollData({});
  }, [setPollData]);

  const startConversation = useCallback(
    async (noteId: string) => {
      console.log("Starting conversation, noteId:", noteId);
      if (!noteId) {
        console.log("No note selected");
        return;
      }

      setLoading(true);
      setError(null);

      try {
        const accessToken = await getAccessToken();
        const asyncTaskId = uuidv4();
        setCurrentAsyncTaskId(asyncTaskId);

        // If there's initial user feedback, we don't need module creation data
        const moduleCreationData = initialUserFeedback
          ? null
          : {
              title,
              description,
              format_example: formatExample,
            };

        const bodyPayload = {
          initial_module_id: initialModuleId,
          selected_note_id: initialNoteId || noteId,
          user_feedback: initialUserFeedback,
          module_creation_data: moduleCreationData,
          initial_section_text: initialSectionText,
          async_task_id: asyncTaskId,
          template_id: templateId,
        };

        console.log("bodyPayload", bodyPayload);

        const response = await APIService.makeAPIPostRequest({
          requestString: "/customization/startModuleConversationSession",
          accessToken,
          body: bodyPayload,
        });

        if (!response.ok) {
          setError("Failed to start conversation session");
          setCurrentStep("error");
        }
      } catch (err) {
        setError("An error occurred while starting the conversation");
        setCurrentStep("error");
        console.error("Error starting conversation:", err);
      } finally {
        setLoading(false);
      }
    },
    [
      getAccessToken,
      initialModuleId,
      templateId,
      title,
      description,
      formatExample,
    ]
  );

  const handleAccept = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIPostRequest({
        requestString: "/customization/acceptModuleConversationSession",
        accessToken,
        body: {
          module_conversation_session_id:
            pollData.module_conversation_session_id,
          accepted_example_text: pollData.generation_result_text,
        },
      });

      if (response.ok) {
        if (onAccept && pollData.generation_result_text) {
          onAccept(pollData.generation_result_text);
        }
      } else {
        setError("Failed to accept results");
        setCurrentStep("error");
      }
    } catch (err) {
      console.error("Error accepting results:", err);
      setError("Failed to accept results");
      setCurrentStep("error");
    }
  }, [
    getAccessToken,
    pollData.module_conversation_session_id,
    pollData.generation_result_text,
    onAccept,
  ]);

  const handleReject = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      await APIService.makeAPIPostRequest({
        requestString: "/customization/rejectModuleConversationSession",
        accessToken,
        body: {
          conversation_session_id: pollData.module_conversation_session_id,
        },
      });
    } catch (err) {
      console.error("Error rejecting results:", err);
      setError("Failed to reject results");
      setCurrentStep("error");
    }
  }, [getAccessToken, pollData.module_conversation_session_id]);

  const handleSendFeedback = useCallback(async () => {
    if (!feedback.trim()) return;

    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessToken();
      const asyncTaskId = uuidv4();
      setCurrentAsyncTaskId(asyncTaskId);

      const response = await APIService.makeAPIPostRequest({
        requestString: "/customization/advanceModuleConversationSession",
        accessToken,
        body: {
          module_conversation_session_id:
            pollData.module_conversation_session_id,
          selected_note_id: selectedNoteId,
          user_feedback: feedback,
          async_task_id: asyncTaskId,
        },
      });

      if (response.ok) {
        setMessages((prev) => [...prev, { type: "user", content: feedback }]);
        setFeedback("");
      } else {
        setError("Failed to send feedback");
        setCurrentStep("error");
      }
    } catch (err) {
      setError("An error occurred while sending feedback");
      setCurrentStep("error");
      console.error("Error sending feedback:", err);
    } finally {
      setLoading(false);
    }
  }, [
    feedback,
    pollData.module_conversation_session_id,
    selectedNoteId,
    getAccessToken,
  ]);

  // Modify handleCreateClick to include the proper initial message
  const handleCreateClick = () => {
    setCurrentStep("conversation");
    setMessages([
      {
        type: "user",
        content: `Create a ${title} section`,
      },
      {
        type: "assistant",
        content:
          "Let's pick a recent encounter note so we can see an example of what this new section will look like.",
      },
    ]);
  };

  // Handle note selection
  const handleNoteSelect = (noteId: string) => {
    console.log("Note selected:", noteId);
    setSelectedNoteId(noteId);
    startConversation(noteId);
  };

  // Add scroll effect
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [messages, isPolling]); // Also trigger on isPolling changes for the "Thinking..." indicator

  useEffect(() => {
    if (
      pollData.user_response_text &&
      !messages.some((m) => m.content === pollData.user_response_text)
    ) {
      setMessages((prev) => [
        ...prev,
        { type: "assistant", content: pollData.user_response_text! },
      ]);
    }
  }, [pollData]);

  // Add this effect to clear the result text when processing starts
  useEffect(() => {
    if (isPolling) {
      setPollData((prev) => ({ ...prev, generation_result_text: undefined }));
    }
  }, [isPolling]);

  // Add effect to handle polling errors
  useEffect(() => {
    if (pollingError) {
      setError(pollingError);
      setCurrentStep("error");
    }
  }, [pollingError]);

  // Add effect to handle initial automatic start
  useEffect(() => {
    if (initialNoteId && initialUserFeedback) {
      setCurrentStep("conversation");
      setSelectedNoteId(initialNoteId);
      setMessages([
        { type: "user", content: initialUserFeedback },
        {
          type: "assistant",
          content:
            "Got it. Let me think about that for a second, and then I'll customize this section for you so that we'll get it right from now on. One moment please...",
        },
      ]);
      startConversation(initialNoteId);
    }
  }, [initialNoteId, initialUserFeedback, startConversation]);

  // Modify the messages section to use the new ThinkingAnimation
  const messagesSection = (
    <Box
      sx={{
        flex: 1,
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        gap: 2,
        px: 2,
      }}
    >
      {messages.map((message, index) => (
        <Box
          key={index}
          sx={{
            maxWidth: "80%",
            alignSelf: message.type === "user" ? "flex-end" : "flex-start",
            bgcolor:
              message.type === "user" ? "primary.light" : "background.paper",
            color:
              message.type === "user" ? "primary.contrastText" : "text.primary",
            p: 2,
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Typography>{message.content}</Typography>
        </Box>
      ))}
      {(isPolling || loading) && (
        <Box
          sx={{
            maxWidth: "80%",
            alignSelf: "flex-start",
            bgcolor: "background.paper",
            p: 2,
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <ThinkingAnimation />
        </Box>
      )}
      <div ref={messagesEndRef} />
    </Box>
  );

  // Modify the right panel content with better fade transitions
  const rightPanelContent = (
    <Box>
      <Fade in={!isPolling && !!pollData.generation_result_text} timeout={500}>
        <Box
          sx={{
            display:
              !isPolling && !!pollData.generation_result_text
                ? "block"
                : "none",
          }}
        >
          <Box sx={{ padding: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  marginBottom: 1,
                }}
              >
                {title}
              </Typography>
            </Box>
            <NoteSectionDisplayBox>
              {pollData.generation_result_text}
            </NoteSectionDisplayBox>
          </Box>
        </Box>
      </Fade>

      <Fade in={isPolling} timeout={500}>
        <Box
          sx={{
            display: isPolling ? "flex" : "none",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            py: 4,
          }}
        >
          <CircularProgress size={40} />
          <Typography sx={{ mt: 2 }}>Generating content...</Typography>
        </Box>
      </Fade>
    </Box>
  );

  // Add error view component
  const errorView = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        p: 3,
        textAlign: "center",
      }}
    >
      <Typography variant="h6" color="error" gutterBottom>
        An Error Occurred
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 2 }}>
        {error}
      </Typography>
      <Button
        variant="contained"
        onClick={() => {
          resetState();
          if (onCancel) onCancel();
        }}
      >
        Close
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Close button stays fixed in top right */}
      <IconButton
        onClick={async () => {
          if (pollData.module_conversation_session_id) {
            await handleReject();
          }
          resetState();
          if (onCancel) onCancel();
        }}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          zIndex: 1,
        }}
      >
        <Close />
      </IconButton>

      {/* Show error view when in error state */}
      {currentStep === "error" ? (
        errorView
      ) : (
        <>
          {/* Only show initial step if we don't have initialNoteId and initialUserFeedback */}
          {currentStep === "initial" &&
            !initialNoteId &&
            !initialUserFeedback && (
              <Box
                sx={{
                  maxWidth: 600,
                  width: "100%",
                  p: 2,
                }}
              >
                <TextField
                  label="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Format Example (Optional)"
                  value={formatExample}
                  onChange={(e) => setFormatExample(e.target.value)}
                  multiline
                  rows={4}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <Button
                  variant="contained"
                  onClick={handleCreateClick}
                  disabled={loading || !title.trim() || !description.trim()}
                >
                  Create
                </Button>
              </Box>
            )}

          {/* Conversation/review view */}
          {(currentStep === "conversation" || currentStep === "review") && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
                height: "calc(100vh - 200px)",
                p: 2,
                width: "100%",
                maxHeight: "100%",
              }}
            >
              {/* Left Panel - Chat Interface */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  bgcolor: "grey.50",
                  borderRadius: 1,
                  p: 2,
                  overflow: "hidden",
                }}
              >
                {/* Chat Header */}
                <Box sx={{ mb: 2 }}>
                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                </Box>

                {messagesSection}

                {/* Chat Input - Only show when note is selected */}
                {selectedNoteId && currentStep === "conversation" && (
                  <Box sx={{ mt: 2 }}>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (!loading && feedback.trim()) {
                          handleSendFeedback();
                        }
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: 2,
                          alignItems: "flex-start",
                        }}
                      >
                        <TextField
                          label="What do you think?"
                          value={feedback}
                          onChange={(e) => setFeedback(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" && !e.shiftKey) {
                              e.preventDefault();
                              if (!loading && feedback.trim()) {
                                handleSendFeedback();
                              }
                            }
                          }}
                          fullWidth
                          multiline
                          rows={2}
                        />
                        <IconButton
                          type="submit"
                          disabled={loading || !feedback.trim()}
                          color="primary"
                          sx={{ mt: 2 }}
                        >
                          <Send />
                        </IconButton>
                      </Box>
                    </form>
                  </Box>
                )}
              </Box>

              {/* Right Panel - Note Picker and Results Display */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  height: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    bgcolor: "grey.50",
                    borderRadius: 1,
                    p: 2,
                    height: "auto",
                    position: "relative",
                    overflow: "auto",
                  }}
                >
                  {!selectedNoteId && currentStep === "conversation" ? (
                    <Box>
                      <Typography variant="h6" sx={{ mb: 2 }}>
                        Select an Encounter Note
                      </Typography>
                      <NotesPicker onNoteSelect={handleNoteSelect} />
                    </Box>
                  ) : (
                    currentStep === "conversation" && rightPanelContent
                  )}
                </Box>

                {/* Buttons outside the panel */}
                {currentStep === "conversation" && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: 2,
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        variant="outlined"
                        onClick={async () => {
                          await handleReject();
                          resetState();
                          if (onCancel) onCancel();
                        }}
                      >
                        Exit
                      </Button>
                      <Button
                        variant="contained"
                        color="success"
                        onClick={handleAccept}
                        disabled={!pollData.generation_result_text}
                      >
                        Save Section
                      </Button>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <FeatureFeedback
                        featureId="module_conversation_v1"
                        prompt="Are you finding this feature useful?"
                        context={{
                          module_conversation_session_id:
                            pollData.module_conversation_session_id,
                        }}
                        mountsUntilShow={2}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default ModuleConversationView;
