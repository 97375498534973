import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Tooltip,
  Typography,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import Logo from "../Icons/Logo";
import { AvailableModule } from "../../types/types";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useThemeContext } from "../../context/theme/context";
import { Avatar } from "@mui/material";
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";

interface NewModuleBlockProps {
  module: AvailableModule;
  handleClick?: () => void;
}

const NewModuleBlock = ({ module, handleClick }: NewModuleBlockProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const { brandLanguage } = useThemeContext();
  const userInfo = getUserInfoFromLocalStorage();

  return (
    <Card
      sx={{
        my: 1.5,
        borderColor: "borderColors.primary",
        border: "0.5px solid",
        borderRadius: "0.4cm",
        "&:hover": {
          boxShadow: 3,
        },
      }}
    >
      <CardContent
        sx={{ py: 1, "&.MuiCardContent-root:last-child": { pb: 1 } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            id="module-text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flexGrow: 1,
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontStyle="normal" fontWeight="700">
                {module.name}
              </Typography>
            </Box>
            <Typography
              fontSize=".8rem"
              fontWeight="300"
              sx={{ color: "text.secondary" }}
            >
              {module.description}
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Tooltip
              title={showPreview ? "Hide preview" : "Show preview"}
              placement="top"
              arrow
            >
              <IconButton
                onClick={() => setShowPreview(!showPreview)}
                size="small"
              >
                {showPreview ? (
                  <VisibilityOff fontSize="small" />
                ) : (
                  <Visibility fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
            {module.is_custom_user_module ? (
              <Tooltip
                title="This is your customized section."
                placement="top"
                arrow
              >
                <Avatar
                  variant="circular"
                  src={userInfo?.img}
                  alt="user photo"
                  sx={{ width: 25, height: 25 }}
                />
              </Tooltip>
            ) : (
              <Tooltip
                title={`This is a ${brandLanguage.brandName} section.`}
                placement="top"
                arrow
              >
                <Box>
                  <Logo size={22} />
                </Box>
              </Tooltip>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleClick}
              size="small"
              sx={{
                borderRadius: 2,
                px: 2,
              }}
            >
              Add
            </Button>
          </Box>
        </Box>
        <Collapse in={showPreview} sx={{ mt: 2 }}>
          <NoteSectionDisplayBox>
            {module?.example || "No example provided."}
          </NoteSectionDisplayBox>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default NewModuleBlock;
