import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  CircularProgress,
  Paper,
  Button,
  Breadcrumbs,
  Link,
  Divider,
  IconButton,
  Snackbar,
  Alert,
  Card,
  CardContent,
  Chip,
  Avatar,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "../context/user";
import APIService from "../services/APIService";
import { FormDetail, Patient } from "../types/types";
import FormDetailView from "../components/Forms/FormDetailView";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PersonIcon from "@mui/icons-material/Person";
import { formatDate } from "../utils/utils";

const FormDetailFullView: React.FC = () => {
  const { formSessionId } = useParams<{ formSessionId: string }>();
  const [formDetail, setFormDetail] = useState<FormDetail | null>(null);
  const [patient, setPatient] = useState<Patient | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const navigate = useNavigate();
  const { getAccessToken, subjectLanguage } = useUser();

  useEffect(() => {
    const fetchFormDetail = async () => {
      if (!formSessionId) return;

      setLoading(true);
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/patient/formDetail?form_session_id=${formSessionId}`,
          accessToken,
        });

        if (response.ok) {
          const formDetailData = response.value.form_detail;
          setFormDetail(formDetailData);

          // Fetch patient information if patient_id is present
          if (formDetailData && formDetailData.patient_id) {
            try {
              const patientResponse = await APIService.makeAPIGetRequest({
                requestString: `/patient/get?patient_id=${formDetailData.patient_id}`,
                accessToken,
              });

              if (patientResponse.ok) {
                setPatient(patientResponse.value.patient);
              }
            } catch (error) {
              console.error("Error fetching patient details:", error);
              // Don't set an error state here as the form should still display
              // even if patient details can't be fetched
            }
          }
        } else {
          setError("Failed to load form details");
        }
      } catch (error) {
        console.error("Error fetching form details:", error);
        setError("An error occurred while loading the form details");
      } finally {
        setLoading(false);
      }
    };

    fetchFormDetail();
  }, [formSessionId, getAccessToken]);

  const handleBackToPatient = () => {
    navigate(-1);
  };

  const handleCopyLink = () => {
    if (!formSessionId) return;

    // Get the forms URL from environment or config
    const formsBaseUrl =
      import.meta.env.VITE_FORMS_URL || "https://forms.jotpsych.com";
    const patientFormUrl = `${formsBaseUrl}/f/${formSessionId}`;

    navigator.clipboard
      .writeText(patientFormUrl)
      .then(() => {
        setSnackbarMessage("Form link copied to clipboard!");
        setSnackbarSeverity("success");
        setSnackbarOpen(true);
      })
      .catch((error) => {
        console.error("Failed to copy link:", error);
        setSnackbarMessage("Failed to copy link");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Format the patient name or provide a default message
  const getPatientDisplay = () => {
    if (!patient) return null;

    const patientName =
      patient.first_name && patient.last_name
        ? `${patient.first_name} ${patient.last_name}`
        : "Unnamed Patient";

    return (
      <Box sx={{ display: "flex", alignItems: "center", mb: 3, mt: 1 }}>
        <Avatar
          sx={{
            mr: 1.5,
            bgcolor: "primary.main",
            width: 40,
            height: 40,
          }}
        >
          {patient.first_name ? (
            patient.first_name[0].toUpperCase()
          ) : (
            <PersonIcon />
          )}
        </Avatar>
        <Box>
          <Typography variant="h6" component="div">
            {patientName}
          </Typography>
          {patient.dob && (
            <Typography variant="body2" color="text.secondary">
              DOB: {patient.dob.split("T")[0].replace(/-/g, "/")}
            </Typography>
          )}
        </Box>
      </Box>
    );
  };

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          mb: 4,
          borderRadius: 2,
          border: 1,
          borderColor: "divider",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <IconButton
            onClick={handleBackToPatient}
            size="small"
            sx={{ mr: 1 }}
            aria-label="back"
          >
            <ArrowBackIcon />
          </IconButton>

          <Link
            underline="hover"
            color="inherit"
            onClick={handleBackToPatient}
            sx={{ cursor: "pointer" }}
          >
            Back
          </Link>
        </Box>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 8 }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" align="center" sx={{ py: 4 }}>
            {error}
          </Typography>
        ) : formDetail ? (
          <Box>
            {getPatientDisplay()}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                mb: 3,
                flexWrap: "wrap",
                gap: 2,
              }}
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {formDetail.form_definition.title}
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  {formDetail.form_definition.description}
                </Typography>
              </Box>

              <Card
                variant="outlined"
                sx={{ minWidth: 300, borderRadius: 2, mx: "auto" }}
              >
                <CardContent>
                  <Typography
                    variant="subtitle1"
                    fontWeight="medium"
                    gutterBottom
                  >
                    Form Status
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 1.5 }}>
                    <Chip
                      label={
                        formDetail.status === "completed"
                          ? "Completed"
                          : formDetail.status === "in_progress"
                          ? "In Progress"
                          : "Pending"
                      }
                      color={
                        formDetail.status === "completed"
                          ? "success"
                          : formDetail.status === "in_progress"
                          ? "primary"
                          : "warning"
                      }
                      size="small"
                      sx={{ mr: 1.5 }}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {formDetail.status === "completed"
                        ? "Form has been completed by the patient"
                        : formDetail.status === "in_progress"
                        ? "Patient has started but not completed the form"
                        : "Form has been assigned but not started"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      position: "relative",
                      mt: 3,
                      mb: 1,
                      "&::before": {
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        right: 0,
                        height: 4,
                        bgcolor: "grey.200",
                        transform: "translateY(-50%)",
                        zIndex: 0,
                        borderRadius: 2,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        zIndex: 1,
                        flex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: "success.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          mb: 1,
                        }}
                      >
                        1
                      </Box>
                      <Typography variant="caption" align="center">
                        Assigned
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align="center"
                      >
                        {formDetail.created_timestamp
                          ? formatDate(formDetail.created_timestamp)
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        zIndex: 1,
                        flex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: formDetail.start_timestamp
                            ? "success.main"
                            : "grey.400",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          mb: 1,
                        }}
                      >
                        2
                      </Box>
                      <Typography variant="caption" align="center">
                        Started
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align="center"
                      >
                        {formDetail.start_timestamp
                          ? formatDate(formDetail.start_timestamp)
                          : "-"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        zIndex: 1,
                        flex: 1,
                      }}
                    >
                      <Box
                        sx={{
                          width: 32,
                          height: 32,
                          borderRadius: "50%",
                          bgcolor: formDetail.end_timestamp
                            ? "success.main"
                            : "grey.400",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          color: "white",
                          mb: 1,
                        }}
                      >
                        3
                      </Box>
                      <Typography variant="caption" align="center">
                        Completed
                      </Typography>
                      <Typography
                        variant="caption"
                        color="text.secondary"
                        align="center"
                      >
                        {formDetail.end_timestamp
                          ? formatDate(formDetail.end_timestamp)
                          : "-"}
                      </Typography>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>

            {formDetail.status !== "completed" && (
              <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<ContentCopyIcon />}
                  onClick={handleCopyLink}
                  sx={{
                    borderRadius: "8px",
                    textTransform: "none",
                    px: 4,
                    py: 1.5,
                    fontSize: "1rem",
                  }}
                >
                  Copy Form Link for {subjectLanguage}
                </Button>
              </Box>
            )}

            <Divider sx={{ mb: 4 }} />

            {formDetail.status === "completed" ? (
              <FormDetailView
                formDefinition={formDetail.form_definition}
                submissionData={formDetail.submission_data}
              />
            ) : (
              <Box sx={{ py: 4, textAlign: "center" }}>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                  This form has not been completed yet.
                </Typography>
                <Typography variant="body1" color="text.secondary">
                  Once the {subjectLanguage} completes the form, their responses
                  will appear here.
                </Typography>
              </Box>
            )}
          </Box>
        ) : (
          <Typography color="error" align="center" sx={{ py: 4 }}>
            Form not found
          </Typography>
        )}
      </Paper>

      <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
        <Button
          variant="outlined"
          onClick={handleBackToPatient}
          startIcon={<ArrowBackIcon />}
        >
          Back to Previous Page
        </Button>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default FormDetailFullView;
