import React, { useState, useEffect, useCallback } from "react";
import {
  Calendar,
  momentLocalizer,
  Views,
  Event,
  SlotInfo,
} from "react-big-calendar";
import withDragAndDrop, {
  EventInteractionArgs,
} from "react-big-calendar/lib/addons/dragAndDrop";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import {
  Box,
  Paper,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Popover,
  Fade,
} from "@mui/material";
import { useEncountersData } from "../../context/EncountersDataContext";
import { Encounter } from "../../types/types";
import { useUser } from "../../context/user";
import { encounterApi } from "../Encounter/encounters";
import { useNavigate } from "react-router-dom";
import AddEncounterModal from "../Encounter/AddEncounterModal";

// Add custom styles for the calendar
const calendarStyles = {
  ".rbc-time-view": {
    border: "1px solid #ddd",
    borderRadius: "4px",
    overflow: "auto",
  },
  ".rbc-time-content": {
    overflowY: "auto",
    maxHeight: "calc(80vh - 170px)",
  },
  ".rbc-time-header": {
    backgroundColor: "#f5f5f5",
  },
  ".rbc-day-slot .rbc-time-slot": {
    borderTop: "1px solid #f0f0f0",
  },
  ".rbc-timeslot-group": {
    minHeight: "60px",
  },
  ".rbc-event": {
    borderRadius: "4px",
    boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
    padding: "4px",
    transition: "all 0.2s ease",
  },
  ".rbc-event--highlighted": {
    boxShadow: "0 0 0 2px #ffeb3b, 0 4px 10px rgba(0,0,0,0.25) !important",
    zIndex: 10,
    transform: "scale(1.05)",
  },
  // Add styles specific to month view
  ".rbc-month-view": {
    height: "100%",
    minHeight: "600px", // Ensure minimum height for month view
    flex: 1,
  },
  ".rbc-month-row": {
    minHeight: "100px", // Ensure rows have enough space
  },
};

// Setup the localizer for the calendar
const localizer = momentLocalizer(moment);

interface CalendarEvent extends Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
  resource: Encounter;
}

// Extend the DnDCalendar to include the onDoubleClickSlot prop
interface ExtendedDnDCalendar {
  onDoubleClickSlot?: (slotInfo: SlotInfo) => void;
}

// Create a drag and drop enabled calendar with proper typing
const DnDCalendar = withDragAndDrop<CalendarEvent, object>(Calendar);

const DEFAULT_ENCOUNTER_DURATION = 30; // Minutes

const CalendarWidget: React.FC = () => {
  // Get encounters data from context
  const {
    allEncounterData,
    isLoading,
    isBackgroundLoading,
    viewMode,
    setViewMode,
    currentDate,
    setCurrentDate,
    refreshEncounters,
    hoveredEncounterId,
    setHoveredEncounterId,
  } = useEncountersData();

  const navigate = useNavigate();

  // Get user context for API authentication
  const { getAccessToken } = useUser();

  const [events, setEvents] = useState<CalendarEvent[]>([]);

  // Add states for hover functionality
  const [hoveredEvent, setHoveredEvent] = useState<CalendarEvent | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  // Set initial time to scroll to
  const scrollToTime = new Date();
  scrollToTime.setHours(scrollToTime.getHours() - 3, 0, 0); // 2 hours before current time

  // State for AddEncounterModal
  const [isAddEncounterModalOpen, setIsAddEncounterModalOpen] = useState(false);
  const [selectedDateTime, setSelectedDateTime] = useState<Date | undefined>(
    undefined
  );
  const [selectedDuration, setSelectedDuration] = useState<number | undefined>(
    undefined
  );

  // Convert encounters to calendar events when encounters data changes
  useEffect(() => {
    const calendarEvents = allEncounterData
      .filter((encounter) => Boolean(encounter.scheduled_for)) // Only include encounters with scheduled_for
      .map((encounter) => {
        // TypeScript now knows scheduled_for can't be null or undefined in this scope
        const startTime = new Date(encounter.scheduled_for as string);

        // Calculate end time based on scheduled_duration or default
        const endTime = new Date(startTime);

        // If scheduled_duration exists use it, otherwise use default duration
        const durationMinutes =
          encounter.scheduled_duration || DEFAULT_ENCOUNTER_DURATION;
        endTime.setMinutes(endTime.getMinutes() + durationMinutes);

        return {
          id: encounter.encounter_id,
          title: getEncounterTitle(encounter),
          start: startTime,
          end: endTime,
          resource: encounter,
        };
      });

    setEvents(calendarEvents);
  }, [allEncounterData]);

  // Helper function to get appropriate title for the event
  const getEncounterTitle = (encounter: Encounter): string => {
    if (encounter.patient) {
      return `${encounter.patient.first_name} ${encounter.patient.last_name}`;
    } else if (encounter.note_title) {
      return encounter.note_title;
    } else {
      return `${encounter.encounter_type || "Encounter"}`;
    }
  };

  // Handle calendar view change - this is now handled by CalendarNavigation component
  const handleViewChange = useCallback(
    (newView: string) => {
      setViewMode(newView as any);
    },
    [setViewMode]
  );

  // Handle calendar date navigation - this is now handled by CalendarNavigation component
  const handleNavigate = useCallback(
    (newDate: Date) => {
      setCurrentDate(newDate);
    },
    [setCurrentDate]
  );

  // Handle event movement (reschedule)
  const moveEvent = useCallback(
    async ({ event, start, end }: EventInteractionArgs<CalendarEvent>) => {
      try {
        // Calculate new duration in minutes
        const newStartTime = new Date(start);
        const newEndTime = new Date(end);
        const durationMinutes = Math.round(
          (newEndTime.getTime() - newStartTime.getTime()) / (1000 * 60)
        );

        // Log the change
        console.log("Event moved:", event.id, "New time:", {
          start: newStartTime.toISOString(),
          duration: durationMinutes,
        });

        // Update local state first for immediate feedback (optimistic update)
        const updatedEvents = events.map((existingEvent) => {
          if (existingEvent.id === event.id) {
            return {
              ...existingEvent,
              start: new Date(start),
              end: new Date(end),
            };
          }
          return existingEvent;
        });

        setEvents(updatedEvents);

        // Also optimistically update the encounter in the allEncounterData
        // This ensures the change will be visible immediately in all components
        const updatedEncounter = {
          ...event.resource,
          scheduled_for: newStartTime.toISOString(),
          scheduled_duration: durationMinutes,
        };

        // Call the API to update the encounter's scheduled_for and scheduled_duration
        const accessToken = await getAccessToken();

        await encounterApi.updateEncounter(
          event.id,
          {
            scheduled_for: newStartTime.toISOString(),
            scheduled_duration: durationMinutes,
          },
          accessToken
        );

        // Quietly refresh encounters in the background to get the updated data
        // Use the new quietRefresh option to avoid full loading spinner
        await refreshEncounters({
          forceFullLoading: false,
          quietRefresh: true,
        });
      } catch (error) {
        console.error("Error updating encounter schedule:", error);
        // If there's an error, do a full refresh to ensure the UI is in sync with server
        await refreshEncounters({
          forceFullLoading: true,
          quietRefresh: false,
        });
      }
    },
    [events, refreshEncounters, getAccessToken]
  );

  // Handle event resize
  const resizeEvent = useCallback(
    async ({ event, start, end }: EventInteractionArgs<CalendarEvent>) => {
      try {
        // Calculate new duration in minutes
        const newStartTime = new Date(start);
        const newEndTime = new Date(end);
        const durationMinutes = Math.round(
          (newEndTime.getTime() - newStartTime.getTime()) / (1000 * 60)
        );

        // Log the resize operation
        console.log("Event resized:", event.id, "New duration:", {
          start: newStartTime.toISOString(),
          duration: durationMinutes,
        });

        // Update local state first for immediate feedback (optimistic update)
        const updatedEvents = events.map((existingEvent) => {
          if (existingEvent.id === event.id) {
            return {
              ...existingEvent,
              start: new Date(start),
              end: new Date(end),
            };
          }
          return existingEvent;
        });

        setEvents(updatedEvents);

        // Also optimistically update the encounter in the allEncounterData
        // This ensures the change will be visible immediately in all components
        const updatedEncounter = {
          ...event.resource,
          scheduled_for: newStartTime.toISOString(),
          scheduled_duration: durationMinutes,
        };

        // Call the API to update the encounter's scheduled_duration
        const accessToken = await getAccessToken();

        await encounterApi.updateEncounter(
          event.id,
          {
            scheduled_for: newStartTime.toISOString(),
            scheduled_duration: durationMinutes,
          },
          accessToken
        );

        // Quietly refresh encounters in the background to get the updated data
        // Use the new quietRefresh option to avoid full loading spinner
        await refreshEncounters({
          forceFullLoading: false,
          quietRefresh: true,
        });
      } catch (error) {
        console.error("Error updating encounter duration:", error);
        // If there's an error, do a full refresh to ensure the UI is in sync with server
        await refreshEncounters({
          forceFullLoading: true,
          quietRefresh: false,
        });
      }
    },
    [events, refreshEncounters, getAccessToken]
  );

  // Format date for display in the dialog and popover
  const formatDate = (date: Date) => {
    return moment(date).format("MMMM D, YYYY h:mm A");
  };

  // Handle mouse enter event
  const handleEventMouseEnter = useCallback(
    (event: CalendarEvent, e: React.MouseEvent<HTMLElement>) => {
      setHoveredEvent(event);
      setAnchorEl(e.currentTarget);
      setHoveredEncounterId(event.id);
    },
    [setHoveredEncounterId]
  );

  // Handle mouse leave event
  const handleEventMouseLeave = useCallback(() => {
    setHoveredEvent(null);
    setAnchorEl(null);
    setHoveredEncounterId(null);
  }, [setHoveredEncounterId]);

  const handleClickEvent = useCallback((event: CalendarEvent) => {
    navigate(`/encounters/${event.id}`);
  }, []);

  // Custom event component to enable hover
  const EventComponent = useCallback(
    ({ event, title }: { event: CalendarEvent; title: React.ReactNode }) => (
      <div
        onMouseEnter={(e) => handleEventMouseEnter(event, e)}
        onMouseLeave={handleEventMouseLeave}
        style={{ height: "100%", width: "100%" }}
      >
        {title}
      </div>
    ),
    [handleEventMouseEnter, handleEventMouseLeave]
  );

  // Map viewMode to react-big-calendar Views
  const getBigCalendarView = () => {
    switch (viewMode) {
      case "day":
        return Views.DAY;
      case "week":
        return Views.WEEK;
      case "month":
        return Views.MONTH;
      default:
        return Views.WEEK;
    }
  };

  // Handle selection slot (click and drag will capture start and end times)
  const handleSelectSlot = useCallback((slotInfo: SlotInfo) => {
    // Set the start time
    setSelectedDateTime(slotInfo.start);

    // Calculate duration in minutes if there's a difference between start and end
    if (slotInfo.start && slotInfo.end) {
      const durationMinutes = Math.round(
        (slotInfo.end.getTime() - slotInfo.start.getTime()) / (1000 * 60)
      );

      // Only set duration if it's actually a drag selection (more than a few minutes)
      if (durationMinutes > 5) {
        setSelectedDuration(durationMinutes);
      } else {
        setSelectedDuration(undefined);
      }
    }

    setIsAddEncounterModalOpen(true);
  }, []);

  // Handle close of AddEncounterModal
  const handleCloseAddEncounterModal = useCallback(() => {
    setIsAddEncounterModalOpen(false);
    setSelectedDateTime(undefined);
    setSelectedDuration(undefined);
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "0.75rem",
        border: "1px solid",
        borderColor: "borderColors.primary",
        overflow: "hidden", // Ensure content doesn't overflow the container
        position: "relative",
      }}
    >
      {/* Background loading indicator */}
      {isBackgroundLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 102,
            p: 1.5,
            borderRadius: 1,
            bgcolor: "background.paper",
            boxShadow: 2,
            display: "flex",
            alignItems: "center",
            border: "1px solid",
            borderColor: "primary.light",
          }}
        >
          <CircularProgress size={16} sx={{ color: "primary.main" }} />
        </Box>
      )}

      <Box
        sx={{
          position: "relative",
          flex: 1,
          height: viewMode === "month" ? "calc(100vh - 240px)" : "auto", // Adjust height specifically for month view
          overflow: "hidden",
          ".rbc-calendar": { height: "100%" },
          ...calendarStyles,
        }}
      >
        <DnDCalendar
          localizer={localizer}
          events={events}
          defaultView={getBigCalendarView()}
          view={getBigCalendarView()}
          views={[Views.DAY, Views.WEEK, Views.MONTH]}
          step={15}
          showMultiDayTimes
          date={currentDate}
          onNavigate={handleNavigate}
          onView={handleViewChange}
          scrollToTime={scrollToTime}
          onSelectEvent={handleClickEvent}
          onEventDrop={moveEvent}
          onEventResize={resizeEvent}
          selectable={true}
          onSelectSlot={handleSelectSlot}
          resizable={true}
          style={{ height: "100%" }}
          timeslots={2}
          dayLayoutAlgorithm="no-overlap"
          toolbar={false} // Hide the default toolbar since we have our own navigation
          components={{
            event: EventComponent,
          }}
          eventPropGetter={(event: CalendarEvent) => {
            // Set different colors based on encounter status
            let backgroundColor = "#3174ad"; // default
            if (event.resource.status === "completed") {
              backgroundColor = "#4caf50";
            } else if (event.resource.status === "processing") {
              backgroundColor = "#ff9800";
            } else if (event.resource.status === "pending") {
              backgroundColor = "#2196f3";
            }

            // Apply highlight styling if this event is being hovered in the list
            const isHighlighted = hoveredEncounterId === event.id;

            return {
              style: {
                backgroundColor,
                transform: isHighlighted ? "scale(1.05)" : undefined,
                boxShadow: isHighlighted
                  ? "0 0 0 2px #ffeb3b, 0 4px 10px rgba(0,0,0,0.25)"
                  : undefined,
                zIndex: isHighlighted ? 10 : undefined,
              },
              className: isHighlighted ? "rbc-event--highlighted" : undefined,
            };
          }}
        />

        {/* Loading overlay with blur effect - only show for initial/full loading */}
        {isLoading && (
          <Fade in={isLoading}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                backdropFilter: "blur(2px)",
                zIndex: 100,
              }}
            >
              <CircularProgress size={60} />
            </Box>
          </Fade>
        )}
      </Box>

      {/* Hover Popover for Encounter Details */}
      <Popover
        open={Boolean(anchorEl) && Boolean(hoveredEvent)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handleEventMouseLeave}
        disableRestoreFocus
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            padding: 2,
            maxWidth: 300,
            boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
          },
        }}
      >
        {hoveredEvent && (
          <Box>
            <Typography variant="h6" gutterBottom>
              {hoveredEvent.title}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Start:</strong> {formatDate(hoveredEvent.start)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>End:</strong> {formatDate(hoveredEvent.end)}
            </Typography>
            <Typography variant="body2" gutterBottom>
              <strong>Type:</strong>{" "}
              {hoveredEvent.resource.encounter_type || "N/A"}
            </Typography>
            {hoveredEvent.resource.patient && (
              <Typography variant="body2" gutterBottom>
                <strong>Patient:</strong>{" "}
                {`${hoveredEvent.resource.patient.first_name} ${hoveredEvent.resource.patient.last_name}`}
              </Typography>
            )}
            {hoveredEvent.resource.inputs && (
              <Typography variant="body2" gutterBottom>
                <strong>Documents:</strong>{" "}
                {hoveredEvent.resource.inputs.length}
              </Typography>
            )}
          </Box>
        )}
      </Popover>

      {/* Add Encounter Modal */}
      <AddEncounterModal
        open={isAddEncounterModalOpen}
        onClose={handleCloseAddEncounterModal}
        initialScheduledTime={selectedDateTime}
        initialDuration={selectedDuration}
      />
    </Paper>
  );
};

export default CalendarWidget;
