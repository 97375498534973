import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  KeyboardEvent,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Autocomplete,
  TextField,
  Typography,
  Box,
  useMediaQuery,
  Theme,
  InputAdornment,
  IconButton,
  Chip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { debounce } from "lodash";
import { Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import NewPatientModal from "./NewPatientModal";
import { capitalize } from "../../utils/utils";

interface PatientOption {
  patient_id: string;
  label: string;
  patient: Patient;
}

interface PatientPickerProps {
  onPatientSelect: (patient: Patient | undefined) => void;
  initialPatient?: Patient;
  onOpen?: () => void;
  autoFocus?: boolean;
}

// Export a type for the ref
export interface PatientPickerRef {
  focusInput: () => void;
}

const PatientPicker = forwardRef<PatientPickerRef, PatientPickerProps>(
  ({ onPatientSelect, initialPatient, onOpen, autoFocus = false }, ref) => {
    const { getAccessToken, subjectLanguage } = useUser();
    const [options, setOptions] = useState<PatientOption[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [newPatientModalOpen, setNewPatientModalOpen] = useState(false);
    const [selectedPatient, setSelectedPatient] =
      useState<PatientOption | null>(
        initialPatient
          ? {
              patient_id: initialPatient.patient_id,
              label: `${initialPatient.first_name} ${initialPatient.last_name}`,
              patient: initialPatient,
            }
          : null
      );
    const [inputValue, setInputValue] = useState("");
    const [hasInitialFetch, setHasInitialFetch] = useState(false);
    const [currentSearchText, setCurrentSearchText] = useState("");

    const isMobile = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down("sm")
    );

    const shouldPickNewPatient = useRef(false);

    const autocompleteRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    // Expose the focusInput method through the ref
    useImperativeHandle(ref, () => ({
      focusInput: () => {
        // Find the input element inside the autocomplete and focus it
        const inputElement =
          inputRef.current ||
          (autocompleteRef.current?.querySelector(
            "input"
          ) as HTMLInputElement | null);

        if (inputElement) {
          inputElement.focus();
        }
      },
    }));

    // Focus the input when autoFocus prop is true
    useEffect(() => {
      if (autoFocus) {
        setTimeout(() => {
          const inputElement =
            inputRef.current ||
            (autocompleteRef.current?.querySelector(
              "input"
            ) as HTMLInputElement | null);

          if (inputElement) {
            inputElement.focus();
          }
        }, 100);
      }
    }, [autoFocus]);

    const fetchPatients = useCallback(
      async (searchTerm: string = "") => {
        setLoading(true);
        try {
          const accessToken = await getAccessToken();
          const endpoint = searchTerm
            ? `/patient/search?q=${encodeURIComponent(
                searchTerm
              )}&page=1&per_page=5`
            : "/patient/list?page=1&per_page=5";
          const response = await APIService.makeAPIGetRequest({
            requestString: endpoint,
            accessToken,
          });
          if (response.ok) {
            const data = await response.value;
            const patientOptions: PatientOption[] = data.patients.map(
              (patient: Patient) => ({
                patient_id: patient.patient_id,
                label: `${patient.first_name} ${patient.last_name}`,
                patient,
              })
            );

            const createNewOption = searchTerm
              ? [
                  {
                    patient_id: "add_new",
                    label: `Create new ${subjectLanguage} "${searchTerm}"`,
                    patient: {} as Patient,
                  },
                ]
              : [
                  {
                    patient_id: "add_new",
                    label: `Add New ${capitalize(subjectLanguage)}`,
                    patient: {} as Patient,
                  },
                ];

            // If there are matching patients and we have a search term, show them first
            if (searchTerm && patientOptions.length > 0) {
              setOptions([...patientOptions, ...createNewOption]);
            } else {
              // Otherwise, show "Create new" first (when no matches or no search term)
              setOptions([...createNewOption, ...patientOptions]);
            }

            if (shouldPickNewPatient.current && patientOptions.length > 0) {
              const firstPatient = patientOptions[0];
              setSelectedPatient(firstPatient);
              onPatientSelect(firstPatient.patient);
              shouldPickNewPatient.current = false;
            }
          }
        } catch (error) {
          console.error("Error fetching patients:", error);
        } finally {
          setLoading(false);
        }
      },
      [getAccessToken, onPatientSelect]
    );

    // Create a custom hook for debounced functions
    const useDebounce = <T extends (...args: any[]) => any>(
      callback: T,
      delay: number
    ) => {
      const callbackRef = useRef<T>(callback);

      // Update ref when callback changes
      useEffect(() => {
        callbackRef.current = callback;
      }, [callback]);

      // Create a stable debounced function that reads from the ref
      const debouncedFunction = useRef(
        debounce((...args: Parameters<T>) => {
          callbackRef.current(...args);
        }, delay)
      );

      // Clean up on unmount
      useEffect(() => {
        return () => {
          debouncedFunction.current.cancel();
        };
      }, []);

      return debouncedFunction.current;
    };

    // Use our custom hook to create a debounced fetch function
    const debouncedFetchPatients = useDebounce(fetchPatients, 300);

    useEffect(() => {
      // When component mounts or dialog opens, ensure any pending debounced calls are canceled
      debouncedFetchPatients.cancel();

      // Clean up on component unmount
      return () => {
        debouncedFetchPatients.cancel();
      };
    }, [open, debouncedFetchPatients]);

    useEffect(() => {
      if (open && !hasInitialFetch) {
        fetchPatients();
        setHasInitialFetch(true);
      }
    }, [open, hasInitialFetch, fetchPatients]);

    useEffect(() => {
      if (initialPatient && !selectedPatient) {
        setSelectedPatient({
          patient_id: initialPatient.patient_id,
          label: `${initialPatient.first_name} ${initialPatient.last_name}`,
          patient: initialPatient,
        });
      } else if (!initialPatient) {
        setSelectedPatient(null);
        setInputValue("");
      }
    }, [initialPatient]);

    const handleInputChange = (
      event: React.ChangeEvent<{}>,
      value: string,
      reason: string
    ) => {
      setInputValue(value);

      // Only trigger search when user is actually typing
      if (reason === "input") {
        setCurrentSearchText(value);

        // Cancel any pending debounced calls to avoid race conditions
        debouncedFetchPatients.cancel();

        if (value.trim() !== "") {
          // Use the debounced search for non-empty queries
          debouncedFetchPatients(value);
        } else {
          // If the input is cleared, fetch the default patient list immediately
          fetchPatients();
        }
      }
    };

    const handleChange = (
      event: React.ChangeEvent<{}>,
      value: PatientOption | null
    ) => {
      if (value) {
        if (value.patient_id === "add_new") {
          setNewPatientModalOpen(true);
          return;
        } else {
          setSelectedPatient(value);
          onPatientSelect(value.patient);
          setInputValue(value.label);
          setCurrentSearchText("");
          // Don't disable searching here
        }
      } else {
        setSelectedPatient(null);
        onPatientSelect(undefined);
        setInputValue("");
        setCurrentSearchText("");
        // Reset and fetch the default list
        fetchPatients();
      }
    };

    const handleNewPatientCreation = async (patientId: string) => {
      setNewPatientModalOpen(false);

      try {
        // Directly fetch the newly created patient by ID
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/patient/get?patient_id=${patientId}&origin=PatientPickerNewPatientCreation`,
          accessToken,
        });

        if (response.ok) {
          const newPatient = await response.value.patient;

          // Create the patient option with the new patient
          const newPatientOption: PatientOption = {
            patient_id: newPatient.patient_id,
            label: `${newPatient.first_name} ${newPatient.last_name}`,
            patient: newPatient,
          };

          // Set the selected patient to be the newly created one
          setSelectedPatient(newPatientOption);
          onPatientSelect(newPatient);
          setInputValue(newPatientOption.label);
          setCurrentSearchText("");

          // Also refresh the patient list to include the new patient
          await fetchPatients();
        } else {
          console.error("Error fetching newly created patient");
          // Fallback to the previous approach if direct fetch fails
          shouldPickNewPatient.current = true;
          await fetchPatients();
        }
      } catch (error) {
        console.error("Error selecting newly created patient:", error);
        // Fallback to the previous approach if direct fetch fails
        shouldPickNewPatient.current = true;
        await fetchPatients();
      }
    };

    // Handle Enter key press to select the first option
    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (
        event.key === "Enter" &&
        open &&
        options.length > 0 &&
        !event.shiftKey &&
        !event.ctrlKey
      ) {
        event.preventDefault();

        // Select the first option
        const firstOption = options[0];
        if (firstOption.patient_id === "add_new") {
          setNewPatientModalOpen(true);
        } else {
          setSelectedPatient(firstOption);
          onPatientSelect(firstOption.patient);
          setInputValue(firstOption.label);
          setCurrentSearchText("");
        }

        setOpen(false);
      }
    };

    return (
      <>
        <Autocomplete
          id="patient-picker"
          open={open}
          onOpen={() => {
            setOpen(true);
            if (onOpen) {
              onOpen();
            }
          }}
          onClose={() => setOpen(false)}
          options={options}
          loading={loading}
          value={selectedPatient}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleChange}
          getOptionLabel={(option) => option.label}
          ref={autocompleteRef}
          componentsProps={{
            popper: {
              placement: "bottom-start",
            },
          }}
          renderOption={(props, option, { index }) => (
            <Box
              component="li"
              {...props}
              key={option.patient_id}
              sx={{
                ...(option.patient_id === "add_new"
                  ? {
                      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      paddingBottom: "8px",
                      marginBottom: "4px",
                    }
                  : {}),
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {option.patient_id === "add_new" ? (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <AddIcon
                        fontSize="small"
                        sx={{
                          color: "primary.main",
                          marginRight: 1,
                        }}
                      />
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: "primary.main",
                        }}
                      >
                        {option.label}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ flexGrow: 1, marginRight: 2 }}>
                      {option.label}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {index === 0 && (
                    <Chip
                      label="Enter"
                      size="small"
                      variant="outlined"
                      icon={<KeyboardReturnIcon fontSize="small" />}
                      sx={{
                        height: "20px",
                        mr: 1,
                        fontSize: "0.7rem",
                        "& .MuiChip-icon": {
                          fontSize: "0.9rem",
                          marginLeft: "4px",
                          marginRight: "-4px",
                        },
                      }}
                    />
                  )}

                  {option.patient.identifier && (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      sx={{
                        flexShrink: 0,
                        maxWidth: isMobile ? "100px" : "none",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {isMobile
                        ? option.patient.identifier.substring(0, 10) + "..."
                        : option.patient.identifier}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Select ${capitalize(subjectLanguage)}`}
              onKeyDown={handleKeyDown}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    {!selectedPatient && (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          size="small"
                          sx={{
                            fontSize: "0.875rem",
                            padding: "4px",
                            marginRight: "4px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setNewPatientModalOpen(true);
                          }}
                          aria-label={`Add new ${subjectLanguage}`}
                          title={`Add new ${subjectLanguage}`}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    )}
                  </>
                ),
                inputRef: inputRef,
              }}
            />
          )}
          isOptionEqualToValue={(option, value) =>
            option?.patient_id === value?.patient_id
          }
          filterOptions={(x) => x}
        />
        <NewPatientModal
          open={newPatientModalOpen}
          onClose={() => setNewPatientModalOpen(false)}
          onSuccessfulSubmission={handleNewPatientCreation}
          simplifiedView={true}
          initialName={currentSearchText}
        />
      </>
    );
  }
);

export default PatientPicker;
