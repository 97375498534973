import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
  FormControlLabel,
  Checkbox,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
  Paper,
} from "@mui/material";
import {
  Mic,
  Stop,
  Settings,
  Warning,
  PlayArrow,
  Pause,
  Refresh,
  MicOff,
  Check,
  Close,
  Headphones,
  HeadsetOff,
  TabOutlined,
} from "@mui/icons-material";
import { useRecordingContext } from "../../context/RecordingContext";
import { useUIState } from "../../context/uiState";
import { useEncounter } from "./EncounterContext";
import { useAudioDataContext } from "../../context/AudioDataContext";
import { useSnackbar } from "notistack";
import { encounterApi } from "./encounters";
import { useUser } from "../../context/user";
import BaseBlock from "../Patient/Blocks/BaseBlock";

interface EncounterRecordingWidgetProps {
  isViewOnly?: boolean;
}

const DICTATION_THRESHOLD = 120; // 120 seconds = 2 minutes

const EncounterRecordingWidget: React.FC<EncounterRecordingWidgetProps> = ({
  isViewOnly = false,
}) => {
  const { getAccessToken } = useUser();
  const { dispatch, state: encounterState } = useEncounter();
  const {
    recordingStatus,
    initiateRecording,
    initiateEndRecording,
    pauseRecording,
    resumeRecording,
    elapsedTime,
    noAudioStatus,
    selectedMicrophone,
    setSelectedMicrophone,
    availableMicrophones,
    microphonePermissionGranted,
    checkMicrophonePermission,
    useSharedMedia,
    setUseSharedMedia,
    initiateReupload,
    currentTranscriptionSessionId,
    setShouldBeCleanedUp,
    allowRecorderControl,
    takeOverRecording,
    noteInformation,
    checkRecordingOwnership,
  } = useRecordingContext();

  const { state, showAlertBanner } = useUIState();
  const { platform, browser } = state;
  const theme = useTheme();

  const [settingsAnchor, setSettingsAnchor] = useState<null | HTMLElement>(
    null
  );
  const [showTakeoverDialog, setShowTakeoverDialog] = useState(false);
  const [isTakingOver, setIsTakingOver] = useState(false);

  const { audioData } = useAudioDataContext();

  const [recordingEndState, setRecordingEndState] = useState<
    "success" | "error" | null
  >(null);

  const { enqueueSnackbar } = useSnackbar();

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    if (hours > 0) {
      return `${hours.toString().padStart(2, "0")}:${mins
        .toString()
        .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    }
    return `${mins.toString().padStart(2, "0")}:${secs
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSettingsClick = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchor(event.currentTarget);
  };

  const handleStartRecording = async () => {
    const success = await initiateRecording();
    // We won't set the start time immediately anymore, since we don't know if it will be
    // a dictation or encounter recording until it ends
  };

  const handleRecordingEnd = async () => {
    const success = await initiateEndRecording();

    // Remove the recording_starter_id from localStorage
    localStorage.removeItem("recording_starter_id");

    // If it's a short recording, show error state
    if (elapsedTime < 5) {
      setRecordingEndState("error");
      setTimeout(() => {
        setRecordingEndState(null);
      }, 3000);
      return;
    }

    // Set the animation state based on success
    setRecordingEndState(success ? "success" : "error");

    // Reset the animation state after 3 seconds
    setTimeout(() => {
      setRecordingEndState(null);
    }, 3000);

    // Only proceed if the initial stop was successful
    if (success) {
      try {
        // Wait for the recording to be fully processed
        const checkInterval = setInterval(async () => {
          // Check if recording is ready to be submitted (all chunks uploaded)
          if (recordingStatus.readyToSubmit && elapsedTime >= 5) {
            clearInterval(checkInterval);

            if (currentTranscriptionSessionId) {
              // Create new document via API
              const accessToken = await getAccessToken();

              // Count existing recordings to get the next number
              const existingRecordings = encounterState.inputs.filter(
                (doc) => doc.type === "recording"
              ).length;

              const newRecording = {
                type: "recording" as const,
                title:
                  elapsedTime >= DICTATION_THRESHOLD
                    ? "Encounter Recording"
                    : `Dictation ${existingRecordings + 1}`,
                content: "Audio recording saved as context",
                transcription_session_id: currentTranscriptionSessionId,
              };

              // Create document through API
              const createdDoc = await encounterApi.createDocument(
                newRecording,
                accessToken
              );

              // Create document reference
              const docRef = {
                encounter_document_id: createdDoc.encounter_document_id,
                type: createdDoc.type,
                created_at: createdDoc.created_at,
              };

              // Add document reference to encounter
              dispatch({ type: "ADD_DOCUMENT", payload: docRef });

              // Set recording times if it's a full encounter recording
              if (elapsedTime >= DICTATION_THRESHOLD) {
                dispatch({
                  type: "SET_RECORDING_TIMES",
                  payload: {
                    startTime: new Date(Date.now() - elapsedTime * 1000),
                    endTime: new Date(),
                  },
                });
              }

              // Show success message
              enqueueSnackbar(
                elapsedTime >= DICTATION_THRESHOLD
                  ? "Encounter recording saved"
                  : "Dictation saved",
                { variant: "success" }
              );

              // Set the session to be cleaned up
              setShouldBeCleanedUp(true);
            }
          }

          // If there was an error uploading chunks
          if (recordingStatus.chunksToBeUploaded) {
            clearInterval(checkInterval);
            // Don't clean up - let the user retry the upload
          }
        }, 500);

        // Clear interval after 30 seconds to prevent infinite checking
        setTimeout(() => {
          clearInterval(checkInterval);
        }, 30000);
      } catch (error) {
        console.error("Failed to save recording:", error);
        enqueueSnackbar("Failed to save recording", { variant: "error" });
      }
    }
  };

  const handleTakeoverRecording = async () => {
    setIsTakingOver(true);
    try {
      const success = await takeOverRecording();
      if (!success) {
        showAlertBanner(
          "Failed to take over recording. Please try again.",
          "error"
        );
      } else {
        setShowTakeoverDialog(false);
      }
    } catch (error) {
      console.error("Error in takeOverRecording:", error);
      showAlertBanner(
        "An error occurred while taking over the recording.",
        "error"
      );
    } finally {
      // Wait a moment before allowing another attempt
      setTimeout(() => {
        setIsTakingOver(false);
      }, 500);
    }
  };

  // Check if the recording is owned by the current tab
  useEffect(() => {
    checkRecordingOwnership();
  }, [checkRecordingOwnership]);

  // Function to render the recording button
  const renderRecordingButton = () => {
    // If the recording is starting or stopping, show a spinner overlay on the button
    if (recordingStatus.isStarting || recordingStatus.isStopping) {
      return (
        <Box
          sx={{
            position: "relative",
            width: 90,
            height: 90,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton
            disabled
            sx={{
              width: 70,
              height: 70,
              borderRadius: "50%",
              backgroundColor: recordingStatus.isStarting
                ? "primary.main"
                : "error.main",
              color: "white",
              opacity: 0.8,
            }}
          >
            {recordingStatus.isStarting ? (
              <Mic sx={{ fontSize: 36 }} />
            ) : (
              <Stop sx={{ fontSize: 36 }} />
            )}
          </IconButton>
          <CircularProgress
            size={85}
            thickness={2}
            sx={{
              position: "absolute",
              color: recordingStatus.isStarting ? "primary.main" : "error.main",
              top: "50%",
              left: "50%",
              marginTop: "-42.5px",
              marginLeft: "-42.5px",
              zIndex: 1,
            }}
          />
        </Box>
      );
    }

    if (recordingStatus.isRecording) {
      // Simplified approach - use CSS variables for audio level indication
      // Map audio level to a simple 0-3 intensity scale
      const intensity =
        audioData > 50 ? 3 : audioData > 30 ? 2 : audioData > 10 ? 1 : 0;

      // Calculate the height percentage for the audio level indicator (0-100%)
      // Scale audioData (typically 0-100) to fill 0-80% of the button height
      // Keeping a min of 5% to always show a little bit of the level
      const audioLevelHeight = Math.min(80, Math.max(5, audioData * 0.8)) + "%";

      return (
        <Box
          sx={{
            position: "relative",
            width: 90,
            height: 90,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Single ring with CSS animation - only if not paused */}
          {!recordingStatus.recordingPaused && (
            <Box
              className={`recording-ring recording-intensity-${intensity}`}
              sx={{
                position: "absolute",
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                border: "2px solid rgba(211, 47, 47, 0.6)",
              }}
            />
          )}

          {/* Main Button */}
          <IconButton
            color="error"
            onClick={handleRecordingEnd}
            className={
              recordingStatus.recordingPaused
                ? ""
                : `recording-button recording-intensity-${intensity}`
            }
            sx={{
              width: 70,
              height: 70,
              borderRadius: "50%",
              backgroundColor: "error.main",
              color: "white",
              "&:hover": {
                backgroundColor: "error.dark",
              },
              zIndex: 2,
              position: "relative",
              overflow: "hidden", // Keep the audio level contained inside the button
              // If paused, use reduced opacity
              opacity: recordingStatus.recordingPaused ? 0.8 : 1,
            }}
          >
            {/* Audio level indicator - only show if not paused */}
            {!recordingStatus.recordingPaused && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: audioLevelHeight,
                  backgroundColor: "rgba(120, 0, 0, 0.5)", // Dark red with transparency
                  transition: "height 0.1s ease-out", // Smooth transition for height changes
                  zIndex: -1, // Place behind the icon
                }}
              />
            )}
            <Stop sx={{ fontSize: 36, position: "relative", zIndex: 1 }} />
          </IconButton>
        </Box>
      );
    } else if (microphonePermissionGranted === true) {
      return (
        <IconButton
          color="primary"
          onClick={handleStartRecording}
          disabled={recordingEndState !== null}
          sx={{
            width: 70,
            height: 70,
            borderRadius: "50%",
            backgroundColor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
            boxShadow: "0 0 20px rgba(63, 81, 181, 0.7)",
          }}
        >
          <Mic sx={{ fontSize: 36 }} />
        </IconButton>
      );
    } else {
      return (
        <IconButton
          color="primary"
          onClick={checkMicrophonePermission}
          sx={{
            width: 70,
            height: 70,
            borderRadius: "50%",
            backgroundColor: "grey.400",
            color: "white",
            "&:hover": {
              backgroundColor: "grey.500",
            },
          }}
        >
          <MicOff sx={{ fontSize: 36 }} />
        </IconButton>
      );
    }
  };

  // Function to render the pause/play button
  const renderPausePlayButton = () => {
    if (!recordingStatus.isRecording) return null;

    return recordingStatus.recordingPaused ? (
      <IconButton
        onClick={resumeRecording}
        sx={{
          width: 50,
          height: 50,
          borderRadius: "50%",
          backgroundColor: "background.paper",
          border: "2px solid",
          borderColor: "primary.main",
          color: "primary.main",
        }}
      >
        <PlayArrow />
      </IconButton>
    ) : (
      <IconButton
        onClick={pauseRecording}
        sx={{
          width: 50,
          height: 50,
          borderRadius: "50%",
          backgroundColor: "background.paper",
          border: "2px solid",
          borderColor: "primary.main",
          color: "primary.main",
        }}
      >
        <Pause />
      </IconButton>
    );
  };

  // If recording is active in another tab, render a simplified UI
  if (!allowRecorderControl) {
    return (
      <BaseBlock
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: 2,
          minHeight: "150px",
          position: "relative",
        }}
      >
        {/* Takeover Dialog */}
        <Dialog
          open={showTakeoverDialog}
          onClose={() => setShowTakeoverDialog(false)}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Recording in Another Tab</DialogTitle>
          <DialogContent>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" gutterBottom>
                This recording is being managed in another browser tab.
              </Typography>

              {noteInformation?.noteTitle && (
                <Box
                  sx={{
                    mt: 1,
                    mb: 1,
                    p: 1,
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  <Typography variant="body2" color="text.secondary">
                    Active Recording:
                  </Typography>
                  <Typography variant="subtitle1">
                    {noteInformation.noteTitle}
                  </Typography>
                </Box>
              )}

              <Typography variant="body1" paragraph>
                To avoid conflicts, recordings can only be controlled from the
                tab that started them.
              </Typography>

              <Typography variant="body2" color="error.main" gutterBottom>
                If you've lost access to the original tab, you can take over the
                recording in this tab, but this may cause conflicts.
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowTakeoverDialog(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isTakingOver}
              onClick={handleTakeoverRecording}
            >
              {isTakingOver ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Record in this tab"
              )}
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ textAlign: "center" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: 2,
            }}
          >
            <TabOutlined sx={{ mr: 1, color: "text.secondary" }} />
            <Typography variant="body2" color="text.secondary">
              This recording is being managed in another browser tab.{" "}
              <Typography
                component="span"
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => setShowTakeoverDialog(true)}
                variant="body2"
                color="text.secondary"
              >
                Options
              </Typography>
            </Typography>
          </Box>
        </Box>
      </BaseBlock>
    );
  }

  return (
    <BaseBlock
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        position: "relative",
        // Simplified CSS for recording animations
        "& .recording-ring": {
          animation: "expandRing 2s infinite",
        },
        "& .recording-button": {
          animation: "buttonPulse 2s infinite ease-in-out",
        },
        // Intensity levels
        "& .recording-intensity-0": {
          boxShadow: "0 0 15px rgba(211, 47, 47, 0.4)",
        },
        "& .recording-intensity-1": {
          boxShadow: "0 0 25px rgba(211, 47, 47, 0.5)",
        },
        "& .recording-intensity-2": {
          boxShadow: "0 0 40px rgba(211, 47, 47, 0.6)",
        },
        "& .recording-intensity-3": {
          boxShadow: "0 0 60px rgba(211, 47, 47, 0.7)",
        },
        // Animation keyframes
        "@keyframes buttonPulse": {
          "0%": {
            transform: "scale(1)",
          },
          "50%": {
            transform: "scale(1.03)",
          },
          "100%": {
            transform: "scale(1)",
          },
        },
        "@keyframes expandRing": {
          "0%": {
            transform: "scale(0.9)",
            opacity: 0.7,
          },
          "100%": {
            transform: "scale(1.5)",
            opacity: 0,
          },
        },
      }}
    >
      {/* Settings Menu */}
      <Menu
        anchorEl={settingsAnchor}
        open={Boolean(settingsAnchor)}
        onClose={() => setSettingsAnchor(null)}
      >
        {platform === "desktop" && (
          <>
            {availableMicrophones?.map((mic) => (
              <MenuItem
                key={mic.deviceId}
                selected={mic.deviceId === selectedMicrophone?.deviceId}
                onClick={() => {
                  setSelectedMicrophone(mic);
                  setSettingsAnchor(null);
                }}
              >
                {mic.label || `Microphone ${mic.deviceId}`}
              </MenuItem>
            ))}
          </>
        )}
      </Menu>

      {/* Timer and Settings Row */}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          padding: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "medium",
            fontFamily: "monospace",
            letterSpacing: 1,
            color: recordingStatus.isRecording
              ? "primary.main"
              : "text.secondary",
          }}
        >
          {formatTime(elapsedTime)}
        </Typography>

        <Box sx={{ display: "flex", gap: 1 }}>
          <IconButton
            size="small"
            onClick={handleSettingsClick}
            sx={{ color: "primary.main" }}
          >
            <Settings />
          </IconButton>
          <Tooltip
            title={
              useSharedMedia
                ? "Headphones mode (click to disable)"
                : "Headphones mode (click to enable)"
            }
          >
            <IconButton
              size="small"
              onClick={() => setUseSharedMedia(!useSharedMedia)}
              sx={{
                color: useSharedMedia ? "primary.main" : "text.secondary",
                opacity: useSharedMedia ? 1 : 0.7,
              }}
            >
              {useSharedMedia ? <Headphones /> : <HeadsetOff />}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      {/* Recording Controls Row */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          width: "100%",
          position: "relative",
          mt: 2.5, // Add margin-top to push controls down below the timer row
          mb: 2.5,
        }}
      >
        {/* Empty left space for symmetry */}
        <Box
          sx={{
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />

        {/* Main Recording Button */}
        {renderRecordingButton()}

        {/* Pause/Play Button always on the right side */}
        <Box
          sx={{
            width: 50,
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {recordingStatus.isRecording && renderPausePlayButton()}
        </Box>
      </Box>

      {/* Warnings and Status */}
      {noAudioStatus && (
        <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
          <Warning color="warning" fontSize="small" sx={{ mr: 0.5 }} />
          <Typography variant="caption" color="warning.main">
            No audio detected
          </Typography>
        </Box>
      )}

      {recordingStatus.chunksToBeUploaded && (
        <Tooltip title="Retry Upload">
          <IconButton color="primary" onClick={initiateReupload} size="small">
            <Refresh />
          </IconButton>
        </Tooltip>
      )}
    </BaseBlock>
  );
};

export default EncounterRecordingWidget;
