import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { TutorialPrompt } from "./TutorialPrompt";
import { trackEvent } from "../../utils/analytics_utils";
import { useGlobalTutorial } from "./GlobalTutorialContext";
import { Box } from "@mui/material";

interface TutorialContextType {
  isActive: boolean;
  currentStep: number;
  totalSteps: number;
  startTutorial: () => void;
  endTutorial: () => void;
  nextStep: () => void;
  previousStep: () => void;
}

const TutorialContext = createContext<TutorialContextType | undefined>(
  undefined
);

export const useTutorial = () => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error("useTutorial must be used within a TutorialProvider");
  }
  return context;
};

interface TutorialProviderProps {
  children: React.ReactNode;
  tutorialKey: string;
  totalSteps: number;
  tutorialDisabled?: boolean;
  onTutorialStart?: () => void;
  onTutorialEnd?: () => void;
}

export const TutorialProvider: React.FC<TutorialProviderProps> = ({
  children,
  tutorialKey,
  totalSteps,
  tutorialDisabled = false,
  onTutorialStart,
  onTutorialEnd,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [maxStepReached, setMaxStepReached] = useState(0);
  const { registerTutorial, unregisterTutorial } = useGlobalTutorial();

  useEffect(() => {
    if (tutorialDisabled) return;

    // FOR DEVELOPMENT: Set this to true to force tutorials to show regardless of localStorage
    const forceShowTutorial = false;

    // Uncomment this line to check localStorage
    const hasSeenTutorial = forceShowTutorial
      ? false
      : localStorage.getItem(`tutorial-${tutorialKey}`);

    // Only show tutorial if it hasn't been seen before
    if (!hasSeenTutorial) {
      setShowPrompt(true);
    }
  }, [tutorialKey, tutorialDisabled]);

  const handleAcceptTutorial = () => {
    setShowPrompt(false);
    setStartTime(new Date());
    if (onTutorialStart) {
      onTutorialStart();
    }
    setTimeout(() => {
      setIsActive(true);
    }, 100);
    localStorage.setItem(`tutorial-${tutorialKey}`, "seen");
  };

  const handleDeclineTutorial = () => {
    setShowPrompt(false);
    trackEvent({
      event: "tutorial_declined",
      tutorial_id: tutorialKey,
    });
    localStorage.setItem(`tutorial-${tutorialKey}`, "seen");
  };

  const startTutorial = useCallback(() => {
    setIsActive(true);
    setCurrentStep(0);
  }, []);

  // Register this tutorial with the global context
  useEffect(() => {
    if (!tutorialDisabled) {
      registerTutorial({
        key: tutorialKey,
        startTutorial,
      });
    }

    return () => {
      unregisterTutorial(tutorialKey);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tutorialKey, startTutorial, tutorialDisabled]);

  const endTutorial = useCallback(() => {
    if (startTime) {
      const duration = new Date().getTime() - startTime.getTime();
      trackEvent({
        event: "tutorial_completed",
        tutorial_id: tutorialKey,
        duration_ms: duration,
        max_step_reached: maxStepReached + 1,
        total_steps: totalSteps,
        completed_percentage: Math.round(
          ((maxStepReached + 1) / totalSteps) * 100
        ),
      });
    }
    if (onTutorialEnd) {
      onTutorialEnd();
    }
    setIsActive(false);
    setCurrentStep(0);
    setStartTime(null);
    setMaxStepReached(0);
  }, [startTime, maxStepReached, totalSteps, tutorialKey]);

  const nextStep = useCallback(() => {
    setCurrentStep((prev) => {
      const nextStep = prev < totalSteps - 1 ? prev + 1 : prev;
      setMaxStepReached((current) => Math.max(current, nextStep));
      return nextStep;
    });
  }, [totalSteps]);

  const previousStep = useCallback(() => {
    setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
  }, []);

  return (
    <TutorialContext.Provider
      value={{
        isActive,
        currentStep,
        totalSteps,
        startTutorial,
        endTutorial,
        nextStep,
        previousStep,
      }}
    >
      {/* Global overlay that appears when tutorial is active */}
      <Box
        id="global-tutorial-overlay"
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bgcolor: "rgba(0, 0, 0, 0.5)",
          zIndex: 1400,
          display: isActive ? "block" : "none",
          pointerEvents: "all",
        }}
      />
      <TutorialPrompt
        open={showPrompt}
        onAccept={handleAcceptTutorial}
        onDecline={handleDeclineTutorial}
        tutorialKey={tutorialKey}
      />
      {children}
    </TutorialContext.Provider>
  );
};
