import React from "react";
import { Stack, TextField, IconButton, Box } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import TranscriptViewer from "./TranscriptViewer";
import { useEncounter } from "./EncounterContext";
import { EncounterDocument, LocalDocumentState } from "../../types/types";

interface DocumentEditorProps {
  document: EncounterDocument | null;
  onClose: () => void;
  onUpdate: (id: string, updates: LocalDocumentState) => void;
  onDelete: (id: string) => void;
  isViewOnly?: boolean;
}

const DocumentEditor: React.FC<DocumentEditorProps> = ({
  document,
  onClose,
  onUpdate,
  onDelete,
  isViewOnly,
}) => {
  const { state } = useEncounter();

  if (!document) return null;

  const isRecording = document.type === "recording";

  // Use the shared local state
  const localState =
    state._localState.localDocumentState[document.encounter_document_id] || {};
  const content = localState.content ?? document.content;
  const title = localState.title ?? document.title;

  const handleContentChange = (newContent: string) => {
    if (!document) return;
    onUpdate(document.encounter_document_id, { content: newContent });
  };

  const handleTitleChange = (newTitle: string) => {
    if (!document) return;
    onUpdate(document.encounter_document_id, { title: newTitle });
  };

  const handleDelete = () => {
    onDelete(document.encounter_document_id);
    onClose();
  };

  return (
    <SlideSidePane
      open={document !== null}
      onClose={onClose}
      title={isViewOnly ? "View Document" : "Edit Document"}
      action={
        !isViewOnly && (
          <IconButton onClick={handleDelete} size="small">
            <DeleteIcon />
          </IconButton>
        )
      }
    >
      <Stack
        spacing={2}
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          fullWidth
          value={title}
          onChange={(e) => handleTitleChange(e.target.value)}
          placeholder="Document title..."
          variant="standard"
          disabled={isViewOnly}
          sx={{ mb: 2 }}
        />

        {isRecording ? (
          document.transcription_session_id && (
            <Box sx={{ flex: 1, minHeight: 0 }}>
              <TranscriptViewer
                transcriptionSessionId={document.transcription_session_id}
              />
            </Box>
          )
        ) : (
          <TextField
            multiline
            fullWidth
            value={content}
            onChange={(e) => handleContentChange(e.target.value)}
            placeholder="Enter note content..."
            disabled={isViewOnly}
            sx={{
              flex: 1,
              "& .MuiInputBase-root": {
                height: "100%",
                display: "flex",
                alignItems: "flex-start",
              },
              "& .MuiInputBase-input": {
                height: "100% !important",
                overflowY: "auto !important",
              },
            }}
            InputProps={{
              sx: {
                height: "100%",
                "& textarea": {
                  height: "100% !important",
                },
              },
            }}
          />
        )}
      </Stack>
    </SlideSidePane>
  );
};

export default DocumentEditor;
