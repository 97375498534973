import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  Tooltip,
  Typography,
  Collapse,
} from "@mui/material";
import Logo from "../Icons/Logo";
import { AccountCircle, Visibility, VisibilityOff } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { AdminSection, AvailableModule } from "../../types/types";
import { Draggable } from "react-beautiful-dnd";

import { useThemeContext } from "../../context/theme/context";
import { Close, DragIndicator, Star } from "@mui/icons-material";
import { HoverContent } from "../Shared/HoverContent";
import { getUserInfoFromLocalStorage } from "../../utils/LocalStorageUtils";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";

interface SectionBlockProps {
  section: AdminSection;
  handleClick?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const SectionBlock = ({
  section,
  handleClick,
  handleRevert,
  availableModules,
  templateModuleMap,
}: SectionBlockProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );

  return (
    <Card
      sx={{
        my: 1.5,
        borderColor: "borderColors.primary",
        border: "0.5px solid",
        borderRadius: "0.4cm",
        "&:hover": {
          boxShadow: 3,
        },
      }}
      onClick={(e) => {
        if (!e.defaultPrevented) {
          setShowPreview(false);
          if (handleClick) handleClick();
        }
      }}
    >
      <CardContent
        sx={{ py: 1, "&.MuiCardContent-root:last-child": { pb: 1 } }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box
            id="module-text"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              flexGrow: 1,
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography fontStyle="normal" fontWeight="700">
                {section.display_name}
              </Typography>
            </Box>
            {moduleInfo && (
              <Typography
                fontSize=".8rem"
                fontWeight="300"
                sx={{ color: "text.secondary" }}
              >
                {moduleInfo?.description || moduleInfo?.public_description}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Tooltip
              title={showPreview ? "Hide preview" : "Show preview"}
              placement="top"
              arrow
            >
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  setShowPreview(!showPreview);
                }}
                size="small"
              >
                {showPreview ? (
                  <VisibilityOff fontSize="small" />
                ) : (
                  <Visibility fontSize="small" />
                )}
              </IconButton>
            </Tooltip>
            {templateModuleMap && templateModuleMap[section.module_id] && (
              <Tooltip
                title="This section has been customized."
                placement="top"
                arrow
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    "&:hover .revert-button": {
                      display: "inherit",
                    },
                  }}
                >
                  <Star fontSize="small" />
                  <Typography fontSize={12}>Customized</Typography>
                  {handleRevert && (
                    <Button
                      variant="text"
                      className="revert-button"
                      sx={{ display: "none" }}
                      onClick={handleRevert}
                    >
                      <Typography fontSize={10}>Revert?</Typography>
                    </Button>
                  )}
                </Box>
              </Tooltip>
            )}
            {moduleInfo?.is_custom_user_module ? (
              <Tooltip
                title="This is your customized section."
                placement="top"
                arrow
              >
                <Avatar
                  variant="circular"
                  src={getUserInfoFromLocalStorage()?.img}
                  alt="user photo"
                  sx={{ width: 25, height: 25 }}
                />
              </Tooltip>
            ) : (
              <Tooltip title="This is a brand section." placement="top" arrow>
                <Box>
                  <Logo size={22} />
                </Box>
              </Tooltip>
            )}
          </Box>
        </Box>
        <Collapse in={showPreview} sx={{ mt: 2 }}>
          <NoteSectionDisplayBox>
            {moduleInfo?.example ||
              moduleInfo?.public_example ||
              "No example provided."}
          </NoteSectionDisplayBox>
        </Collapse>
      </CardContent>
    </Card>
  );
};

interface DraggableSectionBlockProps {
  section: AdminSection;
  index: number;
  handleClick?: () => void;
  handleRemove?: () => void;
  handleRevert?: () => void;
  availableModules?: AvailableModule[];
  templateModuleMap?: { [key: string]: string };
}

export const DraggableSectionBlock = ({
  section,
  index,
  handleClick,
  handleRemove,
  handleRevert,
  availableModules,
  templateModuleMap,
}: DraggableSectionBlockProps) => {
  const [showPreview, setShowPreview] = useState(false);
  const moduleInfo = availableModules?.find(
    (module) => module.module_id === section.module_id
  );
  const { brandLanguage } = useThemeContext();
  const userInfo = getUserInfoFromLocalStorage();

  return (
    <Draggable
      key={section.module_id}
      draggableId={section.module_id}
      index={index}
    >
      {(provided) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          sx={{
            my: 1.5,
            borderColor: "borderColors.primary",
            border: "0.5px solid",
            borderRadius: "0.4cm",
            "&:hover": {
              boxShadow: 3,
            },
          }}
          onClick={(e) => {
            if (!e.defaultPrevented) {
              setShowPreview(false);
              if (handleClick) handleClick();
            }
          }}
        >
          <CardContent
            sx={{ py: 1, "&.MuiCardContent-root:last-child": { pb: 1 } }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                id="drag-handle"
                {...provided.dragHandleProps}
                sx={{ marginLeft: -1, marginRight: 0.5 }}
              >
                <DragIndicator />
              </Box>
              <Box
                id="module-text"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  flexGrow: 1,
                  gap: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Typography
                    fontStyle="normal"
                    fontWeight="700"
                    sx={{
                      cursor: "pointer",
                      display: "inline-block",
                      my: 0.2,
                    }}
                  >
                    {section.display_name}
                  </Typography>
                </Box>
                {moduleInfo && (
                  <Typography
                    fontSize=".8rem"
                    fontWeight="300"
                    sx={{ color: "text.secondary" }}
                  >
                    {moduleInfo?.description || moduleInfo?.public_description}
                  </Typography>
                )}
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Tooltip
                  title={showPreview ? "Hide preview" : "Show preview"}
                  placement="top"
                  arrow
                >
                  <IconButton
                    onClick={(e) => {
                      e.preventDefault();
                      setShowPreview(!showPreview);
                    }}
                    size="small"
                  >
                    {showPreview ? (
                      <VisibilityOff fontSize="small" />
                    ) : (
                      <Visibility fontSize="small" />
                    )}
                  </IconButton>
                </Tooltip>
                {moduleInfo?.is_custom_user_module ? (
                  <Tooltip
                    title="This is your customized section."
                    placement="top"
                    arrow
                  >
                    <Avatar
                      variant="circular"
                      src={userInfo?.img}
                      alt="user photo"
                      sx={{ width: 25, height: 25 }}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={`This is a ${brandLanguage.brandName} section.`}
                    placement="top"
                    arrow
                  >
                    <Box>
                      <Logo size={22} />
                    </Box>
                  </Tooltip>
                )}
                {templateModuleMap && templateModuleMap[section.module_id] && (
                  <Tooltip
                    title="This section has been customized."
                    placement="top"
                    arrow
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        "&:hover .revert-button": {
                          display: "inherit",
                        },
                      }}
                    >
                      <Star fontSize="small" />
                      <Typography fontSize={12}>Customized</Typography>
                      {handleRevert && (
                        <Button
                          variant="text"
                          className="revert-button"
                          sx={{ display: "none" }}
                          onClick={handleRevert}
                        >
                          <Typography fontSize={10}>Revert?</Typography>
                        </Button>
                      )}
                    </Box>
                  </Tooltip>
                )}
                <IconButton onClick={handleRemove} size="small">
                  <Close fontSize="small" />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={showPreview} sx={{ mt: 2 }}>
              <NoteSectionDisplayBox>
                {moduleInfo?.example ||
                  moduleInfo?.public_example ||
                  "No example provided."}
              </NoteSectionDisplayBox>
            </Collapse>
          </CardContent>
        </Card>
      )}
    </Draggable>
  );
};
