import React, { useEffect, useRef, useState } from "react";
import { Box, Paper, Button, Typography, Popper, Fade } from "@mui/material";
import { useTutorial } from "./TutorialContext";

interface TutorialStepProps {
  step: number;
  subSteps?: number[];
  description: string;
  children: React.ReactNode;
  highlightBox?: boolean;
  hideAfterStep?: boolean;
  fullHeight?: boolean;
  showOnce?: boolean;
  onDone?: () => void;
  placement?:
    | "top-start"
    | "top"
    | "top-end"
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "left-start"
    | "left"
    | "left-end"
    | "right-start"
    | "right"
    | "right-end";
}

export const TutorialStep: React.FC<TutorialStepProps> = ({
  step,
  subSteps,
  description,
  children,
  highlightBox = false,
  hideAfterStep = false,
  fullHeight = false,
  showOnce = false,
  onDone,
  placement = "top-start",
}) => {
  const {
    isActive,
    currentStep,
    nextStep,
    previousStep,
    endTutorial,
    totalSteps,
  } = useTutorial();
  const targetRef = useRef<HTMLDivElement>(null);
  const isCurrentStep = (isActive && currentStep === step) || showOnce;
  const isLastStep = step === totalSteps - 1;

  if (hideAfterStep && isActive && currentStep > step) {
    return null;
  }

  const handleNextOrFinish = () => {
    if (isLastStep) {
      endTutorial();
    } else {
      nextStep();
    }
  };

  return (
    <>
      <Box
        ref={targetRef}
        id="tutorial-step"
        sx={{
          backgroundColor: "background.paper",
          position: "relative",
          pointerEvents: isCurrentStep ? "none" : "all",
          ...(fullHeight
            ? {
                height: "100%",
                width: "100%",
                display: "flex",
                flex: 1,
                minHeight: 0,
                minWidth: 0,
              }
            : {
                height: "auto",
                width: "auto",
                display: "block",
                flex: "none",
              }),
          ...(isCurrentStep &&
            highlightBox && {
              outline: "2px solid",
              outlineColor: "primary.main",
              borderRadius: 1,
            }),
          zIndex: isCurrentStep ? 1450 : 0,
        }}
      >
        {children}
      </Box>
      <Popper
        open={isCurrentStep}
        anchorEl={targetRef.current}
        placement={placement}
        transition
        style={{ zIndex: 1500, pointerEvents: "all" }}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                p: 2,
                maxWidth: 300,
                border: "1px solid",
                borderColor: "primary.main",
                boxShadow: 3,
                animation: "float 2s ease-in-out infinite",
                "@keyframes float": {
                  "0%, 100%": {
                    transform: "translateY(0)",
                  },
                  "50%": {
                    transform: "translateY(-3px)",
                  },
                },
              }}
            >
              <Typography variant="body2" gutterBottom>
                {description}
              </Typography>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                {showOnce ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <Button size="small" variant="contained" onClick={onDone}>
                      Done
                    </Button>
                  </Box>
                ) : (
                  <>
                    <Button
                      size="small"
                      onClick={previousStep}
                      disabled={step === 0}
                    >
                      Previous
                    </Button>
                    {!isLastStep && (
                      <Button size="small" onClick={endTutorial}>
                        Skip
                      </Button>
                    )}
                    <Button
                      size="small"
                      variant="contained"
                      onClick={handleNextOrFinish}
                    >
                      {isLastStep ? "Finish" : "Next"}
                    </Button>
                  </>
                )}
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  );
};
