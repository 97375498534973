import { useAuth0 } from "@auth0/auth0-react";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { updateUserInfoToLocalStorage } from "../utils/LocalStorageUtils";
import { AdminSpecialty } from "../types/types";
import { AvailableSpecialtiesLoader } from "../loaders/SpecialtiesLoader";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
  styled,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { RoundedButton } from "../styles/CustomButtons";
import { useThemeContext } from "../context/theme/context";
import React from "react";
import { useUIState } from "../context/uiState";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import { classes } from "mui-tel-input";
import APIService from "../services/APIService";
import { useUser } from "../context/user";

type UserFormType = {
  firstName: string;
  lastName: string;
  email: string;
  institution: string;
  specialization: string;
  discovery_channel: string;
  discovery_channel_text: string;
  referral_code: string;
  mobile_phone_num: string;
  checked_sms_consent: boolean;
};

interface UserInfoFormProps {
  onSuccessfulFormSubmission?: () => void;
}

interface IDiscoveryOption {
  label: string;
  value: string;
}

const CustomMuiTelInput = styled(MuiTelInput)`
  // Custom padding for the input
  & input {
    padding-left: 0px;
  }
`;

// always puts 'other' as last option
const shuffleArray = (array: IDiscoveryOption[]): IDiscoveryOption[] => {
  const otherOption = array.find((option) => option.value === "other");
  const filteredArray = array.filter((option) => option.value !== "other");

  for (let i = filteredArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [filteredArray[i], filteredArray[j]] = [filteredArray[j], filteredArray[i]]; // ES6 destructuring swap
  }

  if (otherOption) {
    filteredArray.push(otherOption);
  }

  return filteredArray;
};

export const UserInfoForm = ({
  onSuccessfulFormSubmission,
}: UserInfoFormProps) => {
  const referralCodeRef = localStorage.getItem("referralCode");
  const [formInfo, setFormInfo] = useState<UserFormType>({
    firstName: "",
    lastName: "",
    email: "",
    institution: "",
    specialization: "",
    discovery_channel: "",
    discovery_channel_text: "",
    referral_code: referralCodeRef ? referralCodeRef : "",
    mobile_phone_num: "",
    checked_sms_consent: false,
  });

  const { getAccessToken } = useUser();

  // Define the options for the "How did you find us?" dropdown
  const discoveryOptions = [
    { label: "LinkedIn", value: "linkedin_ad" },
    { label: "Facebook / Instagram", value: "facebook_ad" },
    { label: "Google", value: "google_ad" },
    {
      label: "Friends / Colleagues",
      value: "friend_colleague",
    },
    { label: "Conference event", value: "conference_event" },
    { label: "Podcast", value: "podcast" },
    { label: "My employer", value: "part_of_group" },
    { label: "Newsletter", value: "newsletter" },
    { label: "Blog", value: "blog" },
    { label: "Other", value: "other" },
  ];

  const shuffledDiscoveryOptions = React.useMemo(
    () => shuffleArray([...discoveryOptions]),
    []
  );

  // would like to use these in future version, but need to change specialties on backend first
  // - 07/02/24
  // const specialtyOptions = [
  //   { label: "Psychiatry", value: "psychiatry" },
  //   { label: "Therapy", value: "therapy" },
  //   { label: "Psychology", value: "psychology" },
  //   { label: "Addiction Medicine", value: "addiction_medicine" },
  //   { label: "Palliative Care", value: "palliative" },
  //   { label: "Primary Care", value: "primary" },
  //   { label: "Integrated Medicine", value: "integrated" },
  //   { label: "Other", value: "other" },
  // ];

  const isOtherSelected = formInfo.discovery_channel === "other";
  const [availableSpecialties, setAvailableSpecialties] =
    useState<AdminSpecialty[]>();
  const [selectedSpecialty, setSelectedSpecialty] = useState<AdminSpecialty>();
  const { user } = useAuth0();
  const nameInputRef = useRef<HTMLInputElement>(null);
  const [noEmailOnLoad, setNoEmailOnLoad] = useState(!user?.email);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useUIState();
  const { platform } = state;
  const [discoverySourceOther, setDiscoverySourceOther] = useState(false);
  const [smsConsent, setSmsConsent] = useState(false);
  const [isMobilePhoneValid, setIsMobilePhoneValid] = useState(false);
  const { brand, brandLanguage } = useThemeContext();

  // States for referral code validation
  const [isValidatingReferral, setIsValidatingReferral] = useState(false);
  const [isReferralValid, setIsReferralValid] = useState<boolean | null>(null);
  const referralDebounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const [formInputErrors, setFormInputErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    referral_code: "",
    discovery_channel: "",
    discovery_channel_text: "",
    specialty: "",
    mobile_phone_num: "",
    checked_sms_consent: "",
  });

  const fetchSpecialtiesData = async () => {
    const specialties = await AvailableSpecialtiesLoader();
    const visibleSpecialties = specialties.filter(
      (specialty) => !specialty.hidden
    );
    setAvailableSpecialties(visibleSpecialties);

    // if (visibleSpecialties && visibleSpecialties.length > 0) {
    //   setSelectedSpecialty(visibleSpecialties[0]);
    // }
  };

  const handleSelectSpecialty = (event: SelectChangeEvent) => {
    if (availableSpecialties) {
      const selectedSpecialty = availableSpecialties.find(
        (specialty) => specialty.specialty_id === event.target.value
      );
      setSelectedSpecialty(selectedSpecialty);
    }
  };

  const validatePhoneNumber = (phoneNumber: string): boolean => {
    return matchIsValidTel(phoneNumber, {
      onlyCountries: [],
      excludedCountries: [],
      continents: [],
    });
  };

  const handlePhoneChange = (newValue: string) => {
    if (newValue.trim() === "") {
      setFormInputErrors((prev) => ({ ...prev, mobile_phone_num: "" }));
      setIsMobilePhoneValid(false);
      setSmsConsent(false);
      setFormInfo((prev) => ({ ...prev, checked_sms_consent: false }));
    } else if (newValue.trim() === "+1") {
      setFormInfo((prev) => ({ ...prev, mobile_phone_num: "" }));
      setIsMobilePhoneValid(false);
      setFormInputErrors((prev) => ({ ...prev, mobile_phone_num: "" }));
    } else {
      const isValid = validatePhoneNumber(newValue);
      setIsMobilePhoneValid(isValid);
      setFormInfo({ ...formInfo, mobile_phone_num: newValue });

      if (isValid) {
        setFormInputErrors((prev) => ({ ...prev, mobile_phone_num: "" }));
      }
    }
  };

  // Handle debounced referral code validation
  const handleReferralCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    setFormInfo({ ...formInfo, referral_code: code });

    // Clear any existing validation error
    setFormInputErrors((prev) => ({ ...prev, referral_code: "" }));

    // Clear previous timer
    if (referralDebounceTimerRef.current) {
      clearTimeout(referralDebounceTimerRef.current);
    }

    // If empty, reset validation state
    if (!code.trim()) {
      setIsReferralValid(null);
      return;
    }

    // Set validating state
    setIsValidatingReferral(true);

    // Set a new timer
    referralDebounceTimerRef.current = setTimeout(async () => {
      try {
        const result = await validateReferralCode(code);
        setIsReferralValid(!!result);
        if (!result) {
          setFormInputErrors((prev) => ({
            ...prev,
            referral_code: "Invalid referral code.",
          }));
        }
      } catch (error) {
        setIsReferralValid(false);
        setFormInputErrors((prev) => ({
          ...prev,
          referral_code: "Error validating referral code.",
        }));
      } finally {
        setIsValidatingReferral(false);
      }
    }, 1000); // 1 second debounce
  };

  const handlePhoneBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.target.value.trim() === "") {
      setFormInputErrors((prev) => ({ ...prev, mobile_phone_num: "" }));
      setSmsConsent(false);
      setFormInfo((prev) => ({ ...prev, checked_sms_consent: false }));
    } else {
      const phone_number = `+1${e.target.value}`;
      const isValid = validatePhoneNumber(phone_number);
      setIsMobilePhoneValid(isValid);
      setFormInfo({ ...formInfo, mobile_phone_num: phone_number });

      if (isValid) {
        setFormInputErrors((prev) => ({ ...prev, mobile_phone_num: "" }));
      } else {
        setFormInputErrors({
          ...formInputErrors,
          mobile_phone_num: isValid
            ? ""
            : "The phone number you entered is invalid.",
        });
      }
    }
  };

  const handleSmsConsentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setSmsConsent(isChecked);
    setFormInfo((prev) => ({
      ...prev,
      checked_sms_consent: isChecked,
    }));
  };

  const validateForm = async (
    formInfo: UserFormType,
    referredByCode: string
  ) => {
    let errors = {
      firstName: "",
      lastName: "",
      email: "",
      referral_code: "",
      discovery_channel: "",
      discovery_channel_text: "",
      specialty: "",
      mobile_phone_num: "",
      checked_sms_consent: "",
    };

    const trimmedFirstName = formInfo.firstName.trim();
    const trimmedLastName = formInfo.lastName.trim();
    const emailAddress = formInfo.email;
    const discoveryChannel = formInfo.discovery_channel;
    const discoveryChannelText = formInfo.discovery_channel_text.trim();
    let mobilePhoneNumber = formInfo.mobile_phone_num;

    let referred_by_user_id = "";

    if (!trimmedFirstName) {
      errors.firstName = "First name is required.";
    }
    if (!trimmedLastName) {
      errors.lastName = "Last name is required.";
    }
    if (!emailAddress) {
      errors.email = "Email is required.";
    }
    if (!discoveryChannel) {
      errors.discovery_channel = "Required Field";
    }

    if (mobilePhoneNumber) {
      if (mobilePhoneNumber != "+1") {
        if (
          !matchIsValidTel(mobilePhoneNumber, {
            onlyCountries: [],
            excludedCountries: [],
            continents: [],
          })
        ) {
          errors.mobile_phone_num = "The phone number you entered is invalid.";
        }
      } else if (mobilePhoneNumber === "+1") {
        setFormInfo({ ...formInfo, mobile_phone_num: "" });
      }

      if (!formInfo.checked_sms_consent) {
        errors.checked_sms_consent =
          "Please check the box below to confirm your consent to receive text messages. If you would not like to provide consent, please remove your phone number from the field above.";
      }
    }

    if (discoveryChannel === "other") {
      if (!discoveryChannelText) {
        errors.discovery_channel_text = "Required Field";
      } else if (discoveryChannelText.length > 50) {
        errors.discovery_channel_text =
          "Please limit your response to 50 characters.";
      }
    }

    if (!selectedSpecialty) {
      errors.specialty = "Please select a specialty.";
    }
    if (referredByCode && referredByCode.length != 0) {
      referred_by_user_id = await validateReferralCode(referredByCode);
      if (!referred_by_user_id) {
        errors.referral_code = "The referral code you entered is invalid.";
      }
    }

    return { errors, referred_by_user_id };
  };

  async function validateReferralCode(referredByCode: string) {
    const accessToken = await getAccessToken();

    const response = await APIService.makeAPIPostRequest({
      requestString: "/referrals/validateReferralCode",
      accessToken: accessToken,
      body: { referred_by_code: referredByCode },
    });

    if (response.ok) {
      const data = response.value;
      if (data.status === 200) {
        return data.referred_by_user_id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  async function updateSpecialty(
    accessToken: string | null,
    specialtyId: string
  ) {
    if (selectedSpecialty) {
      const specialtyResponse = await APIService.makeAPIPostRequest({
        requestString: "/user/addSpecialty",
        accessToken: accessToken,
        body: { specialty_id: specialtyId },
      });
    }
  }

  async function updateUserInfo(
    accessToken: string | null,
    formInfo: UserFormType
  ) {
    const utmParams = getUTMParameters(); // Get UTM parameters

    const secret_p = localStorage.getItem("secret_p");
    const action = localStorage.getItem("action");
    try {
      const response = await APIService.makeAPIPostRequest({
        requestString: "/user/updateUserInfo",
        accessToken: accessToken,
        body: {
          user_info: formInfo,
          brand: brand,
          platform: platform,
          utm: utmParams,
          secret_p: secret_p,
          action: action,
        },
      });

      if (response.ok) {
        const data = response.value;
        if (data.status === 200) {
          localStorage.removeItem("action");
          localStorage.removeItem("secret_p");
          updateUserInfoToLocalStorage(formInfo);
          if (onSuccessfulFormSubmission) {
            onSuccessfulFormSubmission();
            clearUTMParameters();
          } else {
            const win: Window = window;
            win.location = `/`;
          }
          return {
            success: true,
            stripeCustomerId: data.stripe_customer_id,
            stripeSubscriptionId: data.stripe_subscription_id,
          };
        }
      }
      return { success: false };
    } catch (error) {
      return { success: false };
    }
  }

  function getUTMParameters(): Record<string, string> {
    const urlParams = new URLSearchParams(window.location.search);
    const utmParams: Record<string, string> = {};

    urlParams.forEach((value, key) => {
      if (key.startsWith("utm_")) {
        utmParams[key] = value;
      }
    });

    return utmParams;
  }

  function clearUTMParameters(): void {
    const currentUrl = new URL(window.location.href);
    const params = currentUrl.searchParams;

    // Remove all parameters that start with 'utm_'
    Array.from(params.keys()).forEach((param) => {
      if (param.startsWith("utm_")) {
        params.delete(param);
      }
    });

    // Use replaceState to update the URL without reloading the page
    window.history.replaceState(
      {},
      "",
      currentUrl.pathname + currentUrl.search
    );
  }

  function createInvisibleIframe(url: string): void {
    const iframe: HTMLIFrameElement = document.createElement("iframe");
    iframe.style.position = "fixed";
    iframe.style.width = "1px";
    iframe.style.height = "1px";
    iframe.style.border = "none";
    iframe.style.right = "0";
    iframe.style.bottom = "0";
    iframe.src = url;
    document.body.appendChild(iframe);
  }

  async function updateUserReferralInfo(
    accessToken: string | null,
    referredByCode: string,
    referredByUserId: string,
    stripeCustomerId: string,
    stripeSubscriptionId: string
  ) {
    const referral_response = await APIService.makeAPIPostRequest({
      requestString: "/referrals/updateUserReferralInfo",
      accessToken: accessToken,
      body: {
        is_new_user: true,
        referred_by_code: referredByCode,
        referred_by_user_rewarded: false,
        referred_by_user_id: referredByUserId,
        stripe_customer_id: stripeCustomerId,
        stripe_subscription_id: stripeSubscriptionId,
      },
    });
  }

  const handleSubmit = async () => {
    setIsLoading(true);

    const { errors, referred_by_user_id } = await validateForm(
      formInfo,
      formInfo.referral_code
    );
    if (referralCodeRef) {
      localStorage.removeItem("referralCode");
    }
    setFormInputErrors(errors);

    if (
      errors.firstName ||
      errors.lastName ||
      errors.email ||
      errors.referral_code ||
      errors.discovery_channel ||
      errors.discovery_channel_text ||
      errors.mobile_phone_num ||
      errors.checked_sms_consent ||
      errors.specialty
    ) {
      setIsLoading(false);
      return;
    }

    const fullName = `${formInfo.firstName.trim()} ${formInfo.lastName.trim()}`;

    const submissionData = {
      ...formInfo,
      name: fullName, // Assume backend expects a single "name" field
    };

    const accessToken = await getAccessToken();

    if (selectedSpecialty) {
      await updateSpecialty(accessToken, selectedSpecialty.specialty_id);
    }

    const userInfoResult = await updateUserInfo(accessToken, submissionData);

    if (userInfoResult.success) {
      const stripeCustomerId = userInfoResult.stripeCustomerId;
      const stripeSubscriptionId = userInfoResult.stripeSubscriptionId;
      await updateUserReferralInfo(
        accessToken,
        formInfo.referral_code,
        referred_by_user_id,
        stripeCustomerId,
        stripeSubscriptionId
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchSpecialtiesData();
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.email) {
        setFormInfo({ ...formInfo, email: user.email });
        setNoEmailOnLoad(false);
      } else {
        setNoEmailOnLoad(true);
      }
    }
  }, [user]);

  useEffect(() => {
    nameInputRef.current?.focus();
  }, []);

  // Cleanup debounce timer on unmount
  useEffect(() => {
    return () => {
      if (referralDebounceTimerRef.current) {
        clearTimeout(referralDebounceTimerRef.current);
      }
    };
  }, []);

  return (
    <Stack sx={{ p: 4, width: "100%" }} spacing={4}>
      <Typography
        variant="h4"
        gutterBottom
        component="div"
        fontWeight={700}
        align="center"
        sx={{ mb: 4 }}
      >
        Get Started
      </Typography>
      <TextField
        fullWidth
        id="firstName"
        label="First Name"
        variant="outlined"
        margin="dense"
        required
        error={!!formInputErrors.firstName} // Adjust the state to include firstName error
        helperText={formInputErrors.firstName} // Adjust the state to include firstName helper text
        onChange={(e) =>
          setFormInfo({ ...formInfo, firstName: e.target.value })
        }
      />
      <TextField
        fullWidth
        id="lastName"
        label="Last Name"
        variant="outlined"
        margin="dense"
        required
        error={!!formInputErrors.lastName} // Adjust the state to include lastName error
        helperText={formInputErrors.lastName} // Adjust the state to include lastName helper text
        onChange={(e) => setFormInfo({ ...formInfo, lastName: e.target.value })}
      />
      <CustomMuiTelInput
        forceCallingCode
        defaultCountry="US"
        placeholder="Mobile Phone Number (Optional)"
        value={formInfo.mobile_phone_num}
        error={!!formInputErrors.mobile_phone_num}
        helperText={formInputErrors.mobile_phone_num}
        onChange={handlePhoneChange}
        onBlur={handlePhoneBlur}
      />
      {noEmailOnLoad && (
        <TextField
          fullWidth
          id="email"
          label="Email Address"
          variant="outlined"
          margin="dense"
          required
          error={!!formInputErrors.email}
          helperText={formInputErrors.email}
          value={formInfo.email}
          onChange={(e) => setFormInfo({ ...formInfo, email: e.target.value })}
        />
      )}
      {availableSpecialties && (
        <FormControl
          fullWidth
          margin="dense"
          error={!!formInputErrors.specialty}
        >
          <InputLabel id="specialty-label">Specialty</InputLabel>
          <Select
            labelId="specialty-label"
            id="specialty"
            value={selectedSpecialty?.specialty_id || ""}
            onChange={handleSelectSpecialty}
            label="Specialty"
          >
            {availableSpecialties.map((specialty) => (
              <MenuItem
                key={specialty.specialty_id}
                value={specialty.specialty_id}
              >
                {specialty.specialty_name}
              </MenuItem>
            ))}
            <MenuItem key="other" value="other">
              Other
            </MenuItem>
          </Select>
          {formInputErrors.specialty && (
            <FormHelperText>{formInputErrors.specialty}</FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl
        fullWidth
        margin="dense"
        error={!!formInputErrors.discovery_channel}
      >
        <InputLabel id="discovery-source-label">
          How did you find us?
        </InputLabel>
        <Select
          labelId="discovery-source-label"
          id="discoverySource"
          value={formInfo.discovery_channel || ""}
          onChange={(e) =>
            setFormInfo({ ...formInfo, discovery_channel: e.target.value })
          }
          label="How did you find us?"
          required
        >
          <MenuItem value="">Select an option...</MenuItem>
          {shuffledDiscoveryOptions.map((option: IDiscoveryOption) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {formInputErrors.discovery_channel && (
          <FormHelperText>{formInputErrors.discovery_channel}</FormHelperText>
        )}
      </FormControl>
      {isOtherSelected && (
        <TextField
          fullWidth
          id="otherReferral"
          label="Please share how you found us"
          variant="outlined"
          margin="dense"
          error={!!formInputErrors.discovery_channel_text}
          helperText={formInputErrors.discovery_channel_text}
          onChange={(e) =>
            setFormInfo({ ...formInfo, discovery_channel_text: e.target.value })
          }
        />
      )}
      {discoverySourceOther && (
        <TextField
          fullWidth
          id="otherReferral"
          label="Please share how you found us"
          variant="outlined"
          margin="dense"
          error={!!formInputErrors.discovery_channel_text}
          helperText={formInputErrors.discovery_channel_text}
          onChange={(e) =>
            setFormInfo({ ...formInfo, discovery_channel_text: e.target.value })
          }
        />
      )}
      <TextField
        fullWidth
        id="referralCode"
        label="Referral Code (Optional)"
        variant="outlined"
        margin="dense"
        error={!!formInputErrors.referral_code}
        helperText={formInputErrors.referral_code}
        onChange={handleReferralCodeChange}
        value={formInfo.referral_code}
        InputProps={{
          endAdornment: formInfo.referral_code && (
            <InputAdornment position="end">
              {isValidatingReferral ? (
                <CircularProgress size={20} />
              ) : isReferralValid === true ? (
                <CheckCircleIcon color="success" />
              ) : isReferralValid === false ? (
                <CancelIcon color="error" />
              ) : null}
            </InputAdornment>
          ),
        }}
      />
      {isMobilePhoneValid && (
        <FormControl
          fullWidth
          margin="dense"
          error={!!formInputErrors.checked_sms_consent}
        >
          {formInputErrors.checked_sms_consent && (
            <FormHelperText sx={{ pb: 2 }}>
              {formInputErrors.checked_sms_consent}
            </FormHelperText>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={smsConsent}
                onChange={handleSmsConsentChange}
              />
            }
            label="By checking this box, I consent to receive both automated and manual text messages from JotPsych at the phone number provided. These messages may include but are not limited to onboarding information, appointment reminders, and support communications. Msg & data rates may apply. Reply STOP to unsubscribe at any time."
            sx={{
              alignItems: "start",
              m: 0,
              "& .MuiTypography-root": {
                fontStyle: "italic",
                fontSize: "0.75rem",
              },
            }}
            labelPlacement="end"
          />
        </FormControl>
      )}
      <Stack sx={{ justifyContent: "center", alignItems: "center" }}>
        <RoundedButton
          variant="contained"
          color="primary"
          sx={{ px: 4, py: 1.5 }}
          onClick={handleSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress size={"24px"} />
          ) : (
            <Typography variant="body1" fontWeight={700}>
              Next
            </Typography>
          )}
        </RoundedButton>
      </Stack>
    </Stack>
  );
};
