import { useEffect, useState } from "react";
import { BorderBox } from "./layout/BorderBox";
import { UserNote } from "./UserNote";
import { NoteCounts, UserNoteTypeII } from "../types/types";
import {
  Box,
  Typography,
  Link,
  useTheme,
  Select,
  MenuItem,
  SelectChangeEvent,
  Button,
  TextField,
  CircularProgress,
  IconButton,
  Stack,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from "@mui/material";
import CustomButton, { RoundedButton } from "../styles/CustomButtons";
import Pagination from "./Pagination";
import { FilterDropdown } from "./FilterDropDown";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import { Height, Search } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import { EmptyNoteList } from "../components/EmptyNoteList";
import { NotesLoader } from "../loaders/DashboardLoader";
import { useUser } from "../context/user";
import { DeleteModal } from "./DeleteModal";
import APIService from "../services/APIService";
import LayoutWrapper from "./layout/UILayout";
import { removeScrollBar } from "../styles/globalStyles";
import { DismissableCard } from "../Views/Shared/DismissableCard";
import {
  getCachedNotes,
  setCachedNotes,
  isCacheValid,
  clearAllNotesCaches,
} from "../utils/notesCache";
import { useDebounce } from "../hooks/useDebounce";
import TitleIcon from "@mui/icons-material/Title";

export const GroupNotesList: React.FC = () => {
  const { getAccessToken } = useUser();
  const theme = useTheme();

  const [sortedNotes, setSortedNotes] = useState<UserNoteTypeII[]>([]);
  const [notesToDisplay, setNotesToDisplay] = useState<string>(() => {
    const savedSelection = localStorage.getItem("noteFilterSelection");
    return savedSelection || "reviewing";
  });
  const [itemsPerPage, setItemsPerPage] = useState<number>(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [checkedNoteIds, setCheckedNoteIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { userState } = useUser();
  const [sortOrder, setSortOrder] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [noteTitleOnly, setNoteTitleOnly] = useState<boolean>(() => {
    const savedPreference = localStorage.getItem("noteTitleOnly");
    return savedPreference ? JSON.parse(savedPreference) : false;
  });

  const [totalCount, setTotalCount] = useState<number>(0);

  const [loadError, setLoadError] = useState(false);

  const [isLoadingFresh, setIsLoadingFresh] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const debouncedSearchTerm = useDebounce(searchTerm, 500); // 500ms delay

  const getCurrentFilters = () => ({
    category: notesToDisplay as "all" | "reviewing" | "completed",
    sortOrder,
    searchTerm: debouncedSearchTerm,
    itemsPerPage,
    currentPage,
    groupId: userState?.group_ids?.[0] || "",
    noteTitleOnly,
  });

  const fetchAndUpdateNotes = async (showLoading = true) => {
    if (!userState?.group_ids?.length) {
      setIsLoading(false);
      setSortedNotes([]);
      return;
    }

    const firstGroupId = userState.group_ids[0];

    if (showLoading) {
      setIsLoading(true);
    }
    setIsLoadingFresh(true);

    try {
      const currentFilters = getCurrentFilters();
      const { notes, totalCount } = await NotesLoader(
        currentFilters.category,
        itemsPerPage,
        (currentPage - 1) * itemsPerPage,
        sortOrder,
        currentFilters.searchTerm,
        firstGroupId,
        undefined,
        undefined,
        currentFilters.noteTitleOnly
      );

      setSortedNotes(notes);
      setTotalCount(totalCount);
      setLoadError(false);

      // Update cache with group-specific key
      setCachedNotes({
        notes,
        totalCount,
        filters: currentFilters,
        timestamp: Date.now(),
      });
    } catch (error) {
      setLoadError(true);
      console.error("Error fetching group notes:", error);
    } finally {
      setIsLoading(false);
      setIsLoadingFresh(false);
      setIsInitialLoad(false);
    }
  };

  const handleSearch = async () => {
    await fetchAndUpdateNotes(true);
  };

  const filterItems = ["a-z", "Z-A", "recent", "oldest"];

  const sortNotes = (notes: UserNoteTypeII[]) => {
    const sorted = notes.sort((a, b) =>
      sortOrder === -1
        ? b.time_uploaded - a.time_uploaded
        : a.time_uploaded - b.time_uploaded
    );
    return sorted;
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleDeleteNotes = () => {
    if (checkedNoteIds.length > 0) {
      checkedNoteIds.forEach((noteId) => {
        const deleteNote = async () => {
          const accessToken = await getAccessToken();
          const response = await APIService.makeAPIPostRequest({
            requestString: "/notes/delete",
            accessToken: accessToken,
            body: { note_id: noteId },
          });

          if (response.ok) {
            const data = response.value;
            if (data.status === 200) {
              // Clear cache for this group before redirecting
              clearAllNotesCaches();
              const win: Window = window;
              win.location = `/notes`;
            }
          }
        };

        deleteNote();
      });
      setShowDeleteModal(false);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleDeleteNotesClick = () => {
    setShowDeleteModal(true);
  };

  const handleItemsPerPageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSortOrderChange = (event: SelectChangeEvent) => {
    const newSortOrder = event.target.value as unknown as number;

    // Check cache before showing loading state
    const newFilters = {
      ...getCurrentFilters(),
      sortOrder: newSortOrder,
      groupId: userState?.group_ids?.[0] || "",
    };

    const cache = getCachedNotes(newFilters);
    if (cache?.notes && isCacheValid(cache, newFilters)) {
      // Use cached data immediately
      setSortedNotes(cache.notes);
      setTotalCount(cache.totalCount);
      setIsLoadingFresh(true); // Show small loading indicator
      setIsLoading(false); // Don't show full loading state
    } else {
      // No cache, show loading state
      setSortedNotes([]);
      setIsLoading(true);
    }

    setSortOrder(newSortOrder);
  };

  const handleNotesToDisplayChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value as string;
    setNotesToDisplay(newValue);
    localStorage.setItem("noteFilterSelection", newValue);
  };

  const handleNoteTitleOnlyChange = (newValue: boolean) => {
    setNoteTitleOnly(newValue);
    localStorage.setItem("noteTitleOnly", JSON.stringify(newValue));
  };

  useEffect(() => {
    if (!userState?.group_ids?.length) {
      setIsLoading(false);
      setSortedNotes([]);
      return;
    }

    const currentFilters = getCurrentFilters();
    const cache = getCachedNotes(currentFilters);

    console.log("Group notes cache check:", {
      hasCache: !!cache,
      isValid: cache ? isCacheValid(cache, currentFilters) : false,
      filters: currentFilters,
      groupId: userState?.group_ids?.[0],
    });

    if (cache?.notes && isCacheValid(cache, currentFilters)) {
      // Use cached data
      setSortedNotes(cache.notes);
      setTotalCount(cache.totalCount);
      setIsLoading(false);
      setIsInitialLoad(false);

      // Fetch fresh data in background
      fetchAndUpdateNotes(false);
    } else {
      // No valid cache, do a normal fetch
      fetchAndUpdateNotes(true);
    }
  }, [
    currentPage,
    itemsPerPage,
    notesToDisplay,
    sortOrder,
    debouncedSearchTerm,
    userState?.group_ids,
    noteTitleOnly,
  ]);

  return (
    <LayoutWrapper>
      <LayoutWrapper.MainContent>
        {isLoadingFresh && !isInitialLoad && (
          <Box
            sx={{
              position: "fixed",
              top: "1rem",
              right: "1rem",
              zIndex: 1000,
              display: "flex",
              alignItems: "center",
              gap: 1,
              bgcolor: "background.paper",
              padding: "0.5rem",
              borderRadius: "4px",
              boxShadow: 1,
            }}
          >
            <CircularProgress size={20} />
            <Typography variant="caption" color="text.secondary">
              Refreshing...
            </Typography>
          </Box>
        )}

        <Box
          className="NotesList-Container"
          sx={{
            width: "100%",
            maxWidth: "100vw",
            overflow: "hidden",
          }}
        >
          <DeleteModal
            isOpen={showDeleteModal}
            continueText="Delete"
            cancelText="Cancel"
            onCancel={() => {
              setShowDeleteModal(false);
            }}
            onContinue={handleDeleteNotes}
          >
            Are you sure you want to delete this note? This action cannot be
            undone.
          </DeleteModal>
          <Box
            className="NotesListNav-Container"
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              alignItems: { xs: "stretch", md: "flex-start" },
              width: "100%",
              padding: { xs: "1rem", md: "2rem 4rem" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                width: "100%",
                mb: 2,
                gap: 2,
              }}
            >
              <Select
                value={notesToDisplay}
                onChange={handleNotesToDisplayChange}
                sx={{ minWidth: 120, width: { xs: "100%", sm: "auto" } }}
              >
                <MenuItem value="all">All Notes</MenuItem>
                <MenuItem value="reviewing">Reviewing</MenuItem>
                <MenuItem value="completed">Completed</MenuItem>
              </Select>
              <Select
                value={sortOrder as unknown as string}
                onChange={handleSortOrderChange}
                sx={{ minWidth: 120, width: { xs: "100%", sm: "auto" } }}
              >
                <MenuItem value={-1}>Newest First</MenuItem>
                <MenuItem value={1}>Oldest First</MenuItem>
              </Select>
              <Select
                value={itemsPerPage}
                onChange={(event) =>
                  setItemsPerPage(parseInt(event.target.value as string))
                }
                sx={{ minWidth: 120, width: { xs: "100%", sm: "auto" } }}
              >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <TextField
                placeholder="Search notes..."
                variant="outlined"
                size="medium"
                sx={{ flexGrow: 1, width: { xs: "100%", sm: "auto" } }}
                value={searchTerm}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip
                        title={
                          noteTitleOnly
                            ? "Searching note titles only"
                            : "Search all note content"
                        }
                      >
                        <IconButton
                          onClick={() =>
                            handleNoteTitleOnlyChange(!noteTitleOnly)
                          }
                          color={noteTitleOnly ? "primary" : "default"}
                          size="small"
                          sx={{ mr: 1 }}
                        >
                          <TitleIcon />
                        </IconButton>
                      </Tooltip>
                      <IconButton onClick={handleSearch} disabled={isLoading}>
                        {isLoading ? (
                          <CircularProgress size={24} />
                        ) : (
                          <Search />
                        )}
                      </IconButton>
                    </>
                  ),
                }}
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "stretch",
                gap: 2,
                width: { xs: "100%", sm: "auto" },
              }}
            >
              <Button
                variant="outlined"
                onClick={() =>
                  checkedNoteIds.length === sortedNotes.length
                    ? setCheckedNoteIds([])
                    : setCheckedNoteIds(sortedNotes.map((note) => note.note_id))
                }
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                {checkedNoteIds.length === sortedNotes.length
                  ? "Deselect All"
                  : "Select All"}
              </Button>
              <Button
                variant="outlined"
                onClick={handleDeleteNotesClick}
                startIcon={<DeleteIcon />}
                color="error"
                disabled={checkedNoteIds.length === 0}
                sx={{ width: { xs: "100%", sm: "auto" } }}
              >
                Delete
              </Button>
            </Box>
          </Box>
          <Box
            className="notesListMain-container"
            sx={{
              padding: { xs: "1rem", sm: "2rem", md: "4.5rem" },
              paddingTop: "1rem",
            }}
          >
            <Box className="notes-container" sx={{ paddingTop: "1rem" }}>
              {isLoading && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "25vh",
                  }}
                >
                  <Typography
                    variant="h4"
                    color="textColors.lightHeader"
                    sx={{
                      fontSize: {
                        xs: "1.5rem",
                        sm: "1.75rem",
                        md: "2rem",
                        lg: "2.25rem",
                      },
                      textAlign: "center",
                    }}
                  >
                    Loading Notes...
                  </Typography>
                </Box>
              )}
              {!isLoading && (
                <>
                  {sortedNotes.map((note: UserNoteTypeII, index: number) => {
                    return (
                      <UserNote
                        key={note.note_id}
                        index={index}
                        note_id={note.note_id}
                        note_title={note.note_title}
                        patient_id={note.patient_id}
                        patient_name={note.patient_name}
                        status={note.status}
                        review_status={note.review_status}
                        issuing_user_name={note.issuing_user_name}
                        time={note.time_uploaded}
                        summary={note.summary}
                        link={
                          note.status === "completed"
                            ? `/note/${note.note_id}`
                            : `/status/${note.note_id}`
                        }
                        checkedNoteIds={checkedNoteIds}
                        setCheckedNoteIds={setCheckedNoteIds}
                        notesToDisplay={notesToDisplay}
                        origin="user"
                        error_message={note.error_message}
                        completion_status={note.completion_status}
                        completion_timestamp={note.completion_timestamp}
                      />
                    );
                  })}
                </>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  gap: 1,
                  marginTop: 2,
                }}
              >
                <Pagination
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalCount}
                  onPageChange={handlePageChange}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </LayoutWrapper.MainContent>
    </LayoutWrapper>
  );
};
