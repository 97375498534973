import { v4 as uuidv4 } from "uuid";
import { indexedDBManager } from "./IndexDBService";
import * as ebml from "ts-ebml";
import { Buffer } from "buffer";
import RecordRTC from "recordrtc";
import SentryService from "./SentryService";

window.Buffer = Buffer;

interface MediaStreamRecordingOptions {
  getSharedMedia?: boolean;
  getTabMedia?: boolean;
  echoCancellation?: boolean;
  selectedMicrophoneId?: string;
  chunkLength?: number;
}

export interface MediaStreamInfo {
  streamType: "shared" | "microphone";
  selectedMicrophoneId?: string;
  tracks: {
    kind: string;
    label: string;
    enabled: boolean;
    muted: boolean;
    readyState: string;
  }[];
  constraints?: MediaTrackConstraints;
}

interface MediaStreamRecordingCallbacks {
  recorder?: RecorderCallbacks;
  audioStream?: AudioStreamCallbacks;
}

interface RecorderCallbacks {
  onChunkReady: (chunkId: string) => void;
  onChunkError: (chunkId: string, error: unknown) => void;
}

interface AudioStreamCallbacks {
  onAudioImpulse: (data: number) => void;
  onNoAudio: () => void;
  onSharedMediaError: () => void;
}

class WebRecordingService {
  public recorder?: RecordRTC;
  private audioContext?: AudioContext;
  private allStreams: MediaStream[] = [];
  private audioStream?: MediaStream;
  private currentChunkId: string | null = null;
  private recordingTimeout?: number | undefined;
  private recordingOptions?: MediaStreamRecordingOptions | undefined;
  private callbacks?: RecorderCallbacks | undefined;

  // interval ids for checking audio data
  private analyzeAudioIntervalId?: number;

  constructor() {
    indexedDBManager
      .initDB()
      .then(() => {
        console.log("Initialized DB");
      })
      .catch((error) => {
        console.error("Failed to initialize IndexedDB:", error);
      });
  }

  public updateRecordingOption<K extends keyof MediaStreamRecordingOptions>(
    option: K,
    value: MediaStreamRecordingOptions[K]
  ): void {
    console.log(
      `UPDATE RECORDING OPTION: Changing recording option ${option} to ${value}.`
    );
    if (!this.recordingOptions) {
      this.recordingOptions = {};
    }
    this.recordingOptions[option] = value;
  }

  private async createAudioStream(
    options: MediaStreamRecordingOptions,
    callbacks?: AudioStreamCallbacks
  ) {
    let sharedMedia: MediaStream | undefined;
    let tabMedia: MediaStream | undefined;
    let micMedia: MediaStream | undefined;

    try {
      // if requested, get shared media
      if (options.getSharedMedia) {
        try {
          console.log("Requesting shared audio stream");
          sharedMedia = await navigator.mediaDevices.getDisplayMedia({
            // @ts-ignore
            preferCurrentTab: options.getTabMedia ? true : false,
            selfBrowserSurface: "include",
            audio: true,
            video: true,
          });

          // Check if there's an audio track
          if (sharedMedia.getAudioTracks().length > 0) {
            // stop video track because not needed
            const videoTrack = sharedMedia.getVideoTracks()[0];
            videoTrack.stop();

            this.allStreams.push(sharedMedia);
          } else {
            console.log("No audio track in shared media, setting to undefined");
            callbacks?.onSharedMediaError();
            sharedMedia.getTracks().forEach((track) => track.stop());
          }
        } catch (e) {
          console.error("Couldn't get shared audio stream", e);
          // Instead of throwing, we'll just continue without shared media
        }
      }

      // get microphone media
      try {
        micMedia = await navigator.mediaDevices.getUserMedia({
          audio: {
            echoCancellation: options.echoCancellation,
            deviceId: options.selectedMicrophoneId
              ? { exact: options.selectedMicrophoneId }
              : undefined,
          },
          video: false,
        });
        this.allStreams.push(micMedia);
      } catch (e) {
        console.error("Couldn't get mic audio stream", e);
        throw e; // Re-throw this error as we can't proceed without mic audio
      }

      if (!this.audioContext) {
        this.audioContext = new AudioContext();
      }

      let audioStream: MediaStream | undefined;

      // if just mic
      if (micMedia && !sharedMedia && !tabMedia) {
        audioStream = micMedia;
      }

      // if mic and shared media
      if (micMedia && sharedMedia) {
        const sharedSource =
          this.audioContext.createMediaStreamSource(sharedMedia);
        const micSource = this.audioContext.createMediaStreamSource(micMedia);

        const combinedDestination =
          this.audioContext.createMediaStreamDestination();

        sharedSource.connect(combinedDestination);
        micSource.connect(combinedDestination);

        audioStream = combinedDestination.stream;
        this.allStreams.push(audioStream);
      }

      // if mic and tab media
      if (micMedia && tabMedia) {
        const tabSource = this.audioContext.createMediaStreamSource(tabMedia);
        const micSource = this.audioContext.createMediaStreamSource(micMedia);

        const combinedDestination =
          this.audioContext.createMediaStreamDestination();

        tabSource.connect(combinedDestination);
        micSource.connect(combinedDestination);

        audioStream = combinedDestination.stream;
        this.allStreams.push(audioStream);
      }

      this.audioStream = audioStream;

      // send audio level data for visualizer and check for no audio
      if (audioStream) {
        const source = this.audioContext.createMediaStreamSource(audioStream);
        const analyzer = this.audioContext.createAnalyser();
        analyzer.fftSize = 32; // Reduce FFT size for efficiency
        const bufferLength = analyzer.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.connect(analyzer);

        let lastAudioTime = Date.now();
        const silenceThreshold = 0.01; // very low
        const silencePeriod = 3000; // 3 seconds

        const analyzeAudio = () => {
          analyzer.getByteFrequencyData(dataArray);
          const average =
            dataArray.reduce((sum, value) => sum + value, 0) / dataArray.length;

          if (callbacks?.onAudioImpulse) {
            callbacks.onAudioImpulse(average);
          }

          if (average > silenceThreshold) {
            lastAudioTime = Date.now();
          } else if (Date.now() - lastAudioTime > silencePeriod) {
            if (callbacks?.onNoAudio) {
              callbacks.onNoAudio();
            }
            lastAudioTime = Date.now();
          }
        };

        // Use setInterval to control the update frequency
        const intervalId = setInterval(analyzeAudio, 200) as unknown as number; // Update every 200ms

        // Store the interval ID to clear it later if needed
        this.analyzeAudioIntervalId = intervalId;
      }
    } catch (error) {
      console.error(`Error creating audio stream: ${error}`);
      SentryService.logEvent("Error creating audio stream.", {
        level: "error",
        extra: { error },
      });
      throw error; // Re-throw the error to be handled by the caller
    }
  }

  private getSupportedMimeType(): string | undefined {
    const types = [
      "audio/webm;codecs=opus", // Preferred type for modern browsers
      "audio/webm", // Fallback to basic WebM if Opus is not supported
      "audio/mp4", // Mobile
      "audio/ogg", // Who knows
    ];

    return types.find((type) => MediaRecorder.isTypeSupported(type));
  }

  private async createMediaRecorder(
    options: MediaStreamRecordingOptions,
    callbacks?: RecorderCallbacks
  ) {
    if (!this.audioStream) {
      console.error(
        "Cannot create a RecordRTC instance without a defined audio stream."
      );
      return;
    }

    this.recordingOptions = options;
    this.callbacks = callbacks;

    this.startNewChunk();
  }

  private async startNewChunk() {
    if (!this.audioStream) {
      console.error("No audio stream available");
      return;
    }

    if (this.audioStream.active === false) {
      console.error("Audio stream is not active");
      return;
    }

    const mimeType = this.getSupportedMimeType();
    const chunkId = uuidv4();
    this.currentChunkId = chunkId;

    console.log(`Starting new chunk: ${chunkId}`);

    try {
      const newRecorder = new RecordRTC(this.audioStream, {
        type: "audio",
        mimeType: mimeType as unknown as any,
        recorderType: RecordRTC.MediaStreamRecorder,
      });

      this.recorder = newRecorder;

      newRecorder.startRecording();
      console.log(`Recording started for chunk ${chunkId}`);

      // Schedule the next chunk
      this.recordingTimeout = setTimeout(async () => {
        this.stopAndProcessCurrentChunk(newRecorder, chunkId);
        this.startNewChunk();
      }, this.recordingOptions?.chunkLength) as unknown as number;
    } catch (error) {
      console.error(`Error starting new chunk ${chunkId}:`, error);
      SentryService.logEvent("Error starting new chunk.", {
        level: "error",
        extra: { error },
      });
    }
  }

  private async stopAndProcessCurrentChunk(
    recorder: RecordRTC,
    chunkId: string
  ): Promise<void> {
    return new Promise((resolve) => {
      if (recorder) {
        console.log(`Stopping chunk ${chunkId}`);

        try {
          const state = recorder.getState();
          console.log(`Recorder state before stopping: ${state}`);

          if (state !== "recording") {
            console.warn(
              `Recorder is not in 'recording' state. Current state: ${state}`
            );
            resolve();
            return;
          }

          recorder.stopRecording(async () => {
            console.log(`Chunk ${chunkId} stopped`);

            try {
              // Give a little time for the recorder to finalize the blob
              await new Promise((r) => setTimeout(r, 800));

              const blob = recorder.getBlob();
              // console.log(`Blob for chunk ${chunkId}:`, blob?.size);

              if (blob && blob.size > 0) {
                // console.log(`Processing blob for chunk ${chunkId}`);
                await this.processChunk(blob, chunkId!);
              } else {
                // Enhanced error reporting
                const diagnosticInfo = {
                  chunkId,
                  blobExists: !!blob,
                  blobSize: blob ? blob.size : 0,
                  recorderState: recorder.getState(),
                  recorderOptions:
                    (recorder.getInternalRecorder() as any)?.options || {},
                  audioStreamActive: this.audioStream
                    ? this.audioStream.active
                    : false,
                  audioStreamTracks: this.audioStream
                    ? this.audioStream.getTracks().map((track) => ({
                        kind: track.kind,
                        label: track.label,
                        enabled: track.enabled,
                        muted: track.muted,
                        readyState: track.readyState,
                        constraints: track.getConstraints(),
                      }))
                    : [],
                  recordingOptions: this.recordingOptions,
                  chunkLength: this.recordingOptions?.chunkLength,
                  browserInfo: {
                    userAgent: navigator.userAgent,
                    platform: navigator.platform,
                    mediaDevices: !!navigator.mediaDevices,
                  },
                  memoryInfo:
                    // @ts-ignore - Chrome-specific performance memory API
                    performance && performance.memory
                      ? {
                          // @ts-ignore - Chrome-specific performance memory API
                          jsHeapSizeLimit: performance.memory.jsHeapSizeLimit,
                          // @ts-ignore - Chrome-specific performance memory API
                          totalJSHeapSize: performance.memory.totalJSHeapSize,
                          // @ts-ignore - Chrome-specific performance memory API
                          usedJSHeapSize: performance.memory.usedJSHeapSize,
                        }
                      : "Not available",
                };

                console.error(
                  `No valid blob for chunk ${chunkId}`,
                  diagnosticInfo
                );

                // Create a custom error with all the diagnostic info
                const blobError = new Error(
                  `No valid blob for chunk ${chunkId}`
                );

                // Set specific tags for these errors to easily filter in Sentry
                SentryService.setTag("error_type", "blob_chunk_error");
                SentryService.setTag("chunk_id", chunkId);
                SentryService.setTag("requires_replay", "true");

                // Capture the exception with high priority to ensure replay capture
                SentryService.captureException(blobError, {
                  level: "error",
                  tags: {
                    is_critical: "true",
                  },
                  extra: diagnosticInfo,
                });

                // Add a console log that will be captured as a breadcrumb in Sentry
                console.warn(
                  "CRITICAL ERROR: Recording chunk failed to produce valid blob"
                );

                throw blobError;
              }
            } catch (error) {
              console.error(`Error processing chunk ${chunkId}:`, error);
              SentryService.logEvent("Error processing chunk.", {
                level: "error",
                extra: { chunkId, error },
              });
              if (this.callbacks?.onChunkError) {
                this.callbacks.onChunkError(chunkId, error);
              }
            } finally {
              resolve();
            }
          });
        } catch (error) {
          console.error(`Error stopping recorder for chunk ${chunkId}:`, error);
          SentryService.logEvent("Error stopping recorder for chunk.", {
            level: "error",
            extra: { chunkId, error },
          });
          resolve();
        }
      } else {
        console.warn("No recorder to stop");
        resolve();
      }
    });
  }

  private async processChunk(blob: Blob, chunkId: string) {
    try {
      console.log(`Processing chunk ${chunkId}`);
      await indexedDBManager.writeBlob(blob, chunkId);

      if (this.callbacks?.onChunkReady) {
        this.callbacks.onChunkReady(chunkId);
      }
    } catch (error) {
      console.error(`Process chunk error: ${error}`);
      if (this.callbacks?.onChunkError) {
        this.callbacks.onChunkError(chunkId, error);
      }
    }
  }

  public getCurrentChunkId(): string | null {
    return this.currentChunkId;
  }

  public pauseRecording() {
    if (
      this.recorder &&
      this.currentChunkId &&
      this.recorder.getState() === "recording"
    ) {
      console.log("Pausing recording...");
      clearTimeout(this.recordingTimeout);
      this.stopAndProcessCurrentChunk(this.recorder, this.currentChunkId);
    }
  }

  public resumeRecording() {
    if (this.recorder && this.recorder.getState() === "stopped") {
      console.log("Resuming recording...");
      this.startNewChunk();
    }
  }

  public async stopMediaStreamRecording(shortRecording?: boolean) {
    try {
      // end recorder
      if (this.recorder && this.currentChunkId && !shortRecording) {
        await this.stopAndProcessCurrentChunk(
          this.recorder,
          this.currentChunkId
        );
      }

      // clear recording timeout
      if (this.recordingTimeout) {
        clearTimeout(this.recordingTimeout);
        this.recordingTimeout = undefined;
      }

      // clear analysis interval
      if (this.analyzeAudioIntervalId) {
        clearInterval(this.analyzeAudioIntervalId);
        this.analyzeAudioIntervalId = undefined;
      }

      // stop all media streams
      for (const stream of this.allStreams) {
        const tracks = stream.getTracks();
        for (const track of tracks) {
          console.log("Stopping track", track);
          track.stop();
        }
      }

      // remove audioStreams and recorder and audioContext
      this.allStreams = [];
      this.audioStream = undefined;
      this.recorder = undefined;
      this.audioContext = undefined;
    } catch (error) {
      console.error(`Error stopping media stream recording: ${error}`);
      SentryService.logEvent("Error stopping media stream recording.", {
        level: "error",
        extra: { error },
      });
    }
  }

  public async startMediaRecorder(
    {
      getSharedMedia = false,
      getTabMedia = false,
      echoCancellation = false,
      selectedMicrophoneId = undefined,
      chunkLength = 90000,
    }: MediaStreamRecordingOptions = {},
    callbacks: MediaStreamRecordingCallbacks
  ): Promise<boolean> {
    const options = {
      getSharedMedia,
      getTabMedia,
      echoCancellation,
      selectedMicrophoneId,
      chunkLength,
    };

    try {
      if (!this.audioContext) {
        this.audioContext = new AudioContext();
      }

      // if an audioStream hasn't already been started, start one
      if (!this.audioStream) {
        await this.createAudioStream(options, callbacks?.audioStream);
      }

      if (!this.audioStream) {
        throw new Error("No audio stream created.");
      }

      if (!this.recorder) {
        await this.createMediaRecorder(options, callbacks?.recorder);
      }

      if (!this.recorder) {
        throw new Error("No recorder created.");
      }

      return true;
    } catch (error) {
      console.error(`Error creating media stream recorder: ${error}`);
      SentryService.logEvent("Error creating media stream recorder.", {
        level: "error",
        extra: { error },
      });
      return false;
    }
  }

  public getMediaStreamInfo(): MediaStreamInfo | null {
    if (!this.audioStream) {
      return null;
    }

    return {
      streamType: this.recordingOptions?.getSharedMedia
        ? "shared"
        : "microphone",
      selectedMicrophoneId: this.recordingOptions?.selectedMicrophoneId,
      tracks: this.audioStream.getTracks().map((track) => ({
        kind: track.kind,
        label: track.label,
        enabled: track.enabled,
        muted: track.muted,
        readyState: track.readyState,
      })),
      constraints: this.audioStream.getAudioTracks()[0]?.getConstraints(),
    };
  }
}

export default WebRecordingService;
