import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Stack,
  Paper,
  Button,
  Chip,
  Grid,
  Divider,
  IconButton,
} from "@mui/material";
import { useEncounter } from "./EncounterContext";
import { Close } from "@mui/icons-material";
import { format, add, differenceInMinutes } from "date-fns";

interface EncounterScheduleDurationProps {
  onScheduleSelect: (
    date: Date | undefined,
    duration: number | undefined
  ) => void;
  onClose?: () => void;
  autoSelectMode?: boolean;
  initialDateTime?: Date;
  initialDuration?: number;
}

const PRESET_DURATIONS = [
  { label: "15 min", minutes: 15 },
  { label: "30 min", minutes: 30 },
  { label: "45 min", minutes: 45 },
  { label: "1 hour", minutes: 60 },
  { label: "1.5 hours", minutes: 90 },
  { label: "2 hours", minutes: 120 },
];

const EncounterScheduleDuration: React.FC<EncounterScheduleDurationProps> = ({
  onScheduleSelect,
  onClose,
  autoSelectMode = false,
  initialDateTime,
  initialDuration,
}) => {
  const { state, dispatch } = useEncounter();
  // Round to nearest 15 minute increment
  const now = new Date();
  const minutes = now.getMinutes();
  const remainder = minutes % 15;
  if (remainder !== 0) {
    // Round up or down to nearest 15 min
    const roundTo = remainder < 8 ? -remainder : 15 - remainder;
    now.setMinutes(minutes + roundTo);
    now.setSeconds(0);
    now.setMilliseconds(0);
  }

  // Initialize with existing values if available, or with initialDateTime if provided
  const defaultDate =
    initialDateTime ||
    (state.scheduled_for ? new Date(state.scheduled_for) : now);

  const [date, setDate] = useState(format(defaultDate, "yyyy-MM-dd"));
  const [time, setTime] = useState(format(defaultDate, "HH:mm"));

  // Initialize duration from props, state, or existing start/end times
  const [selectedDuration, setSelectedDuration] = useState<number | undefined>(
    initialDuration ||
      state.scheduled_duration ||
      (state.start_time && state.end_time
        ? differenceInMinutes(
            new Date(state.end_time),
            new Date(state.start_time)
          )
        : undefined)
  );

  // Check if initialDuration matches any preset
  const isInitialDurationPreset = initialDuration
    ? PRESET_DURATIONS.some((preset) => preset.minutes === initialDuration)
    : false;

  // For existing encounters with a start and end time
  const [startTime, setStartTime] = useState<string>(
    state.start_time ? format(new Date(state.start_time), "HH:mm") : ""
  );
  const [endTime, setEndTime] = useState<string>(
    state.end_time ? format(new Date(state.end_time), "HH:mm") : ""
  );
  const [isCustomDuration, setIsCustomDuration] = useState(
    (state.start_time && state.end_time) ||
      (!isInitialDurationPreset && initialDuration !== undefined)
      ? true
      : false
  );

  useEffect(() => {
    // Update date and time when initialDateTime changes
    if (initialDateTime) {
      setDate(format(initialDateTime, "yyyy-MM-dd"));
      setTime(format(initialDateTime, "HH:mm"));
    }
    // Handle other state updates as before
    else if (state.scheduled_for) {
      setDate(format(new Date(state.scheduled_for), "yyyy-MM-dd"));
      setTime(format(new Date(state.scheduled_for), "HH:mm"));
    }

    // Update duration when initialDuration changes
    if (initialDuration !== undefined) {
      setSelectedDuration(initialDuration);

      // Check if duration matches preset durations
      const isPresetDuration = PRESET_DURATIONS.some(
        (preset) => preset.minutes === initialDuration
      );

      setIsCustomDuration(!isPresetDuration);

      // Calculate and set start/end times
      if (initialDateTime) {
        const startDateTime = initialDateTime;
        const endDateTime = add(startDateTime, { minutes: initialDuration });

        setStartTime(format(startDateTime, "HH:mm"));
        setEndTime(format(endDateTime, "HH:mm"));
      }
    }
    // Handle duration from existing state if no initialDuration
    else if (state.scheduled_duration) {
      setSelectedDuration(state.scheduled_duration);
    } else if (state.start_time && state.end_time) {
      setStartTime(format(new Date(state.start_time), "HH:mm"));
      setEndTime(format(new Date(state.end_time), "HH:mm"));
      setSelectedDuration(
        differenceInMinutes(
          new Date(state.end_time),
          new Date(state.start_time)
        )
      );
      setIsCustomDuration(true);
    }
  }, [
    initialDateTime,
    initialDuration,
    state.scheduled_for,
    state.scheduled_duration,
    state.start_time,
    state.end_time,
  ]);

  // Add effect for autoSelectMode to automatically call onScheduleSelect when values change
  useEffect(() => {
    if (autoSelectMode && date && time) {
      autoSelect();
    }
  }, [date, time, selectedDuration, isCustomDuration, startTime, endTime]);

  // Function to calculate values and call onScheduleSelect
  const autoSelect = () => {
    let startDateTime: Date | undefined;
    let durationMinutes: number | undefined;

    if (date && time) {
      startDateTime = new Date(`${date}T${time}`);

      if (isCustomDuration && startTime && endTime) {
        // Custom duration using specific start/end times
        const startDate = new Date(`${date}T${startTime}`);
        const endDate = new Date(`${date}T${endTime}`);

        if (startDate < endDate) {
          durationMinutes = differenceInMinutes(endDate, startDate);
          onScheduleSelect(startDateTime, durationMinutes);
        }
      } else if (!isCustomDuration && selectedDuration) {
        // Preset duration
        durationMinutes = selectedDuration;
        onScheduleSelect(startDateTime, durationMinutes);
      } else {
        // Only schedule, no duration
        onScheduleSelect(startDateTime, undefined);
      }
    }
  };

  const handleDurationSelect = (minutes: number) => {
    setSelectedDuration(minutes);
    setIsCustomDuration(false);

    // If we have a date and time, auto-calculate the end time
    if (date && time) {
      const startDateTime = new Date(`${date}T${time}`);
      const endDateTime = add(startDateTime, { minutes });

      setStartTime(format(startDateTime, "HH:mm"));
      setEndTime(format(endDateTime, "HH:mm"));
    }
  };

  const handleDone = () => {
    let startDateTime: Date | undefined;
    let endDateTime: Date | undefined;
    let durationMinutes: number | undefined;

    if (date && time) {
      startDateTime = new Date(`${date}T${time}`);

      if (isCustomDuration && startTime && endTime) {
        // Custom duration using specific start/end times
        const startDate = new Date(`${date}T${startTime}`);
        const endDate = new Date(`${date}T${endTime}`);

        if (startDate < endDate) {
          durationMinutes = differenceInMinutes(endDate, startDate);

          dispatch({
            type: "UPDATE_ENCOUNTER",
            payload: {
              scheduled_for: startDateTime.toISOString(),
              start_time: startDate.toISOString(),
              end_time: endDate.toISOString(),
              scheduled_duration: durationMinutes,
            },
          });
          onScheduleSelect(startDateTime, durationMinutes);
        }
      } else if (!isCustomDuration && selectedDuration) {
        // Preset duration
        durationMinutes = selectedDuration;
        endDateTime = add(startDateTime, { minutes: durationMinutes });

        dispatch({
          type: "UPDATE_ENCOUNTER",
          payload: {
            scheduled_for: startDateTime.toISOString(),
            start_time: startDateTime.toISOString(),
            end_time: endDateTime.toISOString(),
            scheduled_duration: durationMinutes,
          },
        });
        onScheduleSelect(startDateTime, durationMinutes);
      } else {
        // Only schedule, no duration
        dispatch({
          type: "UPDATE_ENCOUNTER",
          payload: {
            scheduled_for: startDateTime.toISOString(),
            scheduled_duration: undefined,
          },
        });
        onScheduleSelect(startDateTime, undefined);
      }
    }

    onClose?.();
  };

  const handleCustomDuration = () => {
    setIsCustomDuration(true);

    // If preset was selected, initialize custom times
    if (selectedDuration && date && time) {
      const startDateTime = new Date(`${date}T${time}`);
      const endDateTime = add(startDateTime, { minutes: selectedDuration });

      setStartTime(format(startDateTime, "HH:mm"));
      setEndTime(format(endDateTime, "HH:mm"));
    } else if (date && time) {
      // Otherwise just use the scheduled time as start time
      setStartTime(time);
      setEndTime(time);
    }
  };

  const handleClear = () => {
    setDate(format(now, "yyyy-MM-dd"));
    setTime(format(now, "HH:mm"));
    setSelectedDuration(undefined);
    setIsCustomDuration(false);
    setStartTime("");
    setEndTime("");

    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: {
        scheduled_for: null,
        start_time: null,
        end_time: null,
        scheduled_duration: undefined,
      },
    });

    onScheduleSelect(undefined, undefined);
  };

  const canSave =
    date &&
    time &&
    (!isCustomDuration ||
      (isCustomDuration &&
        startTime &&
        endTime &&
        new Date(`${date}T${startTime}`) < new Date(`${date}T${endTime}`)));

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          <Typography variant="h6">Schedule & Duration</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
            When will this encounter take place and how long will it be?
          </Typography>
        </Box>
      </Box>

      <Box>
        <Stack spacing={3}>
          {/* Date and time selector */}
          <Box sx={{ mb: 2 }}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                label="Date"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
              <TextField
                label="Time"
                type="time"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                fullWidth
              />
            </Stack>
          </Box>

          {/* Duration selector */}
          <Box>
            <Grid container spacing={1}>
              {PRESET_DURATIONS.map((duration) => (
                <Grid item key={duration.minutes}>
                  <Chip
                    label={duration.label}
                    onClick={() => handleDurationSelect(duration.minutes)}
                    color={
                      selectedDuration === duration.minutes && !isCustomDuration
                        ? "primary"
                        : "default"
                    }
                    variant={
                      selectedDuration === duration.minutes && !isCustomDuration
                        ? "filled"
                        : "outlined"
                    }
                  />
                </Grid>
              ))}
              <Grid item>
                <Chip
                  label="Custom"
                  onClick={handleCustomDuration}
                  color={isCustomDuration ? "primary" : "default"}
                  variant={isCustomDuration ? "filled" : "outlined"}
                />
              </Grid>
            </Grid>

            {/* Custom duration selector */}
            {isCustomDuration && (
              <Stack direction="row" spacing={2} sx={{ mt: 3 }}>
                <TextField
                  label="Start"
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
                <TextField
                  label="End"
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Stack>
            )}
          </Box>

          {/* Action buttons - Only shown if not in autoSelectMode */}
          {!autoSelectMode && (
            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
              <Button variant="contained" onClick={handleDone}>
                Done
              </Button>
            </Box>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default EncounterScheduleDuration;
