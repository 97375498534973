import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  Stack,
  Paper,
  CircularProgress,
  IconButton,
  useTheme,
  Tooltip,
  Collapse,
  Fade,
  Divider,
} from "@mui/material";
import LayoutWrapper from "../layout/UILayout";
import TemplatePicker from "../Pickers/TemplatePicker";
import {
  Patient,
  UserTemplateItem,
  EncounterDocument,
  Encounter,
} from "../../types/types";
import EncounterPatientOverview from "./EncounterPatientOverview";
import { useEncounter, useEncounterActions } from "./EncounterContext";
import EncounterRecordingWidget from "./EncounterRecordingWidget";
import { DeleteModal } from "../DeleteModal";
import {
  KeyboardArrowLeftOutlined,
  Delete,
  CheckCircle,
  BugReport,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { encounterApi } from "./encounters";
import EncounterDocuments from "./EncounterDocuments";
import { format, differenceInMinutes } from "date-fns";
import BaseBlock from "../Patient/Blocks/BaseBlock";
import { ConditionalTooltip } from "../ConditionalTooltip";
import { TutorialProvider } from "../Tutorial/TutorialContext";
import { TutorialStep } from "../Tutorial/TutorialStep";
import { useUser } from "../../context/user";
import EncounterTimeSelector from "./EncounterTimeSelector";
import EncounterOutputsBlock from "./EncounterOutputsBlock";
import { useRecordingContext } from "../../context/RecordingContext";
import { FeatureFeedback } from "../FeatureFeedback/FeatureFeedback";
import EncounterSelectorButtons from "./EncounterSelectorButtons";
import EncounterScheduleDuration from "./EncounterScheduleDuration";
import { capitalize } from "../../utils/utils";
import { RecordingScript } from "../RecordingScript";

const FIRST_NOTE_TEMPLATE_ID = "p:35c69671-e704-4ff7-ba92-66274e8a77ca";
const FIRST_NOTE_TEMPLATE_NAME = "First JotPsych Encounter";

const EncounterView: React.FC = () => {
  const { encounterId } = useParams();
  const {
    subjectLanguage,
    getAccessToken,
    templatesList,
    userState,
    updateOnboardingStep,
  } = useUser();
  const { state, dispatch, activeEncounterId } = useEncounter();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const { cleanUpSession, recordingStatus } = useRecordingContext();
  const [firstNoteMode, setFirstNoteMode] = useState(false);

  const [showRecordingTutorial, setShowRecordingTutorial] =
    useState<boolean>(false);

  const { getSubmissionTooltip, getSubmissionValidation } =
    useEncounterActions();

  const isViewOnly =
    state.status === "processing" || state.status === "completed";

  // Function to check and set first note mode
  const checkFirstNoteMode = async () => {
    if (!userState?.onboardingSteps?.generated_first_note) {
      // Add a timeout to ensure everything is loaded first
      const timeoutId = setTimeout(() => {
        console.log("No first note generated. Switching to first note mode.");
        setFirstNoteMode(true);
        // Set the template to the first note template
        dispatch({
          type: "UPDATE_ENCOUNTER",
          payload: {
            template_id: FIRST_NOTE_TEMPLATE_ID,
            encounter_type: FIRST_NOTE_TEMPLATE_NAME,
            note_title: "First JotPsych Encounter",
          },
        });
      }, 500);

      return () => clearTimeout(timeoutId);
    }
  };

  useEffect(() => {
    const loadEncounter = async () => {
      if (!encounterId) {
        navigate("/schedule");
        return;
      }

      setIsLoading(true);

      // TEMPORARY FIX FOR NOT BEING ABLE TO RECORD
      // There is a bug right now that happens when someone presses stop and then presses either "save for later" or "back"
      // too quickly before the recording session finishes processing. This is a hacky fix. I'm sorry. #foundercode - Jackson
      if (!recordingStatus.isRecording) {
        recordingStatus.readyToRecord = true;
      }

      try {
        if (encounterId === "active") {
          // First try to load existing active encounter
          if (activeEncounterId) {
            const activeEncounter = await encounterApi.getEncounter(
              activeEncounterId,
              await getAccessToken()
            );
            if (activeEncounter) {
              dispatch({ type: "LOAD_ENCOUNTER", payload: activeEncounter });
              window.history.replaceState(
                {},
                "",
                `/encounters/${activeEncounter.encounter_id}`
              );
              await checkFirstNoteMode();
              return;
            }
          }

          // If no active encounter exists or it's invalid, create a new one
          const currentTime = new Date().toISOString();
          const newEncounter = await encounterApi.createEncounter(
            {
              scheduled_for: currentTime,
              encounter_type: templatesList?.[0]?.display_name,
              template_id: templatesList?.[0]?.template_id,
            },
            await getAccessToken()
          );
          dispatch({
            type: "SET_ACTIVE_ENCOUNTER",
            payload: newEncounter.encounter_id,
          });
          window.history.replaceState(
            {},
            "",
            `/encounters/${newEncounter.encounter_id}`
          );
          await checkFirstNoteMode();
          return;
        }

        // Load specific encounter by ID
        const encounter = await encounterApi.getEncounter(
          encounterId,
          await getAccessToken()
        );
        if (encounter) {
          dispatch({ type: "LOAD_ENCOUNTER", payload: encounter });
          await checkFirstNoteMode();
        } else {
          enqueueSnackbar("Encounter not found", { variant: "error" });
          navigate("/schedule");
        }
      } catch (error) {
        console.error("Error loading encounter:", error);
        enqueueSnackbar("Error loading encounter", { variant: "error" });
        navigate("/schedule");
      } finally {
        setIsLoading(false);
      }
    };

    // Only load if we're in a loading state
    if (isLoading) {
      loadEncounter();
    }
  }, [encounterId, dispatch, enqueueSnackbar, isLoading, activeEncounterId]);

  // Set loading to true when encounterId changes
  useEffect(() => {
    setIsLoading(true);
  }, [encounterId]);

  const handleFinishEncounter = async () => {
    if (!state.template_id) {
      return;
    }

    setIsSubmitting(true);

    try {
      const noteId = await encounterApi.submitEncounter(
        state.encounter_id,
        await getAccessToken()
      );

      enqueueSnackbar("Encounter submitted successfully", {
        variant: "success",
      });

      handleCheckFirstNote();

      // Clean up the recording session
      cleanUpSession();

      // Clear the active encounter
      dispatch({ type: "CLEAR_ACTIVE_ENCOUNTER" });

      // Navigate to the status page with the note ID
      navigate(`/status/${noteId}`);
    } catch (error) {
      console.error("Error submitting encounter:", error);
      enqueueSnackbar("Failed to submit encounter", { variant: "error" });
      setIsSubmitting(false);
    }
  };

  const handleSaveForLater = () => {
    dispatch({ type: "CLEAR_ACTIVE_ENCOUNTER" });
    enqueueSnackbar("Encounter saved successfully", { variant: "success" });
    navigate("/schedule");
  };

  const handleCheckFirstNote = async () => {
    if (userState?.onboardingSteps?.generated_first_note !== true) {
      updateOnboardingStep("generated_first_note", true);
    }
  };

  const handleDeleteEncounter = async () => {
    try {
      await encounterApi.deleteEncounter(
        state.encounter_id,
        await getAccessToken()
      );
      dispatch({ type: "CLEAR_ACTIVE_ENCOUNTER" });
      // Clean up the recording session
      cleanUpSession();
      enqueueSnackbar("Encounter deleted successfully", { variant: "success" });
      navigate("/schedule");
    } catch (error) {
      console.error("Error deleting encounter:", error);
      enqueueSnackbar("Failed to delete encounter", { variant: "error" });
    } finally {
      setShowDeleteModal(false);
    }
  };

  const handleBackClick = () => {
    navigate("/schedule");
  };

  const formatDuration = (startTime: Date, endTime: Date) => {
    const minutes = differenceInMinutes(new Date(endTime), new Date(startTime));
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return hours > 0
      ? `${hours}h ${remainingMinutes}m`
      : `${remainingMinutes}m`;
  };

  const hasAudioDocuments = () => {
    return state.inputs?.some((doc) => doc.type === "recording");
  };

  if (isLoading) {
    return (
      <LayoutWrapper>
        <LayoutWrapper.MainContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        </LayoutWrapper.MainContent>
      </LayoutWrapper>
    );
  }

  if (!state) {
    return null;
  }

  return (
    <TutorialProvider
      tutorialKey="encounter-view-v2"
      totalSteps={5}
      tutorialDisabled={isViewOnly ? true : false}
    >
      <LayoutWrapper>
        <LayoutWrapper.TopBar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              px: 2,
            }}
          >
            <Typography
              variant="body2"
              onClick={handleBackClick}
              sx={{
                color: "primary.main",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <KeyboardArrowLeftOutlined />
              Back
            </Typography>

            {/* Empty middle section to maintain spacing */}
            <Box />

            <Stack direction="row">
              <Tooltip
                title={isViewOnly ? undefined : "Delete Encounter"}
                placement="bottom"
                arrow
              >
                <span>
                  <IconButton
                    onClick={() => setShowDeleteModal(true)}
                    disabled={isViewOnly}
                  >
                    <Delete sx={{ fontSize: "1.2rem" }} />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Box>
        </LayoutWrapper.TopBar>
        <LayoutWrapper.MainContent>
          <Box
            sx={{
              width: "100%",
              maxWidth: 1400,
              mx: "auto",
              p: { xs: 1, md: 2 },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
            }}
          >
            {/* Left Column - WHO/WHAT/WHEN selector buttons or Encounter Metadata in view mode */}
            <Box
              sx={{
                width: { xs: "100%", md: "25%" },
                flexShrink: 0,
                order: { xs: 1, md: 1 },
              }}
            >
              {!isViewOnly ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <TutorialStep
                    step={0}
                    description="The left column is where you should start. Use these panels to choose WHO the encounter is with, WHAT type of note you're creating, and WHEN it will take place. Scheduled encounters will automatically appear on your calendar."
                  >
                    {/* Selector buttons stacked vertically */}
                    <EncounterSelectorButtons
                      isViewOnly={isViewOnly}
                      hasAudioDocuments={hasAudioDocuments()}
                      vertical={true}
                    />
                  </TutorialStep>
                </Box>
              ) : (
                <BaseBlock sx={{ p: { xs: 1.5, md: 2 }, height: "100%" }}>
                  <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>
                    Encounter Information
                  </Typography>

                  {/* Patient/Note title */}
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      {capitalize(subjectLanguage)}
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      {state.patient?.first_name +
                        " " +
                        state.patient?.last_name ||
                        state.note_title ||
                        "Not specified"}
                    </Typography>
                  </Box>

                  <Divider sx={{ my: 2 }} />

                  {/* Template */}
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Template
                    </Typography>
                    <Typography variant="body1">
                      {state.encounter_type || "Not specified"}
                    </Typography>
                  </Box>

                  {/* Scheduled time */}
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Scheduled for
                    </Typography>
                    <Typography variant="body1">
                      {state.scheduled_for
                        ? format(
                            new Date(state.scheduled_for),
                            "MMM d, yyyy, h:mm a"
                          )
                        : "Not scheduled"}
                    </Typography>
                  </Box>

                  {/* Start/End times if available */}
                  {state.start_time && (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Started at
                      </Typography>
                      <Typography variant="body1">
                        {format(
                          new Date(state.start_time),
                          "MMM d, yyyy, h:mm a"
                        )}
                      </Typography>
                    </Box>
                  )}

                  {state.end_time && (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Ended at
                      </Typography>
                      <Typography variant="body1">
                        {format(
                          new Date(state.end_time),
                          "MMM d, yyyy, h:mm a"
                        )}
                      </Typography>
                    </Box>
                  )}

                  {/* Duration if both start and end times are available */}
                  {state.start_time && state.end_time && (
                    <Box sx={{ mb: 2 }}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        gutterBottom
                      >
                        Duration
                      </Typography>
                      <Typography variant="body1">
                        {formatDuration(
                          new Date(state.start_time),
                          new Date(state.end_time)
                        )}
                      </Typography>
                    </Box>
                  )}

                  {/* Status */}
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                    >
                      Status
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {state.status === "processing" ? (
                        <>
                          <CircularProgress size={16} />
                          <Typography variant="body1">Processing</Typography>
                        </>
                      ) : (
                        <>
                          <CheckCircle color="success" sx={{ fontSize: 16 }} />
                          <Typography variant="body1">Completed</Typography>
                        </>
                      )}
                    </Box>
                  </Box>
                </BaseBlock>
              )}
            </Box>

            {/* Middle Column - Patient Overview - 50% or 75% width depending on mode */}
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: isViewOnly ? "75%" : "50%",
                },
                display: firstNoteMode
                  ? "flex"
                  : state.note_title && !state.patient_id
                  ? "none"
                  : "flex",
                flexDirection: "column",
                gap: 2,
                order: { xs: 3, md: 2 },
              }}
            >
              {/* Outputs Section */}
              {(state.status === "processing" ||
                state.status === "completed" ||
                (state.outputs && state.outputs.length > 0)) && (
                <Box>
                  <EncounterOutputsBlock patient={state.patient} />
                </Box>
              )}
              <Box
                id="encounter-view-patient-overview"
                sx={{
                  width: "100%",
                }}
              >
                <TutorialStep
                  step={1}
                  description={`If you've selected a ${subjectLanguage} for this encounter, you'll see their details here. If you don't want to assign a specific ${subjectLanguage}, you can give the encounter a title. Remember, JotPsych doesn't share any personally identifiable information with AI models, our own staff, or anyone else.`}
                  fullHeight
                  placement="left-start"
                >
                  {/* Show RecordingScript in first note mode */}
                  {firstNoteMode && (
                    <Box sx={{ mb: 2 }}>
                      <RecordingScript />
                    </Box>
                  )}

                  {/* When there's a patient_id, show the patient overview */}
                  {state.patient_id && !firstNoteMode && (
                    <EncounterPatientOverview
                      patient_id={state.patient_id}
                      isViewOnly={isViewOnly}
                    />
                  )}
                  {!state.patient_id && !firstNoteMode && (
                    <BaseBlock sx={{ p: { xs: 1.5, md: 2 }, height: "100%" }}>
                      <Typography variant="body1" sx={{ mb: 2 }}>
                        Select a {subjectLanguage} for this encounter to see an
                        overview of their care. You can do so in the left
                        column.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        <Box
                          sx={{ display: "flex", alignItems: "center", mb: 1 }}
                        >
                          <Box
                            sx={{
                              width: 40,
                              height: 40,
                              borderRadius: "50%",
                              bgcolor: "grey.200",
                              mr: 2,
                            }}
                          />
                          <Box>
                            <Box
                              sx={{
                                height: 16,
                                width: 150,
                                bgcolor: "grey.200",
                                mb: 0.5,
                                borderRadius: 0.5,
                              }}
                            />
                            <Box
                              sx={{
                                height: 12,
                                width: 100,
                                bgcolor: "grey.200",
                                borderRadius: 0.5,
                              }}
                            />
                          </Box>
                        </Box>

                        {/* Demographics skeleton */}
                        <Box sx={{ mb: 2 }}>
                          <Box
                            sx={{
                              height: 14,
                              width: "30%",
                              bgcolor: "grey.200",
                              mb: 1,
                              borderRadius: 0.5,
                            }}
                          />
                          <Box
                            sx={{
                              height: 12,
                              width: "80%",
                              bgcolor: "grey.200",
                              borderRadius: 0.5,
                            }}
                          />
                        </Box>
                      </Box>
                    </BaseBlock>
                  )}
                </TutorialStep>
              </Box>
            </Box>

            {/* Right Column - Encounter Documents - 25% or 75% width when middle is hidden */}
            <Box
              sx={{
                width: {
                  xs: "100%",
                  md: firstNoteMode
                    ? "25%"
                    : state.note_title && !state.patient_id
                    ? "75%"
                    : "25%",
                },
                display: "flex",
                flexDirection: "column",
                gap: 2,
                order: { xs: 2, md: 3 },
              }}
            >
              {/* Recording Widget - only show when not in view-only mode */}
              {!isViewOnly && (
                <Box
                  id="encounter-view-recording-widget"
                  sx={{
                    width: "100%",
                  }}
                >
                  <TutorialStep
                    step={2}
                    description="When it's time to start the encounter, click the record button. You can also record dictations before or after the encounter. You can change your microphone settings here as well."
                    highlightBox={true}
                    showOnce={showRecordingTutorial}
                    onDone={() => {
                      setShowRecordingTutorial(false);
                    }}
                  >
                    <EncounterRecordingWidget isViewOnly={isViewOnly} />
                  </TutorialStep>
                </Box>
              )}

              {/* Encounter Documents */}
              <Box
                id="encounter-view-forms-docs"
                sx={{
                  width: "100%",
                }}
              >
                <TutorialStep
                  step={3}
                  description={`Dictations, scratch pad notes, and other documents can be added to the encounter and provided to JotPsych as supplemental information to generate a more accurate, robust note.`}
                  fullHeight
                >
                  <BaseBlock sx={{ p: { xs: 1.5, md: 2 }, height: "100%" }}>
                    <EncounterDocuments
                      triggerRecordingTutorial={() =>
                        setShowRecordingTutorial(true)
                      }
                    />
                  </BaseBlock>
                </TutorialStep>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <FeatureFeedback
              featureId="encounter_view_v2"
              prompt="How do you like the new encounter scheduling feature?"
              mountsUntilShow={10}
              percentToShow={20}
            />
          </Box>

          <DeleteModal
            isOpen={showDeleteModal}
            continueText="Delete"
            cancelText="Cancel"
            onCancel={() => setShowDeleteModal(false)}
            onContinue={handleDeleteEncounter}
          >
            Are you sure you want to delete this encounter? This action cannot
            be undone.
          </DeleteModal>
        </LayoutWrapper.MainContent>

        <LayoutWrapper.BottomBar>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              p: 2,
              borderTop: 1,
              borderColor: "divider",
              bgcolor: "background.paper",
              marginLeft: "100px",
            }}
          >
            {isViewOnly ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  color:
                    state.status === "completed"
                      ? "success.main"
                      : "primary.main",
                }}
              >
                {state.status === "processing" ? (
                  <>
                    <CircularProgress size={24} />
                    <Typography>Processing encounter...</Typography>
                  </>
                ) : (
                  <>
                    <CheckCircle />
                    <Typography>Encounter completed</Typography>
                  </>
                )}
              </Box>
            ) : (
              <TutorialStep
                step={4}
                description="When you're ready to generate the encounter note, click submit. You can also save the encounter for later and come back to it."
                highlightBox={true}
              >
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Tooltip
                    title="Not ready to create a note from this encounter yet? Save and come back later through the schedule."
                    placement="top"
                    arrow
                  >
                    <Button variant="outlined" onClick={handleSaveForLater}>
                      Save for Later
                    </Button>
                  </Tooltip>

                  <Tooltip title={getSubmissionTooltip()} placement="top" arrow>
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFinishEncounter}
                        disabled={
                          !getSubmissionValidation().canSubmit || isSubmitting
                        }
                        startIcon={
                          isSubmitting ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                      >
                        {isSubmitting ? "Submitting..." : "Create Note"}
                      </Button>
                    </span>
                  </Tooltip>
                </Box>
              </TutorialStep>
            )}
          </Box>
        </LayoutWrapper.BottomBar>
      </LayoutWrapper>
    </TutorialProvider>
  );
};

export default EncounterView;
