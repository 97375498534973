import React, { useState, useEffect, KeyboardEvent, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stack,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { encounterApi } from "./encounters";
import { useUser } from "../../context/user";
import { useEncountersData } from "../../context/EncountersDataContext";
import { useNavigate } from "react-router-dom";
import { Patient } from "../../types/types";
import PatientPicker, { PatientPickerRef } from "../Patient/PatientPicker";
import TemplatePicker from "../Pickers/TemplatePicker";
import EncounterScheduleDuration from "./EncounterScheduleDuration";

interface AddEncounterModalProps {
  open: boolean;
  onClose: () => void;
  initialScheduledTime?: Date;
  initialDuration?: number;
}

const AddEncounterModal: React.FC<AddEncounterModalProps> = ({
  open,
  onClose,
  initialScheduledTime,
  initialDuration,
}) => {
  const navigate = useNavigate();
  const { getAccessToken, subjectLanguage, templatesList } = useUser();
  const { refreshEncounters } = useEncountersData();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const patientPickerRef = useRef<PatientPickerRef>(null);
  const [enterKeyReleased, setEnterKeyReleased] = useState(true);

  // State for the new encounter
  const [newEncounter, setNewEncounter] = useState({
    patient: undefined as Patient | undefined,
    patientId: undefined as string | undefined,
    templateId: undefined as string | undefined,
    templateName: undefined as string | undefined,
    scheduledFor: undefined as Date | undefined,
    duration: undefined as number | undefined,
  });

  // Set initial scheduled time and duration when props change
  useEffect(() => {
    if (initialScheduledTime || initialDuration) {
      setNewEncounter((prev) => ({
        ...prev,
        scheduledFor: initialScheduledTime || prev.scheduledFor,
        duration: initialDuration || prev.duration,
      }));
    }
  }, [initialScheduledTime, initialDuration]);

  useEffect(() => {
    if (templatesList && templatesList.length > 0) {
      setNewEncounter((prev) => ({
        ...prev,
        templateId: templatesList[0].template_id,
        templateName: templatesList[0].display_name,
      }));
    }
  }, [templatesList, initialScheduledTime, initialDuration]);

  // Focus the patient picker when the modal opens
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (patientPickerRef.current) {
          patientPickerRef.current.focusInput();
        }
      }, 150);
    }
  }, [open]);

  // Handle creating a new encounter
  const handleCreateEncounter = async () => {
    try {
      if (
        !newEncounter.patientId ||
        !newEncounter.templateId ||
        !newEncounter.scheduledFor
      ) {
        console.error("Missing required fields");
        return;
      }

      setIsSubmitting(true);

      const encounterData: any = {
        status: "pending",
        inputs: [],
        outputs: [],
        encounter_type: newEncounter.templateName,
        template_id: newEncounter.templateId,
        patient_id: newEncounter.patientId,
      };

      // Add scheduling information
      if (newEncounter.scheduledFor) {
        encounterData.scheduled_for = newEncounter.scheduledFor.toISOString();

        // Add duration if available
        if (newEncounter.duration) {
          const startTime = newEncounter.scheduledFor;
          const endTime = new Date(
            startTime.getTime() + newEncounter.duration * 60000
          );
          encounterData.start_time = startTime.toISOString();
          encounterData.end_time = endTime.toISOString();
          encounterData.scheduled_duration = newEncounter.duration;
        }
      }

      const createdEncounter = await encounterApi.createEncounter(
        encounterData,
        await getAccessToken()
      );

      // Reset form and close modal
      resetForm();

      // Refresh all encounters to show the new one
      refreshEncounters({ forceFullLoading: false, quietRefresh: true });

      // Close the modal
      onClose();
    } catch (error) {
      console.error("Error creating encounter:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reset the form
  const resetForm = () => {
    setNewEncounter({
      patient: undefined,
      patientId: undefined,
      templateId: undefined,
      templateName: undefined,
      scheduledFor: undefined,
      duration: undefined,
    });
  };

  // Handle closing the modal
  const handleClose = () => {
    resetForm();
    onClose();
  };

  // Handle patient selection
  const handlePatientSelect = (selectedPatient: Patient | undefined) => {
    if (selectedPatient) {
      setNewEncounter({
        ...newEncounter,
        patient: selectedPatient,
        patientId: selectedPatient.patient_id,
      });
    }
  };

  // Handle template selection
  const handleTemplateSelect = (template: any) => {
    setNewEncounter({
      ...newEncounter,
      templateId: template?.template_id,
      templateName: template?.display_name,
    });
  };

  // Handle schedule selection
  const handleScheduleSelect = (
    date: Date | undefined,
    duration: number | undefined
  ) => {
    setNewEncounter({
      ...newEncounter,
      scheduledFor: date,
      duration,
    });
  };

  // Check if form is valid for submission
  const isFormValid = () => {
    return (
      !!newEncounter.patientId &&
      !!newEncounter.templateId &&
      !!newEncounter.scheduledFor
    );
  };

  // Handle Enter key press and release to submit form
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      // Only process the Enter key if it was previously released
      if (
        enterKeyReleased &&
        !event.shiftKey &&
        !event.ctrlKey &&
        isFormValid() &&
        !isSubmitting
      ) {
        event.preventDefault();
        handleCreateEncounter();
      }
      setEnterKeyReleased(false);
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      setEnterKeyReleased(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      sx={{
        "& .MuiDialog-paper": {
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="h6">Schedule an Encounter</Typography>
        </Stack>
      </DialogTitle>

      <DialogContent
        sx={{
          overflowY: "auto",
          paddingBottom: 2,
          flex: 1,
        }}
      >
        <Box sx={{ my: 2 }}>
          {/* Patient picker */}
          <Box sx={{ mb: 3 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="h6">Who is this encounter for?</Typography>
            </Box>
            <PatientPicker
              ref={patientPickerRef}
              onPatientSelect={handlePatientSelect}
              initialPatient={newEncounter.patient}
              autoFocus={open}
            />
          </Box>

          {/* Template picker */}
          <Box sx={{ mb: 3 }}>
            <Box sx={{ mb: 1 }}>
              <Typography variant="h6">What is the encounter type?</Typography>
            </Box>
            <TemplatePicker
              onTemplateSelect={handleTemplateSelect}
              initialTemplateId={newEncounter.templateId}
            />
          </Box>

          {/* Schedule and duration */}
          <Box>
            <EncounterScheduleDuration
              onScheduleSelect={handleScheduleSelect}
              onClose={() => {}}
              autoSelectMode={true}
              initialDateTime={initialScheduledTime}
              initialDuration={initialDuration}
            />
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: "1px solid",
          borderColor: "divider",
          position: "sticky",
          bottom: 0,
          background: "background.paper",
          zIndex: 1,
        }}
      >
        <Button onClick={handleClose}>Cancel</Button>
        <Tooltip
          title={
            !isFormValid()
              ? "Please fill all required fields"
              : isFormValid() && !isSubmitting
              ? "Press Enter to create"
              : ""
          }
          placement="top"
        >
          <span>
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                onClick={handleCreateEncounter}
                disabled={!isFormValid() || isSubmitting}
                sx={{
                  color: "white",
                  paddingRight:
                    isFormValid() && !isSubmitting ? "30px" : "16px",
                }}
              >
                {isSubmitting ? "Creating..." : "Create Encounter"}
              </Button>
              {isFormValid() && !isSubmitting && (
                <KeyboardReturnIcon
                  fontSize="small"
                  sx={{
                    position: "absolute",
                    right: "8px",
                    top: "50%",
                    transform: "translateY(-50%)",
                    fontSize: "1rem",
                    opacity: 0.7,
                    color: "white",
                  }}
                />
              )}
            </Box>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default AddEncounterModal;
