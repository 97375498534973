import React, { useEffect, useState, memo, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  CircularProgress,
  Box,
  Button,
  Link,
  Tooltip,
  IconButton,
} from "@mui/material";
import { useUser } from "../context/user";
import APIService from "../services/APIService";
import { formatContent } from "../utils/utils";
import { ContentCard } from "../Views/Shared/ContentCard";
import { InfoOutlined, Launch } from "@mui/icons-material";
import CollapsibleContentCard from "./CollapsibleContentCard/CollapsibleContentCard";
import { format } from "date-fns";
import SlideSidePane from "../Views/Shared/SlideSidePane";
import { NoteSectionDisplayBox } from "./Note/NoteSectionDisplayBox";
import { NoteDataType } from "../types/types";
import { NoteMetadata } from "./NoteMetadata";
import { useNavigate } from "react-router-dom";
import { FeatureFeedback } from "./FeatureFeedback/FeatureFeedback";
import { trackEvent } from "../utils/analytics_utils";

interface PastVisitSummaryProps {
  patientId: string | undefined;
}

const LoadingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
});

interface PatientData {
  past_visit_summary: string;
  patient: {
    latest_encounter: {
      scheduled_for?: string;
      created_at: string;
      start_time?: string;
      note_id: string;
      outputs?: {
        type: string;
        encounter_document_id: string;
      }[];
    };
  };
}

const VisitSummaryCard = memo(
  ({
    data,
    loading,
    error,
    onViewNote,
  }: {
    data: PatientData | null;
    loading: boolean;
    error: string | null;
    onViewNote: () => void;
  }) => {
    const { subjectLanguage } = useUser();
    const formattedStartTime = data?.patient?.latest_encounter
      ? format(
          new Date(
            data.patient.latest_encounter.start_time ||
              data.patient.latest_encounter.scheduled_for ||
              data.patient.latest_encounter.created_at
          ),
          "MMM d, yyyy - p"
        )
      : null;

    // Get note_id from either direct note_id or from outputs
    const noteId =
      data?.patient?.latest_encounter?.note_id ||
      data?.patient?.latest_encounter?.outputs?.find(
        (output) => output.type === "note"
      )?.encounter_document_id;

    return (
      <CollapsibleContentCard defaultCollapsed={false} sx={{ width: "100%" }}>
        <CollapsibleContentCard.Header
          title="Last Encounter"
          subtitle={formattedStartTime}
          loading={loading}
          onTitleClick={() => {
            if (noteId) {
              onViewNote();
            }
          }}
        />

        <CollapsibleContentCard.Body>
          {loading && (
            <LoadingContainer>
              <CircularProgress size={24} />
            </LoadingContainer>
          )}

          {error && <Typography color="error">{error}</Typography>}

          {!loading && !error && data?.past_visit_summary && (
            <Typography variant="body2">
              {formatContent(data.past_visit_summary)}
            </Typography>
          )}

          {!loading && !error && (
            <>
              {!data?.patient?.latest_encounter ? (
                <Typography variant="body2" color="text.secondary">
                  This {subjectLanguage} has no previous encounters. Let's
                  record one!
                </Typography>
              ) : (
                !data?.past_visit_summary && (
                  <Typography variant="body2" color="text.secondary">
                    No information available about this {subjectLanguage}'s last
                    visit.
                  </Typography>
                )
              )}
            </>
          )}
        </CollapsibleContentCard.Body>
        <CollapsibleContentCard.BottomExtension>
          <Button variant="text" onClick={onViewNote} disabled={!noteId}>
            View Encounter Note
          </Button>
        </CollapsibleContentCard.BottomExtension>
      </CollapsibleContentCard>
    );
  }
);

export const PastVisitSummary: React.FC<PastVisitSummaryProps> = ({
  patientId,
}) => {
  const { getAccessToken, subjectLanguage } = useUser();
  const navigate = useNavigate();
  const [data, setData] = useState<PatientData | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [noteData, setNoteData] = useState<NoteDataType | null>(null);
  const [showFeedback, setShowFeedback] = useState(false);
  const hasTrackedHoverRef = useRef(false);

  const fetchSummary = async () => {
    if (!patientId) return;

    setLoading(true);
    setError(null);

    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/patient/getPreRecordingInformation?patient_id=${patientId}`,
        accessToken,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch summary: ${response.error?.message}`);
      }

      setData(response.value);
    } catch (error) {
      console.error("Error fetching past visit summary:", error);
      setError("Failed to load past visit summary");
    } finally {
      setLoading(false);
    }
  };

  const fetchNoteContent = async (noteId: string) => {
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/notes/${noteId}`,
        accessToken,
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch note: ${response.error?.message}`);
      }

      setNoteData(response.value);
    } catch (error) {
      console.error("Error fetching note content:", error);
      setNoteData(null);
    }
  };

  const handleMouseEnter = useCallback(() => {
    if (!hasTrackedHoverRef.current) {
      hasTrackedHoverRef.current = true;
      trackEvent({
        event: "past_visit_summary_hover",
        hasSummary: !!data?.past_visit_summary,
      });
    }
  }, [patientId, data]);

  const handleViewNoteClick = useCallback(() => {
    // Get note_id from either direct note_id or from outputs
    const noteId =
      data?.patient?.latest_encounter?.note_id ||
      data?.patient?.latest_encounter?.outputs?.find(
        (output) => output.type === "note"
      )?.encounter_document_id;

    if (noteId) {
      trackEvent({
        event: "past_visit_summary_note_open",
      });
      fetchNoteContent(noteId);
      setIsPaneOpen(true);
    }
  }, [data, patientId]);

  useEffect(() => {
    fetchSummary();
  }, [patientId]);

  useEffect(() => {
    const mountCount = parseInt(
      localStorage.getItem("pastVisitSummaryMounts") || "0"
    );
    localStorage.setItem("pastVisitSummaryMounts", String(mountCount + 1));

    const hasFeedbackBeenSubmitted =
      localStorage.getItem("pastVisitSummaryFeedbackSubmitted") === "true";

    const timer = setTimeout(() => {
      setShowFeedback(mountCount >= 2 && !hasFeedbackBeenSubmitted);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleFeedbackComplete = () => {
    setShowFeedback(false);
    localStorage.setItem("pastVisitSummaryFeedbackSubmitted", "true");
  };

  if (!patientId) {
    return (
      <CollapsibleContentCard>
        <CollapsibleContentCard.Header title="Last Encounter" />
        <CollapsibleContentCard.Body>
          <Typography>
            Please select a {subjectLanguage} to view information about their
            last encounter.
          </Typography>
        </CollapsibleContentCard.Body>
      </CollapsibleContentCard>
    );
  }

  return (
    <>
      <Box onMouseEnter={handleMouseEnter}>
        <VisitSummaryCard
          data={data}
          loading={loading}
          error={error}
          onViewNote={handleViewNoteClick}
        />
      </Box>

      <SlideSidePane
        open={isPaneOpen}
        onClose={() => setIsPaneOpen(false)}
        title="Encounter Note"
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h4">{noteData?.note_title}</Typography>
            <Tooltip title="Go to Note">
              <IconButton
                onClick={() => navigate(`/note/${noteData?.note_id}`)}
              >
                <Launch />
              </IconButton>
            </Tooltip>
          </Box>
          {noteData && <NoteMetadata noteData={noteData} />}
          {noteData?.content.sections.map((section) => (
            <Box key={section.module_id}>
              <Typography variant="h6" sx={{ mb: 1 }}>
                {section.name?.replace(/\s*\([^)]*\)/g, "")}
              </Typography>
              <NoteSectionDisplayBox isNA={section.text === "N/A"}>
                {section.text}
              </NoteSectionDisplayBox>
            </Box>
          ))}
        </Box>
      </SlideSidePane>
    </>
  );
};
