import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Paper,
  Alert,
  Divider,
} from "@mui/material";
import {
  Assignment as AssignmentIcon,
  Edit as EditIcon,
  OpenInNew as OpenInNewIcon,
  CloudUpload as CloudUploadIcon,
} from "@mui/icons-material";
import { debounce } from "lodash";
import CollapsibleBaseBlock from "./CollapsibleBaseBlock";
import SlideSidePane from "../../../Views/Shared/SlideSidePane";
import { useUser } from "../../../context/user";
import { styled } from "@mui/material/styles";
import { BlockNoteEditor } from "@blocknote/core";
import { useCreateBlockNote } from "@blocknote/react";
import { BlockNoteView } from "@blocknote/mantine";
import "@blocknote/core/fonts/inter.css";
import "@blocknote/mantine/style.css";
import "../../../styles/blockNoteStyles.css";
import { v4 as uuidv4 } from "uuid";
import APIService from "../../../services/APIService";
import MarkdownImporter from "../../Markdown/MarkdownImporter";

// TreatmentPlanService using APIService pattern
const TreatmentPlanService = {
  // Fetch treatment plan for a patient
  async fetchTreatmentPlan(
    patientId: string,
    accessToken: string | null
  ): Promise<{ markdown: string; treatmentPlanId: string | null }> {
    console.log(`Fetching treatment plan for patient: ${patientId}`);

    if (!accessToken) {
      throw new Error("No access token available");
    }

    try {
      const result = await APIService.makeAPIGetRequest({
        requestString: `/treatment_plan/getTreatmentPlan`,
        params: { patient_id: patientId },
        accessToken,
      });

      if (!result.ok) {
        throw result.error;
      }

      if (result.value && result.value.treatment_plan) {
        return {
          markdown: result.value.treatment_plan.markdown_content || "",
          treatmentPlanId:
            result.value.treatment_plan.treatment_plan_id || null,
        };
      } else {
        // Return empty data if no treatment plan exists yet
        return { markdown: "", treatmentPlanId: null };
      }
    } catch (error: any) {
      console.error("Error fetching treatment plan:", error);
      // If a 404 is returned, it means no treatment plan exists yet
      if (error.message && error.message.includes("patient_not_found")) {
        return { markdown: "", treatmentPlanId: null };
      }
      throw error;
    }
  },

  // Create a new treatment plan for a patient
  async createTreatmentPlan(
    patientId: string,
    markdown: string,
    accessToken: string | null
  ): Promise<string> {
    console.log(`Creating treatment plan for patient: ${patientId}`);

    if (!accessToken) {
      throw new Error("No access token available");
    }

    const result = await APIService.makeAPIPostRequest({
      requestString: `/treatment_plan/createTreatmentPlan`,
      body: {
        patient_id: patientId,
        markdown_content: markdown,
      },
      accessToken,
    });

    if (!result.ok) {
      throw result.error;
    }

    if (result.value && result.value.treatment_plan_id) {
      return result.value.treatment_plan_id;
    } else {
      throw new Error("Failed to create treatment plan - no ID returned");
    }
  },

  // Update an existing treatment plan
  async updateTreatmentPlan(
    patientId: string,
    treatmentPlanId: string,
    markdown: string,
    accessToken: string | null
  ): Promise<void> {
    console.log(
      `Updating treatment plan: ${treatmentPlanId} for patient: ${patientId}`
    );

    if (!accessToken) {
      throw new Error("No access token available");
    }

    const result = await APIService.makeAPIPostRequest({
      requestString: `/treatment_plan/updateTreatmentPlan`,
      body: {
        patient_id: patientId,
        treatment_plan_id: treatmentPlanId,
        markdown_content: markdown,
      },
      accessToken,
    });

    if (!result.ok) {
      throw result.error;
    }
  },

  // Save treatment plan (creates or updates based on whether treatmentPlanId exists)
  async saveTreatmentPlan(
    patientId: string,
    markdown: string,
    treatmentPlanId: string | null,
    accessToken: string | null
  ): Promise<string> {
    console.log(`Saving treatment plan for patient: ${patientId}`);

    if (!accessToken) {
      throw new Error("No access token available");
    }

    // If we don't have a treatment plan ID, create a new one
    if (!treatmentPlanId) {
      return await this.createTreatmentPlan(patientId, markdown, accessToken);
    }

    // Otherwise, update the existing one
    await this.updateTreatmentPlan(
      patientId,
      treatmentPlanId,
      markdown,
      accessToken
    );
    return treatmentPlanId;
  },
};

// Styled components
const EditorContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
}));

interface TreatmentPlanBlockProps {
  patientId: string;
  defaultCollapsed?: boolean;
}

const TreatmentPlanBlock: React.FC<TreatmentPlanBlockProps> = ({
  patientId,
  defaultCollapsed = false,
}) => {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const { subjectLanguage, getAccessToken } = useUser();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [hasLoadedData, setHasLoadedData] = useState(!defaultCollapsed);
  // Add state to track current collapsed state
  const [isCurrentlyCollapsed, setIsCurrentlyCollapsed] =
    useState(defaultCollapsed);

  // Add state to store markdown content for quick syncing
  const [markdownContent, setMarkdownContent] = useState<string>("");

  // Add state to track the treatment plan ID
  const [treatmentPlanId, setTreatmentPlanId] = useState<string | null>(null);

  // Add a flag to prevent saving when loading content from server
  const [isLoadingContent, setIsLoadingContent] = useState(false);

  // Add a ref to track the last fetch time to prevent multiple fetches within a short period
  const lastFetchTimeRef = useRef<number>(0);
  const FETCH_DEBOUNCE_MS = 500; // Minimum time between fetches (milliseconds)

  // Create separate editor instances for main view and side panel
  const mainEditor = useCreateBlockNote();
  const sideEditor = useCreateBlockNote();

  // Fetch treatment plan data
  const fetchData = useCallback(async () => {
    // Check if a fetch was recently performed and skip if so
    const currentTime = Date.now();
    if (currentTime - lastFetchTimeRef.current < FETCH_DEBOUNCE_MS) {
      console.log("Skipping duplicate fetch within debounce period");
      return;
    }

    // Update the last fetch time
    lastFetchTimeRef.current = currentTime;

    console.log("Fetching treatment plan data");
    setLoading(true);
    setError(null);
    setIsLoadingContent(true); // Set flag to prevent auto-save during loading

    if (!hasLoadedData) {
      setHasLoadedData(true);
    }

    try {
      const accessToken = await getAccessToken();
      const result = await TreatmentPlanService.fetchTreatmentPlan(
        patientId,
        accessToken!
      );

      // Store the treatment plan ID
      setTreatmentPlanId(result.treatmentPlanId);

      // Store the markdown in state
      setMarkdownContent(result.markdown);

      // Convert markdown to blocks and update the main editor
      // Add a small timeout to ensure UI responsiveness
      setTimeout(async () => {
        console.log("Updating editor content");
        const blocks = await mainEditor.tryParseMarkdownToBlocks(
          result.markdown
        );
        mainEditor.replaceBlocks(mainEditor.document, blocks);

        // Set loading content to false after content is loaded
        setTimeout(() => setIsLoadingContent(false), 100);
      }, 50);
    } catch (err) {
      console.error("Error fetching treatment plan:", err);
      setError("Failed to load treatment plan. Please try again.");
      setIsLoadingContent(false);
    } finally {
      setLoading(false);
    }
  }, [
    patientId,
    getAccessToken,
    mainEditor,
    hasLoadedData,
    setHasLoadedData,
    setLoading,
    setError,
    setTreatmentPlanId,
    setMarkdownContent,
  ]);

  // Add effect to reset state and reload data when patientId changes
  useEffect(() => {
    // Reset all state when patient changes
    setMarkdownContent("");
    setTreatmentPlanId(null);
    setError(null);
    setIsEditOpen(false);
    setImportModalOpen(false);

    // Reset the last fetch time when changing patients
    lastFetchTimeRef.current = 0;

    // Only fetch data if component is currently open
    if (!isCurrentlyCollapsed) {
      fetchData();
    } else {
      // If collapsed, mark that data hasn't been loaded yet
      setHasLoadedData(false);
    }
  }, [patientId, fetchData, isCurrentlyCollapsed]);

  // Debounced save function
  const debouncedSave = useCallback(
    debounce(async (content: string) => {
      // Skip saving if we're just loading content from server
      if (isLoadingContent) {
        console.log("Skipping save while loading content");
        return;
      }

      try {
        setSaving(true);
        const accessToken = await getAccessToken();

        // Save the treatment plan and get the ID (either existing or new)
        const updatedTreatmentPlanId =
          await TreatmentPlanService.saveTreatmentPlan(
            patientId,
            content,
            treatmentPlanId,
            accessToken!
          );

        // Update the treatment plan ID if it's new
        if (updatedTreatmentPlanId !== treatmentPlanId) {
          setTreatmentPlanId(updatedTreatmentPlanId);
        }
      } catch (err) {
        console.error("Error saving treatment plan:", err);
        setError("Failed to save treatment plan. Please try again.");
      } finally {
        setSaving(false);
      }
    }, 1000), // 1 second debounce
    [patientId, getAccessToken, treatmentPlanId, isLoadingContent]
  );

  // Initialize editor with fetched content
  useEffect(() => {
    // Only fetch data if not collapsed initially
    if (!defaultCollapsed) {
      fetchData();
    }
  }, [defaultCollapsed, fetchData]);

  // Add a new effect to ensure side editor is always synced with main editor when opened
  useEffect(() => {
    if (isEditOpen) {
      const syncSideEditor = async () => {
        setIsLoadingContent(true); // Prevent auto-save during sync
        try {
          // Get latest markdown from main editor
          const currentMarkdown = await mainEditor.blocksToMarkdownLossy(
            mainEditor.document
          );

          // Update the side editor with this content
          const blocks = await sideEditor.tryParseMarkdownToBlocks(
            currentMarkdown
          );
          sideEditor.replaceBlocks(sideEditor.document, blocks);
        } catch (err) {
          console.error("Error syncing side editor:", err);
          setError("Failed to sync content to side panel editor.");
        } finally {
          setTimeout(() => setIsLoadingContent(false), 100);
        }
      };

      syncSideEditor();
    }
  }, [isEditOpen, mainEditor, sideEditor]);

  // Save content when main editor changes
  const handleMainEditorChange = useCallback(async () => {
    // Skip if we're just loading content from server
    if (isLoadingContent) return;

    const markdown = await mainEditor.blocksToMarkdownLossy(
      mainEditor.document
    );
    setMarkdownContent(markdown);
    debouncedSave(markdown);
  }, [mainEditor, debouncedSave, isLoadingContent]);

  // Save content when side editor changes
  const handleSideEditorChange = useCallback(async () => {
    // Skip if we're just loading content from server
    if (isLoadingContent) return;

    const markdown = await sideEditor.blocksToMarkdownLossy(
      sideEditor.document
    );
    setMarkdownContent(markdown);
    debouncedSave(markdown);
  }, [sideEditor, debouncedSave, isLoadingContent]);

  // Handle opening the editor view - sync content to side editor
  const handleOpenEditor = useCallback(async () => {
    try {
      setIsLoadingContent(true); // Prevent auto-save during sync

      // First update markdown content from main editor
      const currentMarkdown = await mainEditor.blocksToMarkdownLossy(
        mainEditor.document
      );
      setMarkdownContent(currentMarkdown);

      // Then initialize side editor with this content
      const blocks = await sideEditor.tryParseMarkdownToBlocks(currentMarkdown);
      sideEditor.replaceBlocks(sideEditor.document, blocks);

      // Finally, open the side pane
      setIsEditOpen(true);

      setTimeout(() => setIsLoadingContent(false), 100);
    } catch (err) {
      console.error("Error syncing editors:", err);
      setError("Failed to sync content between editors.");
      setIsLoadingContent(false);
    }
  }, [mainEditor, sideEditor, setMarkdownContent]);

  // Handle closing the editor view - sync content back to main editor
  const handleCloseEditor = useCallback(async () => {
    try {
      setIsLoadingContent(true); // Prevent auto-save during sync

      // First update markdown content from side editor
      const currentMarkdown = await sideEditor.blocksToMarkdownLossy(
        sideEditor.document
      );
      setMarkdownContent(currentMarkdown);

      // Then update main editor with this content
      const blocks = await mainEditor.tryParseMarkdownToBlocks(currentMarkdown);
      mainEditor.replaceBlocks(mainEditor.document, blocks);

      // Finally, close the side pane
      setIsEditOpen(false);

      setTimeout(() => setIsLoadingContent(false), 100);
    } catch (err) {
      console.error("Error syncing editors:", err);
      setError("Failed to sync content between editors.");
      setIsLoadingContent(false);
    }
  }, [mainEditor, sideEditor, setMarkdownContent]);

  // Handle collapse change
  const handleCollapseChange = (isCollapsed: boolean) => {
    // Update our internal tracking of collapsed state
    setIsCurrentlyCollapsed(isCollapsed);

    // If expanding and we haven't loaded data yet, fetch it
    if (!isCollapsed && !hasLoadedData) {
      fetchData();
    }
  };

  // Handle successful import of markdown
  const handleImportSuccess = async (markdown: string) => {
    try {
      setIsLoadingContent(true); // Prevent auto-save during import

      // Update the editor with the imported markdown
      const blocks = await mainEditor.tryParseMarkdownToBlocks(markdown);
      mainEditor.replaceBlocks(mainEditor.document, blocks);
      setMarkdownContent(markdown);

      // If the side editor is open, update it too
      if (isEditOpen) {
        const sideBlocks = await sideEditor.tryParseMarkdownToBlocks(markdown);
        sideEditor.replaceBlocks(sideEditor.document, sideBlocks);
      }

      // Save the imported content, even if loading content flag is set
      const accessToken = await getAccessToken();
      const updatedTreatmentPlanId =
        await TreatmentPlanService.saveTreatmentPlan(
          patientId,
          markdown,
          treatmentPlanId,
          accessToken!
        );

      // Update the treatment plan ID if it's new
      if (updatedTreatmentPlanId !== treatmentPlanId) {
        setTreatmentPlanId(updatedTreatmentPlanId);
      }

      setTimeout(() => setIsLoadingContent(false), 100);
    } catch (err) {
      console.error("Error updating editor with imported content:", err);
      setError("Failed to update editor with imported content.");
      setIsLoadingContent(false);
    }
  };

  return (
    <>
      <CollapsibleBaseBlock
        defaultCollapsed={defaultCollapsed}
        onCollapseChange={handleCollapseChange}
      >
        <CollapsibleBaseBlock.Header
          title="Treatment Plan"
          icon={
            <AssignmentIcon sx={{ fontSize: 20, color: "text.secondary" }} />
          }
          action={
            <Box sx={{ display: "flex", gap: 1 }}>
              {!markdownContent && (
                <Button
                  variant="outlined"
                  size="small"
                  startIcon={<CloudUploadIcon />}
                  onClick={() => setImportModalOpen(true)}
                  disabled={loading}
                  sx={{
                    borderRadius: "0.5rem",
                    textTransform: "none",
                    borderColor: "borderColors.primary",
                    "&:hover": {
                      borderColor: "primary.main",
                      backgroundColor: "action.hover",
                    },
                  }}
                >
                  Import
                </Button>
              )}
              <Button
                variant="outlined"
                size="small"
                startIcon={<OpenInNewIcon />}
                onClick={handleOpenEditor}
                disabled={loading}
                sx={{
                  borderRadius: "0.5rem",
                  textTransform: "none",
                  borderColor: "borderColors.primary",
                  "&:hover": {
                    borderColor: "primary.main",
                    backgroundColor: "action.hover",
                  },
                }}
              >
                Open
              </Button>
            </Box>
          }
        />

        <CollapsibleBaseBlock.Body>
          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
              <CircularProgress />
            </Box>
          ) : error ? (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          ) : (
            <EditorContainer key={`treatment-plan-editor-${patientId}`}>
              {/* The main editor view with its own editor instance */}
              <BlockNoteView
                editor={mainEditor}
                editable={true}
                theme="light"
                onChange={handleMainEditorChange}
                style={{ maxHeight: "70vh" }}
              />
            </EditorContainer>
          )}
        </CollapsibleBaseBlock.Body>
      </CollapsibleBaseBlock>

      <SlideSidePane
        open={isEditOpen}
        onClose={handleCloseEditor}
        title="Treatment Plan"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
          }}
        >
          <Box sx={{ mb: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}
          </Box>

          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              mb: 2,
              height: "calc(100% - 120px)",
            }}
          >
            {/* The editable view in the side pane uses a different editor instance */}
            <BlockNoteView
              editor={sideEditor}
              theme="light"
              onChange={handleSideEditorChange}
              style={{
                height: "100%",
                overflow: "auto",
                display: "flex",
                flexDirection: "column",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderTop: 1,
              borderColor: "divider",
              pt: 2,
              mt: "auto",
            }}
          >
            <Box sx={{ marginLeft: "auto" }}>
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                onClick={handleCloseEditor}
              >
                Close
              </Button>
            </Box>
          </Box>
        </Box>
      </SlideSidePane>

      {/* Use the new MarkdownImporter component */}
      <MarkdownImporter
        isOpen={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        onImportSuccess={handleImportSuccess}
        endpoint="/treatment_plan"
        contentFieldName="text"
        additionalParams={{
          patient_id: patientId,
        }}
        title="Import Treatment Plan"
        description="Paste text or drag a file to import a treatment plan. Supported file types: .pdf, .docx, .jpg, .jpeg, .png"
        acceptedFileTypes=".pdf,.docx,.jpg,.jpeg,.png"
        warningText={
          markdownContent
            ? "Note: This import will overwrite the existing treatment plan for this patient."
            : undefined
        }
      />
    </>
  );
};

export default TreatmentPlanBlock;
