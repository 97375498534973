import React, { useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Grid,
  Button,
  Tooltip,
  Stack,
} from "@mui/material";
import { Patient } from "../../../types/types";
import SlideSidePane from "../../../Views/Shared/SlideSidePane";
import PatientInformationView from "../PatientInformationView";
import BaseBlock from "./BaseBlock";
import { parseISO } from "date-fns";
import { Edit } from "@mui/icons-material";
import { useUser } from "../../../context/user/context";
import { capitalize } from "../../../utils/utils";
import { Link } from "react-router-dom";

interface PatientOverviewBlockProps {
  patient: Patient;
  onPatientUpdate?: (updatedPatient: Patient) => void;
  actions?: React.ReactNode;
  mode?: "dense" | "default";
  linkToPatient?: boolean;
}

const PatientOverviewBlock: React.FC<PatientOverviewBlockProps> = ({
  patient,
  onPatientUpdate,
  actions,
  mode = "default",
  linkToPatient = false,
}) => {
  const [showEditPane, setShowEditPane] = useState(false);
  const { subjectLanguage } = useUser();

  const formatDate = (dateString: string) => {
    if (!dateString) return "";
    const date = parseISO(dateString);
    return date.toLocaleDateString();
  };

  const handlePatientSave = (updatedPatient: Patient) => {
    if (onPatientUpdate) {
      onPatientUpdate(updatedPatient);
    }
  };

  const renderContent = () => {
    if (mode === "dense") {
      return (
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {linkToPatient ? (
            <Link
              to={`/patient/${patient.patient_id}`}
              style={{ textDecoration: "none" }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  bgcolor: "primary.main",
                  fontSize: "1rem",
                  cursor: "pointer",
                }}
              >
                {`${patient.first_name?.[0] || ""}${
                  patient.last_name?.[0] || ""
                }`}
              </Avatar>
            </Link>
          ) : (
            <Avatar
              sx={{
                width: 40,
                height: 40,
                bgcolor: "primary.main",
                fontSize: "1rem",
              }}
            >
              {`${patient.first_name?.[0] || ""}${
                patient.last_name?.[0] || ""
              }`}
            </Avatar>
          )}

          <Box sx={{ flexGrow: 1 }}>
            {linkToPatient ? (
              <Link
                to={`/patient/${patient.patient_id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 0.5,
                    fontWeight: 500,
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {`${patient.first_name || ""} ${patient.last_name || ""}`}
                </Typography>
              </Link>
            ) : (
              <Typography variant="subtitle1" sx={{ mb: 0.5, fontWeight: 500 }}>
                {`${patient.first_name || ""} ${patient.last_name || ""}`}
              </Typography>
            )}

            <Grid container spacing={1} sx={{ color: "text.secondary" }}>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ display: "flex", gap: 0.5 }}>
                  <span style={{ color: "text.secondary", opacity: 0.7 }}>
                    ID:
                  </span>
                  {patient.identifier || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ display: "flex", gap: 0.5 }}>
                  <span style={{ color: "text.secondary", opacity: 0.7 }}>
                    DOB:
                  </span>
                  {formatDate(patient.dob || "") || "N/A"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ display: "flex", gap: 0.5 }}>
                  <span style={{ color: "text.secondary", opacity: 0.7 }}>
                    Gender:
                  </span>
                  <span style={{ textTransform: "capitalize" }}>
                    {patient.gender || "N/A"}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ display: "flex", gap: 0.5 }}>
                  <span style={{ color: "text.secondary", opacity: 0.7 }}>
                    Contact:
                  </span>
                  {patient.email || patient.phone || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      );
    }

    return (
      <Box sx={{ display: "flex", gap: 2, alignItems: "flex-start" }}>
        {linkToPatient ? (
          <Link
            to={`/patient/${patient.patient_id}`}
            style={{ textDecoration: "none" }}
          >
            <Avatar
              sx={{
                width: 80,
                height: 80,
                bgcolor: "primary.main",
                fontSize: "2rem",
                cursor: "pointer",
              }}
            >
              {`${patient.first_name?.[0] || ""}${
                patient.last_name?.[0] || ""
              }`}
            </Avatar>
          </Link>
        ) : (
          <Avatar
            sx={{
              width: 80,
              height: 80,
              bgcolor: "primary.main",
              fontSize: "2rem",
            }}
          >
            {`${patient.first_name?.[0] || ""}${patient.last_name?.[0] || ""}`}
          </Avatar>
        )}

        <Box sx={{ flexGrow: 1 }}>
          {linkToPatient ? (
            <Link
              to={`/patient/${patient.patient_id}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="h5"
                component="h2"
                gutterBottom
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                {`${patient.first_name || ""} ${patient.last_name || ""}`}
              </Typography>
            </Link>
          ) : (
            <Typography variant="h5" component="h2" gutterBottom>
              {`${patient.first_name || ""} ${patient.last_name || ""}`}
            </Typography>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="text.secondary">
                Identifier (PRN)
              </Typography>
              <Typography variant="body1">
                {patient.identifier || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="text.secondary">
                Date of Birth
              </Typography>
              <Typography variant="body1">
                {formatDate(patient.dob || "") || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="text.secondary">
                Gender
              </Typography>
              <Typography variant="body1" sx={{ textTransform: "capitalize" }}>
                {patient.gender || "N/A"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="text.secondary">
                Phone Number
              </Typography>
              <Typography variant="body1">{patient.phone || "N/A"}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" color="text.secondary">
                Email
              </Typography>
              <Typography variant="body1">{patient.email || "N/A"}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <BaseBlock
        title={`${capitalize(subjectLanguage)} Overview`}
        actions={
          <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
            <Tooltip title="Edit information" arrow>
              <Edit
                onClick={() => setShowEditPane(true)}
                sx={{
                  fontSize: 16,
                  color: "text.secondary",
                  opacity: 0.7,
                  cursor: "pointer",
                  "&:hover": {
                    opacity: 1,
                  },
                }}
              />
            </Tooltip>
            {actions && (
              <>
                <Box
                  sx={{
                    width: "1px",
                    height: "1rem",
                    bgcolor: "divider",
                  }}
                />
                {actions}
              </>
            )}
          </Box>
        }
      >
        {renderContent()}
      </BaseBlock>

      <SlideSidePane
        open={showEditPane}
        onClose={() => setShowEditPane(false)}
        title={`Edit ${capitalize(subjectLanguage)} Information`}
      >
        <PatientInformationView
          patientId={patient.patient_id}
          onPatientSave={handlePatientSave}
        />
      </SlideSidePane>
    </>
  );
};

export default PatientOverviewBlock;
