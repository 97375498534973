import React, { useState, useEffect, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  TextField,
  Paper,
  IconButton,
  Slide,
  Tooltip,
  CircularProgress,
  Divider,
} from "@mui/material";
import {
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  ArrowForward as ArrowForwardIcon,
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
} from "@mui/icons-material";
import { TransitionProps } from "@mui/material/transitions";
import { useUser } from "../../context/user";
import APIService from "../../services/APIService";
import ProviderInformationView from "../Provider/ProviderInformationView";
import { ProviderBillingInfo } from "../../types/types";

// Slide transition for the dialog
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface SendFormOnboardingProps {
  open: boolean;
  onClose: () => void;
}

interface StepContent {
  title: string;
  content: React.ReactNode;
  verification?: () => Promise<boolean> | boolean;
  isCompleted?: () => boolean; // Add function to check if step is already completed
}

const SendFormOnboarding: React.FC<SendFormOnboardingProps> = ({
  open,
  onClose,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [communicationsName, setCommunicationsName] = useState("");
  const [checking, setChecking] = useState(false);
  const [providerInfo, setProviderInfo] = useState<
    ProviderBillingInfo & { email_address?: string }
  >({} as any);
  const [requirements, setRequirements] = useState({
    hasProviderName: false,
    hasSmsEnabled: false,
    // Add more requirements as needed
  });
  const { getAccessToken, subjectLanguage, updateOnboardingStep } = useUser();

  // Fetch user settings and provider info on mount
  useEffect(() => {
    if (open) {
      fetchProviderInfo();
    }
  }, [open]);

  const fetchProviderInfo = async () => {
    setChecking(true);
    try {
      const accessToken = await getAccessToken();
      const response = await APIService.makeAPIGetRequest({
        requestString: `/user/getProviderBillingInfo`,
        accessToken,
      });

      if (response.ok) {
        const data = response.value.provider_billing_info || {};

        // Initialize communications name from provider info if available
        if (data.communications_name) {
          setCommunicationsName(data.communications_name);
        }

        setProviderInfo((prev) => ({
          ...prev,
          ...data,
        }));
      }
    } catch (error) {
      console.error("Error fetching provider information:", error);
    } finally {
      setChecking(false);
    }
  };

  const saveCommunicationsName = async () => {
    // Mock implementation with a delay
    await new Promise((resolve) => setTimeout(resolve, 750));

    // Only allow saving if communications name is not empty
    if (!communicationsName.trim()) {
      return false;
    }

    setRequirements((prev) => ({
      ...prev,
      hasProviderName: true,
    }));

    // Update provider info with communications name
    setProviderInfo((prev) => ({
      ...prev,
      communications_name: communicationsName,
    }));

    return true;
  };

  const handleProviderSave = (
    updatedProvider: ProviderBillingInfo & { email_address?: string }
  ) => {
    setProviderInfo(updatedProvider);
    // Validate required provider fields
    const {
      first_name,
      last_name,
      communications_name,
      city,
      state,
      zip,
      phone,
      email_address,
    } = updatedProvider;
    const hasRequiredFields =
      first_name &&
      last_name &&
      communications_name &&
      city &&
      state &&
      zip &&
      phone;

    return hasRequiredFields;
  };

  // Check if provider info is already complete
  const isProviderInfoComplete = () => {
    const {
      first_name,
      last_name,
      communications_name,
      city,
      state,
      zip,
      phone,
    } = providerInfo;
    return !!(
      first_name &&
      last_name &&
      communications_name &&
      city &&
      state &&
      zip &&
      phone
    );
  };

  // Mock function to register onboarding completion
  const registerOnboardingComplete = async () => {
    setChecking(true);
    try {
      console.log("Onboarding completion registered successfully");

      updateOnboardingStep("sms_form_sending_completed", true);

      return true;
    } catch (error) {
      console.error("Error registering onboarding completion:", error);
      return false;
    } finally {
      setChecking(false);
    }
  };

  // Mock SMS preview component
  const SMSPreview = ({ displayName }: { displayName: string }) => {
    const INTAKE_FORMS_BASE_URL = "https://forms.jotpsych.com";

    let message = "";
    if (displayName) {
      message += `${displayName} has sent you a new form to complete. `;
    } else {
      message += "You have a new form to complete. ";
    }
    message += `Please tap the link below to fill it out:\n\n${INTAKE_FORMS_BASE_URL}/f/abc-xyz`;

    return (
      <Box sx={{ my: 2 }}>
        <Paper
          elevation={0}
          sx={{
            maxWidth: 280,
            p: 2,
            borderRadius: "20px 20px 5px 20px",
            bgcolor: "#34C759", // iOS message green
            color: "white",
            position: "relative",
            ml: 2,
            "&:before": {
              content: "''",
              position: "absolute",
              bottom: 0,
              left: -10,
              width: 20,
              height: 20,
              borderRadius: "0 0 20px 0",
              boxShadow: "5px 0 0 0 #34C759",
              bgcolor: "transparent",
            },
          }}
        >
          <Typography
            variant="body2"
            sx={{
              whiteSpace: "pre-line",
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
              fontWeight: 400,
            }}
          >
            {message}
          </Typography>
        </Paper>
      </Box>
    );
  };

  // Step content definitions
  const steps: StepContent[] = [
    // Welcome step
    {
      title: "Welcome to Form Sending",
      content: (
        <Box>
          <Typography paragraph>
            This wizard will guide you through setting up and sending forms to
            your {subjectLanguage || "patient"}s.
          </Typography>
          <Typography paragraph>
            Forms allow you to collect information from{" "}
            {subjectLanguage || "patient"}s before appointments, reducing
            paperwork and saving time.
          </Typography>
          <Typography variant="h6" sx={{ mt: 3, mb: 1 }}>
            How it works:
          </Typography>
          <Typography component="ol" sx={{ pl: 2 }}>
            <li>
              You select forms to send to a {subjectLanguage || "patient"}
            </li>
            <li>
              The {subjectLanguage || "patient"} receives a text message with a
              secure link
            </li>
            <li>They complete the form on their phone or computer</li>
            <li>Results are automatically saved to their record</li>
          </Typography>
        </Box>
      ),
    },

    // Personalize step
    {
      title: "Personalize Text Messages",
      content: (
        <Box>
          <Typography paragraph>
            When you send a form, {subjectLanguage || "patient"}s will receive a
            text message like the one below.
          </Typography>

          <SMSPreview displayName={communicationsName} />

          <Typography paragraph>
            Personalizing the message with your name helps{" "}
            {subjectLanguage || "patient"}s recognize who is contacting them.
          </Typography>

          <TextField
            fullWidth
            required
            label="Your name or practice name"
            value={communicationsName}
            onChange={(e) => setCommunicationsName(e.target.value)}
            variant="outlined"
            placeholder="Dr. Smith"
            helperText="This name will appear in text messages to your patients"
            sx={{ my: 2 }}
          />
        </Box>
      ),
      verification: async () => {
        // Verify communications name is not empty
        if (!communicationsName.trim()) {
          return false;
        }
        return saveCommunicationsName();
      },
      isCompleted: () => !!communicationsName.trim(),
    },

    // Provider Information step
    {
      title: "Provider Information",
      content: (
        <Box>
          <Typography paragraph>
            We need some information about you to include in communications with{" "}
            {subjectLanguage || "patient"}s.
          </Typography>
          <Typography paragraph>
            Please complete the following fields. This information will be used
            to identify you to {subjectLanguage || "patient"}s and for billing
            purposes.
          </Typography>

          {/* If all required info already exists, show a message instead */}
          {isProviderInfoComplete() ? (
            <Box
              sx={{
                mt: 2,
                mb: 2,
                p: 2,
                borderRadius: 1,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <CheckCircleIcon color="success" sx={{ mr: 1 }} />
                <Typography variant="body1" fontWeight="medium">
                  Your provider information is complete!
                </Typography>
              </Box>
            </Box>
          ) : (
            <ProviderInformationView
              fieldKeys={[
                "first_name",
                "last_name",
                "communications_name",
                "city",
                "state",
                "zip",
                "phone",
                "email_address",
              ]}
              highlightedFields={[
                "communications_name",
                "phone",
                "email_address",
              ]}
              onProviderSave={handleProviderSave}
              paperProps={{
                elevation: 0,
                sx: { p: 0, mb: 0 },
              }}
              // Pass the initial communications name from the previous step
              initialProviderInfo={{
                communications_name: communicationsName,
              }}
            />
          )}

          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            <InfoIcon sx={{ fontSize: 16, verticalAlign: "middle", mr: 0.5 }} />
            Your "Communications Name" will be shown in text messages to{" "}
            {subjectLanguage || "patient"}s.
          </Typography>
        </Box>
      ),
      verification: async () => {
        // Check if we have the required provider information
        return isProviderInfoComplete();
      },
      isCompleted: isProviderInfoComplete,
    },

    // Combined final step (merging Patient Requirements and All Set)
    {
      title: "Ready to Send Forms",
      content: (
        <Box>
          {/* First part - Patient Requirements */}
          <Typography variant="h6" gutterBottom>
            Patient Requirements
          </Typography>
          <Typography paragraph>
            Before you can send forms to a {subjectLanguage || "patient"},
            you'll need to collect:
          </Typography>

          <Box sx={{ mt: 2, mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <CheckCircleIcon color="success" sx={{ mr: 1 }} />
              <Typography>
                {capitalize(subjectLanguage || "patient")}'s phone number
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
              <CheckCircleIcon color="success" sx={{ mr: 1 }} />
              <Typography>
                {capitalize(subjectLanguage || "patient")}'s date of birth (for
                verification)
              </Typography>
            </Box>
          </Box>

          <Typography paragraph>
            These fields are required to send a form and ensure it goes to the
            right person.
          </Typography>

          <Divider sx={{ my: 3 }} />

          {/* Second part - You're All Set */}
          <Box sx={{ textAlign: "center", py: 2 }}>
            <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h6" gutterBottom>
              You're ready to send forms!
            </Typography>
            <Typography paragraph>
              Click the "Send Form" button next to any{" "}
              {subjectLanguage || "patient"} to get started.
            </Typography>
          </Box>
        </Box>
      ),
    },
  ];

  const handleNext = async () => {
    const currentStep = steps[activeStep];

    // For personalize text messages step, validate communications name
    if (activeStep === 1) {
      if (!communicationsName.trim()) {
        return; // Stop here if communications name is empty
      }
    }

    // For provider information step, validate provider info fields and register completion
    if (activeStep === 2) {
      // If all required fields are already filled, register completion and move to next step
      if (isProviderInfoComplete()) {
        setChecking(true);
        const registrationSuccess = await registerOnboardingComplete();
        setChecking(false);

        if (registrationSuccess) {
          // Move to next step without further verification
          setActiveStep((prevStep) => prevStep + 1);
        }
        return;
      }

      // Otherwise check that all fields are filled
      if (
        !providerInfo.first_name ||
        !providerInfo.last_name ||
        !providerInfo.communications_name ||
        !providerInfo.city ||
        !providerInfo.state ||
        !providerInfo.zip ||
        !providerInfo.phone
      ) {
        return; // Don't proceed if required fields are missing
      }
    }

    if (currentStep.verification) {
      // Skip verification if step is already completed
      if (currentStep.isCompleted && currentStep.isCompleted()) {
        setActiveStep((prevStep) => prevStep + 1);
        return;
      }

      setChecking(true);
      const passed = await currentStep.verification();
      setChecking(false);

      if (!passed) {
        // Show error or handle failed verification
        return;
      }
    }

    // Register onboarding complete when moving from provider info to final step
    if (activeStep === 2) {
      setChecking(true);
      const registrationSuccess = await registerOnboardingComplete();
      setChecking(false);

      if (!registrationSuccess) {
        // Could show an error message here if needed
        console.error("Failed to register onboarding completion");
      }
    }

    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    } else {
      onClose();
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  // Helper function to capitalize first letter
  function capitalize(string: string | undefined): string {
    if (!string) return "Patient";
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: "12px",
          overflow: "hidden",
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="h6">Form Sending Guide</Typography>
        <IconButton
          edge="end"
          aria-label="close"
          onClick={onClose}
          size="small"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 4, pt: 2 }}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel>{step.title}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {steps[activeStep].content}
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          borderTop: 1,
          borderColor: "divider",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={handleBack}
          disabled={activeStep === 0 || checking}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>

        <Tooltip
          title={
            activeStep === 1 && !communicationsName.trim()
              ? "Please enter your name or practice name to continue"
              : activeStep === 2 &&
                !isProviderInfoComplete() &&
                (!providerInfo.first_name ||
                  !providerInfo.last_name ||
                  !providerInfo.communications_name ||
                  !providerInfo.city ||
                  !providerInfo.state ||
                  !providerInfo.zip ||
                  !providerInfo.phone)
              ? "Please complete all required provider information fields"
              : ""
          }
          placement="top"
          arrow
        >
          <Box>
            {" "}
            {/* Tooltip requires a wrapper element when target is disabled */}
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={
                checking ||
                (activeStep === 1 && !communicationsName.trim()) ||
                (activeStep === 2 &&
                  !isProviderInfoComplete() &&
                  (!providerInfo.first_name ||
                    !providerInfo.last_name ||
                    !providerInfo.communications_name ||
                    !providerInfo.city ||
                    !providerInfo.state ||
                    !providerInfo.zip ||
                    !providerInfo.phone))
              }
              endIcon={
                checking ? (
                  <CircularProgress size={16} color="inherit" />
                ) : activeStep === steps.length - 1 ? undefined : (
                  <ArrowForwardIcon />
                )
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default SendFormOnboarding;
