import { useEffect, useState } from "react";
import { useNavigate, useNavigation } from "react-router-dom";
import {
  textToParagraph,
  formatDate,
  truncateText,
  truncateTextByCharCount,
} from "../utils/utils";
import { PrimaryButton } from "./Buttons/Buttons";
import { SVGIcon } from "./SVGIcon";
import { UserNoteType } from "../types/types";
import {
  Typography,
  Box,
  CircularProgress,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useUIState } from "../context/uiState";
import { UIStateType } from "../context/uiState/context";
import { ComponentProps } from "react";
import React from "react";
import FullScreenLoader from "./FullScreenLoader";
import { getUserInfoFromLocalStorage } from "../utils/LocalStorageUtils";
import { getErrorMessage } from "../utils/errorUtils";
import {
  Sync,
  Assignment,
  AssignmentTurnedIn,
  Check,
} from "@mui/icons-material";

export const UserNote: React.FC<UserNoteType> = ({
  note_id,
  index,
  note_title,
  status,
  review_status,
  time,
  summary,
  issuing_user_name,
  link,
  checkedNoteIds,
  setCheckedNoteIds,
  origin,
  error_message = "",
  patient_id,
  patient_name,
  completion_status,
  completion_timestamp,
}) => {
  const userInfo = getUserInfoFromLocalStorage();
  const navigate = useNavigate();
  const { state, setState } = useUIState();
  const [loading, setLoading] = useState<boolean>(false);

  const handleClick = () => {
    if (status !== "errored") {
      setLoading(true);

      setState((prevState: UIStateType) => ({
        ...prevState,
        navigation: { currentPage: "note" },
      }));
      navigate(link, { state: { origin: origin } });
    }
  };

  let haveTop = 0;
  if (index === 0) {
    haveTop = 1;
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let note = note_id;
    if (event.target.checked && setCheckedNoteIds) {
      // If the checkbox is checked, add the note to the checkedNoteIds array
      setCheckedNoteIds((prevcheckedNoteIds: string[]) => [
        ...prevcheckedNoteIds,
        note,
      ]);
    } else if (setCheckedNoteIds) {
      // // If the checkbox is unchecked, remove the note from the checkedNoteIds array
      setCheckedNoteIds((prevcheckedNoteIds: string[]) =>
        prevcheckedNoteIds.filter((checkedNote) => checkedNote !== note)
      );
    }
  };

  return (
    <Box
      className="note-container"
      sx={{
        display: "flex",
        alignItems: "flex-start",
        borderTop: haveTop,
        borderBottom: 1,
        paddingTop: "1.5rem",
        paddingBottom: "2rem",
        borderColor: "borderColors.primary",
        "&:hover": {
          cursor: "pointer",
          bgcolor: "backgroundColors.secondary",
        },
      }}
    >
      {origin !== "dashboard" && (
        <Checkbox
          checked={checkedNoteIds && checkedNoteIds.includes(note_id)}
          onChange={handleCheckboxChange}
          sx={{
            color: "borderColors.darkprimary",
            "& .MuiSvgIcon-root": {
              fontSize: "1.5rem",
              padding: "0px",
            },
          }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          paddingTop: "0.5rem",
          px: "1rem",
          width: "100%",
          "&:hover": {
            cursor: "pointer",
            bgcolor: "backgroundColors.secondary",
          },
        }}
        onClick={handleClick}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "0.5rem",
            }}
          >
            <Typography
              color="textColors.darkHeader"
              fontSize="1rem"
              fontWeight="700"
              lineHeight="1.5rem"
              sx={{ marginBottom: "0.25rem" }}
            >
              {note_title || patient_name}
            </Typography>
            {patient_name && note_title && (
              <Typography
                color="textColors.darkText"
                fontSize="0.875rem"
                fontWeight="500"
                lineHeight="1.5rem"
              >
                {patient_name}
              </Typography>
            )}
          </Box>
          <Box>
            {issuing_user_name && (
              <Typography
                color="textColors.darkText"
                fontSize="0.8rem"
                fontStyle="normal"
                fontWeight="600"
                lineHeight="1rem"
                alignItems="center"
                sx={{ paddingBottom: "0.5rem" }}
              >
                {issuing_user_name}
              </Typography>
            )}
            <Typography
              color="textColors.darkText"
              fontSize="0.8rem"
              fontStyle="normal"
              fontWeight="600"
              lineHeight="1rem"
              letterSpacing="0.125rem"
              textTransform="uppercase"
              textAlign="right"
              sx={{ paddingBottom: "0.5rem" }}
            >
              {formatDate(time)}
            </Typography>
            {status === "processing" ? (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress value={100} color="warning" />
              </Box>
            ) : status === "errored" ? (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress
                  value={100}
                  variant="determinate"
                  color="error"
                />
              </Box>
            ) : completion_status ? (
              <Box sx={{ paddingBottom: "0.5rem" }}>
                <LinearProgress
                  variant="determinate"
                  value={100}
                  color={completion_status === "completed" ? "success" : "info"}
                />
              </Box>
            ) : (
              status === "completed" && (
                <Box sx={{ paddingBottom: "0.5rem" }}>
                  <LinearProgress
                    variant="determinate"
                    value={100}
                    color={review_status === "archived" ? "success" : "info"}
                  />
                </Box>
              )
            )}
            {completion_status === "completed" && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  paddingBottom: "0.5rem",
                }}
              >
                <Tooltip
                  title={`Marked completed at: ${
                    completion_timestamp
                      ? formatDate(completion_timestamp)
                      : "Unknown"
                  }`}
                  arrow
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      cursor: "help",
                    }}
                  >
                    <Check sx={{ color: "success.main" }} />
                    <Typography
                      color="textColors.darkText"
                      fontSize="0.8rem"
                      fontWeight="600"
                      lineHeight="1rem"
                    >
                      Completed
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            )}
            {status === "processing" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                  paddingBottom: "0.5rem",
                }}
              >
                <Tooltip title="Note is being processed" arrow>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                      cursor: "help",
                    }}
                  >
                    <Sync sx={{ color: "warning.main" }} />
                    <Typography
                      color="textColors.darkText"
                      fontSize="0.8rem"
                      fontWeight="600"
                      lineHeight="1rem"
                    >
                      Processing
                    </Typography>
                  </Box>
                </Tooltip>
              </Box>
            ) : (
              completion_status === "reviewing" &&
              origin !== "dashboard" && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    paddingBottom: "0.5rem",
                  }}
                >
                  <Tooltip title="Note is being reviewed" arrow>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.5rem",
                        cursor: "help",
                      }}
                    >
                      <Assignment sx={{ color: "info.main" }} />
                      <Typography
                        color="textColors.darkText"
                        fontSize="0.8rem"
                        fontWeight="600"
                        lineHeight="1rem"
                      >
                        Reviewing
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
              )
            )}
          </Box>
        </Box>
        <Typography
          paddingRight="1rem"
          color="textColors.primaryText"
          fontWeight="400"
          lineHeight="1.5rem"
        >
          {status === "errored" ? (
            <div className="text-gray-400">
              There was an error generating this note.
            </div>
          ) : (
            truncateTextByCharCount(summary)
          )}
        </Typography>
        {status === "errored" &&
          error_message &&
          getErrorMessage(error_message, note_id)}
      </Box>
      {loading && <FullScreenLoader />}
    </Box>
  );
};
