import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { Patient } from "../types/types";
import APIService from "../services/APIService";
import { useUser } from "./user";
import { useSnackbar } from "notistack";
import { capitalize } from "../utils/utils";

interface PatientDataContextType {
  patient: Patient | null;
  patientModulesData: any;
  isLoading: boolean;
  isLoadingModules: boolean;
  isTutorialMode: boolean;
  mockPatient: Patient | null;
  fetchPatient: (patientId: string) => Promise<void>;
  fetchPatientData: (patientId: string) => Promise<void>;
  setTutorialMode: (isActive: boolean) => void;
  setIsLoadingModules: (isLoading: boolean) => void;
  setPatient: (patient: Patient) => void;
}

const PatientDataContext = createContext<PatientDataContextType | undefined>(
  undefined
);

export const usePatientData = () => {
  const context = useContext(PatientDataContext);
  if (context === undefined) {
    throw new Error("usePatientData must be used within a PatientDataProvider");
  }
  return context;
};

interface PatientDataProviderProps {
  children: ReactNode;
}

export const PatientDataProvider: React.FC<PatientDataProviderProps> = ({
  children,
}) => {
  const { getAccessToken, subjectLanguage } = useUser();
  const { enqueueSnackbar } = useSnackbar();

  const [patient, setPatientState] = useState<Patient | null>(null);
  const [patientModulesData, setPatientModulesData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModules, setIsLoadingModules] = useState(true);
  const [isTutorialMode, setIsTutorialMode] = useState(false);
  const [mockPatient, setMockPatient] = useState<Patient | null>(null);

  // Add refs to track in-flight requests
  const pendingPatientRequests = useRef<Record<string, boolean>>({});
  const pendingModuleRequests = useRef<Record<string, boolean>>({});

  const setPatient = useCallback(
    (updatedPatient: Patient) => {
      setPatientState(updatedPatient);

      if (isTutorialMode) {
        setMockPatient(updatedPatient);
      }
    },
    [isTutorialMode]
  );

  const fetchPatient = useCallback(
    async (patientId: string) => {
      if (isTutorialMode && mockPatient) {
        setPatientState(mockPatient);
        setIsLoading(false);
        return;
      }

      // Skip if we already have a request in flight for this patient ID
      if (pendingPatientRequests.current[patientId]) {
        console.log(`Skipping duplicate patient fetch for ${patientId}`);
        return;
      }

      pendingPatientRequests.current[patientId] = true;
      setIsLoading(true);
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/patient/get?patient_id=${patientId}&origin=PatientDetailView`,
          accessToken,
        });

        if (response.ok) {
          const data = await response.value;
          setPatientState(data.patient);
        } else {
          throw new Error(`Failed to fetch ${subjectLanguage}`);
        }
      } catch (error) {
        console.error("Error fetching patient:", error);
        enqueueSnackbar(`Failed to load ${subjectLanguage} data`, {
          variant: "error",
        });
      } finally {
        setIsLoading(false);
        // Clear the in-flight status
        delete pendingPatientRequests.current[patientId];
      }
    },
    [
      getAccessToken,
      subjectLanguage,
      enqueueSnackbar,
      isTutorialMode,
      mockPatient,
    ]
  );

  const fetchPatientData = useCallback(
    async (patientId: string) => {
      if (isTutorialMode) {
        const mockModuleData = createMockPatientModules();
        setPatientModulesData(mockModuleData);
        setIsLoadingModules(false);
        return;
      }

      // Skip if we already have a request in flight for this patient ID
      if (pendingModuleRequests.current[patientId]) {
        console.log(`Skipping duplicate patient data fetch for ${patientId}`);
        return;
      }

      pendingModuleRequests.current[patientId] = true;
      setIsLoadingModules(true);
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/patient/getPatientData?patient_id=${patientId}`,
          accessToken,
        });

        if (response.ok) {
          const data = await response.value;
          setPatientModulesData(data.patient_data.patient_module_data);
        } else {
          throw new Error(`Failed to fetch ${subjectLanguage} modules data`);
        }
      } catch (error) {
        console.error("Error fetching patient modules data:", error);
        enqueueSnackbar(`Failed to load ${subjectLanguage} modules data`, {
          variant: "error",
        });
      } finally {
        setIsLoadingModules(false);
        // Clear the in-flight status
        delete pendingModuleRequests.current[patientId];
      }
    },
    [getAccessToken, subjectLanguage, enqueueSnackbar, isTutorialMode]
  );

  const setTutorialMode = useCallback((isActive: boolean) => {
    console.log("Setting patient tutorial mode to:", isActive);
    if (isActive) {
      const mockData = createMockPatient();
      const mockModuleData = createMockPatientModules();

      setMockPatient(mockData);
      setPatientState(mockData);
      setPatientModulesData(mockModuleData);
      setIsLoading(false);
      setIsLoadingModules(false);
    }
    setIsTutorialMode(isActive);
  }, []);

  const createMockPatient = (): Patient => {
    return {
      patient_id: "tutorial-patient-123",
      first_name: "Alex",
      last_name: "Tutorial",
      dob: "1985-06-15",
      gender: "nonbinary",
      email: "alex.tutorial@example.com",
      phone: "555-123-4567",
      address: "123 Main St",
      city: "San Francisco",
      state: "CA",
      zip: "94105",
      insurance_policy_number: "TUT-12345",
      payer_name: "TutorialHealth Insurance",
      updated_at: new Date().toISOString(),
    };
  };

  const createMockPatientModules = () => {
    return {
      medications_block: {
        name: "medications",
        type: "table",
        current_data: {
          rows: [
            {
              id: "med1",
              name: "Lisinopril",
              dosage: "10mg",
              frequency: "Once daily",
              notes: "For hypertension",
              active: true,
              start_date: "2022-08-15",
            },
            {
              id: "med2",
              name: "Escitalopram",
              dosage: "10mg",
              frequency: "Once daily",
              notes: "For anxiety",
              active: true,
              start_date: "2023-01-10",
            },
            {
              id: "med3",
              name: "Trazodone",
              dosage: "50mg",
              frequency: "Once at bedtime",
              notes: "For insomnia",
              active: true,
              start_date: "2023-04-20",
            },
          ],
        },
        current_version: 2,
        last_approved_data: {
          rows: [
            {
              id: "med1",
              name: "Lisinopril",
              dosage: "10mg",
              frequency: "Once daily",
              notes: "For hypertension",
              active: true,
              start_date: "2022-08-15",
            },
            {
              id: "med2",
              name: "Escitalopram",
              dosage: "10mg",
              frequency: "Once daily",
              notes: "For anxiety",
              active: true,
              start_date: "2023-01-10",
            },
          ],
        },
        differentials: {
          iterable_item_added: {
            "root['rows'][2]": {
              id: "med3",
              name: "Trazodone",
              dosage: "50mg",
              frequency: "Once at bedtime",
              notes: "For insomnia",
              active: true,
              start_date: "2023-04-20",
            },
          },
        },
        last_approved_version: 1,
        last_updated: new Date().toISOString(),
        patient_module_id: "mock-medications",
        schema: [
          {
            name: "name",
            description: "Medication",
            required: true,
            type: "text",
          },
          {
            name: "dosage",
            description: "Dosage",
            required: true,
            type: "text",
          },
          {
            name: "frequency",
            description: "Frequency",
            required: true,
            type: "text",
          },
          {
            name: "notes",
            description: "Notes",
            required: false,
            type: "text",
          },
          {
            name: "active",
            description: "Active",
            required: true,
            type: "boolean",
          },
          {
            name: "start_date",
            description: "Start Date",
            required: true,
            type: "date",
          },
        ],
        data: {
          rows: [
            {
              id: "med1",
              name: "Lisinopril",
              dosage: "10mg",
              frequency: "Once daily",
              notes: "For hypertension",
              active: true,
              start_date: "2022-08-15",
            },
            {
              id: "med2",
              name: "Escitalopram",
              dosage: "10mg",
              frequency: "Once daily",
              notes: "For anxiety",
              active: true,
              start_date: "2023-01-10",
            },
            {
              id: "med3",
              name: "Trazodone",
              dosage: "50mg",
              frequency: "Once at bedtime",
              notes: "For insomnia",
              active: true,
              start_date: "2023-04-20",
            },
          ],
          columns: [
            { id: "name", label: "Medication", type: "text" },
            { id: "dosage", label: "Dosage", type: "text" },
            { id: "frequency", label: "Frequency", type: "text" },
            { id: "notes", label: "Notes", type: "text" },
            { id: "active", label: "Active", type: "boolean" },
            { id: "start_date", label: "Start Date", type: "date" },
          ],
        },
      },
      diagnoses_block: {
        name: "diagnoses",
        type: "table",
        current_data: {
          rows: [
            {
              id: "diag1",
              name: "Generalized Anxiety Disorder",
              code: "F41.1",
              status: "Active",
              onset_date: "2023-01-05",
              notes: "Moderate symptoms",
            },
            {
              id: "diag2",
              name: "Essential (primary) hypertension",
              code: "I10",
              status: "Active",
              onset_date: "2022-07-12",
              notes: "Well controlled with medication",
            },
          ],
        },
        current_version: 1,
        last_approved_data: {
          rows: [
            {
              id: "diag1",
              name: "Generalized Anxiety Disorder",
              code: "F41.1",
              status: "Active",
              onset_date: "2023-01-05",
              notes: "Moderate symptoms",
            },
            {
              id: "diag2",
              name: "Essential (primary) hypertension",
              code: "I10",
              status: "Active",
              onset_date: "2022-07-12",
              notes: "Well controlled with medication",
            },
          ],
        },
        last_approved_version: 1,
        last_updated: new Date().toISOString(),
        patient_module_id: "mock-diagnoses",
        schema: [
          {
            name: "name",
            description: "Diagnosis",
            required: true,
            type: "text",
          },
          {
            name: "code",
            description: "ICD-10",
            required: false,
            type: "text",
          },
          {
            name: "status",
            description: "Status",
            required: true,
            type: "text",
          },
          {
            name: "onset_date",
            description: "Onset Date",
            required: false,
            type: "date",
          },
          {
            name: "notes",
            description: "Notes",
            required: false,
            type: "text",
          },
        ],
        data: {
          rows: [
            {
              id: "diag1",
              name: "Generalized Anxiety Disorder",
              code: "F41.1",
              status: "Active",
              onset_date: "2023-01-05",
              notes: "Moderate symptoms",
            },
            {
              id: "diag2",
              name: "Essential (primary) hypertension",
              code: "I10",
              status: "Active",
              onset_date: "2022-07-12",
              notes: "Well controlled with medication",
            },
          ],
          columns: [
            { id: "name", label: "Diagnosis", type: "text" },
            { id: "code", label: "ICD-10", type: "text" },
            { id: "status", label: "Status", type: "text" },
            { id: "onset_date", label: "Onset Date", type: "date" },
            { id: "notes", label: "Notes", type: "text" },
          ],
        },
      },
      history_block: {
        name: "medical history",
        type: "text",
        current_data: {
          content:
            "Patient has a history of anxiety since college. Hypertension diagnosed in 2022. No surgical history. Family history of cardiovascular disease (father) and anxiety disorders (mother). No tobacco use. Occasional alcohol use (1-2 drinks per week). Regular exercise 3 times per week.",
        },
        current_version: 1,
        last_approved_data: {
          content:
            "Patient has a history of anxiety since college. Hypertension diagnosed in 2022. No surgical history. Family history of cardiovascular disease (father) and anxiety disorders (mother). No tobacco use. Occasional alcohol use (1-2 drinks per week). Regular exercise 3 times per week.",
        },
        last_approved_version: 1,
        last_updated: new Date().toISOString(),
        patient_module_id: "mock-history",
        schema: [{ name: "content", type: "text" }],
        data: {
          content:
            "Patient has a history of anxiety since college. Hypertension diagnosed in 2022. No surgical history. Family history of cardiovascular disease (father) and anxiety disorders (mother). No tobacco use. Occasional alcohol use (1-2 drinks per week). Regular exercise 3 times per week.",
        },
      },
    };
  };

  const setIsLoadingModulesMemoized = useCallback((isLoading: boolean) => {
    setIsLoadingModules(isLoading);
  }, []);

  const contextValue: PatientDataContextType = {
    patient,
    patientModulesData,
    isLoading,
    isLoadingModules,
    isTutorialMode,
    mockPatient,
    fetchPatient,
    fetchPatientData,
    setTutorialMode,
    setIsLoadingModules: setIsLoadingModulesMemoized,
    setPatient,
  };

  return (
    <PatientDataContext.Provider value={contextValue}>
      {children}
    </PatientDataContext.Provider>
  );
};
