import { AdminUser } from "../../types/types";
import { UserInfo } from "./UserInfo";
import { UserRow } from "./UserRow";

interface UsersListProps {
  usersList: AdminUser[];
}

export const UserList = ({ usersList }: UsersListProps) => {
  // const { data: users, isLoading } = useQuery("users", fetchUsers);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  return (
    <table className="table-auto w-full border-collapse border-2 border-gray-500">
      <thead className="sticky top-0">
        <tr className="bg-blue-200">
          <th className="px-4 py-2 border-2 border-gray-400">id</th>
          <th className="px-4 py-2 border-2 border-gray-400">Name</th>
          <th className="px-4 py-2 border-2 border-gray-400">Last Login</th>
          <th className="px-4 py-2 border-2 border-gray-400">Logins</th>
          <th className="px-4 py-2 border-2 border-gray-400">Notes</th>
          <th className="px-4 py-2 border-2 border-gray-400">Templates</th>
          <th className="px-4 py-2 border-2 border-gray-400">Beta</th>
          <th className="px-4 py-2 border-2 border-gray-400">Cohort</th>
          <th className="px-4 py-2 border-2 border-gray-400">Features</th>
          <th className="px-4 py-2 border-2 border-gray-400">Payment Status</th>
        </tr>
      </thead>
      <tbody>
        {usersList.map((user) => (
          <UserRow key={user._id} user={user} />
        ))}
      </tbody>
    </table>
  );
};
