import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Grid,
  Button,
  Divider,
} from "@mui/material";
import { Patient } from "../../types/types";
import PatientPicker from "../Patient/PatientPicker";
import { useEncounter } from "./EncounterContext";
import PatientOverviewBlock from "../Patient/Blocks/PatientOverviewBlock";
import { PastVisitSummary } from "../PastVisitSummary";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { capitalize } from "../../utils/utils";
import FormsListBlock from "../Patient/Blocks/FormsListBlock";
import LastEncounterBlock from "../Patient/Blocks/LastEncounterBlock";
import TreatmentPlan from "../Patient/Blocks/TreatmentPlanBlock";
import TreatmentPlanBlock from "../Patient/Blocks/TreatmentPlanBlock";

interface EncounterPatientOverviewProps {
  patient_id?: string | null;
  isViewOnly?: boolean;
}

const EncounterPatientOverview: React.FC<EncounterPatientOverviewProps> = ({
  patient_id,
  isViewOnly = false,
}) => {
  const { getAccessToken, subjectLanguage, userState } = useUser();
  const { dispatch, state } = useEncounter();
  const [patient, setPatient] = useState<Patient | undefined>(undefined);
  const [noteTitle, setNoteTitle] = useState(state.note_title || "");
  const subjectText = subjectLanguage?.toLowerCase() || "patient";

  useEffect(() => {
    setNoteTitle(state.note_title || "");
  }, [state.note_title]);

  useEffect(() => {
    if (patient_id) {
      fetchPatient();
    } else {
      setPatient(undefined);
    }
  }, [patient_id]);

  const fetchPatient = async () => {
    const accessToken = await getAccessToken();
    const response = await APIService.makeAPIGetRequest({
      requestString: `/patient/get?patient_id=${patient_id}&origin=EncounterPatientOverview`,
      accessToken,
    });
    if (response.ok) {
      const data = await response.value;
      setPatient(data.patient);
    }
  };

  const handlePatientSelect = (patient: Patient | undefined) => {
    if (!patient) return; // Only handle when a patient is selected

    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: {
        patient_id: patient.patient_id,
        patient: patient,
        // Clear note title if patient is selected
        note_title: null,
      },
    });
    // Clear note title input when patient selected
    setNoteTitle("");
  };

  const handlePatientUpdate = (updatedPatient: Patient) => {
    dispatch({
      type: "UPDATE_ENCOUNTER",
      payload: {
        patient_id: updatedPatient.patient_id,
      },
    });
    setPatient(updatedPatient);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setNoteTitle(value);
  };

  const handleTitleSubmit = () => {
    if (noteTitle.trim()) {
      dispatch({
        type: "UPDATE_ENCOUNTER",
        payload: {
          note_title: noteTitle.trim(),
          // Clear patient if note title is used
          patient_id: null,
          patient: null,
        },
      });
    }
  };

  const handleTitleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleTitleSubmit();
    }
  };

  // When a patient is selected and loaded, show the patient overview
  if (patient && patient_id) {
    return (
      <Box sx={{ width: "100%", overflow: "auto" }}>
        <PatientOverviewBlock
          patient={patient}
          onPatientUpdate={handlePatientUpdate}
          mode="dense"
          linkToPatient={true}
        />
        {!isViewOnly && (
          <>
            <Box sx={{ mt: 2 }}>
              {/* <PastVisitSummary patientId={patient.patient_id} /> */}
              <LastEncounterBlock patientId={patient.patient_id} />
            </Box>
            {userState?.featureFlags?.treatment_plan && (
              <Box sx={{ mt: 2 }}>
                <TreatmentPlanBlock
                  patientId={patient.patient_id}
                  defaultCollapsed={true}
                />
              </Box>
            )}
            {userState?.featureFlags?.forms && (
              <Box sx={{ mt: 2 }}>
                <FormsListBlock
                  patientId={patient.patient_id}
                  patient={patient}
                  defaultCollapsed={true}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    );
  }

  // Show skeleton loader for the patient overview block
  return (
    <Box sx={{ width: "100%", overflow: "auto" }}>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Box
            sx={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              bgcolor: "grey.200",
              mr: 2,
            }}
          />
          <Box sx={{ width: "70%" }}>
            <Box
              sx={{
                height: 20,
                width: "80%",
                bgcolor: "grey.200",
                mb: 1,
                borderRadius: 1,
              }}
            />
            <Box
              sx={{
                height: 16,
                width: "60%",
                bgcolor: "grey.200",
                borderRadius: 1,
              }}
            />
          </Box>
        </Box>
        <Box sx={{ mt: 2 }}>
          {[...Array(3)].map((_, i) => (
            <Box key={i} sx={{ mb: 2 }}>
              <Box
                sx={{
                  height: 14,
                  width: "40%",
                  bgcolor: "grey.200",
                  mb: 1,
                  borderRadius: 1,
                }}
              />
              <Box
                sx={{
                  height: 16,
                  width: "100%",
                  bgcolor: "grey.200",
                  borderRadius: 1,
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default EncounterPatientOverview;
