import React, { useState, useCallback, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  IconButton,
  TextField,
  Collapse,
  Paper,
  Card,
  Button,
  Zoom,
} from "@mui/material";
import { ThumbUp, ThumbDown } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import APIService from "../../services/APIService";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../../context/user";

interface FeatureFeedbackProps {
  featureId: string;
  prompt?: string;
  show?: boolean;
  onFeedbackComplete?: () => void;
  context?: Record<string, any>;
  mountsUntilShow?: number;
  percentToShow?: number;
}

interface FeedbackPayload {
  feedbackSessionId: string;
  featureId: string;
  type: "rating" | "comment" | "scale";
  rating?: boolean;
  comment?: string;
  scale?: number;
  context?: Record<string, any>;
}

const ThumbButton = styled(IconButton)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

const ThumbButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexShrink: 0,
  marginLeft: theme.spacing(2),
}));

export const FeatureFeedback: React.FC<FeatureFeedbackProps> = ({
  featureId,
  prompt = "Did you find this feature useful?",
  show: forceShow = true,
  onFeedbackComplete,
  context,
  mountsUntilShow = 0,
  percentToShow = 100,
}) => {
  const { getAccessToken } = useUser();
  const [feedbackSessionId] = useState(() => uuidv4());
  const [isLiked, setIsLiked] = useState<boolean | null>(null);
  const [comment, setComment] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const storageKey = `feedback_${featureId}`;
    const mountCountKey = `${storageKey}_mounts`;
    const submittedKey = `${storageKey}_submitted`;

    // Check if feedback was already submitted
    const hasFeedbackBeenSubmitted =
      localStorage.getItem(submittedKey) === "true";
    if (hasFeedbackBeenSubmitted) {
      return;
    }

    // Increment mount count
    const mountCount = parseInt(localStorage.getItem(mountCountKey) || "0");
    localStorage.setItem(mountCountKey, String(mountCount + 1));

    // Determine if we should show the feedback
    if (mountCount >= mountsUntilShow) {
      const randomPercent = Math.random() * 100;
      setShouldShow(randomPercent <= percentToShow);
    }
  }, [featureId, mountsUntilShow, percentToShow]);

  const handleSubmitComplete = async () => {
    setShowThankYou(true);
    // Wait for thank you message to show briefly before closing
    setTimeout(() => {
      setIsSubmitted(true);
      onFeedbackComplete?.();
    }, 1000);
    localStorage.setItem(`feedback_${featureId}_submitted`, "true");
  };

  const handleCancel = () => {
    setIsSubmitted(true);
    localStorage.setItem(`feedback_${featureId}_submitted`, "true");
    onFeedbackComplete?.();
  };

  const submitFeedback = async (payload: FeedbackPayload) => {
    console.log("Submitting feedback payload:", {
      ...payload,
      context,
    });
    const response = await APIService.makeAPIPostRequest({
      requestString: "/feedback/featureFeedback",
      accessToken: await getAccessToken(),
      body: { ...payload, context },
    });

    if (response.ok) {
      console.log("Feedback submitted successfully");
    } else {
      console.error("Failed to submit feedback:", response.error);
    }

    // TODO: Implement API call
  };

  const handleFeedback = async (liked: boolean) => {
    setIsLiked(liked);
    await submitFeedback({
      feedbackSessionId,
      featureId,
      type: "rating",
      rating: liked,
    });
  };

  const handleCommentSubmit = async () => {
    if (isLiked === null) return;
    await submitFeedback({
      feedbackSessionId,
      featureId,
      type: "comment",
      comment,
      rating: isLiked,
    });
    handleSubmitComplete();
  };

  // Only show if both forceShow (the show prop) and shouldShow are true
  const isVisible = forceShow && shouldShow && !isSubmitted;

  return (
    <Zoom in={isVisible} timeout={250}>
      <Card
        sx={{
          position: isVisible ? "relative" : "absolute",
          visibility: isVisible ? "visible" : "hidden",
          mb: isVisible ? 8 : 0,
          width: "100%",
          maxWidth: "80%",
          borderRadius: 3,
          bgcolor: "backgroundColors.primary",
          borderColor: "borderColors.secondary",
          border: "0.5px solid",
          boxShadow: "none",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)",
          p: 2,
          animation:
            forceShow && !isSubmitted
              ? "wiggle 0.5s ease-in-out 0.25s"
              : "none",
        }}
      >
        <Collapse in={!showThankYou}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Typography
              variant="body2"
              sx={{
                flexGrow: 1,
                pr: 1,
              }}
            >
              {prompt}
            </Typography>
            {isLiked === null && (
              <ThumbButtonContainer>
                <ThumbButton
                  color="primary"
                  onClick={() => handleFeedback(true)}
                  aria-label="Yes, this was useful"
                >
                  <ThumbUp />
                </ThumbButton>
                <ThumbButton
                  color="primary"
                  onClick={() => handleFeedback(false)}
                  aria-label="No, this wasn't useful"
                >
                  <ThumbDown />
                </ThumbButton>
              </ThumbButtonContainer>
            )}
          </Box>

          <Collapse in={isLiked !== null}>
            <Box sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={2}
                placeholder="Any additional feedback? (optional)"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleCommentSubmit();
                  }
                }}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  onClick={handleCancel}
                  sx={{ color: "text.secondary" }}
                >
                  No thanks
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={handleCommentSubmit}
                >
                  Submit feedback
                </Button>
              </Box>
            </Box>
          </Collapse>
        </Collapse>

        <Collapse in={showThankYou}>
          <Box sx={{ textAlign: "center", py: 1 }}>
            <Typography variant="body1">Thanks for your feedback!</Typography>
          </Box>
        </Collapse>
      </Card>
    </Zoom>
  );
};
