import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  Grow,
  Chip,
  useTheme,
  Grid,
  Button,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Description as DocumentIcon,
  Article as ArticleIcon,
  Assignment as AssignmentIcon,
  MedicalServices as MedicalIcon,
  Psychology as PsychologyIcon,
  Launch,
} from "@mui/icons-material";
import { useEncounter } from "./EncounterContext";
import BaseBlock from "../Patient/Blocks/BaseBlock";
import { formatDate } from "../../utils/utils";
import SlideSidePane from "../../Views/Shared/SlideSidePane";
import { NoteSectionDisplayBox } from "../Note/NoteSectionDisplayBox";
import { NoteMetadata } from "../NoteMetadata";
import { NoteDataType, Patient } from "../../types/types";
import APIService from "../../services/APIService";
import { useUser } from "../../context/user";
import { useNavigate } from "react-router-dom";
import JotDocViewer, { JotDocDataType } from "../Markdown/JotDocViewer";

interface EncounterOutputsBlockProps {
  patient?: Patient | null;
}

const EncounterOutputsBlock: React.FC<EncounterOutputsBlockProps> = ({
  patient,
}) => {
  const { state } = useEncounter();
  const theme = useTheme();
  const { getAccessToken } = useUser();
  const navigate = useNavigate();
  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const [noteData, setNoteData] = useState<NoteDataType | null>(null);
  const [jotdocData, setJotdocData] = useState<JotDocDataType | null>(null);
  const [currentDocType, setCurrentDocType] = useState<
    "note" | "jotdoc" | null
  >(null);

  // Debug log to help troubleshoot output rendering
  useEffect(() => {
    if (state.outputs && state.outputs.length > 0) {
      console.log("Rendering outputs:", state.outputs);
    }
  }, [state.outputs]);

  const fetchNoteContent = useCallback(
    async (noteId: string) => {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/notes/${noteId}`,
          accessToken,
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch note: ${response.error?.message}`);
        }

        setNoteData(response.value);
        setJotdocData(null);
        setCurrentDocType("note");
      } catch (error) {
        console.error("Error fetching note content:", error);
        setNoteData(null);
      }
    },
    [getAccessToken]
  );

  const fetchJotdocContent = useCallback(
    async (jotdocId: string) => {
      try {
        const accessToken = await getAccessToken();
        const response = await APIService.makeAPIGetRequest({
          requestString: `/jotdoc/getJotDoc`,
          params: { jotdoc_id: jotdocId },
          accessToken,
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch jotdoc: ${response.error?.message}`);
        }

        setJotdocData(response.value.jotdoc);
        setNoteData(null);
        setCurrentDocType("jotdoc");
      } catch (error) {
        console.error("Error fetching jotdoc content:", error);
        setJotdocData(null);
      }
    },
    [getAccessToken]
  );

  const handleViewDocument = useCallback(
    (output: any) => {
      if (
        output.encounter_document_id &&
        output.type?.toLowerCase() === "note"
      ) {
        fetchNoteContent(output.encounter_document_id);
        setIsPaneOpen(true);
      } else if (output.jotdoc_id) {
        fetchJotdocContent(output.jotdoc_id);
        setIsPaneOpen(true);
      }
    },
    [fetchNoteContent, fetchJotdocContent]
  );

  // If there are no outputs, don't render anything
  if (!state.outputs || state.outputs.length === 0) {
    return (
      <BaseBlock title="Encounter Outputs">
        <Box sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="body1" color="text.secondary">
            {state.status === "completed"
              ? "No outputs available for this encounter."
              : state.status === "processing"
              ? "Processing encounter... Outputs will appear here when ready."
              : "Outputs will appear here after the encounter is submitted."}
          </Typography>
        </Box>
      </BaseBlock>
    );
  }

  const formatOutputDate = (date: Date) => {
    try {
      // Convert UTC date to local timezone
      const timestamp = new Date(date).getTime();
      if (!isFinite(timestamp)) return "Invalid date";

      // Create a date object in local timezone
      const localDate = new Date(timestamp);

      // Calculate days difference using local time
      const days = Math.ceil(
        (Date.now() - localDate.getTime()) / (1000 * 60 * 60 * 24)
      );
      if (!isFinite(days)) return "Invalid date";

      return new Intl.RelativeTimeFormat("en", { numeric: "auto" })
        .format(-days, "day")
        .replace("in ", "");
    } catch (e) {
      return "Invalid date";
    }
  };

  // Helper function to get icon based on output type
  const getIconAndColor = (type: string) => {
    switch (type?.toLowerCase()) {
      case "note":
        return { icon: <ArticleIcon />, color: "#2196f3" }; // Blue for notes
      case "progress_note":
        return { icon: <AssignmentIcon />, color: "#ff9800" }; // Orange for progress notes
      case "jotdoc":
        return { icon: <DocumentIcon />, color: "#4caf50" }; // Green for jotdocs
      default:
        return { icon: <DocumentIcon />, color: "#9c27b0" }; // Purple for other types
    }
  };

  return (
    <>
      <BaseBlock title="Created Documents">
        <Grid container spacing={2} sx={{ mb: 1 }}>
          {state.outputs.map((output, index) => {
            const { icon, color } = getIconAndColor(output.type || "default");
            return (
              <Grid item xs={12} sm={6} md={4} key={output.id || index}>
                <Grow in={true} timeout={300 + index * 100}>
                  <Paper
                    sx={{
                      p: 2,
                      backgroundColor: theme.palette.background.paper,
                      border: 1,
                      borderRadius: "0.75rem",
                      borderColor: "borderColors.primary",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      cursor:
                        (output.type?.toLowerCase() === "note" &&
                          output.encounter_document_id) ||
                        output.jotdoc_id
                          ? "pointer"
                          : "default",
                      transition: "all 0.2s",
                      "&:hover": {
                        bgcolor: "action.hover",
                        transform: "translateY(-2px)",
                        boxShadow: 1,
                      },
                    }}
                    onClick={() => {
                      if (
                        (output.type?.toLowerCase() === "note" &&
                          output.encounter_document_id) ||
                        output.jotdoc_id
                      ) {
                        handleViewDocument(output);
                      }
                    }}
                  >
                    <Stack spacing={1} height="100%">
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Box sx={{ color }}>{icon}</Box>
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="subtitle2">
                            {output.title || `Encounter Note`}
                          </Typography>
                          {output.created_at && (
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {formatOutputDate(new Date(output.created_at))}
                            </Typography>
                          )}
                        </Box>
                      </Stack>

                      <Box sx={{ flex: 1 }}>
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {output.description || ""}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          mt: "auto",
                          pt: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="caption"
                          color="primary"
                          sx={{ fontStyle: "italic" }}
                        >
                          Click to view
                        </Typography>
                      </Box>
                    </Stack>
                  </Paper>
                </Grow>
              </Grid>
            );
          })}
        </Grid>
      </BaseBlock>

      <SlideSidePane
        open={isPaneOpen}
        onClose={() => setIsPaneOpen(false)}
        title={
          currentDocType === "note"
            ? "Encounter Note"
            : jotdocData?.title || "Document"
        }
      >
        {currentDocType === "note" && noteData ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4">{noteData?.note_title}</Typography>
              <Tooltip title="Go to Note">
                <IconButton
                  onClick={() => navigate(`/note/${noteData?.note_id}`)}
                >
                  <Launch />
                </IconButton>
              </Tooltip>
            </Box>
            {noteData && (
              <NoteMetadata
                noteData={noteData}
                patient={patient ?? undefined}
              />
            )}
            {noteData?.content.sections.map((section) => (
              <Box key={section.module_id}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                  {section.name?.replace(/\s*\([^)]*\)/g, "")}
                </Typography>
                <NoteSectionDisplayBox isNA={section.text === "N/A"}>
                  {section.text}
                </NoteSectionDisplayBox>
              </Box>
            ))}
          </Box>
        ) : currentDocType === "jotdoc" && jotdocData ? (
          <JotDocViewer jotdoc={jotdocData} />
        ) : (
          <Box sx={{ p: 2, textAlign: "center" }}>
            <Typography variant="body1" color="text.secondary">
              Loading document content...
            </Typography>
          </Box>
        )}
      </SlideSidePane>
    </>
  );
};

export default EncounterOutputsBlock;
